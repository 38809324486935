import { createLogic } from 'redux-logic';
// features
import { receivedSwitchBranchSuccess } from '../branch/actions';
// helpers/constants
import * as G from '../../helpers';
// feature user
import { assignRolesSuccess, revokeRolesSuccess, clearUsersStateAfterSwitchBranch } from './actions';
//////////////////////////////////////////////////

export const handleSwitchBranchLogic = createLogic({
  type: receivedSwitchBranchSuccess,
  validate({ getState, action }: Object, allow: void) {
    allow(action);
  },
  process({ getState, action }: Object, dispatch: void, done: void) {
    dispatch(clearUsersStateAfterSwitchBranch());
    done();
  },
});

const handleAssignRolesSuccess = createLogic({
  type: [
    assignRolesSuccess,
    revokeRolesSuccess,
  ],
  validate({ action, getState }: Object, allow: void) {
    G.showToastrMessageFromLocale('info', 'message:assign-revoke-user-roles');
    allow(action);
  },
});

export default [
  handleSwitchBranchLogic,
  handleAssignRolesSuccess,
];
