import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { Fragment } from 'react';
import { compose } from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// components
import { FormGroupTitleComponent } from '../../../components/form-group-title';
// features
import { withGeoFencingZone, GeoFencingZoneTable } from '../../geo-fencing-zone/rating';
import { IntegrationInfo, IntegrationAudit } from '../../carrier/components/general-tab';
import PayToLocationFormGroupTable from '../../fleet/components/pay-to-location-form-group-table';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box } from '../../../ui';
// feature service-vendor
import { Documents } from './documents';
import PrimaryLocation from './primary-location';
import { makeSelectGeofencingZoneList } from '../selectors';
import {
  deleteGeoFencingZoneRequest,
  toggleGeoFencingZoneDetails,
  createGeoFencingZoneRequest,
} from '../actions';
//////////////////////////////////////////////////

const geofencingZoneMapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectGeofencingZoneList(state),
});

const geoFencingZoneEnhance = compose(
  connect(
    geofencingZoneMapStateToProps,
    {
      deleteGeoFencingZoneRequest,
      createGeoFencingZoneRequest,
      toggleGeoFencingZoneDetails,
    },
  ),
  withGeoFencingZone,
);

const GeofencingZoneFormGroupTable = geoFencingZoneEnhance((props: Object) => {
  const {
    itemList,
    collapsedGroup,
    handleToggleFormGroup,
    handleCreateGeoFencingZone,
  } = props;

  const isEmptyList = G.isNilOrEmpty(itemList);
  const isOpened = R.propOr(true, 'geofencingZone', collapsedGroup);
  const additionalTableSettings = { withoutEmptyList: isEmptyList, checkBoxCellWidth: G.ifElse(isEmptyList, 77, 85) };

  return (
    <Fragment>
      <FormGroupTitleComponent
        mb='0px'
        isOpened={isOpened}
        withArrowDown={true}
        onClickLabel={handleCreateGeoFencingZone}
        endIcon={I.plusRound(G.getTheme('icons.iconColorWhite'))}
        text={G.getWindowLocale('titles:repair-zones', 'Repair Zones')}
        onToggleFormGroup={() => handleToggleFormGroup('geofencingZone')}
      />
      {
        isOpened ?
          <GeoFencingZoneTable {...props} additionalTableSettings={additionalTableSettings} /> :
          <Box height='2px' />
      }
    </Fragment>
  );
});

const GeneralTab = (props: Object) => (
  <Fragment>
    <PayToLocationFormGroupTable
      isServiceVendor={true}
      openModal={props.openModal}
      closeModal={props.closeModal}
      collapsedGroup={props.collapsedGroup}
      handleToggleFormGroup={props.handleToggleFormGroup}
      payToLocation={R.path(['initialValues', GC.SYSTEM_OBJECT_PAY_TO_LOCATION], props)}
      removePayToLocation={() => props.handleSetLocation(null, GC.SYSTEM_OBJECT_PAY_TO_LOCATION)}
      addOrEditPayToLocation={(values: Object) => props.handleSetLocation(values, GC.SYSTEM_OBJECT_PAY_TO_LOCATION)}
    />
    <PrimaryLocation
      openModal={props.openModal}
      closeModal={props.closeModal}
      collapsedGroup={props.collapsedGroup}
      handleSetLocation={props.handleSetLocation}
      handleToggleFormGroup={props.handleToggleFormGroup}
      primaryLocation={R.path(['initialValues', GC.SYSTEM_OBJECT_PRIMARY_LOCATION], props)}
      removePayToLocation={() => props.handleSetLocation(null, GC.SYSTEM_OBJECT_PRIMARY_LOCATION)}
    />
    <GeofencingZoneFormGroupTable
      openModal={props.openModal}
      closeModal={props.closeModal}
      collapsedGroup={props.collapsedGroup}
      handleToggleFormGroup={props.handleToggleFormGroup}
      addTitle={G.getAddTitle(['titles:repair-zone', 'Repair Zone'])}
      editTitle={G.getEditTitle(['titles:repair-zone', 'Repair Zone'])}
    />
    <Documents {...props} />
    <IntegrationInfo
      entities={props.integrationInfoList}
      collapsedGroup={props.collapsedGroup}
      handleToggleFormGroup={props.handleToggleFormGroup}
    />
    <IntegrationAudit
      entities={props.integrationAuditList}
      collapsedGroup={props.collapsedGroup}
      handleToggleFormGroup={props.handleToggleFormGroup}
    />
  </Fragment>
);

export default GeneralTab;
