// constants
import * as GC from '../../../../constants';
import { ENUMS } from '../../../../constants/enums';
// feature template-inspection
import * as C from '../constants';
//////////////////////////////////////////////////

const defaultInspectionTemplate = {
  [GC.FIELD_NAME]: null,
  [GC.FIELD_DESCRIPTION]: null,
  [GC.FIELD_INSPECTION_SECTIONS]: [],
  [GC.FIELD_SIGNATURE_REQUIRED]: false,
  [GC.FIELD_ENABLE_LOCATION_TRACKING]: false,
  nameStatus: C.INSPECTION_NAME_STATUS_FILLED,
  [GC.FIELD_TYPE]: ENUMS.ENUM_INSPECTION_TYPE_GENERAL,
};

const defaultSection = {
  name: null,
  components: [],
  expanded: true,
};

export {
  defaultSection,
  defaultInspectionTemplate,
};
