import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// components
import { PageTitle } from '../../components/page-title';
import { ProgressBar } from '../../components/progress-bar';
import { PageActions } from '../../components/page-actions';
import { ConfirmComponent } from '../../components/confirm';
import { closeModal, openModal } from '../../components/modal/actions';
import {
  TableRow,
  renderRows,
  FixedColumn,
  TableColumn,
  classStyles,
  TableContainer,
  renderFixedRow,
  FixedContentWrapper,
  renderFixedColumnRow,
} from '../../components/list';
// features
import { AuthByCurrentBranch } from '../permission';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { PageWrapper, PageHeaderWrapper } from '../../ui';
// feature sequences
import { IconWrapper } from './ui';
import { makeSelectSequencesList } from './selectors';
import { columnSettings } from './settings/fields-settings';
import SequenceTypeFormComponent from './components/sequence-type';
import {
  deleteSequenceRequest,
  updateSequenceRequest,
  createNewSequenceRequest,
} from './actions';
import {
  getPercentage,
  getProgressColor,
  getTableContainerPaddingLeft,
} from './helpers';
//////////////////////////////////////////////////

export const createDeleteSequencePopup = (props: Object) => (sequence: Object) => {
  const { openModal, closeModal, deleteSequenceRequest } = props;

  const confirmationContent = (
    <ConfirmComponent
      name={sequence.displayName}
      textLocale={G.getWindowLocale('messages:delete-confirmation-text-double', 'Are you sure you want to delete')}
    />
  );
  const modal = {
    component: confirmationContent,
    options: {
      width: 600,
      controlButtons: [
        {
          name: G.getWindowLocale('actions:delete', 'Delete'),
          type: 'button',
          action: () => {
            closeModal();
            deleteSequenceRequest(sequence.guid);
          },
        },
      ],
    },
  };
  openModal(modal);
};

const enhance = compose(
  withHandlers(() => {
    const refs = {};

    return {
      handleDeleteSequence: createDeleteSequencePopup,
      handleAddSequence: ({ openModal, createNewSequenceRequest }: Object) => () => {
        const component = <SequenceTypeFormComponent submitAction={createNewSequenceRequest} />;
        const modal = {
          p: 15,
          component,
          options: {
            width: 340,
            height: 'auto',
            title: G.getWindowLocale('titles:add-sequence', 'Add Sequence'),
          },
        };
        openModal(modal);
      },
      handleEditSequence: ({ openModal, updateSequenceRequest }: Object) => (sequence: Object) => {
        const sequenceTypeProps = {
          initialValues: sequence,
          submitAction: updateSequenceRequest,
        };
        const component = <SequenceTypeFormComponent {...sequenceTypeProps} />;
        const modal = {
          p: 15,
          component,
          options: {
            width: 340,
            height: 'auto',
            title: G.getWindowLocale('titles:edit-sequence', 'Edit Sequence'),
          },
        };
        openModal(modal);
      },
      handleRegisterChild: () => (ref: Object, refName: string) => (
        refs[refName] = ref
      ),
      handleScrollTop: () => (scrollTop: number, refName: string) => (
        refs[refName].scrollTop = scrollTop
      ),
      handleScrollLeft: () => (scrollLeft: number, refName: string) => (
        refs[refName].scrollLeft = scrollLeft
      ),
    };
  }),
  branch(
    (props: Object) => R.not(props.initialDataLoaded),
    renderNothing,
  ),
  pure,
);
const ProgressBarWithTheme = (props: Object) => (
  G.notEquals(props.progress, NaN)
  && (
    <ProgressBar
      isShowProgress={true}
      progress={props.progress}
      progressColor={getProgressColor(props.progress)}
    />
  )
);

export const customRenderTable = (
  entity: Object,
  field: Object,
  index: string,
) => {
  if (R.propEq(GC.FIELD_SEQUENCE_USED, 'nameForAttribute', field)) {
    const progress = G.ifElse(
      R.equals(entity.approxValue, entity.startNum),
      0,
      getPercentage(R.subtract(entity.approxValue, entity.step), entity.endNum),
    );
    return (
      <TableColumn pr='20px' key={index} width={field.width}>
        <ProgressBarWithTheme progress={progress} />
      </TableColumn>
    );
  }
  if (R.propEq(GC.FIELD_SEQUENCE_TYPE, 'nameForAttribute', field)) {
    return (
      <TableColumn pr='20px' key={index} width={field.width}>
        {G.getEnumLocale(R.pathOr(null, [GC.FIELD_SEQUENCE_TYPE], entity))}
      </TableColumn>
    );
  }
};

const customRenderTableColumn = () => <TableColumn width='40px' />;

const tableConfigs = {
  rowHeight: '56px',
  fixedColumnOptions: [],
};

const tableContainerPaddingLeft = getTableContainerPaddingLeft(tableConfigs.fixedColumnOptions);

const fixedRowConfigs = {
  fixedRowFields: columnSettings,
  rowHeight: tableConfigs.rowHeight,
  paddingLeft: tableContainerPaddingLeft,
};

export const renderFixedColumnOptions = (entity: Object) => (
  tableConfigs.fixedColumnOptions.map((option: Object, index: number) => (
    <TableColumn key={index} width={option.width}>
      {R.prop(option.nameForAttribute, entity)}
    </TableColumn>
  ))
);

export const renderSequencesFixedRows = (
  props: Object,
  sequences: Array,
) => (
  sequences.map((sequence: Object) => (
    <TableRow
      py='18px'
      px='15px'
      ta='left'
      key={sequence.guid}
      height={tableConfigs.rowHeight}
    >
      <TableColumn width='20px' pr='0px'>
        <AuthByCurrentBranch
          elementBranchGuid={R.prop(GC.FIELD_BRANCH_GUID, sequence)}
        >
          <IconWrapper onClick={() => props.handleEditSequence(sequence)}>
            {I.pencil(G.getTheme('icons.iconColor'))}
          </IconWrapper>
        </AuthByCurrentBranch>
      </TableColumn>
      <TableColumn width='20px' pr='0px'>
        <AuthByCurrentBranch
          elementBranchGuid={R.prop(GC.FIELD_BRANCH_GUID, sequence)}
        >
          <IconWrapper onClick={() => props.handleDeleteSequence(sequence)}>
            {I.trash(G.getTheme('icons.iconColor'))}
          </IconWrapper>
        </AuthByCurrentBranch>
      </TableColumn>
      {renderFixedColumnOptions(sequence)}
    </TableRow>
  ))
);

export const renderFixedContentWrapper = (props: Object, entities: Array) => (
  <FixedContentWrapper>
    {renderFixedRow(props, fixedRowConfigs)}
    <FixedColumn horizontalPosition='left' distance='0' zIndex='9'>
      <div
        css={classStyles.fixedColumn}
        ref={(ref: Object) => props.handleRegisterChild(ref, 'fixedColumnLeft')}
      >
        {renderFixedColumnRow(tableConfigs, customRenderTableColumn)}
        {
          R.and(
            G.isNotNil(entities),
            renderSequencesFixedRows(props, entities),
          )
        }
      </div>
    </FixedColumn>
  </FixedContentWrapper>
);

export const SequencesListComponent = (props: Object) => {
  const { sequencesList, handleScrollTop, handleScrollLeft, handleAddSequence } = props;

  return (
    <PageWrapper p='15' bgColor={G.getTheme('pages.layOutBgColor')}>
      <PageHeaderWrapper justify='flex-start'>
        <PageTitle
          withCount={true}
          count={G.getOrElse(sequencesList, 'length', 0)}
          title={G.getWindowLocale('titles:sequences', 'Sequences')}
        />
      </PageHeaderWrapper>
      <TableContainer
        pt={tableConfigs.rowHeight}
        pl={G.ifElse(R.isEmpty(sequencesList), 70, tableContainerPaddingLeft)}
        onScroll={({ currentTarget }: object) => {
          handleScrollLeft(currentTarget.scrollLeft, 'fixedRow');
          handleScrollTop(currentTarget.scrollTop, 'fixedColumnLeft');
        }}
      >
        {renderFixedContentWrapper(props, sequencesList)}
        {
          R.and(
            G.isNotNil(sequencesList),
            renderRows(sequencesList, { columnsArr: columnSettings, customRenderTable }),
          )
        }
      </TableContainer>
      <PageActions
        shadowColor={G.getTheme('createButton.shadowColor')}
        mainAction={{
          action: handleAddSequence,
          text: G.getWindowLocale('actions:add-sequence', 'Add Sequence'),
        }}
      />
    </PageWrapper>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  sequencesList: makeSelectSequencesList(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
}));

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  deleteSequenceRequest,
  updateSequenceRequest,
  createNewSequenceRequest,
})(enhance(SequencesListComponent));
