// helpers/constants
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

export const routeTemplateSettings = {
  [GC.FIELD_NAME]: {
    name: 'titles:name',
  },
  [GC.FIELD_BRANCH_NAME]: {
    name: 'titles:branch-name',
  },
  [GC.FIELD_CREATED_DATE]: {
    fieldType: 'date',
    filterType: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_CREATED_BY]: {
    name: 'titles:created-by',
  },
  [GC.FIELD_CLO_COUNT]: {
    filterType: 'number',
    name: 'titles:clo-count',
  },
  [GC.FIELD_TEL_COUNT]: {
    filterType: 'number',
    name: 'titles:tel-count',
  },
  [`${GC.FIELD_CLOS}.${GC.FIELD_BRANCH_NAME}`]: {
    collection: true,
    name: ['titles:clo', 'titles:branch-name'],
  },
  [`${GC.SYSTEM_LIST_TELS}.${GC.FIELD_BRANCH_NAME}`]: {
    collection: true,
    name: ['titles:tels', 'titles:branch-name'],
  },
  [GC.FIELD_ACTIVE]: {
    fieldType: 'boolean',
    filterType: 'boolean',
    name: 'titles:active',
  },
};
