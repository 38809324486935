import * as R from 'ramda';
import { put, call } from 'redux-saga/effects';
// components
import { setAudioPlaying } from '../../../components/audio-player/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature sockets-v2
import * as A from '../actions';
//////////////////////////////////////////////////

function* showNotificationReceivedToaster(data: Object) {
  const warningLevel = R.path(['notification', GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_LEVEL], data);

  const warningLevelsData = {
    [GC.NOTIFICATION_WARNING_LEVEL_TYPE_INFO]: 'info',
    [GC.NOTIFICATION_WARNING_LEVEL_TYPE_CRITICAL]: 'error',
    [GC.NOTIFICATION_WARNING_LEVEL_TYPE_WARNING]: 'warning',
  };

  const text = G.getWindowLocale(
    'messages:new-notification-received',
    'You have a new notification received. Please, check it.',
  );

  yield call(
    G.showToastrMessage,
    R.propOr('info', warningLevel, warningLevelsData),
    text,
    { disableCloseButtonFocus: true },
  );

  const isForTel = R.pathEq(
    GC.NOTIFICATION_OBJECT_TYPE_TEL,
    ['notification', GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJ_TYPE],
    data,
  );

  const isImportant = R.or(
    R.equals(warningLevel, GC.NOTIFICATION_WARNING_LEVEL_TYPE_WARNING),
    R.equals(warningLevel, GC.NOTIFICATION_WARNING_LEVEL_TYPE_CRITICAL),
  );

  if (R.path(['notification', GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_ALARM_ENABLED], data)) {
    yield put(setAudioPlaying(true));
  }

  if (R.and(isForTel, isImportant)) yield put(A.socketTelWarningReceived(data));
}

function* handleNotificationMessageReceived(payload: Object) {
  yield call(showNotificationReceivedToaster, payload);

  yield put(A.updateNotificationsStore(payload));
}

export default handleNotificationMessageReceived;
