import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = { mb: 25, width: 310 };

export const defaultValues = {
  [GC.FIELD_SEQUENCE_TYPE]: '',
  [GC.FIELD_SEQUENCE_STEP]: '',
  [GC.FIELD_SEQUENCE_PREFIX]: '',
  [GC.FIELD_SEQUENCE_SUFFIX]: '',
  [GC.FIELD_SEQUENCE_END_NUMBER]: '',
  [GC.FIELD_SEQUENCE_DISPLAY_NAME]: '',
  [GC.FIELD_SEQUENCE_START_NUMBER]: '',
};

export const validationSchema = {
  [GC.FIELD_SEQUENCE_TYPE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_SEQUENCE_PREFIX]: Yup.string()
    .notRequired()
    .nullable(true)
    .max(32, G.getShouldBeLessOrEqualLocaleTxt(32)),
  [GC.FIELD_SEQUENCE_SUFFIX]: Yup.string()
    .notRequired()
    .nullable(true)
    .max(32, G.getShouldBeLessOrEqualLocaleTxt(32)),
  [GC.FIELD_SEQUENCE_DISPLAY_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(80, G.getShouldBeLessOrEqualLocaleTxt(80)),
  [GC.FIELD_SEQUENCE_END_NUMBER]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .positive(G.getShouldBePositiveLocaleTxt())
    .max(2000000000, G.getShouldBeLessOrEqualLocaleTxt(2000000000))
    .typeError('Should be a ${type}'), // eslint-disable-line
  [GC.FIELD_SEQUENCE_STEP]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .positive(G.getShouldBePositiveLocaleTxt())
    .max(2000000000, G.getShouldBeLessOrEqualLocaleTxt(2000000000))
    .typeError('Should be a ${type}'), // eslint-disable-line
  [GC.FIELD_SEQUENCE_START_NUMBER]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .positive(G.getShouldBePositiveLocaleTxt())
    .max(2000000000, G.getShouldBeLessOrEqualLocaleTxt(2000000000))
    .typeError('Should be a ${type}'), // eslint-disable-line
};

export const DEFAULT_SEQUENCE_FORM_SEQUENCE_TYPE_OPTIONS = [
  GC.EMPTY_OPTION_OBJECT,
  { label: G.getWindowLocale('titles:clo', 'CLO'), value: GC.SEQUENCE_TYPE_CLO },
  { label: G.getWindowLocale('titles:tel', 'TEL'), value: GC.SEQUENCE_TYPE_TEL },
  { label: G.getWindowLocale('titles:route', 'Route'), value: GC.SEQUENCE_TYPE_ROUTE },
  { label: G.getWindowLocale('titles:invoice', 'Invoice'), value: GC.SEQUENCE_TYPE_INVOICE },
  { label: G.getWindowLocale('titles:carrier', 'Carrier'), value: GC.SEQUENCE_TYPE_CARRIER },
  { label: G.getWindowLocale('titles:branch', 'Branch'), value: GC.SEQUENCE_TYPE_BRANCH },
  { label: G.getWindowLocale('titles:templates', 'Templates'), value: GC.SEQUENCE_TYPE_TEMPLATES },
  { label: G.getWindowLocale('titles:task', 'Task'), value: GC.SEQUENCE_TYPE_TASK },
  { label: G.getWindowLocale('titles:claim', 'Claim'), value: GC.SEQUENCE_TYPE_CLAIM },
  { label: G.getWindowLocale('titles:fleet', 'Fleet'), value: GC.SEQUENCE_TYPE_FLEET },
];

export const fieldSettings = [
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_SEQUENCE_TYPE,
    label: ['titles:where-used', 'Where Used'],
    options: DEFAULT_SEQUENCE_FORM_SEQUENCE_TYPE_OPTIONS,
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:name', 'Name'],
    fieldName: GC.FIELD_SEQUENCE_DISPLAY_NAME,
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:prefix', 'Prefix'],
    fieldName: GC.FIELD_SEQUENCE_PREFIX,
  },
  {
    type: 'number',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_SEQUENCE_START_NUMBER,
    label: ['titles:start-number', 'Start Number'],
  },
  {
    type: 'number',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_SEQUENCE_END_NUMBER,
    label: ['titles:end-number', 'End Number'],
  },
  {
    type: 'number',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_SEQUENCE_STEP,
    label: ['titles:increment-by', 'Increment By'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:suffix', 'Suffix'],
    fieldName: GC.FIELD_SEQUENCE_SUFFIX,
  },
];

export const columnSettings = [
  {
    width: 150,
    name: 'titles:branch',
    nameForAttribute: GC.FIELD_BRANCH_NAME,
  },
  {
    width: 100,
    customLogic: true,
    name: 'titles:where-used',
    nameForAttribute: GC.FIELD_SEQUENCE_TYPE,
  },
  {
    width: 150,
    name: 'titles:name',
    nameForAttribute: GC.FIELD_SEQUENCE_DISPLAY_NAME,
  },
  {
    width: 80,
    name: 'titles:prefix',
    nameForAttribute: GC.FIELD_SEQUENCE_PREFIX,
  },
  {
    width: 90,
    name: 'titles:start-number',
    nameForAttribute: GC.FIELD_SEQUENCE_START_NUMBER,
  },
  {
    width: 90,
    name: 'titles:end-number',
    nameForAttribute: GC.FIELD_SEQUENCE_END_NUMBER,
  },
  {
    width: 90,
    name: 'titles:increment-by',
    nameForAttribute: GC.FIELD_SEQUENCE_STEP,
  },
  {
    width: 80,
    name: 'titles:suffix',
    nameForAttribute: GC.FIELD_SEQUENCE_SUFFIX,
  },
  {
    width: 120,
    name: 'titles:approx-value',
    nameForAttribute: GC.FIELD_SEQUENCE_APPROX_VALUE,
  },
  {
    width: 80,
    customLogic: true,
    name: 'titles:used',
    nameForAttribute: GC.FIELD_SEQUENCE_USED,
  },
];
