import * as R from 'ramda';
import React, { memo } from 'react';
import styled from 'styled-components';
// features
import { Avatar } from '../../../profile/components/profile-photo';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { Box, Flex } from '../../../../ui';
//////////////////////////////////////////////////

const getBorder = ({ theme, isDragging }: Object) => G.ifElse(
  isDragging,
  `2px dashed ${R.path(['colors', '#E4E4E4'], theme)}`,
  '2px solid transparent',
);

const Container = styled.div`
  padding: 8px;
  min-height: 40px;
  user-select: none;
  margin-bottom: 8px;
  border-radius: 8px;
  border: ${getBorder};
  background-color: #FAFAFB;
  color: ${R.path(['theme', 'colors', 'black'])};
`;

const TaskBoardItem = (props: Object) => {
  const {
    index,
    assignee,
    provided,
    isDragging,
    handleCreateOrUpdateTask,
    task: { guid, title, dueDate, taskNumber, objectType, objectName },
  } = props;

  const getDueDateBackgroundAndColor = () => {
    const currentDate = G.getCurrentDay();

    if (G.isAfter(dueDate, currentDate)) return { color: '#7D828C', bg: 'transparent' };

    if (G.isBefore(dueDate, currentDate)) return { color: 'white', bg: 'middleDarkOrange' };

    return { color: 'black', bg: 'light.yellow' };
  };

  const className = 'task_board_item';

  return (
    <Container
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      p='8px'
      mb='8px'
      minHeight={40}
      userSelect='none'
      borderRadius='8px'
      data-index={index}
      data-testid={guid}
      className={className}
      isDragging={isDragging}
      ref={provided.innerRef}
      data-is-dragging={isDragging}
      onClick={() => handleCreateOrUpdateTask({ guid, taskNumber })}
    >
      <Box mb='8px' className={className} color='greyMatterhorn' wordBreak='break-word'>
        {taskNumber}
      </Box>
      <Box mb='8px' className={className} color='greyMatterhorn' wordBreak='break-word'>
        {title}
      </Box>
      {
        G.isNotNilAndNotEmpty(objectName) &&
        <Box mb='8px' className={className}>{G.getEnumLocale(objectType)}: {objectName}</Box>
      }
      <Flex className={className} justifyContent='space-between'>
        <Avatar
          {...G.getUserInfo(assignee)}
          zIndex='0'
          action={() => null}
          className={className}
          url={assignee.profilePhotoUrl}
          iconsColor={G.ifElse(G.isNotNil(assignee.profilePhotoUrl), 'transparent')}
          avatarAdditionalStyles={{
            m: 0,
            className,
            width: 25,
            height: 25,
            fontSize: 11,
            border: G.ifElse(R.isNil(assignee.profilePhotoUrl), '1px solid', 'none'),
          }}
        />
        <Box
          {...getDueDateBackgroundAndColor()}
          p='4px'
          borderRadius='4px'
          className={className}
        >
          {G.getWindowLocale('titles:due', 'Due')}: {dueDate}
        </Box>
      </Flex>
    </Container>
  );
};

export default memo(TaskBoardItem);
