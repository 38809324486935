import * as R from 'ramda';
import { Waypoint } from 'react-waypoint';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
// components
import { TitlePanel } from '../../../components/title-panel';
import { EditReport } from '../../../components/edit-report';
import { Avatar } from '../../profile/components/profile-photo';
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import PC from '../../permission/role-permission';
import { makeSelectCurrentUserSettingsFields } from '../../profile/selectors';
import { setExpandedContainerOptions } from '../../expanded-container/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, Grid, PageWrapper, RelativeBox } from '../../../ui';
// feature task-management
import Task from '../task';
import TaskBoardItem from './components/task';
import TitleTabs from '../components/title-tabs';
import AssigneeFilter from './components/assignee-filter';
import { TASK_BOARD_FILTER_PARAMS } from '../report/settings';
import {
  makeSelectEntities,
  makeSelectTaskBoard,
  makeSelectTaskBoardSummary,
  makeSelectSelectedTaskBoard,
  makeSelectTaskBoardPagination,
  makeSelectAvailableTaskBoards,
  makeSelectTaskBoardTotalCount,
  makeSelectTaskBoardListLoading,
  makeSelectTaskTypesTaskStatuses,
  makeSelectTaskBoardGlobalFilterValue,
  makeSelectTaskBoardAdditionalFilterValue,
  makeSelectGroupedByTaskTypesTaskStatusMap,
} from '../selectors';
import {
  getTaskBoardRequest,
  setSelectedTaskBoard,
  updateTaskStatusRequest,
  createOrUpdateTaskRequest,
  setDefaultTaskBoardRequest,
  setTaskBoardGlobalFilterValue,
  createOrUpdateTaskBoardRequest,
  resetTaskBoardListAndPagination,
  setEntityListByEntityTypeSuccess,
  setTaskBoardAdditionalFilterValue,
} from '../actions';
//////////////////////////////////////////////////

const setColumnsWrapperWidth = (list: Array) => {
  const columnsCount = R.length(list);

  const minWidth = R.add(R.multiply(columnsCount, 200), R.multiply(R.dec(columnsCount), 20));
  const maxWidth = R.add(R.multiply(columnsCount, 400), R.multiply(R.dec(columnsCount), 20));

  return { minWidth, maxWidth };
};

const StatusList = ({ statusList = [] }: Object) => {
  const summary = useSelector(makeSelectTaskBoardSummary());

  return (
    <Grid
      {...setColumnsWrapperWidth(statusList)}
      top='0px'
      bg='bgGrey'
      zIndex={10}
      gridGap={20}
      fontSize={19}
      fontWeight='bold'
      position='sticky'
      gridAutoFlow='column'
      gridTemplateColumns='repeat(auto-fit, minmax(185px, 1fr))'
    >
      {
        statusList.map(({ name, guid }: Object, index: number) => {
          const count = R.pathOr(0, [guid, 'totalTasks'], summary);

          return (
            <Box
              p={10}
              key={index}
              height={40}
              minWidth={150}
              color='greyMatterhorn'
              borderTopLeftRadius={4}
              borderTopRightRadius={4}
            >
              {name} {G.ifElse(G.isNotZero(count), `(${count})`)}
            </Box>
          );
        })
      }
    </Grid>
  );
};

const reorder = (list: Array, startIndex: number, endIndex: number) => {
  const result = Array.from(list);

  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

const reorderTasks = ({ columns, source, destination }: Object) => {
  const currentDroppableId = R.prop('droppableId', source);
  const nextDroppableId = R.prop('droppableId', destination);
  const current = R.prop(currentDroppableId, columns);
  const next = R.pathOr([], [nextDroppableId], columns);
  const target = current[source.index];

  if (R.equals(currentDroppableId, nextDroppableId)) {
    const reordered = reorder(current, source.index, destination.index);

    return R.assoc(currentDroppableId, reordered, columns);
  }

  return {
    ...columns,
    [currentDroppableId]: R.remove(source.index, 1, current),
    [nextDroppableId]: R.sortBy(R.prop(GC.FIELD_DUE_DATE), R.insert(destination.index, target, next)),
  };
};

const TaskList = (props: Object) => {
  const {
    listId,
    taskList,
    assignee,
    listType,
    statusColor,
    availableColumns,
    isCombineEnabled,
    ignoreContainerClipping,
    handleCreateOrUpdateTask,
  } = props;

  const isDropAvailable = R.includes(listId, availableColumns);
  const isDragDisabled = G.hasNotAmousCurrentUserPermissions(PC.TASK_WRITE);

  const makeStyles = useCallback(({ draggingFromThisWith }: Object) => {
    const getBorderAndBackgroundColor = () => {
      if (R.and(isDropAvailable, R.isNil(draggingFromThisWith))) {
        return {
          borderColor: 'azure',
          bg: 'rgba(25, 118, 210, 0.08)',
        };
      }

      return {
        borderColor: G.ifElse(draggingFromThisWith, statusColor, '#E4E4E4'),
        bg: draggingFromThisWith ? `rgba${R.slice(3, -1, statusColor)}, 0.08)` : 'white',
      };
    };

    return ({
      width: '100%',
      minWidth: 185,
      height: '100%',
      borderRadius: 12,
      userSelect: 'none',
      p: '8px 8px 0 8px',
      border: '1px solid',
      ...getBorderAndBackgroundColor(),
      transition: 'background-color 0.2s ease, opacity 0.1s ease',
    });
  }, [statusColor, isDropAvailable]);

  return (
    <Droppable
      type={listType}
      droppableId={listId}
      isCombineEnabled={isCombineEnabled}
      isDropDisabled={G.isFalse(isDropAvailable)}
      ignoreContainerClipping={ignoreContainerClipping}
    >
      {(dropProvided: Object, dropSnapshot: Object) => (
        <Box bg='white' maxWidth={400} borderRadius={12}>
          <Box
            {...dropProvided.droppableProps}
            {...makeStyles(dropSnapshot, dropProvided)}
          >
            <Box pb='8px' minHeight={250} ref={dropProvided.innerRef}>
              {
                taskList.map((task: Object, index: number) => (
                  <Draggable
                    index={index}
                    isDragDisabled={isDragDisabled}
                    key={G.getGuidFromObject(task)}
                    draggableId={G.getGuidFromObject(task)}
                  >
                    {(dragProvided: Object, dragSnapshot: Object) => (
                      <TaskBoardItem
                        task={task}
                        assignee={assignee}
                        provided={dragProvided}
                        key={G.getGuidFromObject(task)}
                        isDragging={dragSnapshot.isDragging}
                        handleCreateOrUpdateTask={handleCreateOrUpdateTask}
                        isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
                      />
                    )}
                  </Draggable>
                ))
              }
              <Box
                height={66}
                width='100%'
                borderRadius='8px'
                border='2px dashed'
                borderColor='#E4E4E4'
                visibility={G.ifElse(dropSnapshot.isDraggingOver, 'visible', 'hidden')}
              />
              {dropProvided.placeholder}
            </Box>
          </Box>
        </Box>
      )}
    </Droppable>
  );
};

const TaskBoard = (props: Object) => {
  const {
    tasks,
    assignee,
    statusColumns,
    handleUpdateTaskStatus,
    handleCreateOrUpdateTask,
    groupedByTaskTypesTaskStatusMap,
  } = props;

  const { guid, lastName, firstName } = R.or(assignee, {});

  const { fullText, avatarText } = G.getUserInfo(assignee);

  const [opened, setOpened] = useState(true);
  const [columns, setColumns] = useState({});
  const [availableColumns, setAvailableColumns] = useState([]);

  useEffect(() => {
    const groupedTasks = R.groupBy(
      R.path(['status', GC.FIELD_CONFIG_GUID]),
      R.sortBy(R.prop(GC.FIELD_DUE_DATE), R.or(tasks, [])),
    );

    const columns = R.map(({ configGuid }: Object) => R.propOr([], configGuid, groupedTasks), statusColumns);

    setColumns(columns);
  }, [tasks, statusColumns]);

  const handleDragEnd = useCallback((result: Object) => {
    const { source, destination, draggableId } = result;

    setAvailableColumns([]);

    if (R.not(destination)) return;

    if (R.equals(source.droppableId, destination.droppableId)) return;

    const data = reorderTasks({
      source,
      columns,
      destination,
    });

    handleUpdateTaskStatus({
      assigneeGuid: guid,
      taskGuid: draggableId,
      prevTaskStatusConfigGuid: source.droppableId,
      taskStatusConfigGuid: destination.droppableId,
    });

    setColumns(data);
  }, [columns]);


  const handleDragStart = useCallback((column: Object) => {
    const status = R.path(['source', 'droppableId'], column);

    const taskType = R.compose(
      R.path([GC.FIELD_TYPE, GC.FIELD_CONFIG_GUID]),
      R.find(R.propEq(column.draggableId, GC.FIELD_GUID)),
      R.prop(status),
    )(columns);

    setAvailableColumns(R.propOr([], taskType, groupedByTaskTypesTaskStatusMap));
  }, [columns, groupedByTaskTypesTaskStatusMap]);

  const wrapperStyles = useMemo(() => setColumnsWrapperWidth(R.keys(columns)), [columns]);

  return (
    <Box {...wrapperStyles}>
      <Box
        top={76}
        height='2px'
        opacity='30%'
        position='sticky'
        bg='greyMatterhorn'
      />
      <Box my='-2px' position='sticky' top={36} height='2px' bg='bgGrey' />
      <Box
        mb='2px'
        mt='-2px'
        top={38}
        zIndex={2}
        bg='bgGrey'
        height={38}
        position='sticky'
      >
        <Flex left={10} position='sticky' height='100%' width='max-content'>
          <Box mr={10} cursor='pointer' onClick={() => setOpened(R.not)}>
            {G.ifElse(opened, I.arrowUpSimple, I.arrowDownSimple)(G.getTheme('colors.greyMatterhorn'))}
          </Box>
          <Avatar
            action={() => null}
            fullText={fullText}
            avatarText={avatarText}
            url={assignee.profilePhotoUrl}
            avatarAdditionalStyles={{
              m: 0,
              width: 25,
              height: 25,
              fontSize: 11,
              border: G.ifElse(R.isNil(assignee.profilePhotoUrl), '1px solid', 'none'),
            }}
          />
          <Box ml={10} color='greyMatterhorn'>
            {firstName} {lastName} ({R.length(R.keys(tasks))} {G.getWindowLocale('titles:tasks', 'Tasks')})
          </Box>
        </Flex>
      </Box>
      {
        opened &&
        <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
          <Grid
            gridGap={20}
            gridAutoFlow='column'
            gridTemplateColumns='repeat(auto-fit, minmax(185px, 1fr))'
          >
            {R.keys(columns).map((key: Object) => (
              <TaskList
                key={key}
                index={key}
                listId={key}
                listType='task'
                assignee={assignee}
                availableColumns={availableColumns}
                taskList={R.propOr([], key, columns)}
                handleCreateOrUpdateTask={handleCreateOrUpdateTask}
                statusColor={R.pathOr('rgb(255,255,255)', [key, GC.FIELD_COLOR], statusColumns)}
              />
            ))}
          </Grid>
        </DragDropContext>
      }
    </Box>
  );
};

const titlePanelMapStateToProps = (state: Object) => createStructuredSelector({
  availableTaskBoards: makeSelectAvailableTaskBoards(state),
  globalFilterValue: makeSelectTaskBoardGlobalFilterValue(state),
  additionalFilterValue: makeSelectTaskBoardAdditionalFilterValue(state),
});

const titlePanelActions = {
  setSelectedTaskBoard,
  setDefaultTaskBoardRequest,
  setTaskBoardGlobalFilterValue,
  createOrUpdateTaskBoardRequest,
  resetTaskBoardListAndPagination,
  setTaskBoardAdditionalFilterValue,
};

const TaskBoardTitlePanel = connect(titlePanelMapStateToProps, titlePanelActions)((props: Object) => {
  const {
    entities,
    openModal,
    openLoader,
    closeLoader,
    taskStatuses,
    selectedTaskBoard,
    globalFilterValue,
    availableTaskBoards,
    getTaskBoardRequest,
    setSelectedTaskBoard,
    additionalFilterValue,
    handleCreateOrUpdateTask,
    setDefaultTaskBoardRequest,
    setTaskBoardGlobalFilterValue,
    createOrUpdateTaskBoardRequest,
    resetTaskBoardListAndPagination,
    setEntityListByEntityTypeSuccess,
    setTaskBoardAdditionalFilterValue,
  } = props;

  const reportList = R.map(
    (item: Object) => R.assoc('defaultReport', item.defaultBoard, item),
    R.or(availableTaskBoards, []),
  );

  const handleSelectReport = useCallback((reportGuid: string) => {
    const selected = R.find(R.propEq(reportGuid, GC.FIELD_GUID), availableTaskBoards);

    setSelectedTaskBoard(selected);
    getTaskBoardRequest();
  }, [availableTaskBoards]);

  const handleEditReport = useCallback(() => {
    const fields = R.map(
      ({ name, guid }: Object) => ({ name, value: guid, statusGuid: guid, type: 'string' }),
      R.or(taskStatuses, []),
    );

    const makeBoard = (board: Object) => {
      const { fields, defaultReport } = board;

      const columns = R.map(({ name, sequence }: Object) => ({ sequence, statusGuid: name }), fields);

      return R.mergeRight(
        R.omit(['fields', 'defaultReport'], board),
        { columns, defaultBoard: defaultReport },
      );
    };

    const createOrUpdatedBoardRequest = (board: Object) =>
      createOrUpdateTaskBoardRequest(makeBoard(board));

    const reportFields = R.map(({ guid, freezed, statusGuid }: Object) => ({
      guid,
      freezed,
      [GC.FIELD_NAME]: statusGuid,
    }), R.propOr([], 'columns', selectedTaskBoard));

    const usedReport = R.mergeRight(
      selectedTaskBoard,
      { fields: reportFields, owner: R.prop(GC.FIELD_CREATED_BY, selectedTaskBoard) },
    );

    const component = (
      <EditReport
        fields={fields}
        requestPending={false}
        usedReport={usedReport}
        disableSortByFields={true}
        useAdditionalFilters={true}
        onReportSet={getTaskBoardRequest}
        additionalFilters={TASK_BOARD_FILTER_PARAMS}
        createReportRequest={createOrUpdatedBoardRequest}
        updateReportRequest={createOrUpdatedBoardRequest}
        addFieldText={G.getWindowLocale('titles:add-status', 'Add Status')}
        editReportText={G.getWindowLocale('titles:edit-board', 'Edit Board')}
        setReport={(board: Object) => setSelectedTaskBoard(makeBoard(board))}
        addFieldsText={G.getWindowLocale('titles:add-statuses', 'Add Statuses')}
        updateReportText={G.getWindowLocale('titles:update-board', 'Update Board')}
        createNewReportText={G.getWindowLocale('titles:create-new-board', 'Create New Board')}
      />
    );

    const modal = G.getDefaultReportModal(component);

    openModal(modal);
  }, [taskStatuses, selectedTaskBoard]);

  const handleSetGlobalFilter = useCallback((value: string) => {
    if (R.equals(globalFilterValue, value)) return;

    setTaskBoardGlobalFilterValue(value);
    resetTaskBoardListAndPagination();
    getTaskBoardRequest();
  }, [globalFilterValue]);

  const handleSetAdditionalFilter = useCallback((value: Array) => {
    if (R.equals(additionalFilterValue, value)) return;

    setTaskBoardAdditionalFilterValue(value);
    resetTaskBoardListAndPagination();
    getTaskBoardRequest();
  }, [additionalFilterValue]);

  const assigneeFilterProps = {
    entities,
    openLoader,
    closeLoader,
    additionalFilterValue,
    handleSetAdditionalFilter,
    setEntityListByEntityTypeSuccess,
  };

  const tittlePanelStyles = {
    pt: 15,
    bg: 'white',
    minWidth: '100%',
    width: 'max-content',
  };

  return (
    <TitlePanel
      zIndex={12}
      height='auto'
      noExportable={true}
      reportList={reportList}
      withoutQuickFilter={true}
      withoutPinnedReports={true}
      hiddenRightFilterInfo={false}
      withGlobalSearchFilter={true}
      selectReportFormName='taskBoard'
      type={GC.TASK_MANAGEMENT_REPORT}
      selectedReport={selectedTaskBoard}
      handleEditReport={handleEditReport}
      setUsedReport={setSelectedTaskBoard}
      titlePanelStyles={tittlePanelStyles}
      globalFilterValue={globalFilterValue}
      filterProps={TASK_BOARD_FILTER_PARAMS}
      handleListRequest={getTaskBoardRequest}
      handleSelectReport={handleSelectReport}
      getItemListRequest={getTaskBoardRequest}
      handleSetGlobalFilter={handleSetGlobalFilter}
      changeDefaultReportRequest={setDefaultTaskBoardRequest}
      additionalComponent={<AssigneeFilter {...assigneeFilterProps} />}
      editReportText={G.getWindowLocale('titles:edit-board', 'Edit Board')}
      noEditReport={G.hasNotAmousCurrentUserPermissions(PC.TASK_BOARD_WRITE)}
      selectReportText={G.getWindowLocale('titles:select-board', 'Select Board')}
      customTitleComponent={<TitleTabs handleCreateTask={handleCreateOrUpdateTask} />}
      title={G.getWindowLocale('titles:task-management-report', 'Task Management Report')}
    />
  );
});

const TaskManagementBoard = (props: Object) => {
  const {
    loading,
    entities,
    taskBoard,
    openModal,
    openLoader,
    pagination,
    closeModal,
    totalCount,
    closeLoader,
    selectedTaskBoard,
    getTaskBoardRequest,
    currentUserSettings,
    taskTypeTaskStatuses,
    updateTaskStatusRequest,
    createOrUpdateTaskRequest,
    setExpandedContainerOptions,
    groupedByTaskTypesTaskStatusMap,
    setEntityListByEntityTypeSuccess,
  } = props;

  if (G.isNilOrEmpty(selectedTaskBoard)) return null;

  const { taskStatuses } = taskTypeTaskStatuses;

  const handleCreateOrUpdateTask = useCallback(({ guid, taskNumber }: Object = {}) => {
    const component = (
      <Task
        {...taskTypeTaskStatuses}
        taskGuid={guid}
        entities={entities}
        closeModal={closeModal}
        openLoader={openLoader}
        closeLoader={closeLoader}
        currentUserSettings={currentUserSettings}
        createOrUpdateTaskRequest={createOrUpdateTaskRequest}
        setExpandedContainerOptions={setExpandedContainerOptions}
        groupedByTaskTypesTaskStatusMap={groupedByTaskTypesTaskStatusMap}
        setEntityListByEntityTypeSuccess={setEntityListByEntityTypeSuccess}
      />
    );

    const title = R.isNil(guid) ?
      G.getWindowLocale('titles:create-task', 'Create Task') :
      `${G.getWindowLocale('titles:task-number', 'Task Number')}: ${taskNumber}`;

    const modal = {
      p: '0px',
      component,
      options: {
        title,
        movable: false,
        overflow: 'auto',
        maxHeight: '90vh',
      },
    };

    openModal(modal);
  }, [entities, taskTypeTaskStatuses, groupedByTaskTypesTaskStatusMap]);

  const statusColumns = useMemo(
    () => R.compose(
      R.indexBy(R.prop(GC.FIELD_CONFIG_GUID)),
      R.filter(G.isNotNil),
      R.map(({ statusGuid }: Object) => R.find(R.propEq(statusGuid, GC.FIELD_CONFIG_GUID), R.or(taskStatuses, []))),
      R.propOr([], 'columns'),
    )(selectedTaskBoard),
    [taskStatuses, selectedTaskBoard],
  );

  const handleUpdateTaskStatus = useCallback((options: Object) => {
    const { taskGuid, assigneeGuid, taskStatusConfigGuid, prevTaskStatusConfigGuid } = options;

    const indexed = R.indexBy(R.prop(GC.FIELD_CONFIG_GUID), taskStatuses);

    const requestData = R.pick(
      [GC.FIELD_NAME, GC.FIELD_COMPLETED, GC.FIELD_CONFIG_GUID],
      R.propOr({}, taskStatusConfigGuid, indexed),
    );

    updateTaskStatusRequest({
      taskGuid,
      requestData,
      assigneeGuid,
      taskStatusConfigGuid,
      prevTaskStatusConfigGuid,
    });
  }, [taskStatuses]);

  const emptyBoard = G.isNilOrEmpty(R.prop('columns', selectedTaskBoard));
  const hasFilters = G.isNotNilAndNotEmpty(selectedTaskBoard.searchCriteria);

  return (
    <PageWrapper
      pl={65}
      pt='0px'
      pb='0px'
      overflowX='auto'
      overflowY='hidden'
      bgColor={G.getThemeByCond(emptyBoard, 'colors.white', 'colors.bgGrey')}
    >
      <TaskBoardTitlePanel
        entities={entities}
        openModal={openModal}
        openLoader={openLoader}
        closeLoader={closeLoader}
        taskStatuses={taskStatuses}
        selectedTaskBoard={selectedTaskBoard}
        getTaskBoardRequest={getTaskBoardRequest}
        handleCreateOrUpdateTask={handleCreateOrUpdateTask}
        setEntityListByEntityTypeSuccess={setEntityListByEntityTypeSuccess}
      />
      {
        emptyBoard ? (
          <Flex
            p={50}
            width='100%'
            fontSize={36}
            border='1px solid'
            color='dark.mainLight'
            borderColor='dark.grey'
            justifyContent='center'
          >
            {G.getWindowLocale('titles:create-board-to-see-data', 'Please, create a Board to see your data')}
          </Flex>
        ) : (
          <RelativeBox
            px={10}
            pb={40}
            bg='bgGrey'
            width='100%'
            height='100%'
            minWidth={1415}
            overflowY='auto'
            maxHeight={`calc(100vh - ${G.ifElse(hasFilters, '115px', '75px')})`}
          >
            <StatusList statusList={R.values(statusColumns)} />
            {
              taskBoard.map((item: Object, index: number) => (
                <TaskBoard
                  {...item}
                  key={index}
                  index={index}
                  statusColumns={statusColumns}
                  handleUpdateTaskStatus={handleUpdateTaskStatus}
                  handleCreateOrUpdateTask={handleCreateOrUpdateTask}
                  groupedByTaskTypesTaskStatusMap={groupedByTaskTypesTaskStatusMap}
                />
              ))
            }
            {
              G.isAllTrue(
                R.not(loading),
                R.gt(totalCount, pagination.offset),
                R.gte(pagination.offset, pagination.limit),
              ) &&
              <Waypoint onEnter={() => getTaskBoardRequest()} />
            }
          </RelativeBox>
        )
      }
    </PageWrapper>
  );
};


const mapStateToProps = (state: Object) => createStructuredSelector({
  entities: makeSelectEntities(state),
  taskBoard: makeSelectTaskBoard(state),
  loading: makeSelectTaskBoardListLoading(state),
  totalCount: makeSelectTaskBoardTotalCount(state),
  pagination: makeSelectTaskBoardPagination(state),
  selectedTaskBoard: makeSelectSelectedTaskBoard(state),
  taskTypeTaskStatuses: makeSelectTaskTypesTaskStatuses(state),
  currentUserSettings: makeSelectCurrentUserSettingsFields(state),
  groupedByTaskTypesTaskStatusMap: makeSelectGroupedByTaskTypesTaskStatusMap(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  openLoader,
  closeLoader,
  getTaskBoardRequest,
  updateTaskStatusRequest,
  createOrUpdateTaskRequest,
  setExpandedContainerOptions,
  setEntityListByEntityTypeSuccess,
})(TaskManagementBoard);
