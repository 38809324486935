import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
// helpers
import * as G from '../../../../helpers';
// feature template-inspection
import * as C from '../constants';
import { updateComponentFields } from '../actions';
import {
  InputsOption,
  CheckboxOption,
  DropdownOption,
} from './option-components';
//////////////////////////////////////////////////

const VT = R.prop('valueTypeMap', C);

const Option = ({ component, option, setOptionValue }: Object) => {
  const { id } = component;

  const {
    type,
    field,
    label,
    minCount,
    renderItems,
    additionalLabel,
    valueType = VT.value,
  } = option;

  const value = R.prop(valueType, option);
  const additionalValueMap = R.prop(VT.additionalValueMap, option);

  if (G.isAnyTrue(
    R.equals(type, C.INSPECTION_OPTION_TYPE_RADIO),
    R.equals(type, C.INSPECTION_OPTION_TYPE_DROPDOWN),
    R.equals(type, C.INSPECTION_OPTION_TYPE_CHECKBOXES),
  )) {
    return (
      <DropdownOption
        type={type}
        field={field}
        label={label}
        values={value}
        fieldName={id}
        minCount={minCount}
        options={renderItems}
        valueType={valueType}
        setOptionValue={setOptionValue}
        additionalLabel={additionalLabel}
      />
    );
  }

  if (R.equals(type, C.INSPECTION_OPTION_TYPE_RANGE)) {
    return (
      <InputsOption
        field={field}
        label={label}
        values={value}
        fieldName={id}
        inputType='number'
        options={renderItems}
        valueType={valueType}
        setOptionValue={setOptionValue}
      />
    );
  }

  if (R.equals(type, C.INSPECTION_OPTION_TYPE_INPUTS)) {
    return (
      <InputsOption
        field={field}
        label={label}
        values={value}
        fieldName={id}
        inputType='text'
        options={renderItems}
        valueType={valueType}
        setOptionValue={setOptionValue}
      />
    );
  }

  if (R.equals(type, C.INSPECTION_OPTION_TYPE_CHECKBOX)) {
    return (
      <CheckboxOption
        type={type}
        field={field}
        label={label}
        value={value}
        fieldName={id}
        options={renderItems}
        valueType={valueType}
        setOptionValue={setOptionValue}
        additionalValueMap={additionalValueMap}
      />
    );
  }
};

const Options = ({ component }: Object) => {
  const dispatch = useDispatch();

  const { options } = component;

  const setOptionValue = useCallback((
    field: string, value: string, valueType: string = VT.value, isAdditional: boolean,
  ) => {
    const optionsToUpdate = R.clone(options);
    const optionToUpdate = R.find(R.propEq(field, 'field'), optionsToUpdate);

    if (isAdditional) {
      optionToUpdate[VT.additionalValueMap] = value;
    } else {
      optionToUpdate[valueType] = value;
    }

    dispatch(updateComponentFields({
      ...component,
      options: optionsToUpdate,
    }));
  }, [component, options]);

  return (
    R.map((option: Object) => {
      const { field } = option;

      return (
        <Option
          key={field}
          option={option}
          component={component}
          setOptionValue={setOptionValue}
        />
      );
    }, options)
  );
};

export default Options;
