import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const defaultStyles = {
  width: 210,
  errorTop: '105%',
  afterTop: '14px',
  errorLeft: '10px',
  labelWidth: '100%',
  isClearable: true,
  errorWidth: '100%',
  flexDirection: 'column',
  errorPosition: 'absolute',
};

const stringRequired = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

export const primaryLocationDefaultFields = {
  [GC.FIELD_ZIP]: null,
  [GC.FIELD_EMAILS]: [],
  [GC.FIELD_CITY]: null,
  [GC.FIELD_STATE]: null,
  [GC.FIELD_PHONE]: null,
  [GC.FIELD_COUNTRY]: null,
  [GC.FIELD_ADDRESS_1]: null,
  [GC.FIELD_ADDRESS_2]: null,
  [GC.FIELD_CONTACT_NAME]: null,
  [GC.FIELD_LOCATION_NAME]: null,
};

export const primaryLocationValidationSchema = Yup.object().shape({
  [GC.FIELD_ZIP]: stringRequired,
  [GC.FIELD_CITY]: stringRequired,
  [GC.FIELD_STATE]: stringRequired,
  [GC.FIELD_COUNTRY]: stringRequired,
  [GC.FIELD_ADDRESS_1]: stringRequired,
  [GC.FIELD_LOCATION_NAME]: stringRequired,
  [GC.FIELD_PHONE]: Yup.string().nullable(true),
  [GC.FIELD_EMAILS]: Yup.array().nullable(true),
  [GC.FIELD_ADDRESS_2]: Yup.string().nullable(true),
  [GC.FIELD_CONTACT_NAME]: Yup.string().nullable(true),
});

export const primaryLocationFieldSettings = [
  {
    ...defaultStyles,
    loc: 'titles:name',
    fieldName: GC.FIELD_LOCATION_NAME,
  },
  {
    ...defaultStyles,
    loc: 'titles:address1',
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS_1,
  },
  {
    ...defaultStyles,
    loc: 'titles:address2',
    fieldName: GC.FIELD_ADDRESS_2,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:city',
    fieldName: GC.FIELD_CITY,
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:state',
    fieldName: GC.FIELD_STATE,
  },
  {
    ...defaultStyles,
    type: 'text',
    fieldName: GC.FIELD_ZIP,
    loc: 'titles:postal-code',
  },
  {
    ...defaultStyles,
    width: 300,
    isWrapped: true,
    type: 'countrySelect',
    loc: 'titles:country',
    fieldName: GC.FIELD_COUNTRY,
    inputWrapMargin: '5px 400px 5px 5px',
  },
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:contact-name',
    fieldName: GC.FIELD_CONTACT_NAME,
  },
  {
    ...defaultStyles,
    type: 'phoneNumber',
    loc: 'titles:phone',
    fieldName: GC.FIELD_PHONE,
  },
  {
    ...defaultStyles,
    type: 'number',
    loc: 'titles:phone-extension',
    fieldName: GC.FIELD_PHONE_EXTENSION,
  },
  {
    ...defaultStyles,
    width: 300,
    type: 'multiEmail',
    loc: 'titles:emails',
    // shouldOverrideEmails: true,
    fieldName: GC.FIELD_EMAILS,
  },
];
