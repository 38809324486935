// helpers/constants
import * as G from '../../../helpers';
//////////////////////////////////////////////////

export const notesFields = () => (
  [
    {
      order: 1,
      type: 'textarea',
      endLabel: 'plus',
      validate: G.required,
      nameForAttribute: 'notes',
      name: G.getWindowLocale('titles:notes', 'Notes'),
      placeholder: G.getWindowLocale('titles:add-your-notes-here', 'Add Your Notes Here'),
    },
    {
      type: 'select',
      validate: G.required,
      nameForAttribute: 'position',
      name: G.getWindowLocale('titles:position', 'Position'),
    },
  ]
);

export const getPositionOptions = () => [
  {
    value: 1,
    name: G.getWindowLocale('titles:position-top', 'Position 1 (top of the panel)'),
  },
  {
    value: 2,
    name: G.getWindowLocale('titles:position-middle', 'Position 2 (middle of the panel)'),
  },
  {
    value: 3,
    name: G.getWindowLocale('titles:position-bottom', 'Position 3 (bottom of the panel)'),
  },
];
