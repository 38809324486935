import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
// forms
import { Textarea } from '../../../../forms/formik/fieldset2/ui';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// ui
import { Flex, Text, Badge } from '../../../../ui';
// feature template-inspection
import * as H from '../helpers';
import * as C from '../constants';
import FormField from './form-field';
import { SwitchableFlex } from '../ui';
import { updateTemplateFields } from '../actions';
import { NameWithEdit, CheckboxComponent, SelectDropdown } from './option-components';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.dark.blue');

const vehicleTypeInfo = {
  [C.INSPECTION_TRUCK]: {
    icon: I.truck(iconColor, 16, 15),
    name: G.getWindowLocale('titles:inspection-truck', 'Truck'),
  },
  [C.INSPECTION_TRAILER]: {
    icon: I.trailer(iconColor, 16, 15),
    name: G.getWindowLocale('titles:inspection-trailer', 'Trailer'),
  },
};

const VehicleType = ({ currentTemplate }: Object) => {
  const vehicleType = R.prop(GC.FIELD_VEHICLE_TYPE, currentTemplate);

  const { icon, name } = R.prop(vehicleType, vehicleTypeInfo);

  const title = `Vehicle Type ${name}`;

  return (
    <Flex width={100} title={title} mt={10}>
      <Badge variant='grey'>
        <Flex gap='8px'>
          {icon}
          {name}
        </Flex>
      </Badge>
    </Flex>
  );
};

const SelectInspectionType = ({ currentTemplate }: Object) => {
  const dispatch = useDispatch();

  const inspectionType = R.prop(GC.FIELD_TYPE, currentTemplate);

  const dropdownOptions = H.getInspectionTypeOptions();

  const handleTypeChange = useCallback((choice: Object) => {
    const inspectionType = R.prop('value', choice);

    dispatch(updateTemplateFields({
      ...currentTemplate,
      [GC.FIELD_TYPE]: inspectionType,
    }));
  }, [currentTemplate]);

  return (
    <SelectDropdown
      mb={15}
      value={inspectionType}
      options={dropdownOptions}
      onChange={handleTypeChange}
      placeholder={G.getWindowLocale('titles:inspection-type', 'Inspection Type')}
    />
  );
};

const Header = ({ viewOnly, currentTemplate }: Object) => {
  const dispatch = useDispatch();

  const {
    name,
    nameStatus,
    description,
    signatureRequired,
    enableLocationTracking,
  } = currentTemplate;

  const errorMessage = G.getWindowLocale('titles:inspection-template-name-error', 'Enter template name');

  const setTemplateName = useCallback((value: string) => {
    dispatch(updateTemplateFields({
      ...currentTemplate,
      name: value,
      nameStatus: G.ifElse(
        G.isNilOrEmpty(value),
        C.INSPECTION_NAME_STATUS_EMPTY,
        C.INSPECTION_NAME_STATUS_FILLED,
      ),
    }));
  }, [currentTemplate]);

  const handleNameEdit = useCallback(() => {
    if (viewOnly) return;

    dispatch(updateTemplateFields({
      ...currentTemplate,
      nameStatus: C.INSPECTION_NAME_STATUS_EDIT,
    }));
  }, [viewOnly, currentTemplate]);

  const handleDescriptionEdit = useCallback((e: Event) => {
    if (viewOnly) return;

    const value = R.path(['currentTarget', 'value'], e);

    dispatch(updateTemplateFields({
      ...currentTemplate,
      description: R.or(value, null),
    }));
  }, [viewOnly, currentTemplate]);

  const toggleLocationTracking = useCallback(() => {
    if (viewOnly) return;

    dispatch(updateTemplateFields({
      ...currentTemplate,
      enableLocationTracking: R.not(enableLocationTracking),
    }));
  }, [viewOnly, currentTemplate, enableLocationTracking]);

  const toggleSignatureRequired = useCallback(() => {
    if (viewOnly) return;

    dispatch(updateTemplateFields({
      ...currentTemplate,
      signatureRequired: R.not(signatureRequired),
    }));
  }, [viewOnly, currentTemplate, signatureRequired]);

  const nameLabel = G.getWindowLocale('titles:inspection-template-name', 'Template name');
  const descriptionLabel = G.getWindowLocale('titles:inspection-template-description', 'Template description');

  return (
    <SwitchableFlex
      p={20}
      gap={20}
      width='100%'
      maxHeight={210}
      disabled={viewOnly}
      borderBottom='1px solid'
      justifyContent='space-between'
      bg={G.getTheme('colors.white')}
      borderColor={G.getTheme('colors.dark.lightGrey')}
    >
      <Flex gap={20}>
        <Flex
          gap='0px 8px'
          minWidth={210}
          flexDirection='column'
          alignItems='flex-start'
        >
          <Text
            mt={-25}
            fontSize={20}
            fontWeight='bold'
            color='colors.greyMatterhorn'
          >
            {G.getWindowLocale('titles:inspection-template', 'Inspection Template')}
          </Text>
          <NameWithEdit
            name={name}
            focus={true}
            fontSize={16}
            label={nameLabel}
            status={nameStatus}
            errorMessage={errorMessage}
            maxLength={C.MAX_NAME_LENGTH}
            setInputValue={setTemplateName}
            handleNameEdit={handleNameEdit}
          />
          <VehicleType currentTemplate={currentTemplate} />
        </Flex>
        <FormField label={descriptionLabel}>
          <Textarea width={400} onBlur={handleDescriptionEdit}>{description}</Textarea>
        </FormField>
      </Flex>
      <Flex
        mr={10}
        gap={12}
        minWidth={200}
        alignSelf='flex-end'
        flexDirection='column'
        alignItems='flex-start'
      >
        <CheckboxComponent
          name='location-tracking'
          value={enableLocationTracking}
          setCheckboxValue={toggleLocationTracking}
          label={G.getWindowLocale('titles:inspection-enable-location-tracking', 'Enable location tracking')}
        />
        <CheckboxComponent
          name='signature-required'
          value={signatureRequired}
          setCheckboxValue={toggleSignatureRequired}
          label={G.getWindowLocale('titles:inspection-signature-required', 'Signature is required')}
        />
        <SelectInspectionType currentTemplate={currentTemplate} />
      </Flex>
    </SwitchableFlex>
  );
};

export default Header;
