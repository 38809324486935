import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setReports = createAction('setReports');
export const selectItem = createAction('selectItem');
export const setEditPage = createAction('setEditPage');
export const setUsedReport = createAction('setUsedReport');
export const setFilterProps = createAction('setFilterProps');
export const setListLoading = createAction('setListLoading');
export const cleanUsedReport = createAction('cleanUsedReport');
export const setRenderColumn = createAction('setRenderColumn');
export const checkReportValid = createAction('checkReportValid');
export const cleanQuickFilter = createAction('cleanQuickFilter');
export const setReportPending = createAction('setReportPending');
export const openEditReportModal = createAction('openEditReportModal');
export const createReportRequest = createAction('createReportRequest');
export const updateReportRequest = createAction('updateReportRequest');
export const deleteReportRequest = createAction('deleteReportRequest');
export const setUsedReportStatus = createAction('setUsedReportStatus');
export const setQuickFilterParams = createAction('setQuickFilterParams');
export const getReportDataRequest = createAction('getReportDataRequest');
export const setIgnorePromptStatus = createAction('setIgnorePromptStatus');
export const setOriginReportParams = createAction('setOriginReportParams');
export const resetListAndPagination = createAction('resetListAndPagination');
export const cleanReportStateAction = createAction('cleanReportStateAction');
export const exportReportDataRequest = createAction('exportReportDataRequest');
export const getListOfReportsRequest = createAction('getListOfReportsRequest');
export const updateReportOwnerRequest = createAction('updateReportOwnerRequest');
export const receivedUsedReportSuccess = createAction('receivedUsedReportSuccess');
export const changeDefaultReportRequest = createAction('changeDefaultReportRequest');
export const getAvailableReportsRequest = createAction('getAvailableReportsRequest');
export const receivedDeleteReportSuccess = createAction('receivedDeleteReportSuccess');
export const receivedUpdateReportSuccess = createAction('receivedUpdateReportSuccess');
export const getQuickFilteredListRequest = createAction('getQuickFilteredListRequest');
export const receivedListOfReportsSuccess = createAction('receivedListOfReportsSuccess');
export const receivedAvailableReportsSuccess = createAction('receivedAvailableReportsSuccess');
