// helpers/constants
import * as GC from '../../../constants';
// utilities
import endpoints from '../../../utilities/endpoints';
//////////////////////////////////////////////////

export const exportEndpointsMap = {
  [GC.USER_REPORT]: endpoints.userExportReport,
  [GC.REPORT_REPORT]: endpoints.reportExportReport,
  [GC.BRANCH_REPORT]: endpoints.branchExportReport,
  [GC.FLEET_DRIVER_REPORT]: endpoints.fleetDriverExportReport,
  [GC.FLEET_TRUCK_REPORT]: endpoints.fleetTruckExportReport,
  [GC.FLEET_TRAILER_REPORT]: endpoints.fleetTrailerExportReport,
};
