import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { css } from 'styled-components';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// features
import { Avatar } from '../../profile/components/profile-photo';
// ui
import { AbsoluteBox } from '../../../ui';
// feature task-management
import { objectTypeOptions } from '../report/settings';
//////////////////////////////////////////////////

const taskObjectTypeToSubObjectTypeMap = {
  [GC.TASK_MANAGEMENT_OBJECT_TYPE_TRUCK]: [GC.TASK_MANAGEMENT_SUB_OBJECT_TYPE_EQUIPMENT_SERVICE],
  [GC.TASK_MANAGEMENT_OBJECT_TYPE_TRAILER]: [GC.TASK_MANAGEMENT_SUB_OBJECT_TYPE_EQUIPMENT_SERVICE],
};

const defaultInputWrapperStyles = {
  mb: 25,
  width: 250,
};

const wideInputWrapperStyles = {
  mb: 25,
  width: 550,
};

const SingleValue = (props: Object) => {
  const { data } = props;

  if (R.isNil(data)) return;

  const { label, avatarText, profilePhotoUrl } = data;

  return (
    <AbsoluteBox pl={12} height='100%'>
      <Avatar
        action={() => null}
        url={profilePhotoUrl}
        avatarText={avatarText}
        avatarAdditionalStyles={{
          m: 0,
          width: 25,
          height: 25,
          fontSize: 11,
          border: G.ifElse(R.isNil(profilePhotoUrl), '1px solid', 'none'),
        }}
      />
      <TextComponent ml='5px' maxWidth={160} title={label} withEllipsis={true}>
        {label}
      </TextComponent>
    </AbsoluteBox>
  );
};

const requiredObjectTypes = R.compose(
  R.reject((item: string) => R.includes(item, [GC.TASK_MANAGEMENT_OBJECT_TYPE_GENERAL])),
  R.map(R.prop(GC.FIELD_VALUE)),
  R.tail,
)(objectTypeOptions);

const settings = {
  [GC.FIELD_TITLE]: {
    type: 'text',
    isRequired: true,
    label: ['titles:title'],
    inputWrapperStyles: defaultInputWrapperStyles,
  },
  [GC.FIELD_TYPE]: {
    isRequired: true,
    type: 'reactSelect',
    options: 'taskTypes',
    label: ['titles:type'],
    shouldCustomChange: true,
    inputWrapperStyles: {
      ...defaultInputWrapperStyles,
      zIndex: 91,
    },
    customChangeHandler: (value: string, _: any, props: Object) => {
      const { values, taskTypes, setValues, groupedByTaskTypesTaskStatusMap } = props;

      const taskType = R.find(R.propEq(value, GC.FIELD_GUID), taskTypes);

      const { taskTitle, taskDescription } = R.or(taskType, {});

      const currentStatus = R.prop(GC.FIELD_STATUS, values);
      const statusMap = R.propOr([], value, groupedByTaskTypesTaskStatusMap);

      const status = G.ifElse(
        R.or(R.isEmpty(statusMap), R.includes(currentStatus, statusMap)),
        currentStatus,
        null,
      );

      const newValues = R.mergeRight(
        values,
        {
          status,
          [GC.FIELD_TYPE]: value,
          [GC.FIELD_TITLE]: R.propOr(taskTitle, GC.FIELD_TITLE, values),
          [GC.FIELD_DESCRIPTION]: R.propOr(taskDescription, GC.FIELD_DESCRIPTION, values),
        },
      );

      setValues(newValues);
    },
  },
  [GC.FIELD_STATUS]: {
    isRequired: true,
    type: 'reactSelect',
    options: 'taskStatuses',
    label: ['titles:status'],
    inputWrapperStyles: {
      ...defaultInputWrapperStyles,
      mr: 250,
    },
    reactSelectAdditionalStyles: {
      menu: (baseStyles: Object) => ({
        ...baseStyles,
        zIndex: 15,
      }),
    },
  },
  [GC.FIELD_START_DATE]: {
    type: 'datePicker',
    label: ['titles:start-date'],
    inputWrapperStyles: defaultInputWrapperStyles,
  },
  [GC.FIELD_DUE_DATE]: {
    isRequired: true,
    type: 'datePicker',
    label: ['titles:due-date'],
    inputWrapperStyles: defaultInputWrapperStyles,
  },
  [GC.FIELD_DESCRIPTION]: {
    type: 'editor',
    shouldCustomChange: true,
    label: ['titles:description'],
    customChangeHandler: ({ value }: Object, _: any, { setFieldValue }: Object) => setFieldValue('editorState', value),
    inputWrapperStyles: {
      ...wideInputWrapperStyles,
      additionalStyles: css`
        height: 260px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }
      `,
    },
  },
  [GC.FIELD_ASSIGNEE_GUID]: {
    isRequired: true,
    type: 'reactSelect',
    options: 'assigneeOptions',
    label: ['titles:assignee'],
    components: { SingleValue },
    inputWrapperStyles: defaultInputWrapperStyles,
    reactSelectAdditionalStyles: {
      menu: (baseStyles: Object) => ({
        ...baseStyles,
        zIndex: 15,
      }),
    },
  },
  [GC.FIELD_SUPERVISOR_GUID]: {
    type: 'reactSelect',
    components: { SingleValue },
    label: ['titles:supervisor'],
    options: 'userGeneralOptions',
    inputWrapperStyles: defaultInputWrapperStyles,
    reactSelectAdditionalStyles: {
      menu: (baseStyles: Object) => ({
        ...baseStyles,
        zIndex: 15,
      }),
    },
  },
  [GC.FIELD_OBJECT_TYPE]: {
    isRequired: true,
    type: 'reactSelect',
    shouldCustomChange: true,
    options: objectTypeOptions,
    label: ['titles:object-type'],
    inputWrapperStyles: defaultInputWrapperStyles,
    disabled: (_: any, { version, relatedTask }: Object) => R.or(R.isNotNil(version), relatedTask),
    customChangeHandler: (objectType: Object, _: any, { values, setValues }: Object) => {
      const currentSubObjectType = R.prop(GC.FIELD_SUB_OBJECT_TYPE, values);

      const statusObjectType = G.ifElse(
        R.includes(currentSubObjectType, R.propOr([], objectType, taskObjectTypeToSubObjectTypeMap)),
        currentSubObjectType,
        null,
      );

      setValues(R.mergeRight(
        values,
        {
          objectType,
          statusObjectType,
          [GC.FIELD_TASK_OBJECT]: null,
          [GC.FIELD_SUB_OBJECT_GUID]: null,
        },
      ));
    },
  },
  [GC.FIELD_TASK_OBJECT]: {
    type: 'reactSelect',
    shouldCustomChange: true,
    components: { SingleValue },
    options: 'taskObjectOptions',
    label: ['titles:task-object', 'Task Object'],
    inputWrapperStyles: defaultInputWrapperStyles,
    customChangeHandler: (taskObject: Object, _: any, { values, setValues }: Object) =>
      setValues(R.mergeRight(values, { taskObject, [GC.FIELD_SUB_OBJECT_GUID]: null })),
    isRequired: ({ values: { objectType } }: Object) => R.includes(
      objectType,
      requiredObjectTypes,
    ),
    disabled: (_: any, { version, objectType, relatedTask }: Object) => G.isAnyTrue(
      relatedTask,
      R.isNotNil(version),
      R.equals(objectType, GC.TASK_MANAGEMENT_OBJECT_TYPE_GENERAL),
    ),
  },
  [GC.FIELD_SUB_OBJECT_TYPE]: {
    type: 'reactSelect',
    options: 'subObjectTypeOptions',
    inputWrapperStyles: defaultInputWrapperStyles,
    label: ['titles:sub-object-type', 'Sub Object Type'],
    customDisabledFunction: 'handleDisableSubObjectFields',
  },
  [GC.FIELD_SUB_OBJECT_GUID]: {
    type: 'reactSelect',
    options: 'subObjectOptions',
    label: ['titles:sub-object', 'Sub Object'],
    inputWrapperStyles: defaultInputWrapperStyles,
    customDisabledFunction: 'handleDisableSubObjectFields',
    isRequired: R.path(['values', GC.FIELD_SUB_OBJECT_TYPE]),
  },
};

export const defaultValues = R.map(() => null, settings);

export const validationSchema = Yup.lazy(({ objectType, subObjectType }: Object) => Yup.object().shape({
  ...R.map(({ isRequired }: Object) => G.ifElse(isRequired, G.yupStringRequired), settings),
  [GC.FIELD_TASK_OBJECT]: G.ifElse(
    R.includes(objectType, requiredObjectTypes),
    G.yupStringRequired,
  ),
  [GC.FIELD_TASK_OBJECT]: G.ifElse(
    R.includes(objectType, requiredObjectTypes),
    G.yupStringRequired,
  ),
  [GC.FIELD_SUB_OBJECT_GUID]: G.ifElse(
    R.isNotNil(subObjectType),
    G.yupStringRequired,
  ),
}));

export const fieldSettings = R.compose(
  R.values,
  R.mapObjIndexed((item: Object, fieldName: string) => R.assoc('fieldName', fieldName, item)),
  R.pick([
    GC.FIELD_TITLE,
    GC.FIELD_TYPE,
    GC.FIELD_DESCRIPTION,
    GC.FIELD_STATUS,
    GC.FIELD_ASSIGNEE_GUID,
    GC.FIELD_SUPERVISOR_GUID,
    GC.FIELD_OBJECT_TYPE,
    GC.FIELD_TASK_OBJECT,
    GC.FIELD_SUB_OBJECT_TYPE,
    GC.FIELD_SUB_OBJECT_GUID,
    GC.FIELD_START_DATE,
    GC.FIELD_DUE_DATE,
  ]),
)(settings);
