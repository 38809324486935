import * as R from 'ramda';
import { delay } from 'redux-saga';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
import {
  checkReportFunction,
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportSagas } from '../../report-common';
// sagas
import { crudSaga, visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature task-management
import * as A from './actions';
import {
  makeSelectUsedReport,
  makeSelectObjectGuid,
  makeSelectPagination,
  makeSelectPageVisited,
  makeSelectFilterParams,
  makeSelectAssigneeGuid,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTaskBoardVisited,
  makeSelectSelectedTaskBoard,
  makeSelectTableTitleFilters,
  makeSelectTaskBoardAssignees,
  makeSelectAvailableTaskBoards,
  makeSelectTaskBoardPagination,
  makeSelectTaskBoardGlobalFilterValue,
  makeSelectTaskBoardAdditionalFilterValue,
} from './selectors';
//////////////////////////////////////////////////

function* handleGetItemListSaga({ payload }: Object) {
  try {
    if (G.isTrue(payload)) yield put(openLoader());

    yield put(A.setListLoading(true));

    const availableReports = yield select(makeSelectAvailableReports());

    if (G.isNilOrEmpty(availableReports)) {
      yield put(closeLoader());
      yield put(A.setListLoading(false));

      return;
    }

    const objectGuid = yield select(makeSelectObjectGuid());
    const pagination = yield select(makeSelectPagination());
    const reportParams = yield select(makeSelectUsedReport());
    const assigneeGuid = yield select(makeSelectAssigneeGuid());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const systemFields = [GC.FIELD_TASK_NUMBER];
    const guids = R.pathOr(null, ['guids'], payload);
    const fields = G.getOrElse(reportParams, 'fields', []);

    const reqBody = {
      ...pagination,
      guids,
      fields,
      objectGuid,
      orderFields,
      assigneeGuid,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const reqData = G.addSystemFieldsToTableReport(
      G.setSearchCriteria({ reqBody, filterParams: newFilterParams }),
      systemFields,
    );

    const endpoint = R.prop(G.ifElse(R.isNotNil(assigneeGuid), 'taskListForAssignee', 'taskList'), endpointsMap);

    const res = yield call(sendRequest, 'post', endpoint, { data: reqData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, guids }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(closeLoader());
    yield put(A.setListLoading(false));
  } catch (err) {
    yield put(closeLoader());
    yield put(A.setListLoading(false));

    yield call(G.handleException, err, 'handleGetItemListSaga exception');
  }
}

function* handleDeleteItemSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'delete', endpointsMap.getTaskByGuid(payload));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'toastr:success:204');

      yield put(closeModal());
      yield put(A.deleteItemSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteItemSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteItemSaga exception');
  }
}

function* createOrUpdateTaskRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { callback, prevStatus, prevAssigneeGuid } = payload;

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const method = G.ifElse(
      G.getGuidFromObject(payload),
      'put',
      'post',
    );

    const options = {
      data: R.assoc(GC.BRANCH_GUID, R.pathOr(branchGuid, [GC.BRANCH_GUID], payload), payload),
    };

    const res = yield call(sendRequest, method, endpointsMap.task, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(closeModal());

      if (R.pathEq(GC.ROUTE_PATH_TASK_MANAGEMENT_BOARD, ['location', 'pathname'], window)) {
        const { guid, assigneeGuid } = data;

        if (R.isNotNil(R.prop(GC.FIELD_VERSION, payload))) {
          if (G.notEquals(assigneeGuid, prevAssigneeGuid)) {
            yield put(A.removeTaskFromBoard({ guid, [GC.FIELD_ASSIGNEE_GUID]: prevAssigneeGuid }));
          }

          const taskStatusConfigGuid = R.path([GC.FIELD_STATUS, GC.FIELD_CONFIG_GUID], data);

          if (G.notEquals(prevStatus, taskStatusConfigGuid)) {
            yield put(A.updateTaskStatusSuccess({
              assigneeGuid,
              taskGuid: guid,
              taskStatusConfigGuid,
              shouldNotUpdateStatus: true,
              prevTaskStatusConfigGuid: prevStatus,
            }));
          }
        }

        yield put(A.createOrUpdateTaskBoardItemSuccess(data));
      } else {
        yield put(A.createOrUpdateTaskSuccess(data));
      }

      G.callFunction(callback);
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteItemSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteItemSaga exception');
  }
}

function* updateTaskStatusRequest({ payload }: Object) {
  try {
    const { taskGuid, requestData, assigneeGuid, taskStatusConfigGuid, prevTaskStatusConfigGuid } = payload;

    const res = yield call(sendRequest, 'put', endpointsMap.changeTaskStatus(taskGuid), { data: requestData });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.updateTaskStatusSuccess({
        taskGuid,
        assigneeGuid,
        taskStatusConfigGuid,
        prevTaskStatusConfigGuid,
        dropdownOption: requestData,
      }));
    } else {
      yield call(G.handleFailResponse, res, 'updateTaskStatusRequest fail');
    }
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteItemSaga exception');
  }
}

// configs
function* getTaskTypeListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      successAction: A.getTaskTypeListSuccess,
      parentSagaName: 'getTaskTypeListRequest',
      endpoint: endpointsMap.taskTypeConfigList,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getTaskTypeListRequest exception');
  }
}

function* getTaskStatusListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      successAction: A.getTaskStatusListSuccess,
      parentSagaName: 'getTaskStatusListRequest',
      endpoint: endpointsMap.taskStatusConfigList,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getTaskStatusListRequest exception');
  }
}

function* getGroupedByTaskTypesTaskStatusMapRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      endpoint: endpointsMap.groupedByTaskTypesStatusMap,
      successAction: A.getGroupedByTaskTypesTaskStatusMapSuccess,
      parentSagaName: 'getGroupedByTaskTypesTaskStatusMapRequest',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getGroupedByTaskTypesTaskStatusMapRequest exception');
  }
}

// board
function* getTaskBoardSummaryRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const assigneeGuids = yield select(makeSelectTaskBoardAssignees());

    if (G.isNilOrEmpty(assigneeGuids)) return yield put(closeLoader());

    const options = {
      data: R.assoc('assigneeGuids', assigneeGuids, payload),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.taskSummaryByStatus, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getTaskBoardSummarySuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getTaskBoardSummaryRequest fail');
    }

    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());

    yield call(G.handleException, err, 'getTaskBoardSummaryRequest exception');
  }
}

function* getTaskBoardRequest() {
  try {
    yield put(openLoader());
    yield put(A.setTaskBoardListLoading(true));

    const availableTaskBoards = yield select(makeSelectAvailableTaskBoards());

    if (G.isNilOrEmpty(availableTaskBoards)) {
      yield put(closeLoader());
      yield put(A.setTaskBoardListLoading(false));

      return;
    }

    const pagination = yield select(makeSelectTaskBoardPagination());
    const selectedTaskBoard = yield select(makeSelectSelectedTaskBoard());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const globalFilterValue = yield select(makeSelectTaskBoardGlobalFilterValue());
    const additionalFilterValue = yield select(makeSelectTaskBoardAdditionalFilterValue());

    const { columns, orderFields, searchCriteria } = selectedTaskBoard;

    const reqBody = {
      ...pagination,
      columns,
      orderFields,
      globalFilterValue,
      additionalFilterValue,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
      additionalFilterType: G.ifElse(G.isNotNilAndNotEmpty(additionalFilterValue), GC.ADDITIONAL_FILTER_TYPE_ASSIGNEE),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.taskBoardList, { data: reqBody });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getTaskBoardSuccess(data));

      if (G.isNotNilAndNotEmpty(data)) {
        yield call(getTaskBoardSummaryRequest, { payload: reqBody });
      }
    } else {
      yield call(G.handleFailResponse, res, 'getTaskBoardRequest fail');
    }

    yield put(closeLoader());
    yield put(A.setTaskBoardListLoading(false));
  } catch (err) {
    yield put(closeLoader());
    yield put(A.setTaskBoardListLoading(false));

    yield call(G.handleException, err, 'getTaskBoardRequest exception');
  }
}

function* getAvailableTaskBoardsRequest({ notSetSelectedBoard }: Object = {}) {
  try {
    const currentBranchGuid = G.getAmousCurrentBranchGuidFromWindow();

    const params = {
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };

    const res = yield call(sendRequest, 'get', endpointsMap.taskBoardList, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const boards = R.map((item: Object) => R.assoc(
        'columns',
        R.sortBy(R.prop('sequence'), R.propOr([], 'columns', item)),
        item,
      ), R.or(data, []));

      const checkedBoards = checkReportFunction(boards);

      yield put(A.getAvailableTaskBoardsSuccess(checkedBoards));

      if (G.isNilOrEmpty(boards)) {
        const defaultBoard = {
          name: '',
          fields: [],
          prompt: false,
          searchCriteria: [],
          type: 'TASK_MANAGEMENT',
          guid: 'TASK_MANAGEMENT_DEFAULT',
        };

        return yield put(A.setSelectedTaskBoard(defaultBoard));
      }

      if (R.not(notSetSelectedBoard)) {
        const defaultBoard = R.or(R.find(R.propEq(true, 'defaultBoard'), checkedBoards), R.head(checkedBoards));

        yield put(A.setSelectedTaskBoard(defaultBoard));
      }
    } else {
      yield call(G.handleFailResponse, res, 'getAvailableTaskBoardsRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getAvailableTaskBoardsRequest exception');
  }
}

function* createOrUpdateTaskBoardRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const currentBranchGuid = G.getAmousCurrentBranchGuidFromWindow();
    const method = G.ifElse(G.isNotNil(R.prop(GC.FIELD_VERSION, payload)), 'put', 'post');
    const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));

    const options = {
      data: R.mergeRight(payload, { searchCriteria, [GC.FIELD_BRANCH_GUID]: currentBranchGuid }),
    };

    const res = yield call(sendRequest, method, endpointsMap.taskBoard, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const report = R.head(checkReportFunction(R.of(Array, data)));

      yield put(A.setSelectedTaskBoard(report));

      yield call(getAvailableTaskBoardsRequest, { notSetSelectedBoard: true });
      yield call(getTaskBoardRequest);
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateTaskBoardRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateTaskBoardRequest exception');
  }
}

function* removeTaskBoardRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'delete', endpointsMap.removeTaskBoard(payload));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'toastr:success:204');

      yield put(closeModal());
      yield put(A.removeTaskBoardSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'removeTaskBoardRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeTaskBoardRequest exception');
  }
}

function* setDefaultTaskBoardRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const userGuid = G.getAmousCurrentUserGuidFromWindow();

    const options = {
      params: { userGuid },
    };

    const endpoint = endpointsMap.setDefaultTaskBoard(payload.reportGuid);

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(getAvailableTaskBoardsRequest);
      yield call(getTaskBoardRequest);
    } else {
      yield call(G.handleFailResponse, res, 'setDefaultTaskBoardRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'setDefaultTaskBoardRequest exception');
  }
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(GC.TASK_MANAGEMENT_REPORT, A, handleGetItemListSaga, { createUpdateReportSuccessCallback });

function* handleVisitTaskManagementPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader());

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_TASK_MANAGEMENT_PAGE);

    const { type, objectGuid, assigneeGuid } = payload;

    yield put(A.setInitialState());

    const reportPageVisited = yield select(makeSelectPageVisited());
    const boardPageVisited = yield select(makeSelectTaskBoardVisited());

    yield call(getTaskTypeListRequest);
    yield call(getTaskStatusListRequest);
    yield call(getGroupedByTaskTypesTaskStatusMapRequest);

    if (R.equals(type, 'boardList')) {
      yield put(closeLoader());

      yield call(getAvailableTaskBoardsRequest, { notSetSelectedBoard: true });

      break;
    }

    if (R.equals(type, 'board')) {
      yield put(closeLoader());

      const notSetSelectedBoard = R.and(boardPageVisited, G.notEquals(type, 'driverTasks'));

      yield call(getAvailableTaskBoardsRequest, { notSetSelectedBoard });
      yield call(getTaskBoardRequest);

      break;
    }

    const notSetUsedReport = R.and(reportPageVisited, G.notEquals(type, 'driverTasks'));

    yield put(A.setIgnorePromptStatus(false));
    yield put(A.setReportPending());
    yield put(A.setObjectGuid(objectGuid));
    yield put(A.setAssigneeGuid(assigneeGuid));

    yield call(handleAvailableReportsRequest, { payload, notSetUsedReport });
    yield call(handleGetItemListSaga, { payload: true });

    yield put(closeLoader());

    break;
  }
}

function* taskManagementWatcherSaga() {
  yield takeLatest(GC.VISIT_TASK_MANAGEMENT_PAGE, handleVisitTaskManagementPageSaga);
  // report
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  // configs
  yield takeLatest(A.getTaskTypeListRequest, getTaskTypeListRequest);
  yield takeLatest(A.getTaskStatusListRequest, getTaskStatusListRequest);
  yield takeLatest(A.getGroupedByTaskTypesTaskStatusMapRequest, getGroupedByTaskTypesTaskStatusMapRequest);
  // item
  yield takeLatest(A.deleteItemRequest, handleDeleteItemSaga);
  yield takeLatest(A.updateTaskStatusRequest, updateTaskStatusRequest);
  yield takeLatest(A.createOrUpdateTaskRequest, createOrUpdateTaskRequest);
  // board
  yield takeLatest(A.getTaskBoardRequest, getTaskBoardRequest);
  yield takeLatest(A.removeTaskBoardRequest, removeTaskBoardRequest);
  yield takeLatest(A.setDefaultTaskBoardRequest, setDefaultTaskBoardRequest);
  yield takeLatest(A.getAvailableTaskBoardsRequest, getAvailableTaskBoardsRequest);
  yield takeLatest(A.createOrUpdateTaskBoardRequest, createOrUpdateTaskBoardRequest);
}

export default taskManagementWatcherSaga;
