import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as GC from '../../../constants';
// feature route-mass-create-report
import RouteNameList from '../components/route-name-list';
//////////////////////////////////////////////////

export const tableSettings = {
  cellFontSize: 13,
  titleFontSize: 14,
  titleRowHeight: 45,
  tableRowHeight: 40,
  useMainColors: true,
  checkBoxCellWidth: 70,
  expandableItems: true,
  allowSelectItems: true,
  maxHeight: 'calc(100vh - 140px)',
  expandedDetailsComponent: RouteNameList,
};

export const columnSettings = {
  [GC.FIELD_STATUS]: {
    width: 120,
    type: 'enum',
    name: 'titles:status',
  },
  totalCount: {
    width: 120,
    name: 'titles:total-count',
  },
  startedBy: {
    width: 200,
    name: 'titles:started-by',
    customComponent: ({ data }: Object) => {
      const user = `${R.pathOr('', ['createdByFirstName'], data)} ${
        R.pathOr('', ['createdByLastName'], data)} (${R.pathOr('', ['createdByLoginId'], data)})`;

      return (
        <TextComponent
          title={user}
          display='block'
          maxWidth='100%'
          withEllipsis={true}
        >
          {user}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 150,
    type: 'date',
    name: 'titles:created-date',
  },
  routeTemplateName: {
    width: 170,
    name: 'titles:route-template-name',
  },
  routeTemplateEnterpriseName: {
    width: 170,
    name: 'titles:route-template-branch',
  },
  error: {
    width: 150,
    name: 'titles:error',
  },
};

export const report = {
  fields: [
    { sequence: 1, name: GC.FIELD_STATUS },
    { sequence: 2, name: 'totalCount' },
    { sequence: 3, name: 'startedBy' },
    { sequence: 4, name: GC.FIELD_CREATED_DATE },
    { sequence: 5, name: 'routeTemplateName' },
    { sequence: 6, name: 'routeTemplateEnterpriseName' },
    { sequence: 7, name: 'error' },
  ],
};
