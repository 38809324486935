import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// forms
import { Toggle } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// feature role
import { ROLE_LOCALES } from '../settings/role-settings';
//////////////////////////////////////////////////

const ToggleInput = (props: Object) => (
  <Flex m='5px 10px'>
    <Box
      mr='5px'
      color={R.and(
        R.propEq(GC.FIELD_ROLE_USER_PERMISSION_WRITE, GC.FIELD_VALUE, props),
        G.getTheme('colors.lightGrey'),
      )}
    >
      {G.getWindowLocale('titles:read', 'Read')}
    </Box>
    <Toggle {...props} icons={false} checked={R.propEq(GC.FIELD_ROLE_USER_PERMISSION_WRITE, GC.FIELD_VALUE, props)} />
    <Box
      ml='5px'
      color={R.and(
        R.propEq(GC.FIELD_ROLE_USER_PERMISSION_WRITE, GC.FIELD_VALUE, props),
        G.getTheme('colors.light.green'),
      )}
    >
      {G.getWindowLocale('titles:write', 'Write')}
    </Box>
  </Flex>
);

const FieldComponent = (props: Object) => {
  const fieldsMap = {
    toggle: <ToggleInput {...R.omit(['uiType'], props)} />,
    'read-only': <Box px='5px' m='5px 10px' fontWeight='bold'>-</Box>,
  };
  return R.prop(props.uiType, fieldsMap);
};

const RoleFieldsetComponent = (props: Object) => {
  const { fields, values, handleChange, handleBlur, handleToggleSinglePermission } = props;

  return (
    <Box>
      {
        fields.map((item: Object, index: number) => {
          const permissionName = R.prop(GC.FIELD_ROLE_PERMISSION_NAME, item);
          const permissionDisplayName = R.pathOr(permissionName, [permissionName], ROLE_LOCALES);

          return (
            <Flex key={index}>
              <Flex
                pl={15}
                my='5px'
                width={370}
              >
                <Box mr={10}>
                  <Toggle
                    icons={false}
                    checked={item.active}
                    onChange={(e: Event) => handleToggleSinglePermission({
                      permission: item,
                      active: e.currentTarget.checked,
                    })}
                  />
                </Box>
                <TextComponent
                  fontSize={16}
                  maxWidth={310}
                  display='block'
                  withEllipsis={true}
                  title={permissionDisplayName}
                  color={R.not(item.active) && G.getTheme('colors.light.grey')}
                >
                  {permissionDisplayName}
                </TextComponent>
              </Flex>
              <FieldComponent
                onBlur={handleBlur}
                uiType={item.uiType}
                onChange={handleChange}
                id={R.prop(GC.FIELD_ROLE_PERMISSION_NAME, item)}
                disabled={R.or(item.disabled, R.not(item.active))}
                value={R.path([R.prop(GC.FIELD_ROLE_PERMISSION_NAME, item)], values)}
              />
            </Flex>
          );
        })
      }
    </Box>
  );
};

export default RoleFieldsetComponent;
