import React from 'react';
import * as R from 'ramda';
// components
import { PageTitle } from '../../../components/page-title';
import { FormButtons2 } from '../../../components/form-buttons-2';
import { FormGroupTitleComponent } from '../../../components/form-group-title';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import {
  Toggle,
  Fieldset2,
  FormGroupWrapper,
  LeftLabelsWrapper,
} from '../../../forms';
// ui
import {
  Box,
  Flex,
  RelativeBox,
  AbsoluteBox,
  PageHeaderWrapper,
} from '../../../ui';
// feature role
import * as H from '../helpers';
import RoleFieldsetComponent from './fieldset';
import { AddDoctypeComponent } from './add-doc-type';
import { roleFields } from '../settings/field-settings';
//////////////////////////////////////////////////

const PermissionGroupComponent = (props: Object) => (
  <RelativeBox zIndex={10}>
    {
      props.groups.map(({ groupName, opened, permissions }: Object) => (
        <FormGroupWrapper key={groupName} isOpened={opened}>
          <FormGroupTitleComponent
            isOpened={opened}
            withArrowDown={true}
            text={H.getGroupNameLocale(groupName)}
            onToggleFormGroup={() => props.handleClickToggleGroup(groupName)}
          >
            <AbsoluteBox
              top='0'
              left={272}
              width={100}
              onClick={G.stopPropagation}
            >
              <Toggle
                icons={false}
                borderColor={G.getTheme('colors.mainBlue')}
                checked={H.getAllActivePermissionsChecked(permissions)}
                onChange={(e: Event) =>
                  props.handleToggleGroupPermissions({
                    groupName,
                    permissions,
                    active: R.path(['currentTarget', 'checked'], e),
                  })
                }
              />
              <Box
                ml='5px'
                zIndex={10}
                color={G.getTheme('colors.white')}
              >
                {G.getWindowLocale('titles:all-active', 'All Active')}
              </Box>
            </AbsoluteBox>
            {
              R.gt(R.length(H.getAllAvailablePermissions(R.values(permissions))), 1) &&
              <AbsoluteBox
                top='0'
                left={417}
                width={93}
                onClick={G.stopPropagation}
              >
                <Toggle
                  icons={false}
                  borderColor={G.getTheme('colors.mainBlue')}
                  checked={H.getAllGroupPermissionsChecked(props, R.values(permissions))}
                  onChange={() => props.handleChangeAllToggleInputs(R.values(permissions))}
                />
                <Box
                  ml='5px'
                  zIndex={10}
                  color={G.getTheme('colors.white')}
                >
                  {G.getWindowLocale('titles:all-write', 'All Write')}
                </Box>
              </AbsoluteBox>
            }
          </FormGroupTitleComponent>
          <RoleFieldsetComponent
            {...props}
            values={props.values}
            handleBlur={props.handleBlur}
            fields={R.values(permissions)}
            handleChange={props.handleChangeToggleInput}
            handleToggleSinglePermission={props.handleToggleSinglePermission}
          />
        </FormGroupWrapper>
      ))
    }
  </RelativeBox>
);

const UserPermissionsComponent = (props: Object) => {
  const {
    title,
    values,
    editMode,
    handleBlur,
    isSubmitting,
    handleSubmit,
    handleClickCancel,
    handleChangeRoleType,
    handleClickSaveAsNew,
    permissionsGroupsList,
    handleClickToggleGroup,
    handleChangeToggleInput,
    handleChangeAllToggleInputs,
    handleToggleSinglePermission,
    handleToggleGroupPermissions,
  } = props;

  const groups = R.values(permissionsGroupsList);

  const getDocumentTypeField = R.compose(
    R.of(Array),
    R.assoc('label', G.ifElse(
      props.editMode,
      ['titles:document-types-edit', 'Edit Document Type'],
      ['titles:document-types-add', 'Add Document Type']),
    ),
    R.prop(GC.FIELD_ROLE_AVAILABLE_DOCUMENT_TYPE_GUIDS),
  )(roleFields);

  const getDocTypeOptions = R.map((item: Object) => (
    {
      label: item.displayedValue,
      value: G.getParentGuidOrGuidFromObject(item),
    }
  ), props.availableDocumentTypes);

  return (
    <form onSubmit={handleSubmit}>
      <PageHeaderWrapper pt={43} height='auto' aI='flex-end' justify='space-between'>
        <Flex alignItems='flex-end'>
          <PageTitle title={title} />
          <Fieldset2
            {...G.getFormikProps(props)}
            handleChange={handleChangeRoleType}
            handlers={{ handleDisableRoleType: () => editMode }}
            fields={R.of(Array, R.prop(GC.FIELD_TYPE, roleFields))}
          />
          {
            G.isNotNilAndNotEmpty(R.prop(GC.FIELD_TYPE, values)) &&
            <Flex height='max-content' alignItems='flex-start'>
              <Fieldset2
                {...G.getFormikProps(props)}
                fields={R.of(Array, R.prop(GC.FIELD_NAME, roleFields))}
              />
              <AddDoctypeComponent
                {...G.getFormikProps(props)}
                options={getDocTypeOptions}
                fields={getDocumentTypeField}
              />
            </Flex>
          }
        </Flex>
      </PageHeaderWrapper>
      {
        G.isNotNilAndNotEmpty(R.prop(GC.FIELD_TYPE, values)) &&
        <RelativeBox zIndex={10} minHeight='calc(100vh - 156px)'>
          <LeftLabelsWrapper width={370} />
          <PermissionGroupComponent
            groups={groups}
            values={values}
            handleBlur={handleBlur}
            handleClickToggleGroup={handleClickToggleGroup}
            handleChangeToggleInput={handleChangeToggleInput}
            handleChangeAllToggleInputs={handleChangeAllToggleInputs}
            handleToggleSinglePermission={handleToggleSinglePermission}
            handleToggleGroupPermissions={handleToggleGroupPermissions}
          />
        </RelativeBox>
      }
      <FormButtons2
        saveAsNewBtnType='button'
        submitting={isSubmitting}
        handleClickCancel={handleClickCancel}
        handleClickSaveAsNew={handleClickSaveAsNew}
        showSaveAsNew={G.isFunction(handleClickSaveAsNew)}
      />
    </form>
  );
};

export default UserPermissionsComponent;
