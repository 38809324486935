import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withHandlers, renderNothing } from 'react-recompose';
// components
import { Table } from '../../components/table';
import { TitlePanel } from '../../components/title-panel';
import { getConfirmModal } from '../../components/confirm';
import { PageActions } from '../../components/page-actions';
import { openModal, closeModal } from '../../components/modal/actions';
// features
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
// ui
import { PageWrapper, ZOrderWrapper } from '../../ui';
// feature route-mass-create-report
import { report, tableSettings, columnSettings } from './settings/table-settings';
import {
  selectItem,
  toggleDetails,
  deleteItemsRequest,
  getItemListRequest,
  resetListAndPagination,
} from './actions';
import {
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectListLoading,
} from './selectors';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleDeleteItems: (props: Object) => () => {
      const { itemList, openModal, closeModal, deleteItemsRequest } = props;

      const selectedList = G.getSelectedItemGuids(itemList);

      if (G.isNilOrEmpty(selectedList)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        submitAction: () => deleteItemsRequest(selectedList),
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: G.getWindowLocale(
          'messages:confirm-delete-entities',
          'Are you sure you want to delete these entities?',
        ),
      });

      openModal(modalContent);
    },
  }),
  branch(
    ({ initialDataLoaded }: Object) => R.not(initialDataLoaded),
    renderNothing,
  ),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    selectItem,
    pagination,
    toggleDetails,
    getItemListRequest,
  } = props;

  const allChecked = G.isAllChecked(itemList);

  const data = {
    report,
    loading,
    itemList,
    allChecked,
    totalCount,
    pagination,
    tableSettings,
    columnSettings,
    hasSelectable: true,
    toggle: toggleDetails,
    onOptionClick: selectItem,
    handleLoadMoreEntities: getItemListRequest,
  };

  return <Table {...data} />;
};

const RouteMassCreateReport = enhance((props: Object) => {
  const { itemList, totalCount, getItemListRequest, handleDeleteItems, resetListAndPagination } = props;

  const reloadAction = () => {
    resetListAndPagination();
    setTimeout(() => getItemListRequest(), 100);
  };

  return (
    <PageWrapper pt={15} pr={15}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          display='flex'
          withCount={true}
          withReload={true}
          noExportable={true}
          hideFilterInfo={true}
          popperWithCount={true}
          totalCount={totalCount}
          withoutQuickFilter={true}
          reloadAction={reloadAction}
          hiddenRightFilterInfo={true}
          title={G.getWindowLocale('titles:route-mass-create-report', 'Route Mass Create Report')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        {renderTable(props)}
      </ZOrderWrapper>
      <PageActions
        shadowColor={G.getTheme('createButton.shadowColor')}
        count={R.filter(R.prop('selected'), R.or(itemList, [])).length}
        mainAction={{
          showCount: true,
          action: handleDeleteItems,
          color: G.getTheme('colors.light.mainRed'),
          text: G.getWindowLocale('actions:delete', 'Delete'),
        }}
      />
    </PageWrapper>
  );
});

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  selectItem,
  toggleDetails,
  deleteItemsRequest,
  getItemListRequest,
  resetListAndPagination,
})(RouteMassCreateReport);
