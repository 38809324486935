import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const getPercentage = (a: number, b: number) => Math.floor((a / b) * 100);

export const getTableContainerPaddingLeft = (fixedColumnOptions: Array) => (
  R.reduce((sum: number, field: Object) => sum + field.width, 70, fixedColumnOptions)
);

export const getProgressColor = (progress: number) => {
  let color = G.getTheme('progressBar.progressBgRed');
  if (R.lte(progress, 60)) {
    color = G.getTheme('progressBar.progressBgGreen');
  } else if (R.lte(progress, 90)) {
    color = G.getTheme('progressBar.progressBgYellow');
  }

  return color;
};
