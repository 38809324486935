import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature role
import * as A from './actions';
import * as H from './helpers';
import { LIST_OWN, LIST_AVAILABLE } from './constants';
//////////////////////////////////////////////////

const report = {
  fields: [
    {
      sequence: 1,
      name: 'name',
      freezed: false,
      reference: false,
    },
  ],
};

const totalCountRoles = {
  [LIST_OWN]: null,
  [LIST_AVAILABLE]: null,
};

const initialState = {
  [LIST_OWN]: {},
  loading: false,
  permissions: null,
  usedReport: report,
  activeList: LIST_OWN,
  [LIST_AVAILABLE]: {},
  requestPending: false,
  defaultPermissions: [],
  availablePermissions: [],
  permissionsGroupsList: {},
  totalCount: totalCountRoles,
  editRoleInitialValues: null,
  roleSelectedDocumentTypes: [],
  editRoleUserPermissionGroupList: {},
  permissionsGroupsListUnnormalized: null,
};

const getRoleListRequest = (state: Object) => (
  P.$all(
    P.$set('roleList', {}),
    P.$set('loading', true),
    P.$set('activeList', LIST_OWN),
    P.$set('requestPending', true),
    state,
  )
);

const getRoleListFail = (state: Object) => (
  P.$set('requestPending', false, state)
);

const resetUserPermissions = (state: Object) => P.$all(
  P.$set('defaultPermissions', initialState.defaultPermissions),
  P.$set('availablePermissions', initialState.availablePermissions),
  P.$set('permissionsGroupsList', initialState.permissionsGroupsList),
  P.$set('editRoleInitialValues', initialState.editRoleInitialValues),
  P.$set('editRoleUserPermissionGroupList', initialState.editRoleUserPermissionGroupList),
  state,
);

const getRoleListSuccess = (state: Object, data: Array) => (
  G.ifElse(
    G.isNotEmpty(data),
    P.$all(
      P.$set('loading', false),
      P.$set('requestPending', false),
      P.$set(`totalCount.${LIST_OWN}`, R.length(R.keys(data))),
      P.$set('roleList', H.mapWithSelectedFalseAndIndexByGuid(data)),
      state,
    ),
    state,
  )
);

const availableRoleListRequest = (state: Object) => (
  P.$set('requestPending', true, state)
);

const getRoleAvailableListFail = (state: Object) => (
  P.$set('requestPending', false, state)
);

const availableRoleListSuccess = (state: Object, data: Array) => (
  G.ifElse(
    G.isNotEmpty(data),
    P.$all(
      P.$set('loading', false),
      P.$set('requestPending', false),
      P.$set(`totalCount.${LIST_AVAILABLE}`, R.length(R.keys(data))),
      P.$set('roleAvailableList', H.mapWithSelectedFalseAndIndexByGuid(data)),
      state,
    ),
    P.$set('loading', false, state),
  )
);

const addNewRoleRequest = (state: Object) => (
  P.$set('requestPending', true, state)
);

const addNewRoleFail = (state: Object) => (
  P.$set('requestPending', false, state)
);

const addNewRoleSuccess = (state: Object, data: Object) => (
  P.$all(
    P.$set('requestPending', false),
    P.$add('roleList', { [data.guid]: data }),
    state,
  )
);

const updateRoleRequest = (state: Object) => (
  P.$set('requestPending', true, state)
);

const updateRoleFail = (state: Object) => (
  P.$set('requestPending', false, state)
);

const updateRoleSuccess = (state: Object) => (
  P.$set('requestPending', false, state)
);

const removeRoleRequest = (state: Object) => (
  P.$set('requestPending', true, state)
);

const selectItem = (state: Object, id: Object) => {
  const { roleList } = state;
  if (R.equals(id, 'all')) {
    const value = R.not(R.all((item: Object) => item.selected, R.values(roleList)));
    return P.$set(
      'roleList',
      R.map((item: Object) => R.assoc('selected', value, item), roleList),
      state,
    );
  }
  return P.$toggle(`roleList.${id}.selected`, state);
};

export const removeRoleFail = (state: Object) => (
  P.$set('requestPending', false, state)
);

const removeRoleSuccess = (state: Object, data: any) => {
  const { roleList, totalCount, roleAvailableList } = state;

  if (G.isArray(data)) {
    const filterRoles = (item: any) => R.not(R.includes(G.getGuidFromObject(item), data));
    const roles = R.filter(filterRoles, roleList);
    const rolesAvailable = R.filter(filterRoles, roleAvailableList);
    const totalRoleList = R.subtract(totalCount.roleList, R.length(data));
    const totalRoleAvailableList = R.subtract(totalCount.roleAvailableList, R.length(data));

    return P.$all(
      P.$set('roleList', roles),
      P.$set('requestPending', false),
      P.$set('roleAvailableList', rolesAvailable),
      P.$set(`totalCount.${LIST_OWN}`, totalRoleList),
      P.$set(`totalCount.${LIST_AVAILABLE}`, totalRoleAvailableList),
      state,
    );
  }

  return P.$all(
    P.$drop(`roleList.${data}`),
    P.$set('requestPending', false),
    P.$drop(`roleAvailableList.${data}`),
    P.$set(`totalCount.${LIST_OWN}`, R.dec(totalCount.roleList)),
    P.$set(`totalCount.${LIST_AVAILABLE}`, R.dec(totalCount.roleAvailableList)),
    state,
  );
};

const getPermissionsGroupsListRequest = (state: Object) => (
  P.$set('requestPending', true, state)
);

const getPermissionsGroupsListFail = (state: Object) => (
  P.$set('requestPending', false, state)
);

const getPermissionsGroupsListSuccess = (state: Object, payload: Object) => (
  P.$all(
    P.$set('requestPending', false),
    P.$set('permissionsGroupsListUnnormalized', payload.data),
    P.$set('permissions', payload.mapped),
    state,
  )
);

const getRolePermissionsGroupsListRequest = (state: Object) => (
  P.$set('requestPending', true, state)
);

const getRolePermissionsGroupsListFail = (state: Object) => (
  P.$set('requestPending', false, state)
);

const getRolePermissionsGroupsListSuccess = (state: Object, data: Object) => {
  const { permissions } = state;
  const { rolePermissions, defaultPermissions, availablePermissions } = data;
  const availableAndDefaultPermissions = H.getAvailableAndDefaultUserPermissions(
    defaultPermissions,
    availablePermissions,
  );
  const availableAndDefaultPermissionNames = R.map((item: Object) =>
    item.permissionName,
    availableAndDefaultPermissions,
  );
  const filteredInitialRolePermissions = R.filter(
    (item: Object) => R.or(
      R.isNil(availablePermissions),
      R.includes(R.prop(GC.FIELD_ROLE_PERMISSION_NAME, item), availableAndDefaultPermissionNames),
    ),
    rolePermissions,
  );
  const userPermissions = R.reduce(
    (acc: Object, currentValue: Object) => Object.assign(
      acc,
      { [R.prop(GC.FIELD_ROLE_PERMISSION_NAME, currentValue)]: currentValue.action },
    ),
    {},
    filteredInitialRolePermissions,
  );
  const initialValues = R.compose(
    R.mergeRight(userPermissions),
    R.dissoc('rolePermissions'),
  )(data);
  const initialPermissionNames = R.map(({ permissionName }: Object) =>
    permissionName,
    R.or(filteredInitialRolePermissions, []),
  );
  const getDefaultUserPermissions = R.compose(
    R.mapObjIndexed((item: Object, key: Object) => (
      {
        opened: true,
        groupName: key,
        selected: false,
        permissions: R.indexBy(R.prop(GC.FIELD_ROLE_PERMISSION_NAME), R.map((permission: Object) =>
          R.mergeRight(
            permission,
            {
              checked: false,
              disabled: G.notContain(GC.FIELD_ROLE_USER_PERMISSION_WRITE, permission.actions),
              active: R.includes(R.prop(GC.FIELD_ROLE_PERMISSION_NAME, permission), initialPermissionNames),
            },
          ),
          item,
        )),
      }
    )),
    R.groupBy(R.prop('group')),
    R.map((item: Object) => {
      if (G.isNilOrEmpty(defaultPermissions)) return item;
      const permissionActions = R.compose(
        R.pick(['actions', 'defaultPermission']),
        R.find(R.propEq(R.prop(GC.FIELD_ROLE_PERMISSION_NAME, item), GC.FIELD_ROLE_PERMISSION_NAME)),
      )(availableAndDefaultPermissions);
      return R.mergeRight(item, permissionActions);
    }),
    R.filter((item: Object) => {
      if (G.isNilOrEmpty(availableAndDefaultPermissions)) return item;
      return R.includes(R.prop(GC.FIELD_ROLE_PERMISSION_NAME, item), availableAndDefaultPermissionNames);
    }),
  )(permissions);
  return P.$all(
    P.$set('requestPending', false),
    P.$set('editRoleInitialValues', initialValues),
    P.$set('defaultPermissions', defaultPermissions),
    P.$set('availablePermissions', availablePermissions),
    P.$set('editRoleUserPermissionGroupList', getDefaultUserPermissions),
    state,
  );
};

const handleToggleGroupRequest = (state: Object, payload: Object) => {
  const { groupGuid, form } = payload;
  if (R.equals(form, 'edit')) {
    return P.$toggle(`editRoleUserPermissionGroupList.${groupGuid}.opened`, state);
  }
  return P.$toggle(`permissionsGroupsList.${groupGuid}.opened`, state);
};

const clearRolesStateAfterSwitchBranch = (state: Object) => (
  P.$all(
    P.$set('roleList', initialState.roleList),
    P.$set('roleAvailableList', initialState.roleAvailableList),
    P.$set('editRoleInitialValues', initialState.editRoleInitialValues),
    P.$set('editRoleUserPermissionGroupList', initialState.editRoleUserPermissionGroupList),
    state,
  )
);

const checkAllRoles = (state: Object, toggle: boolean) => {
  const { permissionsGroupsList } = state;
  const changeValues = { active: toggle, checked: toggle };
  const groups = R.values(permissionsGroupsList);
  const changedPermissionObj = (thisObj: Object) => R.mergeRight(thisObj, changeValues);
  const changedGroupObj = (thisObj: Object) => (
    R.mergeRight(
      thisObj,
      {
        [GC.FIELD_ROLE_USER_PERMISSIONS]: R.mapObjIndexed(
          changedPermissionObj,
          R.prop(GC.FIELD_ROLE_USER_PERMISSIONS, thisObj),
        ),
      },
    )
  );
  const newObj = R.compose(
    R.indexBy(R.prop('groupName')),
    R.map((item: Object) => R.assoc('selected', false, item)),
    R.values(),
    R.mapObjIndexed(changedGroupObj),
  )(groups);
  return (
    P.$set('permissionsGroupsList', newObj, state)
  );
};

const getRoleSelectedDocumentTypes = (state: Object, data: Object) => (
  P.$set('roleSelectedDocumentTypes', data, state)
);

export const setActiveList = (state: Object, activeList: string) => (
  P.$set('activeList', activeList, state)
);

const getUserPermissionsByRoleTypeSuccess = (state: Object, data: Object) => {
  const { permissions } = state;
  const { defaultPermissions, availablePermissions } = data;
  const availableAndDefaultPermissions = H.getAvailableAndDefaultUserPermissions(
    defaultPermissions,
    availablePermissions,
  );
  const availableAndDefaultPermissionNames = R.map((item: Object) =>
    item.permissionName,
    availableAndDefaultPermissions,
  );
  const getDefaultUserPermissions = R.compose(
    R.mapObjIndexed((item: Object, key: Object) => (
      {
        opened: true,
        groupName: key,
        selected: false,
        permissions: R.indexBy(R.prop(GC.FIELD_ROLE_PERMISSION_NAME), R.map((permission: Object) =>
          R.mergeRight(
            permission,
            {
              disabled: G.notContain(GC.FIELD_ROLE_USER_PERMISSION_WRITE, permission.actions),
              active: R.and(G.isNotNilAndNotEmpty(defaultPermissions), permission.defaultPermission),
              checked: R.and(G.isNotNilAndNotEmpty(defaultPermissions), permission.defaultPermission),
            },
          ),
          item,
        )),
      }
    )),
    R.groupBy(R.prop('group')),
    R.map((item: Object) => {
      if (G.isNilOrEmpty(defaultPermissions)) return item;
      const permissionActions = R.compose(
        R.pick(['actions', 'defaultPermission']),
        R.find(R.propEq(R.prop(GC.FIELD_ROLE_PERMISSION_NAME, item), GC.FIELD_ROLE_PERMISSION_NAME)),
      )(availableAndDefaultPermissions);
      return R.mergeRight(item, permissionActions);
    }),
    R.filter(({ permissionName }: Object) => R.includes(permissionName, availableAndDefaultPermissionNames)),
  )(permissions);
  return P.$all(
    P.$set('defaultPermissions', defaultPermissions),
    P.$set('availablePermissions', availablePermissions),
    P.$set('permissionsGroupsList', getDefaultUserPermissions),
    state,
  );
};


const toggleSinglePermissionRequest = (state: Object, payload: Object) => {
  const { form, permission } = payload;

  const permissionName = R.prop(GC.FIELD_ROLE_PERMISSION_NAME, permission);

  const path = G.ifElse(
    R.equals(form, 'edit'),
    `editRoleUserPermissionGroupList.${R.path(['group'], permission)}.permissions.${permissionName}.active`,
    `permissionsGroupsList.${R.path(['group'], permission)}.permissions.${permissionName}.active`,
  );

  return P.$toggle(path, state);
};

const toggleGroupPermissionsRequest = (state: Object, payload: Object) => {
  const { form, active, groupName } = payload;

  const path = G.ifElse(
    R.equals(form, 'edit'),
    `editRoleUserPermissionGroupList.${groupName}.permissions`,
    `permissionsGroupsList.${groupName}.permissions`,
  );

  const newPermissions = R.compose(
    R.indexBy(R.prop(GC.FIELD_ROLE_PERMISSION_NAME)),
    R.map(R.assoc(GC.FIELD_ACTIVE, active)),
    R.values,
  )(P.$get(path, state));

  return P.$set(path, newPermissions, state);
};

export default createReducer({
  [A.selectItem]: selectItem,
  [A.checkAllRoles]: checkAllRoles,
  [A.setActiveList]: setActiveList,
  [A.addNewRoleFail]: addNewRoleFail,
  [A.removeRoleFail]: removeRoleFail,
  [A.updateRoleFail]: updateRoleFail,
  [A.getRoleListFail]: getRoleListFail,
  [A.addNewRoleRequest]: addNewRoleRequest,
  [A.addNewRoleSuccess]: addNewRoleSuccess,
  [A.updateRoleRequest]: updateRoleRequest,
  [A.updateRoleSuccess]: updateRoleSuccess,
  [A.removeRoleRequest]: removeRoleRequest,
  [A.removeRoleSuccess]: removeRoleSuccess,
  [A.getRoleListSuccess]: getRoleListSuccess,
  [A.getRoleListRequest]: getRoleListRequest,
  [A.resetUserPermissions]: resetUserPermissions,
  [A.handleToggleGroupRequest]: handleToggleGroupRequest,
  [A.getRoleAvailableListFail]: getRoleAvailableListFail,
  [A.availableRoleListSuccess]: availableRoleListSuccess,
  [A.availableRoleListRequest]: availableRoleListRequest,
  [A.getRoleSelectedDocumentTypes]: getRoleSelectedDocumentTypes,
  [A.getPermissionsGroupsListFail]: getPermissionsGroupsListFail,
  [A.toggleGroupPermissionsRequest]: toggleGroupPermissionsRequest,
  [A.toggleSinglePermissionRequest]: toggleSinglePermissionRequest,
  [A.getPermissionsGroupsListRequest]: getPermissionsGroupsListRequest,
  [A.getPermissionsGroupsListSuccess]: getPermissionsGroupsListSuccess,
  [A.getRolePermissionsGroupsListFail]: getRolePermissionsGroupsListFail,
  [A.clearRolesStateAfterSwitchBranch]: clearRolesStateAfterSwitchBranch,
  [A.getUserPermissionsByRoleTypeSuccess]: getUserPermissionsByRoleTypeSuccess,
  [A.getRolePermissionsGroupsListRequest]: getRolePermissionsGroupsListRequest,
  [A.getRolePermissionsGroupsListSuccess]: getRolePermissionsGroupsListSuccess,
}, initialState);
