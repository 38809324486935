// feature template-inspection
import * as I from '../assets/component-icons';
//////////////////////////////////////////////////

const iconsConfig = [
  { iconName: 'oilIcon', Icon: I.oilIcon },
  { iconName: 'pipeIcon', Icon: I.pipeIcon },
  { iconName: 'hornIcon', Icon: I.hornIcon },
  { iconName: 'gearsIcon', Icon: I.gearsIcon },
  { iconName: 'brakeIcon', Icon: I.brakeIcon },
  { iconName: 'strapIcon', Icon: I.strapIcon },
  { iconName: 'safetyIcon', Icon: I.safetyIcon },
  { iconName: 'idCardIcon', Icon: I.idCardIcon },
  { iconName: 'mufflerIcon', Icon: I.mufflerIcon },
  { iconName: 'batteryIcon', Icon: I.batteryIcon },
  { iconName: 'padlockIcon', Icon: I.padlockIcon },
  { iconName: 'airPumpIcon', Icon: I.airPumpIcon },
  { iconName: 'serviceIcon', Icon: I.serviceIcon },
  { iconName: 'radiatorIcon', Icon: I.radiatorIcon },
  { iconName: 'highHoldIcon', Icon: I.highHoldIcon },
  { iconName: 'fuelLevelIcon', Icon: I.fuelLevelIcon },
  { iconName: 'engineFanIcon', Icon: I.engineFanIcon },
  { iconName: 'handbrakeIcon', Icon: I.handbrakeIcon },
  { iconName: 'carCameraIcon', Icon: I.carCameraIcon },
  { iconName: 'windshieldIcon', Icon: I.windshieldIcon },
  { iconName: 'automotiveIcon', Icon: I.automotiveIcon },
  { iconName: 'brakeFluidIcon', Icon: I.brakeFluidIcon },
  { iconName: 'beltEngineIcon', Icon: I.beltEngineIcon },
  { iconName: 'stopEngineIcon', Icon: I.stopEngineIcon },
  { iconName: 'lifeJacketIcon', Icon: I.lifeJacketIcon },
  { iconName: 'tireChainsIcon', Icon: I.tireChainsIcon },
  { iconName: 'checkEngineIcon', Icon: I.checkEngineIcon },
  { iconName: 'medicalCardIcon', Icon: I.medicalCardIcon },
  { iconName: 'transmissionIcon', Icon: I.transmissionIcon },
  { iconName: 'differentialIcon', Icon: I.differentialIcon },
  { iconName: 'brakeCaliperIcon', Icon: I.brakeCaliperIcon },
  { iconName: 'coolantFluidIcon', Icon: I.coolantFluidIcon },
  { iconName: 'exhaustFluidIcon', Icon: I.exhaustFluidIcon },
  { iconName: 'carLubricantIcon', Icon: I.carLubricantIcon },
  { iconName: 'windowHeaterIcon', Icon: I.windowHeaterIcon },
  { iconName: 'steeringWheelIcon', Icon: I.steeringWheelIcon },
  { iconName: 'exhaustSystemIcon', Icon: I.exhaustSystemIcon },
  { iconName: 'spareCarFusesIcon', Icon: I.spareCarFusesIcon },
  { iconName: 'carBatteryLVDIcon', Icon: I.carBatteryLVDIcon },
  { iconName: 'rearWheelDriveIcon', Icon: I.rearWheelDriveIcon },
  { iconName: 'brakeConnectorIcon', Icon: I.brakeConnectorIcon },
  { iconName: 'universalPointIcon', Icon: I.universalPointIcon },
  { iconName: 'oilPressureLowIcon', Icon: I.oilPressureLowIcon },
  { iconName: 'absWarningLightIcon', Icon: I.absWarningLightIcon },
  { iconName: 'coolantLevelLowIcon', Icon: I.coolantLevelLowIcon },
  { iconName: 'fireExtinguisherIcon', Icon: I.fireExtinguisherIcon },
  { iconName: 'couplingKingPinIcon', Icon: I.couplingKingPinIcon },
  { iconName: 'suspensionSystemIcon', Icon: I.suspensionSystemIcon },
  { iconName: 'reflectiveTriangleIcon', Icon: I.reflectiveTriangleIcon },
  { iconName: 'rearWindowDemisterIcon', Icon: I.rearWindowDemisterIcon },
  { iconName: 'coolantTemperatureIcon', Icon: I.coolantTemperatureIcon },
  { iconName: 'powerSteeringLiquidIcon', Icon: I.powerSteeringLiquidIcon },
  { iconName: 'stabilityControlOffIcon', Icon: I.stabilityControlOffIcon },
  { iconName: 'dieselParticleFilterIcon', Icon: I.dieselParticleFilterIcon },
  { iconName: 'highExhaustTemperatureIcon', Icon: I.highExhaustTemperatureIcon },
  { iconName: 'absTrailerWarningLightIcon', Icon: I.absTrailerWarningLightIcon },
];

export default iconsConfig;
