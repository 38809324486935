import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// utilities
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature sequences
import * as A from './actions';
//////////////////////////////////////////////////

export const initialState = {
  transformers: [],
  sequencesList: [],
  currentSequence: {},
};

const getSequencesListSuccess = (state: Object, data: Object) => {
  const mappedList = R.map((item: Object) => R.assoc(
    GC.FIELD_BRANCH_NAME,
    G.getBranchNameFromWindowByGuid(G.getBranchGuidFromObject(item)),
    item,
  ), R.or(data, []));

  return P.$set('sequencesList', mappedList, state);
};

const getSequenceSuccess = (state: Object, data: Object) => (
  P.$set('currentSequence', data, state)
);

const getSequenceTransformersSuccess = (state: Object, data: Object) => (
  P.$set('transformers', data, state)
);

const createNewSequenceSuccess = (state: Object, data: Object) => P.$add('sequencesList', data, state);

const updateSequenceSuccess = (state: Object, data: Object) => {
  const { sequencesList } = state;

  const index = R.findIndex(R.propEq(G.getGuidFromObject(data), GC.FIELD_GUID), sequencesList);

  return P.$set(`sequencesList.${index}`, data, state);
};

const deleteSequenceSuccess = (state: Object, guid: Object) => {
  const { sequencesList } = state;

  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), sequencesList);

  return P.$drop(`sequencesList.${index}`, state);
};

export default createReducer({
  [A.getSequenceSuccess]: getSequenceSuccess,
  [A.updateSequenceSuccess]: updateSequenceSuccess,
  [A.deleteSequenceSuccess]: deleteSequenceSuccess,
  [A.getSequencesListSuccess]: getSequencesListSuccess,
  [A.createNewSequenceSuccess]: createNewSequenceSuccess,
  [A.getSequenceTransformersSuccess]: getSequenceTransformersSuccess,
}, initialState);
