import * as R from 'ramda';
import * as Yup from 'yup';
import moment from 'moment';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature template/route
import { getFields, getRouteFields, validationSchemaClo } from './create-routes-settings';
//////////////////////////////////////////////////

const scheduleTabsOptions = [
  {
    value: 0,
    text: G.getWindowLocale('titles:routes', 'Routes'),
  },
  {
    value: 1,
    text: G.getWindowLocale('titles:scheduler', 'Scheduler'),
  },
];

const scheduleDateFields = [
  {
    isRequired: true,
    type: 'datePicker',
    fieldName: GC.FIELD_SCHEDULE_DATE,
    inputWrapperStyles: { width: 120 },
    placeholder: GC.DEFAULT_DATE_PLACEHOLDER,
    label: G.getWindowLocale('titles:schedule-from', 'Schedule From'),
  },
];

const getDayFields = (i: number) => GC.shortDays.map((day: string) => ({
  width: 35,
  fieldName: `routes.${i}.${day}`,
  inputStyles: { pl: '5px', pr: '5px' },
  inputWrapperStyles: { m: '20px 15px 0 0'},
  label: G.getWindowLocale(`titles:${day}`, G.toTitleCase(day)),
}));

const getScheduleRouteFields = (i: number) => [
  ...getRouteFields(i),
  ...getDayFields(i),
];

const getRadioDayOptions = (values: Object) => GC.shortDays.map((day: string) => ({
  value: day,
  label: `${G.toTitleCase(day)} (${R.length(values[day] ? values[day] : [])})`,
}));

const getScheduleRouteByDayFields = (values: Object) => [
  {
    type: 'radio',
    fieldName: 'dayOfWeek',
    options: getRadioDayOptions(values),
    radioGroupWrapperStyles: {
      mt: 0,
      mr: 15,
      width: 550,
    },
  },
  ...scheduleDateFields,
];

const getNewReferenceFields = () => ({
  uniq: G.generateGuid(),
  [GC.FIELD_VALUE]: null,
  [GC.FIELD_REFERENCE_TYPE_GUID]: null,
});

const getContainerFields = (containerInternalId: string) => ({
  containerInternalId,
  uniq: G.generateGuid(),
  [GC.FIELD_CONTAINER_NUMBER]: null,
  [GC.FIELD_CONTAINER_INITIAL]: null,
});

const mapReferences = ((references: Object) => {
  if (G.isNilOrEmpty(references)) R.of(Array, getNewReferenceFields());

  return R.map(R.assoc('value', null), references);
});

const getRouteStartDate = (props: Object) => {
  const { dayOfWeek, scheduleDate } = props;

  const dayEnum = GC.shortDaysToDayKeysMap[dayOfWeek];

  const targetWeekday = GC.momentDayNumberMap[dayEnum];

  const startDateMoment = moment(scheduleDate);

  const startWeekday = startDateMoment.weekday();

  let daysUntilNextWeekday = targetWeekday - startWeekday;

  if (daysUntilNextWeekday <= 0) {
    daysUntilNextWeekday += 7;
  }

  const nextWeekday = startDateMoment.add(daysUntilNextWeekday, 'days');

  return G.convertInstanceToDefaultDateFormat(nextWeekday);
};

const getNewRow = (clos: Array, values: Object) => {
  const cloFields = R.reduce(
    (acc: Object, { guid, references, containers }: Object) => {
      const refsToUse = mapReferences(references);

      const containersToUse = R.map(
        ({ containerInternalId }: Object) => getContainerFields(containerInternalId),
        R.or(containers, []),
      );

      return R.assoc(
        guid,
        {
          [GC.FIELD_REFERENCES]: R.map(R.assoc('uniq', G.generateGuid()), refsToUse),
          [GC.FIELD_CONTAINERS]: R.map(R.assoc('uniq', G.generateGuid()), containersToUse),
        },
        acc,
      );
    },
    { uniq: G.generateGuid() },
    clos,
  );

  const startDate = getRouteStartDate(values);

  return R.mergeRight({ [GC.FIELD_FIRST_PICKUP_START_DATE]: startDate }, cloFields);
};

const getCopyRow = R.compose(
  R.mapObjIndexed((value: Object, key: string) => {
    if (G.isAnyTrue(R.equals(key, GC.FIELD_FIRST_PICKUP_START_DATE), R.equals(key, 'eventData'), R.equals(key, 'uniq'))) return value;

    return R.assoc(
      'containers',
      R.map((container: Object) => (
        {...container, [GC.FIELD_CONTAINER_NUMBER]: null, [GC.FIELD_CONTAINER_INITIAL]: null }
      ), G.getPropFromObject('containers', value)),
      value,
    );
  }),
  R.clone,
);

const getDefaultTemplateFields = (props: Object) => {
  const { clos, asyncInitialData } = props;

  const routesDetailsMap = R.path(['data', 'routesDetailsMap'], asyncInitialData);

  const scheduleDate = G.getCurrentDay();

  if (G.isNilOrEmpty(routesDetailsMap)) return { [GC.FIELD_SCHEDULE_DATE]: scheduleDate };

  const days = R.compose(
    R.mapObjIndexed((routes: Array, dayOfWeek: string) => R.map(
        (routeData: Object) => {
          const { eventData } = routeData;

          const route = getNewRow(clos, { dayOfWeek, scheduleDate });

          const eventDataToUse = R.mapObjIndexed(
            (data: Array, guid: string) => ({
              ...data, guid,
            }),
            eventData,
          );

          return {
            ...route,
            eventData: eventDataToUse,
          };
        },
        routes,
      ),
    ),
    G.renameKeys(GC.dayEnumToToShortDayKeysMap),
  )(routesDetailsMap);

  return { [GC.FIELD_SCHEDULE_DATE]: scheduleDate, ...days };
};

const getAllowedValues = (props: Object) => {
  const { values, cloGuid, itemIndex, routeIndex, dayOfWeek, referenceTypes } = props;

  const itemRefTypeGuid = R.path(
    [dayOfWeek, routeIndex, cloGuid, GC.FIELD_REFERENCES, itemIndex, GC.FIELD_REFERENCE_TYPE_GUID],
    values,
  );

  return R.path(
    ['allowedValues'],
    R.find(R.propEq(itemRefTypeGuid, GC.FIELD_GUID), R.pathOr([], [GC.REF_SCOPE_NAME_CLO], referenceTypes)),
  );
};

const getAllowedOptions = (props: Object) => {
  const allowedValues = getAllowedValues(props);

  if (G.isNotNilAndNotEmpty(allowedValues)) {
    return R.map(
      (item: string) => ({ label: item, value: item }),
      getAllowedValues(props),
    );
  }

  return [];
};

const getReferenceFields = (props: Object) => {
  const allowedValues = getAllowedValues(props);

  let fields;

  if (G.isNotNilAndNotEmpty(allowedValues)) {
    fields = getFields(1);
  } else {
    fields = getFields(0);
  }

  if (G.isNotNil(R.path(['item', GC.FIELD_GUID], props))) {
    fields = R.assocPath([0, 'disabled'], true, fields);
  }

  return fields;
};

const getValidationSchema = (clos: Array, values: Object) => {
  const routeValidationSchema = {
    [GC.FIELD_FIRST_PICKUP_START_DATE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
    ...R.compose(
      R.map(() => Yup.object().shape(validationSchemaClo)),
      G.indexByGuid,
    )(clos),
  };

  const validation = Yup.array().of(Yup.object().shape(routeValidationSchema));

  const schema = R.reduce(
    (acc: Object, key: string) => R.assoc(key, validation, acc),
    {},
    R.keys(R.pick(GC.shortDays, values)),
  );

  return schema;
};

export {
  getNewRow,
  getCopyRow,
  getAllowedOptions,
  getReferenceFields,
  getValidationSchema,
  scheduleTabsOptions,
  getScheduleRouteFields,
  getDefaultTemplateFields,
  getScheduleRouteByDayFields,
};
