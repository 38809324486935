import * as R from 'ramda';
import { createSelector } from 'reselect';
// report-common
import { getReportSelectors } from '../../report-common';
//////////////////////////////////////////////////

const {
  store,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
} = getReportSelectors('routeMassCreateReport');

const makeSelectItemList = () => createSelector(
  store,
  ({ itemList }: Object) => R.sortBy(R.prop('index'), R.values(itemList)),
);

export {
  makeSelectItemList,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
};
