import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// sagas
import { crudSaga, visitPageSaga } from '../../../sagas';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature templates
import * as A from './actions';
//////////////////////////////////////////////////

function* handleGetListSaga() {
  try {
    yield put(A.setListLoading(true));
    const res = yield call(sendRequest, 'get', endpointsMap.containerTypeList);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetListSaga fail');
    }
    yield put(A.setListLoading(false));
  } catch (err) {
    yield put(A.setListLoading(false));
    yield call(G.handleException, err, 'handleGetListSaga exception');
  }
}

function* handleDeleteListItemSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'delete', endpointsMap.getRemoveContainerType(payload));
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.deleteListItemSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteListItemSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteListItemSaga exception');
  }
}

function* createOrUpdateListItemSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const method = G.ifElse(
      G.isNotNilAndNotEmpty(G.getGuidFromObject(payload)),
      'put',
      'post',
    );
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.assoc(GC.BRANCH_GUID, branchGuid, payload),
    };
    yield call(crudSaga, {
      method,
      options,
      shouldCloseModal: true,
      showSuccessMessage: true,
      endpoint: endpointsMap.containerType,
      successAction: A.receivedListItemSuccess,
      successMessage: 'messages:success:200-201',
      parentSagaName: 'createOrUpdateContainerTypeRequest',
    });
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'createOrUpdateContainerTypeRequest exception');
  }
}

function* handleVisitPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CONTAINER_TYPE_LIST_PAGE);
    yield put(openLoader({ showDimmer: true }));
    yield call(handleGetListSaga);
    yield put(closeLoader());
    break;
  }
}

function* pageWatcherSaga() {
  yield takeLatest(GC.VISIT_CONTAINER_TYPE_LIST_PAGE, handleVisitPageSaga);
  yield takeLatest(A.getItemListRequest, handleGetListSaga);
  yield takeLatest(A.deleteListItemRequest, handleDeleteListItemSaga);
  yield takeLatest(A.createOrUpdateListItemRequest, createOrUpdateListItemSaga);
}

export default pageWatcherSaga;
