import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
// feature template-inspection
import * as C from './constants';
//////////////////////////////////////////////////

const VT = R.prop('valueTypeMap', C);

const emptySectionError = G.getWindowLocale(
  'titles:inspection-empty-section-error',
  'Please, add any component to the section!',
);

const plugValidator = [{ fn: () => true }];

// list values
const checkAllListValuesFilled = R.all(R.compose(G.isNotNilAndNotEmpty, R.prop('value')));

// list uniq
const checkUniqListValues = (values: Array) => R.equals(R.length(values), R.length(R.uniqBy(R.prop('value'), values)));

// radio
const checkAllRadioValuesFilledError = G.getWindowLocale(
  'titles:inspection-radio-error',
  'Every radio option must be filled!',
);

const checkUniqRadioValuesError = G.getWindowLocale(
  'titles:inspection-radio-uniq-error',
  'Every radio choice must be unique!',
);

// dropdown
const checkAllDropdownValuesFilledError = G.getWindowLocale(
  'titles:inspection-dropdown-error',
  'Every dropdown option must be filled!',
);

const checkUniqDropdownValuesError = G.getWindowLocale(
  'titles:inspection-dropdown-uniq-error',
  'Every dropdown choice must be unique!',
);

// checkboxes
const checkAllCheckboxesValuesFilledError = G.getWindowLocale(
  'titles:inspection-checkboxes-error',
  'Every checkbox input must be filled!',
);

const checkUniqCheckboxesValuesError = G.getWindowLocale(
  'titles:inspection-checkbox-uniq-error',
  'Every checkbox item must be unique!',
);

// object values
const checkAllObjectValuesFilled = R.compose(R.all(G.isNotNilAndNotEmpty), R.values);

// range values
const checkRangeFilledError = G.getWindowLocale(
  'titles:inspection-range-error',
  'Every range input must be filled!',
);

const checkMinMaxValuesFilled = (values: Object) => R.lt(
  +R.prop(C.INSPECTION_OPTION_TYPE_MINIMUM, values),
  +R.prop(C.INSPECTION_OPTION_TYPE_MAXIMUM, values),
);

const checkRangeMinMaxError = G.getWindowLocale(
  'titles:inspection-range-min-max-error',
  'Minimum value must be smaller then maximum!',
);

// pass /fail values
const checkPassFailFilledError = G.getWindowLocale(
  'titles:inspection-pass-fail-error',
  'Pass / Fail input must be filled!',
);

// dynamic value
const checkDynamicAdditionalOption = (value: boolean, additionalValue: Object) => G.ifElse(
  R.and(value, G.isNotNilAndNotEmpty(additionalValue)),
  checkAllObjectValuesFilled(additionalValue),
  true,
);

const checkDynamicOptionError = G.getWindowLocale(
  'titles:inspection-dynamic-option-error',
  'Dynamic option input must be filled!',
);

// validators
const validators = {
  [C.INSPECTION_OPTION_TYPE_RADIO]: [
    { fn: checkAllListValuesFilled, e: checkAllRadioValuesFilledError },
    { fn: checkUniqListValues, e: checkUniqRadioValuesError },
  ],
  [C.INSPECTION_OPTION_TYPE_DROPDOWN]: [
    { fn: checkAllListValuesFilled, e: checkAllDropdownValuesFilledError },
    { fn: checkUniqListValues, e: checkUniqDropdownValuesError },
  ],
  [C.INSPECTION_OPTION_TYPE_CHECKBOXES]: [
    { fn: checkAllListValuesFilled, e: checkAllCheckboxesValuesFilledError },
    { fn: checkUniqListValues, e: checkUniqCheckboxesValuesError },
  ],
  [C.INSPECTION_OPTION_TYPE_RANGE]: [
    { fn: checkAllObjectValuesFilled, e: checkRangeFilledError },
    { fn: checkMinMaxValuesFilled, e: checkRangeMinMaxError },
  ],
  [C.INSPECTION_OPTION_TYPE_INPUTS]: [{ fn: checkAllObjectValuesFilled, e: checkPassFailFilledError }],
  [C.INSPECTION_OPTION_TYPE_CHECKBOX]: [{ fn: checkDynamicAdditionalOption, e: checkDynamicOptionError }],
};

const validateOption = (props: Object) => {
  const { type, valueType = VT.value } = props;

  const value = R.prop(valueType, props);
  const additionalValueMap = R.prop(VT.additionalValueMap, props);
  const componentValidators = R.propOr(plugValidator, type, validators);

  let validatorError = null;

  componentValidators.every((componentValidator: Object) => {
    const { fn, e } = componentValidator;

    const result = fn(value, additionalValueMap);

    if (R.not(result)) {
      validatorError = e;

      return false;
    }

    return true;
  });

  return validatorError;
};

const validateComponent = (component: Object) => {
  const { options } = component;

  if (G.isNilOrEmpty(options)) return null;

  let errorMessage = null;

  options.every((option: Object) => {
    const error = validateOption(option);

    if (G.isNotNilAndNotEmpty(error)) {
      errorMessage = error;

      return false;
    }

    return true;
  });

  return errorMessage;
};

export {
  emptySectionError,
  validateComponent,
};
