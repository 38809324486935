import * as R from 'ramda';
import { FieldArray } from 'formik';
import { pure, compose } from 'react-recompose';
import React, { Fragment, useEffect } from 'react';
// components
import { Tabs, withTabs } from '../../../../components/tabs';
import { AddressBlock } from '../../../../components/address-block';
// features
import { Fieldset } from '../../../new-do/forms/formik/fieldset';
// forms
import { Fieldset2 } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex, Divider, StickedBox, RelativeFlex } from '../../../../ui';
// feature template/route
import { RouteTrips } from './route-trips';
import { getTabs } from './create-routes-form';
import {
  containerFields,
  getNewReferenceFields,
} from '../settings/create-routes-settings';
import {
  getNewRow,
  getCopyRow,
  getAllowedOptions,
  getReferenceFields,
  getScheduleRouteByDayFields,
} from '../settings/schedule-route-settings';
//////////////////////////////////////////////////

// TODO: refactor with Fieldset2

const blueColor = G.getTheme('colors.dark.blue');
const darkGreyColor = G.getTheme('colors.dark.grey');
const lightGreyColor = G.getTheme('colors.light.lightGrey');
const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

const Header = (props: Object) => {
  const { values, dayOfWeek, handleSetActiveTab } = props;

  useEffect(() => {
    handleSetActiveTab(0);
  }, [dayOfWeek]);

  return (
    <StickedBox
      top='0px'
      height={70}
      zIndex={100}
      display='block'
      bg={G.getTheme('modal.bgColor')}
    >
      <Flex height='100%' alignItems='center'>
        <Box mx={15}>
          {G.getWindowLocale('actions:schedule-day', 'Schedule Day')}
        </Box>
        <Fieldset2
          {...G.getFormikProps(props)}
          fields={getScheduleRouteByDayFields(values)}
        />
      </Flex>
    </StickedBox>
  );
};

const ArrayHeader = (props: Object) => {
  const { push, clos, count, values } = props;

  return (
    <Flex py={10} px={15}>
      <Box>
        {G.getWindowLocale('actions:add-route', 'Add Route')}
      </Box>
      {
        R.lt(count, 25) &&
        true &&
        <Box
          mx='5px'
          cursor='pointer'
          onClick={() => push(getNewRow(clos, values))}
        >
          {I.plusRound(blueColor)}
        </Box>
      }
    </Flex>
  );
};

const AddReference = ({ push }: Object) => (
  <Flex>
    <Box mr={10}>
      {G.getWindowLocale('titles:references', 'Reference')}
    </Box>
    <Box
      cursor='pointer'
      onClick={() => push(getNewReferenceFields())}
    >
      {I.plusRound(blueColor)}
    </Box>
  </Flex>
);

const ItemRow = (props: Object) => {
  const {
    remove,
    itemIndex,
    arrayLength,
    referenceTypeOptions,
  } = props;

  const zIndex = R.add(11, R.subtract(arrayLength, itemIndex));

  return (
    <RelativeFlex mt={15} zIndex={zIndex}>
      <Box>
        <Flex
          display='flex'
          cursor='pointer'
          alignItems='center'
          onClick={() => remove(itemIndex)}
          title={G.getWindowLocale('titles:remove', 'Remove')}
        >
          {I.trash(blueColor)}
        </Flex>
      </Box>
      <Fieldset
        {...props}
        calcZIndex={true}
        fieldsetType='array'
        rowMapIndex={itemIndex}
        fieldsWrapperStyles={{ pb: 10 }}
        fields={getReferenceFields(props)}
        referenceTypes={referenceTypeOptions}
        allowedValues={getAllowedOptions(props)}
      />
    </RelativeFlex>
  );
};

const LoadInfo = ({ data, index, loadTitle }: Object) => {
  const { rate, orderType, enterpriseName } = data;

  const events = R.pathOr([], [GC.FIELD_LOAD_STOPS], data);

  const customerInfo = `${G.getWindowLocale('titles:customer', 'Customer')}: ${enterpriseName}`;

  const rateInfo = `${G.getWindowLocale('titles:rate', 'Rate')}: ${G.getRateTotalWithCurrencySymbol(rate)}`;

  const orderTypeInfo = orderType ?
    `${G.getWindowLocale('titles:orderType', 'Order Type')} - ${G.getDisplayedValueFromObject(orderType)}` : null;

  return (
    <Box color={greyMatterhornColor}>
      <Box mb='5px' fontSize={14} fontWeight='bold'>{`${loadTitle} ${index}, ${customerInfo}`}</Box>
      <Box mb={10} fontSize={12} fontWeight='bold'>{G.createStringFromArray([rateInfo, orderTypeInfo], ', ')}</Box>
      <Flex>
        {
          events.map((event: Object, eventIndex: number) => (
            <Box key={eventIndex} mr={15}>
              <Box mb='4px' fontSize={13} fontWeight='bold'>
                {`${G.getWindowLocale('titles:stop', 'Stop')} ${R.inc(eventIndex)}: ${G.toTitleCase(event.eventType)}`}
              </Box>
              <AddressBlock location={R.pathOr({}, [GC.FIELD_LOCATION], event)} />
            </Box>
          ))
        }
      </Flex>
    </Box>
  );
};

const RouteRow = (props: Object) => {
  const {
    clos,
    field,
    values,
    dayOfWeek,
    allowCopy,
    routeIndex,
    formikArrayProps,
    handleChangeInput,
    handleSetActiveTab,
    referenceTypeOptions,
  } = props;

  const copyText = G.getWindowLocale('titles:copy-route', 'Copy Route');
  const removeText = G.getWindowLocale('titles:remove-route', 'Remove Route');

  const closLength = R.length(clos);

  const realRoute = G.isNotNilAndNotEmpty(dayOfWeek);

  const startDate = R.path([dayOfWeek, routeIndex, GC.FIELD_FIRST_PICKUP_START_DATE], values);

  const routeStartDate = G.convertInstanceToDefaultUserFormat(startDate);

  return (
    <Box width='100%'>
      <Flex mt={15} alignItems='center'>
        <Flex ml='5px' mr={20} alignItems='start' flexDirection='column'>
          {
            realRoute &&
            <Flex
              mb='3px'
              cursor='pointer'
              color={blueColor}
              title={removeText}
              alignItems='center'
              onClick={() => {
                formikArrayProps.remove(routeIndex);
                handleSetActiveTab(0);
              }}
            >
              <Box mr='6px'>{I.trash(blueColor)}</Box>
              {removeText}
            </Flex>
          }
          {
            realRoute && allowCopy &&
            <Flex
              cursor='pointer'
              title={copyText}
              color={blueColor}
              alignItems='center'
              onClick={() => formikArrayProps.push(getCopyRow(R.path([dayOfWeek, routeIndex], values)))}
            >
              <Box mr='6px'>{I.copy(blueColor)}</Box>
              {copyText}
            </Flex>
          }
        </Flex>
        {
          realRoute &&
          <Box>{`${G.getWindowLocale('titles:route-start-from', 'Route Start From')} ${routeStartDate}`}</Box>
        }
      </Flex>
      <Divider text={G.getWindowLocale('titles:trips', 'Trips', { caseAction: 'upperCase' })} />
      <RouteTrips {...props} realRoute={realRoute} />
      <Divider text={G.getWindowLocale('titles:orders', 'Orders', { caseAction: 'upperCase' })} />
      {
        clos.map((clo: Object, cloIndex: number) => {
          const { guid, containers } = clo;

          return (
            <RelativeFlex
              py={10}
              key={cloIndex}
              flexDirection='column'
              alignItems='flex-start'
              zIndex={R.subtract(closLength, cloIndex)}
              borderBottom={`1px solid ${lightGreyColor}`}
            >
              <LoadInfo
                data={clo}
                index={R.inc(cloIndex)}
                loadTitle={G.getWindowLocale('titles:clo', 'Order')}
              />
              {
                realRoute &&
                <Flex mt={10} alignItems='start'>
                  <FieldArray
                    name={`${dayOfWeek}.${routeIndex}.${guid}.${GC.FIELD_REFERENCES}`}
                    render={(formikRefArrayProps: Object) => (
                      <Flex
                        zIndex={1}
                        alignItems='flex-start'
                      >
                        <Box minWidth={450}>
                          <AddReference {...props} push={formikRefArrayProps.push} />
                          <Box zIndex='0'>
                            {
                              R.gt(R.length(R.path([guid, GC.FIELD_REFERENCES], field)), 0) &&
                              R.path([guid, GC.FIELD_REFERENCES], field).map((item: string, j: number) => (
                                <ItemRow
                                  {...props}
                                  {...formikRefArrayProps}
                                  key={j}
                                  item={item}
                                  itemIndex={j}
                                  cloGuid={guid}
                                  routeIndex={routeIndex}
                                  referenceTypeOptions={referenceTypeOptions}
                                  arrayLength={R.length(R.path([guid, GC.FIELD_REFERENCES], field))}
                                  arrayName={`${dayOfWeek}.${routeIndex}.${guid}.${GC.FIELD_REFERENCES}`}
                                />
                              ))
                            }
                          </Box>
                        </Box>
                      </Flex>
                    )}
                  />
                  {
                    G.isNotNilAndNotEmpty(containers) &&
                    <FieldArray
                      key={`${routeIndex}${guid}`}
                      name={`${dayOfWeek}.${routeIndex}.${guid}.${GC.FIELD_CONTAINERS}`}
                      render={(formikContainerArrayProps: Object) => (
                        <Box zIndex='0'>
                          <Box color={greyMatterhornColor} fontWeight='bold' textAlign='center'>
                            {G.getWindowLocale('titles:containers', 'Containers')}
                          </Box>
                          {
                            containers.map(({ weightUom, containerNumber, fullContainerWeight }: any, i: number) => (
                              <Flex pt={15} key={i}>
                                <Box mb={12} mr={10} fontSize={12} color={greyMatterhornColor}>
                                  {`${R.or(containerNumber, '')} ${R.or(fullContainerWeight, '')} ${R.or(weightUom, '')}`}
                                </Box>
                                <Fieldset
                                  {...props}
                                  {...formikContainerArrayProps}
                                  itemIndex={i}
                                  rowMapIndex={i}
                                  calcZIndex={true}
                                  fieldsetType='array'
                                  fields={containerFields}
                                  handleChangeInput={handleChangeInput}
                                  fieldsWrapperStyles={{ px: 0, pb: 15, ml: 'auto' }}
                                  arrayLength={R.length(R.path([guid, GC.FIELD_CONTAINERS], field))}
                                  arrayName={`${dayOfWeek}.${routeIndex}.${guid}.${GC.FIELD_CONTAINERS}`}
                                />
                              </Flex>
                            ))
                          }
                        </Box>
                      )}
                    />
                  }
                </Flex>
              }
            </RelativeFlex>
          );
        })
      }
    </Box>
  );
};

const DayRoutes = (props: Object) => {
  const {
    values,
    errors,
    touched,
    activeTab,
    dayOfWeek,
    handleSetActiveTab,
    referenceTypeOptions,
  } = props;

  const routes = R.pathOr([], [dayOfWeek], values);

  return (
    <FieldArray
      name={dayOfWeek}
      render={(formikArrayProps: Object) => (
        <Box width='100%'>
          {
            G.isNotNilAndNotEmpty(dayOfWeek) &&
            <Fragment>
              <ArrayHeader {...props} count={R.length(routes)} push={formikArrayProps.push} />
              <Tabs
                activeTab={activeTab}
                handleClickTab={handleSetActiveTab}
                activeTabStyles={{ bg: darkGreyColor }}
                wrapperStyles={{ maxWidth: '100%', overflow: 'auto' }}
                tabs={getTabs(routes, R.pathOr([], [dayOfWeek], errors), R.pathOr([], [dayOfWeek], touched))}
              />
            </Fragment>
          }
          {
            G.isNotNilAndNotEmpty(dayOfWeek) && G.isNotEmpty(routes) &&
            <Flex
              px={15}
              zIndex='0'
              overflow='auto'
              id='route_wrapper'
              alignItems='flex-start'
              height='calc(100vh - 310px)'
            >
              <RouteRow
                {...props}
                key={activeTab}
                routeIndex={activeTab}
                field={R.prop(activeTab, routes)}
                formikArrayProps={formikArrayProps}
                allowCopy={R.lt(R.length(routes), 25)}
                referenceTypeOptions={referenceTypeOptions}
              />
            </Flex>
          }
          {
            G.isNilOrEmpty(dayOfWeek) &&
            <Box p={15} pb={0}>
              {G.getWindowLocale('actions:route-preview', 'Route Preview')}
            </Box>
          }
          {
            G.isNilOrEmpty(dayOfWeek) &&
            <Flex
              px={15}
              zIndex='0'
              overflow='auto'
              id='route_wrapper'
              alignItems='flex-start'
              height='calc(100vh - 310px)'
            >
              <RouteRow
                {...props}
                key={activeTab}
                routeIndex={activeTab}
                field={R.prop(activeTab, routes)}
                formikArrayProps={formikArrayProps}
                allowCopy={R.lt(R.length(routes), 25)}
                referenceTypeOptions={referenceTypeOptions}
              />
            </Flex>
          }
        </Box>
      )}
    />
  );
};

const enhance = compose(
  withTabs,
  pure,
);

const ScheduleRoutesTab = enhance((props: Object) => {
  const {
    values,
    referenceTypes,
  } = props;

  const { dayOfWeek } = values;

  const referenceTypeOptions = G.getOptionsFromDataArrayByPath(
    [GC.FIELD_NAME],
    [GC.FIELD_GUID],
    R.pathOr([], [GC.REF_SCOPE_NAME_CLO], referenceTypes),
  );

  return (
    <Fragment>
      <Header {...props} dayOfWeek={dayOfWeek} />
      <DayRoutes {...props} dayOfWeek={dayOfWeek} referenceTypeOptions={referenceTypeOptions} />
    </Fragment>
  );
});

export {
  ScheduleRoutesTab,
};
