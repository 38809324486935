import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  ITEM_DIMENSIONS_OPTIONS,
  DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
} from '../../../helpers/options';
//////////////////////////////////////////////////

export const rowHeight = 30;
export const tableMaxHeight = 'calc(100vh - 145px)';

export const report = {
  fields: [
    { name: GC.FIELD_NAME, sequence: 1 },
    { name: GC.FIELD_EMPTY_CONTAINER_WEIGHT, sequence: 2 },
    { name: GC.FIELD_WEIGHT_UOM, sequence: 3 },
    { name: GC.FIELD_WIDTH, sequence: 4 },
    { name: GC.FIELD_HEIGHT, sequence: 5 },
    { name: GC.FIELD_LENGTH, sequence: 6 },
    { name: GC.FIELD_DIMENSIONS_UOM, sequence: 7 },
  ],
};

export const tableSettings = {
  rowHeight: 55,
  cellFontSize: 14,
  titleFontSize: 14,
  titleRowHeight: 55,
  tableRowHeight: 55,
  allowEditBtn: true,
  useMainColors: true,
  moreBtnCellWidth: 50,
  allowSelectAll: true,
  expandableItems: false,
  maxHeight: 'calc(100vh - 145px)',
};

export const columnSettings = {
  [GC.FIELD_NAME]: {
    width: 200,
    name: 'titles:name',
  },
  [GC.FIELD_EMPTY_CONTAINER_WEIGHT]: {
    width: 200,
    name: 'titles:empty-container-weight',
  },
  [GC.FIELD_WEIGHT_UOM]: {
    width: 200,
    name: 'titles:weight-uom',
  },
  [GC.FIELD_WIDTH]: {
    width: 150,
    name: 'titles:width',
  },
  [GC.FIELD_HEIGHT]: {
    width: 150,
    name: 'titles:height',
  },
  [GC.FIELD_LENGTH]: {
    width: 150,
    name: 'titles:length',
  },
  [GC.FIELD_DIMENSIONS_UOM]: {
    width: 200,
    name: 'titles:dimensions-uom',
  },
};

const defaultStyles = {
  width: '270px',
  labelPl: '10px',
  errorTop: '110%',
  afterTop: '15px',
  errorLeft: '5px',
  errorTitle: true,
  afterRight: '15px',
  errorWidth: '100%',
  errorOverflow: 'hidden',
  flexDirection: 'column',
  errorPosition: 'absolute',
  labelWidth: 'max-content',
  errorWhiteSpace: 'nowrap',
  inputWrapMargin: '5px 0px',
  errorTextOverflow: 'ellipsis',
};

const fieldRequired = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

export const containerTypeFieldSettings = [
  {
    ...defaultStyles,
    type: 'text',
    loc: 'titles:name',
    fieldName: GC.FIELD_NAME,
  },
  {
    ...defaultStyles,
    type: 'number',
    loc: 'titles:empty-container-weight',
    fieldName: GC.FIELD_EMPTY_CONTAINER_WEIGHT,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:weight-uom',
    fieldName: GC.FIELD_WEIGHT_UOM,
    options: DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
  },
  {
    ...defaultStyles,
    type: 'number',
    loc: 'titles:width',
    fieldName: GC.FIELD_WIDTH,
  },
  {
    ...defaultStyles,
    type: 'number',
    loc: 'titles:height',
    fieldName: GC.FIELD_HEIGHT,
  },
  {
    ...defaultStyles,
    type: 'number',
    loc: 'titles:length',
    fieldName: GC.FIELD_LENGTH,
  },
  {
    ...defaultStyles,
    type: 'select',
    loc: 'titles:dimensions-uom',
    options: ITEM_DIMENSIONS_OPTIONS,
    fieldName: GC.FIELD_DIMENSIONS_UOM,
  },
];

export const defaultContainerTypeFields = {
  [GC.FIELD_NAME]: null,
  [GC.FIELD_WIDTH]: null,
  [GC.FIELD_HEIGHT]: null,
  [GC.FIELD_LENGTH]: null,
  [GC.FIELD_WEIGHT_UOM]: null,
  [GC.FIELD_DIMENSIONS_UOM]: null,
  [GC.FIELD_EMPTY_CONTAINER_WEIGHT]: null,
};

export const getContainerTypeFormValidationSchema = (isEditMode: boolean, values: Object) => {
  const { width, height, length, emptyContainerWeight } = values;
  let schema = {
    [GC.FIELD_NAME]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt()),
  };
  if (G.isNotNilAndNotEmpty(emptyContainerWeight)) {
    schema = R.assoc(GC.FIELD_WEIGHT_UOM, fieldRequired, schema);
  }
  if (G.isOneNotNilOrNotEmpty([width, height, length])) {
    schema = R.assoc(GC.FIELD_DIMENSIONS_UOM, fieldRequired, schema);
  }

  return schema;
};
