// helpers/constants
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.FIELD_ROLE_NAME]: {
    width: 200,
    name: 'titles:role-name',
  },
};
