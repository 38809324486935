import * as R from 'ramda';
import React from 'react';
import { compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
import { ActionsElement } from '../../../components/actions-element';
// features
import AuthHoc from '../../permission';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// utilities
import routesMap from '../../../utilities/routes';
//////////////////////////////////////////////////

const enhance = compose(
  AuthHoc([PC.USER_WRITE, PC.USER_READ]),
  withHandlers({
    handleRemove: (props: Object) => (user: Object) => {
      const { openModal, closeModal, deleteItemRequest } = props;

      const content = (
        <ConfirmComponent
          name={R.prop(GC.FIELD_USER_LOGIN_ID, user)}
          textLocale={G.getWindowLocale('messages:before:remove')}
        />
      );

      const modal = {
        component: content,
        options: {
          height: 'auto',
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                closeModal();
                deleteItemRequest(R.prop(GC.FIELD_GUID, user));
              },
            },
          ],
        },
      };

      openModal(modal);
    },
    handleEdit: ({ currentUserGuid }: string) => ({ guid }: string) => {
      const route = G.ifElse(
        R.equals(guid, currentUserGuid),
        routesMap.profile,
        routesMap.getUserSettingsRoute(guid),
      );
      G.goToRoute(route);
    },
  }),
);

export const renderOptions = (props: Object) => {
  const { user, handleEdit, handleRemove } = props;
  const editOption = {
    frontIcon: I.pencil(G.getTheme('icons.iconColor')),
    text: G.getWindowLocale('actions:edit', 'Edit'),
    action: () => handleEdit(user),
    permissions: [
      PC.USER_WRITE,
      PC.ROLE_LIUBANCHYK_SUPER_ADMIN,
    ],
  };
  const deleteOption = {
    frontIcon: I.trash(G.getTheme('icons.iconColor')),
    text: G.getWindowLocale('actions:delete', 'Delete'),
    action: () => handleRemove(user),
    permissions: [
      PC.USER_WRITE,
      PC.ROLE_LIUBANCHYK_SUPER_ADMIN,
    ],
  };
  return G.ifElse(
    R.propEq(GC.USER_TYPE_DRIVER, GC.FIELD_USER_TYPE, user),
    [editOption],
    [editOption, deleteOption],
  );
};

export const ElementActionsComponent = (props: Object) => (
  <ActionsElement options={renderOptions(props)} />
);

export default enhance(ElementActionsComponent);
