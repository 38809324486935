import * as R from 'ramda';
import React, { Fragment } from 'react';
// components
import { TabsMui } from '../../../components/tabs-mui';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { MainActionButton } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
//////////////////////////////////////////////////

const TitleTabs = ({ totalCount, handleCreateTask }: Object) => {
  const tabs = [
    {
      value: routesMap.taskManagementReport,
      text: G.getWindowLocaleArr(['titles:task-management', 'titles:report']),
      withCount: R.equals(window.location.pathname, routesMap.taskManagementReport),
    },
    {
      withCount: false,
      value: routesMap.taskManagementBoard,
      text: G.getWindowLocaleArr(['titles:task-management', 'titles:board']),
    },
    {
      withCount: false,
      value: routesMap.taskManagementBoardList,
      text: G.getWindowLocale('titles:board-list', 'Board List'),
    },
  ];

  const tabsStyles = {
    maxHeight: 36,
    minHeight: 36,
    marginTop: 'auto',
    minWidth: 'unset',
    width: 'max-content',
  };

  return (
    <Fragment>
      <TabsMui
        tabs={tabs}
        count={totalCount}
        tabsStyles={tabsStyles}
        activeMuiTab={window.location.pathname}
        setActiveMuiTab={(route: string) => G.goToRoute(route)}
        tabStyles={{ minHeight: 36, padding: '10px', minWidth: 'unset' }}
      />
      {
        G.isFunction(handleCreateTask) &&
        <MainActionButton ml={10} height={30} width={130} onClick={() => handleCreateTask()}>
          {G.getWindowLocale('actions:create-task', 'Create Task')}
        </MainActionButton>
      }
    </Fragment>
  );
};

export default TitleTabs;
