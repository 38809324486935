import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
import { getConfirmModal } from '../../../components/confirm';
import { FormGroupTable } from '../../../components/form-group-table';
// forms
import { FieldsetComponent } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature service-vendor
import { primaryLocationColumnSettings } from '../settings/column-settings';
import {
  primaryLocationDefaultFields,
  primaryLocationFieldSettings,
  primaryLocationValidationSchema,
} from '../settings/formik-settings';
//////////////////////////////////////////////////

const formEnhance = compose(
  withFormik({
    validationSchema: primaryLocationValidationSchema,
    handleSubmit: (values: Object, { props }: Object) =>
      props.handleSetLocation(values, GC.SYSTEM_OBJECT_PRIMARY_LOCATION),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      primaryLocationDefaultFields,
      initialValues,
    ),
  }),
  pure,
);

const PrimaryLocationForm = formEnhance((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <FieldsetComponent
      {...G.getFormikProps(props)}
      justifyContent='space-between'
      fields={primaryLocationFieldSettings}
    />
    <FormFooter boxStyles={{ pt: 10 }} closeModal={props.closeModal} />
  </form>
));


const enhance = compose(
  withHandlers({
    handleOpenLocationForm: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        primaryLocation,
        handleSetLocation,
      } = props;

      const titleLocaleArr = G.ifElse(
        G.isNilOrEmpty(primaryLocation),
        ['titles:add-primary-location', 'Add Primary Location'],
        ['titles:edit-primary-location', 'Edit Primary Location'],
      );
      const title = G.getWindowLocale(...titleLocaleArr);
      const component = (
        <PrimaryLocationForm
          openModal={openModal}
          closeModal={closeModal}
          initialValues={primaryLocation}
          handleSetLocation={handleSetLocation}
        />
      );
      const modal = {
        p: 15,
        component,
        options: {
          title,
          width: 730,
          height: 'auto',
          overflow: 'auto',
          maxHeight: '87vh',
        },
      };

      openModal(modal);
    },
    handleRemove: (props: Object) => () => {
      const { openModal, closeModal, primaryLocation, handleSetLocation } = props;

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        submitAction: () => handleSetLocation(null, GC.SYSTEM_OBJECT_PRIMARY_LOCATION),
        text: `${G.getWindowLocale(
          'messages:delete-confirmation-text-double',
          'Are you sure you want to delete',
        )} ${G.getPropFromObject(GC.FIELD_NAME, primaryLocation)}?`,
      });
      openModal(modalContent);
    },
  }),
  pure,
);

const PrimaryLocation = (props: Object) => {
  const {
    handleRemove,
    collapsedGroup,
    primaryLocation,
    handleOpenLocationForm,
  } = props;

  const entities = G.ifElse(
    G.isNotNilAndNotEmpty(primaryLocation),
    R.of(Array, primaryLocation),
    [],
  );

  return (
    <FormGroupTable
      entities={entities}
      fields={collapsedGroup}
      handleDeleteRow={handleRemove}
      handleEditRow={handleOpenLocationForm}
      handleAddClick={handleOpenLocationForm}
      groupName={GC.SYSTEM_OBJECT_PRIMARY_LOCATION}
      entitiesFields={primaryLocationColumnSettings}
      isHiddenAddIcon={G.isNotNilAndNotEmpty(entities)}
      panelTitle={G.getWindowLocale('titles:primary-location', 'Primary Location')}
      isOpened={R.path(['collapsedGroup', GC.SYSTEM_OBJECT_PRIMARY_LOCATION], props)}
      handleToggleFormGroup={() => props.handleToggleFormGroup(GC.SYSTEM_OBJECT_PRIMARY_LOCATION)}
    />
  );
};

export default enhance(PrimaryLocation);
