import * as R from 'ramda';
import { createSelector } from 'reselect';
// report-common
import { getReportSelectors } from '../../../report-common';
//////////////////////////////////////////////////

const {
  store,
  makeSelectListType,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} = getReportSelectors('templateInspection');

const makeSelectItemList = () => createSelector(
  store,
  ({ itemList }: Object) => R.sortBy(R.prop('index'), R.values(itemList)),
);

const makeSelectReportType = () => createSelector(
  store,
  ({ reportType }: Object) => reportType,
);

const makeSelectCurrentTemplate = () => createSelector(
  store,
  ({ currentInspectionTemplate }: Object) => currentInspectionTemplate,
);

const makeSelectActiveSection = () => createSelector(
  store,
  ({ activeSection }: Object) => activeSection,
);

export {
  // report
  makeSelectListType,
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectReportType,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
  // inspection
  makeSelectActiveSection,
  makeSelectCurrentTemplate,
};
