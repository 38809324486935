import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
// forms
import { Error } from '../../../../forms/ui';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { Flex, Badge } from '../../../../ui';
// feature template-inspection
import {
  updateComponentFields,
  deleteComponentFromSection,
} from '../actions';
import * as C from '../constants';
import { SectionComponentUI } from '../ui';
import ComponentForm from './component-form';
import DeleteComponent from './delete-component';
import { NameWithEdit } from './option-components';
import { componentsSettings } from '../settings/component-configs/components-settings';
//////////////////////////////////////////////////

const ComponentHeader = ({ component }: Object) => {
  const dispatch = useDispatch();

  const type = R.prop('type', component);
  const componentName = R.prop('name', component);
  const nameStatus = R.prop('nameStatus', component);
  const errorMessage = R.prop('errorMessage', component);
  const componentProps = R.prop(type, componentsSettings);

  const { icon, name } = componentProps;

  const nameErrorMessage = G.getWindowLocale('titles:inspection-name-component-error', 'Enter component name');

  const setComponentName = useCallback((value: string) => {
    dispatch(updateComponentFields({
      ...component,
      name: value,
      nameStatus: G.ifElse(
        G.isNilOrEmpty(value),
        C.INSPECTION_NAME_STATUS_EMPTY,
        C.INSPECTION_NAME_STATUS_FILLED,
      ),
    }));
  }, [component]);

  const handleNameEdit = useCallback(() => {
    dispatch(updateComponentFields({
      ...component,
      nameStatus: C.INSPECTION_NAME_STATUS_EDIT,
    }));
  }, [component]);

  const handleDeleteComponent = useCallback(() => dispatch(deleteComponentFromSection(component)), [component]);

  const removeComponentText = G.getWindowLocale(
    'messages:remove-inspection-component-text',
    'Are you sure you want delete this component?',
  );

  return (
    <Flex gap={4} width='100%'>
      { errorMessage && <Error mt={2} mr={8} fontSize={16} cursor='help' title={errorMessage}>*</Error> }
      <Flex width='100%' justifyContent='space-between'>
        <NameWithEdit
          focus={true}
          status={nameStatus}
          name={componentName}
          maxLength={C.MAX_NAME_LENGTH}
          errorMessage={nameErrorMessage}
          handleNameEdit={handleNameEdit}
          setInputValue={setComponentName}
        />
        <Flex gap='12px'>
          <Badge variant='grey'>
            <Flex gap='8px'>
              {icon}
              {name}
            </Flex>
          </Badge>
          <DeleteComponent
            confirmAction={handleDeleteComponent}
            confirmationText={removeComponentText}
            title={G.getWindowLocale('titles:inspection-delete-component', 'Delete Component')}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

const VehicleComponent = ({ component }: Object) => (
  <SectionComponentUI>
    <ComponentHeader component={component} />
    <ComponentForm component={component} />
  </SectionComponentUI>
);

export default VehicleComponent;
