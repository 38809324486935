import * as R from 'ramda';
import { delay, eventChannel } from 'redux-saga';
import { all, put, call, take, fork, race, select, takeLatest } from 'redux-saga/effects';
// common
import { makeSelectInitialDataLoaded } from '../../common/selectors';
import { initialDataLoadSuccess, setSocketConnection } from '../../common/actions';
// features
import PC from '../permission/role-permission';
import { receivedSwitchBranchSuccess } from '../branch/actions';
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { makeSelectCarrierToken } from '../carrier-portal/selectors';
// helpers/constants
import * as G from '../../helpers';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature sockets-v2
import * as A from './actions';
import * as C from './constants';
import {
  logSocket,
  getSocketURI,
  parseDestination,
  reconnectSocketSaga,
  resetSocketReconnectCounter,
} from './helpers';
import handleGenericMessageReceived from './service-sagas/generic';
import handleStatisticMessageReceived from './service-sagas/statistic';
import handleLoadBoardMessageReceived from './service-sagas/load-board';
import handleNotificationMessageReceived from './service-sagas/notification';
//////////////////////////////////////////////////

const downloadFile = ({ url, fileName }: Object) => G.downloadFileFromUrl(url, fileName);

function* watchUserDocumentGeneratedReceivedSaga() {
  while (true) { // eslint-disable-line
    yield delay(2000);

    if (R.isNil(window.amousSocketUserDocumentGeneratedReceived)) {
      window.amousSocketUserDocumentGeneratedReceived = [];
    }

    const spliced = window.amousSocketUserDocumentGeneratedReceived.splice(0, 1)[0];

    if (G.isNotNilAndNotEmpty(spliced)) downloadFile(spliced);
  }
}

function* watchEventChannelMessagesSaga(eventChannel: Object) {
  while (true) { // eslint-disable-line
    const { type, event } = yield take(eventChannel);

    if (R.or(
      R.equals(type, C.SOCKET_CLOSE),
      R.equals(type, C.SOCKET_ERROR),
    )) {
      logSocket(type, event);

      eventChannel.close();

      yield put(A.tryToReconnectSocket());

      return;
    }

    let message;

    try {
      const data = R.prop('data', event);

      message = JSON.parse(data);
    } catch (error) {
      G.handleException('error', 'parseSocketMessage exception');

      return;
    }

    logSocket(type, message);

    const { action, destination, payload } = message;

    if (R.equals(action, C.messageType.message)) {
      const { serviceType, actionPart } = parseDestination(destination);

      if (R.isNil(serviceType)) return;

      switch (serviceType) {
        case C.socketTypes.loadBoard:
          if (R.or(
            G.isCurrentBranchTypeCustomer(),
            G.hasNotAmousCurrentUserPermissions([PC.EXTERNAL_LOAD_BOARD_EXECUTE]),
          )) return;

          yield call(handleLoadBoardMessageReceived, actionPart, payload);
          break;
        case C.socketTypes.generic:
          yield call(handleGenericMessageReceived, payload);
          break;
        case C.socketTypes.statistic:
          yield call(handleStatisticMessageReceived, actionPart, payload);
          break;
        case C.socketTypes.notification:
          yield call(handleNotificationMessageReceived, payload);
          break;
        default:
      }
    }
  }
}

const listenToSocketMessages = (
  socket: Object,
) => eventChannel((emit: Function) => {
  socket.addEventListener('open', (event: Event) => emit({ type: C.SOCKET_OPEN, event }));
  socket.addEventListener('close', (event: Event) => emit({ type: C.SOCKET_CLOSE, event }));
  socket.addEventListener('error', (event: Event) => emit({ type: C.SOCKET_ERROR, event }));
  socket.addEventListener('message', (event: Event) => emit({ type: C.SOCKET_MESSAGE, event }));

  return () => socket.close();
});

function* listenPostActionsSaga(socket: Object) {
  while (true) { // eslint-disable-line
    const { payload } = yield take(A.socketPostMessage);

    try {
      const data = JSON.stringify(payload);

      socket.send(data);
    } catch (error) {
      G.handleException('error', 'listenPostActionsSaga exception');
    }
  }
}

function* connectToSocketSaga({ token }: Object) {
  const uri = getSocketURI(token);

  const socket = new WebSocket(uri); // eslint-disable-line

  const socketEventChannel = yield call(listenToSocketMessages, socket);

  const { type } = yield take(socketEventChannel);

  if (R.equals(type, C.SOCKET_OPEN)) {
    yield put(A.socketConnectionSuccess());

    resetSocketReconnectCounter();
  } else {
    socketEventChannel.close();
    setSocketConnection(false);

    yield put(A.tryToReconnectSocket());

    return;
  }

  const { cancel } = yield race({
    cancel: take(A.socketDisconnectRequest),
    task: all([
      call(watchEventChannelMessagesSaga, socketEventChannel),
      call(listenPostActionsSaga, socket),
      put(setSocketConnection(true)),
    ]),
  });

  if (cancel) {
    socketEventChannel.close();
  }
}

function* getSocketTokenSaga() {
  try {
    const carrierToken = yield select(makeSelectCarrierToken());

    if (G.isNotNilAndNotEmpty(carrierToken)) {
      return yield call(connectToSocketSaga, { token: carrierToken });
    }

    const enterpriseGuid = yield select(makeSelectCurrentBranchGuid());

    const res = yield call(sendRequest, 'post', endpointsMap.webSocketServiceToken, { data: { enterpriseGuid } });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(connectToSocketSaga, data);
    } else {
      yield call(G.handleFailResponse, res, 'getSocketTokenSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getSocketTokenSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* tryToReconnectSocketSaga() {
  const reconnectAllowed = yield call(reconnectSocketSaga);

  if (reconnectAllowed) yield put(A.socketConnectRequest());
}

function* handleSwitchBranchSaga() {
  const initialDataLoaded = yield select(makeSelectInitialDataLoaded());

  if (R.not(initialDataLoaded)) return;

  yield put(A.socketDisconnectRequest());

  yield put(A.socketConnectRequest());
}

function* socketWatcherSagaV2() {
  yield fork(watchUserDocumentGeneratedReceivedSaga);
  yield takeLatest(A.socketConnectRequest, getSocketTokenSaga);
  yield takeLatest(A.tryToReconnectSocket, tryToReconnectSocketSaga);
  yield takeLatest(receivedSwitchBranchSuccess, handleSwitchBranchSaga);

  yield take(initialDataLoadSuccess);

  yield put(A.socketConnectRequest());
}

export default socketWatcherSagaV2;
