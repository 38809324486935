// components
import { getUserRoleTypeOptions } from '../../../components/filter/settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const FILTER_PARAMS = [
  {
    type: 'string',
    value: GC.FIELD_BRANCH_NAME,
    name: G.getWindowLocale('titles:branch', 'Branch'),
  },
  {
    type: 'string',
    value: GC.FIELD_USER_LOGIN_ID,
    name: G.getWindowLocale('titles:user-id', 'User ID'),
  },
  {
    type: 'selectMultiple',
    value: GC.FIELD_USER_TYPE,
    options: getUserRoleTypeOptions(),
    name: G.getWindowLocale('titles:type', 'Type'),
  },
  {
    type: 'boolean',
    value: GC.FIELD_ACTIVE,
    name: G.getWindowLocale('titles:active', 'Active'),
  },
  {
    type: 'string',
    value: GC.FIELD_USER_EMAIL,
    name: G.getWindowLocale('titles:email', 'Email'),
  },
  {
    type: 'string',
    value: GC.FIELD_USER_FIRST_NAME,
    name: G.getWindowLocale('titles:first-name', 'First Name'),
  },
  {
    type: 'string',
    value: GC.FIELD_USER_LAST_NAME,
    name: G.getWindowLocale('titles:last-name', 'Last Name'),
  },
  {
    type: 'string',
    collection: true,
    name: G.getWindowLocale('titles:roles', 'Roles'),
    value: `${GC.SYSTEM_OBJECT_ROLES}.${GC.FIELD_ROLE_NAME}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.DISPATCHING_GROUPS_NAME,
    name: G.getWindowLocale('titles:dispatching-group', 'Dispatching Group'),
  },
  {
    type: 'string',
    value: GC.FIELD_USER_PHONE_NUMBER,
    name: G.getWindowLocale('titles:phone-number', 'Phone'),
  },
  {
    type: 'string',
    value: GC.FIELD_USER_FAX_NUMBER,
    name: G.getWindowLocale('titles:fax-number', 'Fax'),
  },
  {
    type: 'string',
    value: GC.FIELD_USER_SMS_SENDER_NAME,
    name: G.getWindowLocale('titles:sms-sender-name', 'Sender Name'),
  },
];
