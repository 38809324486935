import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const selectItem = createAction('selectItem');
export const setListLoading = createAction('setListLoading');
export const setInitialState = createAction('setInitialState');
export const getItemListRequest = createAction('getItemListRequest');
export const getItemListSuccess = createAction('getItemListSuccess');
export const deleteListItemRequest = createAction('deleteListItemRequest');
export const deleteListItemSuccess = createAction('deleteListItemSuccess');
export const receivedListItemSuccess = createAction('receivedListItemSuccess');
export const createOrUpdateListItemRequest = createAction('createOrUpdateListItemRequest');
