import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// report-common
import { getFilterParamsFromColumnSettings } from '../../../report-common';
//////////////////////////////////////////////////

export const objectTypeOptions = [
  {
    value: GC.TASK_MANAGEMENT_OBJECT_TYPE_GENERAL,
    label: G.getEnumLocale(GC.TASK_MANAGEMENT_OBJECT_TYPE_GENERAL),
  },
  {
    value: GC.TASK_MANAGEMENT_OBJECT_TYPE_DRIVER,
    label: G.getEnumLocale(GC.TASK_MANAGEMENT_OBJECT_TYPE_DRIVER),
  },
  {
    value: GC.TASK_MANAGEMENT_OBJECT_TYPE_TRUCK,
    label: G.getEnumLocale(GC.TASK_MANAGEMENT_OBJECT_TYPE_TRUCK),
  },
  {
    value: GC.TASK_MANAGEMENT_OBJECT_TYPE_TRAILER,
    label: G.getEnumLocale(GC.TASK_MANAGEMENT_OBJECT_TYPE_TRAILER),
  },
  {
    value: GC.TASK_MANAGEMENT_OBJECT_TYPE_FLEET_VENDOR,
    label: G.getEnumLocale(GC.TASK_MANAGEMENT_OBJECT_TYPE_FLEET_VENDOR),
  },
];

const subObjectTypeOptions = [
  {
    value: GC.TASK_MANAGEMENT_SUB_OBJECT_TYPE_EQUIPMENT_SERVICE,
    label: G.getEnumLocale(GC.TASK_MANAGEMENT_SUB_OBJECT_TYPE_EQUIPMENT_SERVICE),
  },
];

export const tableSettings = {
  cellFontSize: 13,
  titleFontSize: 14,
  tableRowHeight: 40,
  titleRowHeight: 45,
  allowEditBtn: true,
  moreBtnCellWidth: 50,
  allowSelectAll: false,
  checkBoxCellWidth: 55,
  expandableItems: false,
  searchableTitles: true,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 145px)',
  checkBoxCellJustifyContent: 'unset',
};

export const columnSettings = {
  [`${GC.FIELD_STATUS}.${GC.FIELD_NAME}`]: {
    width: 200,
    name: 'titles:status',
  },
  [`${GC.FIELD_STATUS}.${GC.FIELD_COMPLETED}`]: {
    width: 200,
    type: 'boolean',
    name: 'titles:completed',
    filter: {
      type: 'boolean',
    },
  },
  [`${GC.FIELD_TYPE}.${GC.FIELD_NAME}`]: {
    width: 200,
    name: 'titles:type',
  },
  [GC.FIELD_TITLE]: {
    width: 200,
    name: 'titles:title',
  },
  [GC.FIELD_TASK_NUMBER]: {
    width: 200,
    name: 'titles:task-number',
  },
  [GC.FIELD_START_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:start-date',
    filter: {
      type: 'date',
    },
  },
  [GC.FIELD_DUE_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:due-date',
    filter: {
      type: 'date',
    },
  },
  [GC.FIELD_OBJECT_TYPE]: {
    width: 200,
    type: 'enum',
    name: 'titles:object-type',
    filter: {
      type: 'selectMultiple',
      options: objectTypeOptions,
      placeholder: { text: 'Choose', key: 'titles:choose' },
    },
  },
  [GC.FIELD_OBJECT_NAME]: {
    width: 200,
    name: 'titles:object-name',
  },
  [`${GC.FIELD_ASSIGNEE}.${GC.FIELD_FIRST_NAME}`]: {
    width: 200,
    name: ['titles:assignee', 'titles:first-name'],
  },
  [`${GC.FIELD_ASSIGNEE}.${GC.FIELD_LAST_NAME}`]: {
    width: 200,
    name: ['titles:assignee', 'titles:last-name'],
  },
  [`${GC.FIELD_ASSIGNEE}.${GC.FIELD_USER_LOGIN_ID}`]: {
    width: 200,
    name: ['titles:assignee', 'titles:login-id'],
  },
  [`${GC.FIELD_SUPERVISOR}.${GC.FIELD_FIRST_NAME}`]: {
    width: 200,
    name: ['titles:supervisor', 'titles:first-name'],
  },
  [`${GC.FIELD_SUPERVISOR}.${GC.FIELD_LAST_NAME}`]: {
    width: 200,
    name: ['titles:supervisor', 'titles:last-name'],
  },
  [`${GC.FIELD_SUPERVISOR}.${GC.FIELD_USER_LOGIN_ID}`]: {
    width: 200,
    name: ['titles:supervisor', 'titles:login-id'],
  },
  [GC.FIELD_COMPLETED_BY]: {
    width: 200,
    name: 'titles:completed-by',
  },
  [GC.FIELD_COMPLETED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:completed-date',
    filter: {
      type: 'date',
    },
  },
  [GC.FIELD_SUB_OBJECT_TYPE]: {
    width: 200,
    type: 'enum',
    name: 'titles:sub-object-type',
    filter: {
      type: 'selectMultiple',
      options: subObjectTypeOptions,
      placeholder: { text: 'Choose', key: 'titles:choose' },
    },
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-date',
    filter: {
      type: 'date',
    },
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 200,
    name: 'titles:updated-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:update-on',
    filter: {
      type: 'date',
    },
  },
};

export const FILTER_PARAMS = getFilterParamsFromColumnSettings(columnSettings);

export const TASK_BOARD_FILTER_PARAMS = R.reject(({ value }: Object) => R.includes(
  value,
  [
    `${GC.FIELD_STATUS}.${GC.FIELD_NAME}`,
    `${GC.FIELD_STATUS}.${GC.FIELD_COMPLETED}`,
  ],
), FILTER_PARAMS);
