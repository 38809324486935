import * as R from 'ramda';
import { put, call, race, take, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
// common
import { initialDataLoadFail, initialDataLoadSuccess } from '../../common/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import {
  makeSelectAccessToPagesList,
  makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import routesMap from '../../utilities/routes';
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature sequences
import * as A from './actions';
//////////////////////////////////////////////////

export function* getSequencesListSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.listSequences, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getSequencesListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getSequencesListSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getSequencesListSaga exception');
  }
}

export function* handleGetSequenceRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { guid } = payload;
    const endpoint = endpointsMap.getSequenceEndpoint(guid);
    const res = yield call(sendRequest, 'get', endpoint);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getSequenceSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetSequenceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetSequenceRequest exception');
  }
}

export function* handleGetSequenceNextValRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const guid = payload;
    const endpoint = endpointsMap.getSequenceNextValEndpoint(guid);
    const res = yield call(sendRequest, 'get', endpoint);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      if (R.equals(data.messageCode, 422)) {
        yield call(G.showToastrMessage, 'error', 'messages:error:422-sequences');
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetSequenceNextValRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetSequenceNextValRequest exception');
  }
}

export function* getSequenceTransformersSaga() {
  try {
    const res = yield call(sendRequest, 'get', endpointsMap.sequenceTransformersList);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getSequenceTransformersSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetSequenceTransformersRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetSequenceTransformersRequest exception');
  }
}

export function* handleGetSequencesByTypeRequestSaga({ payload }: Object) {
  try {
    let type;
    let branchGuid;
    if (G.isObject(payload)) {
      type = payload.type;
      branchGuid = payload.branchGuid;
    } else {
      type = payload;
      branchGuid = yield select(makeSelectCurrentBranchGuid());
    }
    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: branchGuid, type },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.listSequences, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getSequencesByTypeSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetSequencesByTypeRequestSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetSequencesByTypeRequestSaga exception');
  }
}

export function* handleCreateNewSequenceRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        ...payload,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.sequence, options);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(getSequencesListSaga);
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateNewSequenceRequest fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateNewSequenceRequest exception');
  }
}

export function* handleUpdateSequenceRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        ...payload,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'put', endpointsMap.sequence, options);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield call(getSequencesListSaga);
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateSequenceRequest fail', true);
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateSequenceRequest exception');
  }
}

export function* handleDeleteSequenceRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const guid = payload;
    const endpoint = endpointsMap.getSequenceEndpoint(guid);
    const res = yield call(sendRequest, 'delete', endpoint);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      if (R.equals(data.messageCode, 400)) {
        yield call(G.showToastrMessage, 'error', data.messageText);
      } else {
        yield put(A.deleteSequenceSuccess(guid));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteSequenceRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteSequenceRequest exception');
  }
}

export function* handleVisitSequencesListPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    const route = payload;
    const accessToPagesList = yield select(makeSelectAccessToPagesList());
    const isInitialDataLoaded = yield select(makeSelectInitialDataLoadedStatus());
    const isAllowed = R.includes(route, accessToPagesList);
    if (R.not(isInitialDataLoaded)) {
      yield race({
        fail: take(initialDataLoadFail),
        success: take(initialDataLoadSuccess),
      });
    }
    if (R.not(isAllowed)) {
      yield put({ type: GC.CHECK_VISIT_SEQUENCES_LIST_PAGE, payload: route });
      yield take(GC.CHECK_VISIT_SEQUENCES_LIST_PAGE_ALLOWED);
    }
    yield put(openLoader({ showDimmer: true }));
    yield call(getSequencesListSaga);
    yield put(closeLoader());
    break;
  }
}

export function* handleVisitSequenceEditSaga(action: Object) {
  while (true) { // eslint-disable-line
    const { route } = action.payload;
    const accessToPagesList = yield select(makeSelectAccessToPagesList());
    const isInitialDataLoaded = yield select(makeSelectInitialDataLoadedStatus());
    const isAllowed = R.includes(route, accessToPagesList);
    if (R.not(isInitialDataLoaded)) {
      yield race({
        fail: take(initialDataLoadFail),
        success: take(initialDataLoadSuccess),
      });
    }
    if (R.not(isAllowed)) {
      yield put({ type: GC.CHECK_VISIT_SEQUENCE_EDIT_PAGE, payload: route });
      yield take(GC.CHECK_VISIT_SEQUENCE_EDIT_PAGE_ALLOWED);
    }
    yield put(openLoader({ showDimmer: true }));
    yield call(handleGetSequenceRequest, action);
    yield put(closeLoader());
    break;
  }
}

export function* handleVisitSequenceNewSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    const route = payload;
    const accessToPagesList = yield select(makeSelectAccessToPagesList());
    const isInitialDataLoaded = yield select(makeSelectInitialDataLoadedStatus());
    const isAllowed = R.includes(route, accessToPagesList);
    if (R.not(isInitialDataLoaded)) {
      yield race({
        fail: take(initialDataLoadFail),
        success: take(initialDataLoadSuccess),
      });
    }
    if (R.not(isAllowed)) {
      yield put({ type: GC.CHECK_VISIT_SEQUENCE_CREATE_PAGE, payload: route });
      yield take(GC.CHECK_VISIT_SEQUENCE_CREATE_PAGE_ALLOWED);
    }
    break;
  }
}

export function* SequencesWatcherSaga() {
  yield takeLatest(A.getSequenceRequest, handleGetSequenceRequest);
  yield takeLatest(A.deleteSequenceRequest, handleDeleteSequenceRequest);
  yield takeLatest(A.updateSequenceRequest, handleUpdateSequenceRequest);
  yield takeLatest(GC.VISIT_SEQUENCE_EDIT_PAGE, handleVisitSequenceEditSaga);
  yield takeLatest(GC.VISIT_SEQUENCE_CREATE_PAGE, handleVisitSequenceNewSaga);
  yield takeLatest(A.createNewSequenceRequest, handleCreateNewSequenceRequest);
  yield takeLatest(A.getSequenceNextValRequest, handleGetSequenceNextValRequest);
  yield takeLatest(GC.VISIT_SEQUENCES_LIST_PAGE, handleVisitSequencesListPageSaga);
  yield takeLatest(A.getSequencesByTypeRequest, handleGetSequencesByTypeRequestSaga);
}

export default SequencesWatcherSaga;
