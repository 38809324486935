import * as R from 'ramda';
import styled from 'styled-components';
import { fontSize } from 'styled-system';
// forms
import { renderReportBorderColor } from './helpers';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const SectionHeader = styled.div`
  height: 40px;
  display: flex;
  padding: 0px 15px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  color: ${() => G.getTheme('colors.light.black')};
  background-color: ${() => G.getTheme('colors.light.lightGrey')};
  border-bottom: 1px solid ${() => G.getTheme('colors.lightGrey')};
`;

export const ReportWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ jc }: Object) => jc || 'baseline'};
  & svg {
    cursor: pointer;
  }
  & img {
    height: 20px;
  }
`;

export const RightTitlePanel = styled.div`
  z-index: 11;
  display: flex;
  align-items: center;
  & #dropdown-user-export {
    & > div > img {
      height: 20px;
    }
  }
`;

export const FormWrapper = styled.div`
  & form > div:nth-child(1) > div:nth-child(2) {
    margin: 10px auto;
  }
`;

export const LinkText = styled.div`
  cursor: pointer;
  font-size: 12px;
  color: ${() => G.getTheme('colors.light.blue')};
`;

export const Group = styled.div`
  ${fontSize}

  display: flex;
  align-items: center;

  z-index: ${({ zI }: Object) => zI || 11};
`;

export const FilterDate = styled.div`
  display: flex;
  z-index: ${({ zI }: Object) => zI || 11};
  & > div:last-child {
    margin: 0;
  }
  & .react-datepicker {
    width: max-content;
  }
  & .react-datepicker-popper {
    top: 5px !important;
    left: -81px !important;
  }
  & .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
    height: 183px !important;
  }
`;

export const Option = styled.div`
  display: flex;
  padding: 5px 15px;
  justify-content: space-between;
  color: ${() => G.getTheme('downFilter.textColor')};
  &:hover {
    cursor: pointer;
    background: ${() => G.getTheme('dropDown.item.hover.bgColor')};
  }
  & > span > span {
    padding: 0 3px;
    &:hover svg path {
      fill: ${() => G.getTheme('colors.light.darkRed')};
    }
  }
`;

export const ReportPanelOption = styled(Option)`
  color: ${() => G.getTheme('colors.greyMatterhorn')};
  & svg path {
    fill: ${({ isDefault }: Object) => isDefault && G.getTheme('colors.light.darkRed')};
  }
  &:hover {
    font-weight: bold;
    color: ${() => G.getTheme('colors.dark.blue')};
    background: ${() => G.getTheme('colors.white')};
  }
`;

export const GenerateFile = styled.div`
  display: flex;
  & span {
    padding: 0 3px;
  }
  &:hover {
    cursor: pointer;
    & span {
      color: ${() => G.getTheme('colors.light.darkRed')};
      & path {
        fill: ${() => G.getTheme('colors.light.darkRed')};
      }
    }
  }
`;
