import * as R from 'ramda';
import React, { useState, Fragment, useEffect, useCallback } from 'react';
// components
import { FormFooter2 } from '../../../components/form-footer';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// ui
import { Flex } from '../../../ui';
// feature task-management
import TaskForm from './form';
import Activities from './activities';
import { makeInitialValues } from '../helpers';
//////////////////////////////////////////////////

const Task = (props: Object) => {
  const {
    taskGuid,
    openLoader,
    closeLoader,
    currentUserSettings,
    createOrUpdateTaskRequest,
    setExpandedContainerOptions,
  } = props;

  const [entities, setEntities] = useState(R.propOr({}, 'entities', props));
  const [initialValues, setInitialValues] = useState(R.propOr({}, 'initialValues', props));

  const handleOpenDetails = useCallback((visitPageGuid: string, objectType: string) => {
    if (R.equals(objectType, GC.TASK_MANAGEMENT_OBJECT_TYPE_GENERAL)) return;

    const componentMap = {
      [GC.TASK_MANAGEMENT_OBJECT_TYPE_TRUCK]: GC.PAGE_FLEET_TRUCK_PROFILE_V2,
      [GC.TASK_MANAGEMENT_OBJECT_TYPE_DRIVER]: GC.PAGE_FLEET_DRIVER_PROFILE_V2,
      [GC.TASK_MANAGEMENT_OBJECT_TYPE_TRAILER]: GC.PAGE_FLEET_TRAILER_PROFILE_V2,
      [GC.TASK_MANAGEMENT_OBJECT_TYPE_FLEET_VENDOR]: GC.PAGE_FLEET_VENDOR_PROFILE_V2,
    };

    const componentType = componentMap[objectType];

    if (R.isNil(componentType)) return;

    setExpandedContainerOptions({ opened: true, zIndex: 1301, visitPageGuid, componentType });
  }, []);

  useEffect(() => {
    if (R.isNil(taskGuid)) return;

    const getInitialValuesRequest = async () => {
      openLoader();

      const res = await sendRequest('get', endpointsMap.getTaskByGuid(taskGuid));

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setInitialValues(makeInitialValues(data));
      }

      closeLoader();
    };

    getInitialValuesRequest();
  }, []);

  const activitiesMaxHeight = G.ifElse(
    R.isNotNil(R.prop(GC.FIELD_COMPLETED_BY, initialValues)),
    723,
    681,
  );

  return (
    <Fragment>
      <Flex alignItems='stretch'>
        <TaskForm
          {...props}
          pt={30}
          px={15}
          entities={entities}
          hideFormFooter={true}
          setEntities={setEntities}
          initialValues={initialValues}
          handleOpenDetails={handleOpenDetails}
          submitAction={createOrUpdateTaskRequest}
        />
        {
          R.isNotNil(taskGuid) &&
          <Activities
            taskGuid={taskGuid}
            openLoader={openLoader}
            closeLoader={closeLoader}
            activitiesMaxHeight={activitiesMaxHeight}
            currentUserSettings={currentUserSettings}
            users={R.pathOr([], [GC.FIELD_ASSIGNEE], entities)}
          />
        }
      </Flex>
      <FormFooter2
        formId='task-form'
        submitDisabled={G.hasNotAmousCurrentUserPermissions(PC.TASK_WRITE)}
        boxStyles={{ p: 15, bg: '#F6F6F6', borderTop: '1px solid', borderColor: '#E4E4E4' }}
      />
    </Fragment>
  );
};

export default Task;
