import React from 'react';
import * as R from 'ramda';
// components
import TextComponent from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// feature work-order
import { isSingleCurrency } from '../helpers';
//////////////////////////////////////////////////

export const TotalComponent = ({ ml, mr, maxWidth, totalText }: Object) => (
  <Box ml={ml} mr={mr} p='5px' borderRadius='5px' bg='titleDarkBlue' color={G.getTheme('colors.white')}>
    <TextComponent display='block' title={totalText} withEllipsis={true} maxWidth={maxWidth}>
      {totalText}
    </TextComponent>
  </Box>
);

export const InvoicesTotals = ({ invoices }: Object) => {
  const isSingleInvoiceCurrency = isSingleCurrency(invoices);
  const isSingleNormalizedCurrency = isSingleCurrency(R.map(R.prop(GC.FIELD_NORMALIZED_TOTAL), invoices));

  const currencySymbol = G.getCurrencySymbol(R.path([0, GC.FIELD_CURRENCY], invoices));

  const normalizedCurrencySymbol = G.getCurrencySymbol(
    R.path([0, GC.FIELD_NORMALIZED_TOTAL, GC.FIELD_CURRENCY], invoices),
  );

  const showNormalizedTotal = R.and(
    isSingleNormalizedCurrency,
    R.or(
      R.not(isSingleInvoiceCurrency),
      R.and(isSingleInvoiceCurrency, G.notEquals(currencySymbol, normalizedCurrencySymbol)),
    ),
  );

  if (G.isAllFalse(showNormalizedTotal, isSingleInvoiceCurrency)) return null;

  const normalizedTotal = G.mathRoundNumber(R.reduce(
    (acc: number, { normalizedTotal }: Object) => R.add(acc, R.pathOr(0, [GC.FIELD_TOTAL], normalizedTotal)),
    0,
    invoices,
  ));

  const total = G.calculateTotal(invoices);

  return (
    <Flex width='100%' p='5px 15px' justifyContent='center' bg={G.getTheme('colors.whiteGrey')}>
      {
        showNormalizedTotal &&
        <TotalComponent
          maxWidth={250}
          totalText={`${G.getWindowLocale('titles:normalized-total', 'Normalized Total')}: ${
            normalizedCurrencySymbol} ${normalizedTotal}`}
        />
      }
      {
        isSingleInvoiceCurrency &&
        <TotalComponent
          ml={10}
          maxWidth={150}
          totalText={`${G.getWindowLocale('titles:total', 'Total')}: ${currencySymbol} ${total}`}
        />
      }
    </Flex>
  );
};
