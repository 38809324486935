import * as R from 'ramda';
import React from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { PhotoshopPicker } from 'react-color';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withState,
  withHandlers,
  renderNothing,
  withPropsOnChange,
} from 'react-recompose';
// components
import { FormButtons } from '../../../components/form-buttons';
import ImageSlider from '../../../components/slider-component/index';
import { FormGroupTitleComponent } from '../../../components/form-group-title';
// features
import { StyledFormTitle } from '../../auth/ui';
import { makeSelectCurrentBranch } from '../../branch/selectors';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// svgs
import * as I from '../../../svgs';
// forms
import {
  Label,
  Input,
  Toggle,
  Textarea,
  FormGroup,
  InputWrapper,
  renderOptions,
  SelectWrapper,
  ToggleWrapper,
  SelectComponent,
  LeftLabelsWrapper,
} from '../../../forms';
// ui
import {
  Box,
  Flex,
  Wrapper,
  AbsoluteBox,
  RelativeBox,
  RelativeFlex,
  ActionButton,
} from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature styling
import { getPositionOptions } from '../settings/fields';
import { makeSelectSplashScreenConfigs } from '../selectors';
import { updateSplashScreenSettingsRequest, setSplashScreenRequest } from '../actions';
import {
  StylingImage,
  DropzoneAction,
  DropzoneWrapper,
  ColorPickerWrapper,
  StylingPreviewWrapper,
} from '../ui';
//////////////////////////////////////////////////

export const enhance = compose(
  withState('stylingState', 'setStylingState', {}),
  withState('infoPanelIsOpen', 'toggleInfoPanelIsOpen', false),
  withState(
    'infoPanelState',
    'setInfoPanelState',
    {
      panelExpanded: false,
      notes: R.of(Array, { title: '', subtitle: '', position: 1 }),
    },
  ),
  withHandlers(() => {
    const refs = {};

    return {
      handleGetInfoPanelRef: (props: Object) => (ref: Object, refName: string) => {
        refs[refName] = ref;

        if (G.isNotNilAndNotEmpty(refs[refName])) {
          const title = R.pathOr('', ['infoPanelState', 'notes', refName, 'title'], props);
          const subtitle = R.pathOr('', ['infoPanelState', 'notes', refName, 'subtitle'], props);
          refs[refName].innerHTML = `<h1>${title}</h1> <p>${subtitle}</p>`;
        }
      },
      handleClickSave: (props: Object) => () => {
        const {
          dnsPrefix,
          stylingState,
          currentBranch,
          infoPanelState,
          splashScreenSettings,
          setSplashScreenRequest,
          updateSplashScreenSettingsRequest,
        } = props;

        const { notes, panelColor, panelExpanded, buttonBackgroundColor } = infoPanelState;

        const panels = notes.filter(({ title }: Object) => (
          G.isNotNilAndNotEmpty(title)
        ));

        const data = {
          ...stylingState,
          dnsPrefix,
          panelColor,
          panelExpanded,
          buttonBackgroundColor,
          panelsJson: JSON.stringify(panels),
          [GC.FIELD_BRANCH_GUID]: G.getGuidFromObject(currentBranch),
        };

        G.ifElse(
          R.equals(R.path(['dnsPrefix'], splashScreenSettings), R.path(['dnsPrefix'], currentBranch)),
          updateSplashScreenSettingsRequest,
          setSplashScreenRequest,
        )(data);
      },
      handleSetStylingProp: ({ stylingState, setStylingState }: Object) => (prop: string, value: any) =>
        setStylingState(R.assoc(prop, value, stylingState)),
      handleSetImage: ({ stylingState, setStylingState }: Object) => (prop: string, event: Object) => {
        const imageFile = R.head(event);
        const imageType = /image.*/;
        let propFileName = 'logoFile';

        if (R.includes('BackgroundImageUrl', prop)) {
          const prefList = ['first', 'second', 'third'];
          const isSelected = (item: Object) => R.includes(item, prop);

          propFileName = R.head(R.map(
            (item: Object) => `${item}BackgroundFile`,
            R.filter(isSelected, prefList),
          ));
        }

        if (imageFile.type.match(imageType)) {
          const reader = new window.FileReader();

          reader.onload = () => {
            setStylingState(R.assoc(prop, reader.result, stylingState));

            setStylingState({
              ...stylingState,
              [prop]: reader.result,
              [propFileName]: imageFile,
            });
          };

          reader.readAsDataURL(imageFile);
        }
      },
      handleToggleInfoPanel: ({ toggleInfoPanelIsOpen }: Object) => () =>
        toggleInfoPanelIsOpen((prevValue: boolean) => R.not(prevValue)),
      handleChangePanelColor: ({ setInfoPanelState }: Object) => (color: Object) =>
        setInfoPanelState((prev: Object) => R.assoc('panelColor', R.path(['hex'], color), prev)),
      handleChangeButtonBackgroundColor: ({ setInfoPanelState }: Object) => (color: Object) =>
        setInfoPanelState((prev: Object) => R.assoc('buttonBackgroundColor', R.path(['hex'], color), prev)),
      handleChangePanelExpanded: (props: Object) => ({ currentTarget }: Object) => {
        const { setInfoPanelState, toggleInfoPanelIsOpen } = props;

        const value = R.path(['checked'], currentTarget);

        setInfoPanelState((prev: Object) => R.assoc('panelExpanded', value, prev));
        toggleInfoPanelIsOpen(value);
      },
      handleChangeWelcomeText: ({ setStylingState }: Object) => ({ currentTarget }: Object) => {
        const value = G.getValueFromObject(currentTarget);

        setStylingState((prev: Object) => R.assoc('welcomeText', value, prev));
      },
      handleChangeNoteInput: (props: Object) => ({ currentTarget }: Object, index: number, prop: string) => {
        const { infoPanelState, setInfoPanelState } = props;

        const value = G.getValueFromObject(currentTarget);
        const notes = R.clone(R.path(['notes'], infoPanelState));
        const conflicterIndex = R.findIndex(R.propEq(value, 'position'), notes);
        const changedValue = notes[index][prop];

        notes[index][prop] = value;

        if (R.and(R.equals('position', prop), G.notEquals(conflicterIndex, -1))) {
          notes[conflicterIndex][prop] = changedValue;
        }

        setInfoPanelState((prev: Object) => R.assoc('notes', notes, prev));
      },
      handleAddNotesFields: ({ infoPanelState, setInfoPanelState }: Object) => () => {
        let notes = R.clone(R.path(['notes'], infoPanelState));

        const freePositions = R.filter(
          (position: number) => (
            R.not(R.any((note: Object) => R.equals(position, R.path(['position'], note)), notes))
          ), [1, 2, 3]);

        notes = R.append({ title: '', subtitle: '', position: R.head(freePositions) }, notes);

        setInfoPanelState((prev: Object) => R.assoc('notes', notes, prev));
      },
      handleClickCancel: ({ history }: Object) => () => history.goBack(),
    };
  }),
  withPropsOnChange(['splashScreenSettings'], (props: Object) => {
    const { setStylingState, setInfoPanelState, splashScreenSettings, toggleInfoPanelIsOpen } = props;

    const {
      guid,
      panels,
      version,
      logoUrl,
      panelColor,
      welcomeText,
      panelExpanded,
      buttonBackgroundColor,
      thirdBackgroundImageUrl,
      firstBackgroundImageUrl,
      secondBackgroundImageUrl,
    } = splashScreenSettings;

    setStylingState(
      (prev: Object) => (
        {
          ...prev,
          guid,
          version,
          logoUrl,
          welcomeText,
          logoFile: null,
          thirdBackgroundImageUrl,
          firstBackgroundImageUrl,
          secondBackgroundImageUrl,
          thirdBackgroundFile: null,
          firstBackgroundFile: null,
          secondBackgroundFile: null,
        }
      ));

    const defaultNotesObj = {
      title: '', subtitle: '', position: 1,
    };

    let notes = G.ifElse(
      G.isNilOrEmpty(panels),
      R.of(Array, defaultNotesObj),
      panels,
    );

    const freePositions = R.filter(
      (position: number) => (
        R.not(R.any((note: Object) => R.equals(position, R.path(['position'], note)), notes))
      ), [1, 2, 3]);

    if (R.and(G.isNotNilAndNotEmpty(secondBackgroundImageUrl), R.lt(R.length(notes), 2))) {
      const position = freePositions.splice(0, 1);

      notes.push({...defaultNotesObj, position: R.head(position) });
    }

    if (R.and(G.isNotNilAndNotEmpty(thirdBackgroundImageUrl), R.lt(R.length(notes), 3))) {
      const position = freePositions.splice(0, 1);

      notes.push({...defaultNotesObj, position: R.head(position) });
    }

    const sortByPosition = G.sortByProp('position');
    notes = sortByPosition(notes);

    setInfoPanelState({ notes, panelColor, buttonBackgroundColor, panelExpanded: R.or(panelExpanded, false)});
    toggleInfoPanelIsOpen(R.or(panelExpanded, false));
  }),
  branch(
    ({ initialDataLoaded }: Object) => R.not(initialDataLoaded),
    renderNothing,
  ),
  pure,
);

export const renderDropzoneActions = (onSetImage: Function, onClearImage: Function) => (
  <AbsoluteBox top={-15} zIndex={11} right={-10}>
    <DropzoneAction>
      <Dropzone className='dropzone' onDrop={onSetImage}>
        {({ getRootProps, getInputProps }: Object) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <img alt='edit' src={G.composeImgLink('dark', 'edit')} />
          </div>
        )}
      </Dropzone>
    </DropzoneAction>
    <DropzoneAction ml={10} onClick={onClearImage}>
      <img alt='clear' src={G.composeImgLink('light', 'trash')} />
    </DropzoneAction>
  </AbsoluteBox>
);

export const renderDropzoneSection = ({
  stylingState,
  handleSetImage,
  handleSetStylingProp,
}: Object,
  prop: string,
  title: string,
  note: string,
) => (
  <RelativeFlex pb={10} flexWrap='wrap'>
    <Box width={200}>
      <RelativeFlex zIndex={11} m='15px 0 0 15px' alignItems='center' flexWrap='wrap'>
        {title}
        {
          note &&
          <Box
            mt='5px'
            fontSize={10}
          >
            {note}
          </Box>
        }
      </RelativeFlex>
    </Box>
    <DropzoneWrapper m='15px 15px 0' withLogo={G.isNotNil(stylingState[prop])}>
      <Dropzone
        className='drop-zone'
        onDrop={(event: Object) => handleSetImage(prop, event)}
      >
        {({ getRootProps, getInputProps }: Object) => (
          <AbsoluteBox
            {...getRootProps()}
            width='100%'
            height='100%'
            justifyContent='center'
            opacity={G.ifElse(G.isNotNilAndNotEmpty(R.prop(prop, stylingState)), 0)}
          >
            <input {...getInputProps()} />
            <span>{G.getWindowLocale('actions:upload', 'Upload')}</span>
          </AbsoluteBox>
        )}
      </Dropzone>
      {
        G.isNotNil(R.prop(prop, stylingState)) &&
        <Flex
          alignItems='center'
          justifyContent='center'
          width={G.ifElse(R.equals(prop, 'logoUrl'), '90%', '100%')}
          height={G.ifElse(R.equals(prop, 'logoUrl'), '90%', '100%')}
        >
          <StylingImage
            size='100%'
            bgImage={R.prop(prop, stylingState)}
            bs={G.ifElse(R.equals(prop, 'logoUrl'), 'contain', 'cover')}
          />
        </Flex>
      }
      {
        R.and(
          G.isNotNil(stylingState[prop]),
          renderDropzoneActions(
            (event: Object) => handleSetImage(prop, event),
            () => handleSetStylingProp(prop, null),
          ),
        )
      }
    </DropzoneWrapper>
  </RelativeFlex>
);

export const renderLoginTitleSection = (props: Object, validationMessage: string) => {
  const { stylingState, handleChangeWelcomeText } = props;

  return (
    <FormGroup display='flex' direction='row'>
      <RelativeFlex ml={15} zIndex={11} width={185} flexDirection='column' alignItems='flex-start'>
        <Label fontSize={14}>
          {G.getWindowLocale('titles:login-title', 'Login Title')}
        </Label>
        {
          validationMessage &&
          <Box
            mt='5px'
            fontSize={10}
          >
            {validationMessage}
          </Box>
        }
      </RelativeFlex>
      <InputWrapper>
        <Input
          width={262}
          fontSize={16}
          maxLength={40}
          m='10px 15px 15px'
          onChange={handleChangeWelcomeText}
          value={R.path(['welcomeText'], stylingState)}
        />
      </InputWrapper>
    </FormGroup>
  );
};

export const renderColorPickerSection = (
  onChangeComplete: Function,
  title: string,
  color: string,
) => (
  <RelativeFlex pb={10} zIndex={11} flexWrap='wrap'>
    <Box width={200}>
      <RelativeFlex m='15px 0 0 15px' alignItems='center'>
        {title}
      </RelativeFlex>
    </Box>
    <ColorPickerWrapper>
      <PhotoshopPicker color={color} onChangeComplete={onChangeComplete} />
    </ColorPickerWrapper>
  </RelativeFlex>
);

export const renderPanelExpandedToggle = ({ infoPanelState, handleChangePanelExpanded }: Object) => (
  <FormGroup display='flex' direction='row'>
    <Label width={185} fontSize={14} m='5px 0 5px 15px'>
      {G.getWindowLocale('titles:panel-expanded', 'Panel Expanded')}
    </Label>
    <ToggleWrapper m='0 15px'>
      <Toggle
        icons={false}
        onChange={handleChangePanelExpanded}
        checked={R.path(['panelExpanded'], infoPanelState)}
      />
    </ToggleWrapper>
  </FormGroup>
);

export const renderNoteTextarea = (
  index: number,
  note: Object,
  withEndLabel: boolean,
  handleAddNotesFields: Function,
  handleChangeNoteInput: Function,
  title: string,
  label: string,
  maxLength: number,
  validationMessage: string,
) => (
  <FormGroup direction='row' align='flex-start'>
    <RelativeFlex zIndex={11} width={185} m='5px 0 0 15px'flexDirection='column' alignItems='flex-start'>
      <Label fontSize={14}>
        {`${label} ${R.inc(index)}`}
        {
          G.isTrue(withEndLabel) &&
          <Box ml='5px' cursor='pointer' onClick={handleAddNotesFields}>
            {I.plusRound()}
          </Box>
        }
      </Label>
      {
        validationMessage &&
        <Box
          mt='5px'
          fontSize={10}
        >
          {validationMessage}
        </Box>
      }
    </RelativeFlex>
    <Textarea
      width={320}
      m='5px 15px'
      fontSize={14}
      type='textarea'
      value={note[title]}
      maxLength={maxLength}
      onChange={(e: Object) => handleChangeNoteInput(e, index, title)}
      placeholder={G.getWindowLocale('titles:add-your-notes-here', 'Add Your Notes Here')}
    />
  </FormGroup>
);

export const renderPositionSelect = (
  index: number,
  note: Object,
  handleChangeNoteInput: Function,
) => (
  <FormGroup display='flex' direction='row'>
    <Label fontSize={14} width={185} m='5px 0 5px 15px'>
      {G.getWindowLocale('titles:position', 'Position')}
    </Label>
    <SelectWrapper>
      <SelectComponent
        width={262}
        m='5px 15px'
        fontSize={14}
        value={R.path(['position'], note)}
        onChange={(e: Object) => handleChangeNoteInput(e, index, 'position')}
      >
        {renderOptions(getPositionOptions())}
      </SelectComponent>
    </SelectWrapper>
  </FormGroup>
);

const createBgImageName = (prop: string, index: number) => {
  let name = null;

  switch (index) {
    case 0: name = `first${prop}`;
      break;
    case 1: name = `second${prop}`;
      break;
    case 2: name = `third${prop}`;
      break;
    default: name = `first${prop}`;
      break;
  }

  return name;
};

export const renderNotesFields = (
  props: Object,
  note: Object,
  withEndLabel: boolean,
  index: number,
) => {
  const imageFieldName = createBgImageName('BackgroundImageUrl', index);
  const { handleAddNotesFields, handleChangeNoteInput } = props;

  return (
    <RelativeFlex
      pb={10}
      key={index}
      alignItems='baseline'
      flexDirection='column'
      justifyContent='flex-start'
    >
      {
        renderNoteTextarea(
          index,
          note,
          withEndLabel,
          handleAddNotesFields,
          handleChangeNoteInput,
          'title',
          G.getWindowLocale('titles:title', 'Title'),
          40,
          `*${R.trim(G.getShouldBeFromToLocaleTxt(0, 40))}`,
        )
      }
      {
        renderNoteTextarea(
          index,
          note,
          withEndLabel=false,
          handleAddNotesFields,
          handleChangeNoteInput,
          'subtitle',
          G.getWindowLocale('titles:untertitel', 'Subtitle'),
          100,
          `*${R.trim(G.getShouldBeFromToLocaleTxt(0, 100))}`,
        )
      }
      {renderDropzoneSection(
        props,
        imageFieldName,
        G.getWindowLocale('titles:background-image', 'Background Image'),
        '*Recommend to use Full HD (1920x1080 and upper)',
      )}
    </RelativeFlex>
  );
};

export const renderNotesForm = (props: Object) => {
  const notes = R.path(['infoPanelState', 'notes'], props);

  return (
    notes.map((note: Object, index: number) => {
      const withEndLabel = R.and(
        R.equals(index, R.dec(R.length(notes))),
        G.notEquals(R.length(notes), 3),
      );

      return renderNotesFields({ ...props }, note, withEndLabel, index);
    })
  );
};

export const renderInfoPanelsSection = (props: Object) => {
  const { infoPanelState, handleChangePanelColor } = props;

  return (
    <RelativeBox width='50%'>
      <LeftLabelsWrapper width={200} />
      <RelativeFlex pb={10} flexWrap='wrap'>
        <Box width={200}>
          <RelativeFlex m='15px 0 0 15px' alignItems='center'>
            {G.getWindowLocale('titles:additional-info-panels', 'Additional Info Panels')}
          </RelativeFlex>
        </Box>
      </RelativeFlex>
      {
        renderColorPickerSection(
          handleChangePanelColor,
          G.getWindowLocale('titles:font-color', 'Font Color'),
          R.pathOr(G.getTheme('colors.light.mainLight'), ['panelColor'], infoPanelState),
        )
      }
      {renderNotesForm(props)}
    </RelativeBox>
  );
};

export const renderStylingPreviewWrapper = ({
  stylingState,
  infoPanelState,
  handleGetInfoPanelRef,
}: Object,
options: Array) => {
  const urlList = R.pick([
    'firstBackgroundImageUrl',
    'secondBackgroundImageUrl',
    'thirdBackgroundImageUrl',
  ], stylingState);

  const isSelected = (key: string) => G.isNotNil(urlList[key]);

  const bgImages = R.map(
    (key: Object) => urlList[key],
    R.filter(isSelected, R.keys(urlList)),
  );

  let optionsWithImg = options.map((item: object, i: number) => ({
    ...R.pick(['title', 'subtitle'], item),
    img: R.pathOr(R.head(bgImages), [i], bgImages),
  }));

  const sortByOrder = G.sortByProp('order');

  optionsWithImg = sortByOrder(optionsWithImg);

  if (R.and(G.isNotNil(bgImages), G.isNotNil(R.path(['logoUrl'], stylingState)))) {
    return (
      <StylingPreviewWrapper
        bs='cover'
        jc='center'
        height='625px'
      >
        <ImageSlider
          splashScreen={true}
          slides={optionsWithImg}
          handleGetInfoPanelRef={handleGetInfoPanelRef}
          color={R.path(['panelColor'], infoPanelState)}
        />
        <Flex
          width='37%'
          height='100%'
          alignItems='center'
          justifyContent='center'
        >
          <Flex
            width='100%'
            maxWidth={400}
            alignItems='center'
            flexDirection='column'
            justifyContent='center'
          >
            <Flex
              mb={50}
              width={100}
              height={100}
              alignItems='center'
              justifyContent='center'
            >
              <StylingImage size='90%' bgImage={R.path(['logoUrl'], stylingState)} />
            </Flex>
            <StyledFormTitle>
              {R.or(
                R.path(['welcomeText'], stylingState),
                G.getWindowLocale('titles:login-to-amous', 'Login to Amous'),
              )}
            </StyledFormTitle>
            <Box
              my={25}
              width={350}
              height={250}
              boxShadow='0px 3px 20px 8px rgba(34, 31, 31, 0.06)'
            />
            <ActionButton
              width={350}
              height={48}
              fontSize={18}
              zIndex='unset'
              fontWeight='bold'
              borderRadius='4px'
              textColor={G.getTheme('colors.black')}
              bgColor={R.pathOr(G.getTheme('colors.lime'), ['buttonBackgroundColor'], infoPanelState)}
            >
              {G.getWindowLocale('actions:log-in', 'Log in')}
            </ActionButton>
          </Flex>
        </Flex>
      </StylingPreviewWrapper>
    );
  }

  return (
    <StylingPreviewWrapper>
      <p>
        {G.getWindowLocale('titles:nothing-to-show', 'Nothing To Show')}
        <br />
        {G.getWindowLocale(
          'messages:please-select-logo-and-bg',
          'Please, upload company logo and background image first.',
        )}
      </p>
    </StylingPreviewWrapper>
  );
};

const formatNotesToOptions = (infoPanelState: Object) => R.map((note: Object) => R.compose(
  R.assoc('order', R.path(['position', note])),
  R.pick(['title', 'subtitle']),
), R.pathOr([], ['notes'], infoPanelState));

export const SplashScreenComponent = (props: Object) => {
  const { infoPanelState, handleChangeButtonBackgroundColor } = props;

  return (
    <Box>
      <FormGroupTitleComponent
        mb='0'
        text={G.getWindowLocale('titles:splash-screen-styling-settings', 'Splash Screen Styling Settings')}
      />
      <Wrapper>
        <RelativeBox width='50%'>
          <LeftLabelsWrapper width={200} />
          {
            renderDropzoneSection(
              props,
              'logoUrl',
              G.getWindowLocale('titles:logo', 'Logo'),
            )
          }
          {renderLoginTitleSection(props, `*${R.trim(G.getShouldBeFromToLocaleTxt(0, 40))}`)}
          {
            renderColorPickerSection(
              handleChangeButtonBackgroundColor,
              G.getWindowLocale('titles:login-button-color', 'Login Button Color'),
              R.pathOr(G.getTheme('colors.lime'), ['buttonBackgroundColor'], infoPanelState),
            )
          }
        </RelativeBox>
        {renderInfoPanelsSection(props)}
      </Wrapper>
      <FormGroupTitleComponent
        mb='0'
        text={G.getWindowLocale('titles:settings-preview', 'Settings Preview')}
      />
      {renderStylingPreviewWrapper(props, formatNotesToOptions(infoPanelState))}
      <FormButtons {...props} />
    </Box>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  currentBranch: makeSelectCurrentBranch(state),
  splashScreenSettings: makeSelectSplashScreenConfigs(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
}));

export default connect(mapStateToProps, {
  setSplashScreenRequest,
  updateSplashScreenSettingsRequest,
})(enhance(SplashScreenComponent));
