import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature template-inspection
import * as C from '../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = { mt: 10, mb: 25, zIndex: 2, width: 370 };

export const vehicleTypeDropdownOptions = [
  {
    label: '',
    value: '',
  },
  {
    label: G.getWindowLocale('titles:inspection-truck', 'Truck'),
    value: C.INSPECTION_TRUCK,
  },
  {
    label: G.getWindowLocale('titles:inspection-trailer', 'Trailer'),
    value: C.INSPECTION_TRAILER,
  },
];

export const getFields = () => [
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_NAME,
    maxLength: C.MAX_NAME_LENGTH,
    label: ['titles:inspection-template-name', 'Template Name'],
  },
  {
    type: 'select',
    inputWrapperStyles,
    fieldName: GC.FIELD_VEHICLE_TYPE,
    options: vehicleTypeDropdownOptions,
    label: ['titles:inspection-vehicle-type', 'Vehicle Type'],
  },
];

export const defaultValues = {
  [GC.FIELD_NAME]: '',
  [GC.FIELD_VEHICLE_TYPE]: '',
};

export const getValidationSchemaObject = () => ({
  [GC.FIELD_NAME]: Yup.string().required(G.getRequiredLocaleTxt()),
  [GC.FIELD_VEHICLE_TYPE]: Yup.string().required(G.getRequiredLocaleTxt()),
});
