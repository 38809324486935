import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
// forms
import {
  InputWrapper,
  CheckboxWrapper,
} from '../../../../forms/formik/fieldset2/input-wrapper';
//////////////////////////////////////////////////

const defaultLabelStyles = {
  right: 0,
  top: -18,
};

const FormField = (props: Object) => {
  const {
    type,
    label,
    width,
    children,
    inputLabelStyles = {},
    inputWrapperStyles = {},
    checkboxLabelStyles = {},
  } = props;

  const wrapperStyles = {
    ...R.pick(['name', 'label', 'labelStyles'], props),
    labelStyles: {
      ...defaultLabelStyles,
      ...inputLabelStyles,
    },
    inputWrapperStyles: {
      width,
      mt: 15,
      ...inputWrapperStyles,
    },
    checkboxLabelStyles: {
      pl: 10,
      ...checkboxLabelStyles,
    },
  };

  if (R.equals(type, 'checkbox')) {
    return (
      <CheckboxWrapper {...wrapperStyles} >
        {children}
      </CheckboxWrapper>
    );
  }

  if (R.equals(type, 'reactSelect')) {
    return (
      <InputWrapper {...wrapperStyles}>
        {children}
      </InputWrapper>
    );
  }

  if (G.isNotNilAndNotEmpty(label)) {
    return (
      <InputWrapper {...wrapperStyles} >
        {children}
      </InputWrapper>
    );
  }

  return children;
};

export default FormField;
