import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// common
import { updateReportFromReportListSuccess } from '../../common/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportReducers } from '../../report-common';
// feature toll charges
import * as A from './actions';
//////////////////////////////////////////////////

const initial = {
  truckList: {},
  driverList: {},
  filterProps: [],
  truckGuid: null,
  driverGuid: null,
};

const {
  setReports,
  initialState,
  setUsedReport,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  getItemListSuccess,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  setInitialStateOmitReport,
  handleUpdateReportFromReportListSuccess,
} = getReportReducers(initial);

const setTruckGuid = (state: Object, truckGuid: string) => P.$set('truckGuid', truckGuid, state);

const setDriverGuid = (state: Object, driverGuid: string) => P.$set('driverGuid', driverGuid, state);

const updateTollChargeSuccess = (state: Object, data: Object) => {
  const { guid, truckGuid, driverGuid } = data;

  const { truckList, driverList } = state;

  const truck = R.pathOr(null, [truckGuid], truckList);
  const driver = R.pathOr(null, [driverGuid], driverList);
  const tollCharge = R.mergeRight(data, { truck, driver });

  return P.$set(`itemList.${guid}`, tollCharge, state);
};

const receivedDriverListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  const indexedDriverList = R.indexBy(R.prop(GC.FIELD_GUID), data);

  return P.$set('driverList', indexedDriverList, state);
};

const getTruckListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  const indexedTruckList = R.indexBy(R.prop(GC.FIELD_GUID), data);

  return P.$set('truckList', indexedTruckList, state);
};

const receivedDriverPayrollNumberListByGuidsSuccess = (state: Object, data: Array) => {
  const { itemList } = state;

  const indexedPayrollList = R.indexBy(R.prop(GC.FIELD_GUID), data);

  const mappedList = R.map((item: Object) => {
    const payrollGuid = G.getPropFromObject(GC.FIELD_PAYROLL_GUID, item);

    if (G.isNilOrEmpty(payrollGuid)) return item;

    const payrollNumber = R.path([payrollGuid, GC.FIELD_PAYROLL_NUMBER], indexedPayrollList);

    return R.assoc(GC.FIELD_PAYROLL_NUMBER, payrollNumber, item);
  }, itemList);

  return P.$set('itemList', mappedList, state);
};

const receivedVendorPayrollNumberListByGuidsSuccess = (state: Object, data: Array) => {
  const { itemList } = state;

  const indexedPayrollList = R.indexBy(R.prop(GC.FIELD_GUID), data);

  const mappedList = R.map((item: Object) => {
    const payrollGuid = G.getPropFromObject(GC.FIELD_VENDOR_PAYROLL_GUID, item);

    if (G.isNilOrEmpty(payrollGuid)) return item;

    const payrollNumber = R.path([payrollGuid, GC.FIELD_PAYROLL_NUMBER], indexedPayrollList);

    return R.assoc(GC.FIELD_VENDOR_PAYROLL_NUMBER, payrollNumber, item);
  }, itemList);

  return P.$set('itemList', mappedList, state);
};

export default createReducer({
  [A.setReports]: setReports,
  [A.setTruckGuid]: setTruckGuid,
  [A.setDriverGuid]: setDriverGuid,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.getTruckListSuccess]: getTruckListSuccess,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.updateTollChargeSuccess]: updateTollChargeSuccess,
  [A.receivedDriverListSuccess]: receivedDriverListSuccess,
  [A.setInitialStateOmitReport]: setInitialStateOmitReport,
  [updateReportFromReportListSuccess]: handleUpdateReportFromReportListSuccess,
  [A.receivedDriverPayrollNumberListByGuidsSuccess]: receivedDriverPayrollNumberListByGuidsSuccess,
  [A.receivedVendorPayrollNumberListByGuidsSuccess]: receivedVendorPayrollNumberListByGuidsSuccess,
}, initialState);
