import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportSelectors } from '../../report-common';
//////////////////////////////////////////////////

const {
  store,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} = getReportSelectors('taskManagement');

const makeSelectItemList = () => createSelector(
  store,
  ({ itemList }: Object) => R.sortBy(R.prop('index'), R.values(itemList)),
);

const makeSelectTaskTypesTaskStatuses = () => createSelector(
  store,
  R.pick(['taskTypes', 'taskStatuses']),
);

const makeSelectGroupedByTaskTypesTaskStatusMap = () => createSelector(
  store,
  R.propOr({}, 'groupedByTaskTypesTaskStatusMap'),
);

const makeSelectEntities = () => createSelector(
  store,
  R.propOr({}, 'entities'),
);

// task board
const makeSelectTaskBoard = () => createSelector(
  store,
  R.compose(
    R.filter(R.prop('tasks')),
    R.propOr([], 'taskBoard'),
  ),
);

const makeSelectTaskBoardAssignees = () => createSelector(
  store,
  R.compose(
    R.map(R.path([GC.FIELD_ASSIGNEE, GC.FIELD_GUID])),
    R.filter(R.prop('tasks')),
    R.propOr([], 'taskBoard'),
  ),
);

const makeSelectTaskBoardListLoading = () => createSelector(
  store,
  R.prop('taskBoardListLoading'),
);

const makeSelectTaskBoardPagination = () => createSelector(
  store,
  R.prop('taskBoardPagination'),
);

const makeSelectTaskBoardTotalCount = () => createSelector(
  store,
  R.prop('taskBoardTotalCount'),
);

const makeSelectTaskBoardGlobalFilterValue = () => createSelector(
  store,
  R.propOr('', 'taskBoardGlobalFilterValue'),
);

const makeSelectTaskBoardAdditionalFilterValue = () => createSelector(
  store,
  R.propOr([], 'taskBoardAdditionalFilterValue'),
);

const makeSelectAvailableTaskBoards = () => createSelector(
  store,
  R.propOr([], 'availableTaskBoards'),
);

const makeSelectSelectedTaskBoard = () => createSelector(
  store,
  R.propOr({}, 'selectedTaskBoard'),
);

const makeSelectTaskBoardSummary = () => createSelector(
  store,
  R.propOr({}, 'taskBoardSummary'),
);

const makeSelectAssigneeGuid = () => createSelector(
  store,
  R.prop(GC.FIELD_ASSIGNEE_GUID),
);

const makeSelectObjectGuid = () => createSelector(
  store,
  R.prop(GC.FIELD_OBJECT_GUID),
);

const makeSelectTaskBoardVisited = () => createSelector(
  store,
  (state: Object) => G.isNotNilAndNotEmpty(R.path(['selectedTaskBoard', 'columns'], state)),
);

export {
  makeSelectItemList,
  makeSelectEntities,
  makeSelectTaskBoard,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectObjectGuid,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectAssigneeGuid,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectTaskBoardSummary,
  makeSelectAvailableReports,
  makeSelectTaskBoardVisited,
  makeSelectTableTitleFilters,
  makeSelectSelectedTaskBoard,
  makeSelectTaskBoardAssignees,
  makeSelectAvailableTaskBoards,
  makeSelectTaskBoardTotalCount,
  makeSelectTaskBoardPagination,
  makeSelectTaskBoardListLoading,
  makeSelectTaskTypesTaskStatuses,
  makeSelectTaskBoardGlobalFilterValue,
  makeSelectTaskBoardAdditionalFilterValue,
  makeSelectGroupedByTaskTypesTaskStatusMap,
};
