import * as R from 'ramda';
import Dropzone from 'react-dropzone';
import parse from 'html-react-parser';
import { css } from 'styled-components';
import { Editor } from 'react-draft-wysiwyg';
import React, { useRef, useState, Fragment, useEffect, useCallback, useLayoutEffect } from 'react';
// components
import { TabsMui } from '../../../components/tabs-mui';
import { TextComponent } from '../../../components/text';
import { FormFooter2 } from '../../../components/form-footer';
import { toolbarSettings } from '../../../components/activities/components';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
import { Avatar } from '../../profile/components/profile-photo';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// utilities
import endpointsMap from '../../../utilities/endpoints';
import { sendRequest, sendRequestWithQSParamsSerializer } from '../../../utilities/http';
// ui
import { Box, Span, Flex, EditorWrapper, scrollableContainerCss4px } from '../../../ui';
//////////////////////////////////////////////////

const blackColor = G.getTheme('colors.black');
const darkGreyColor = G.getTheme('colors.#7D828C');
const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

const avatarAdditionalStyles = { m: 0, width: 30, height: 30, fontSize: 11 };

const EditorComponent = ({ value, setValue, setIsEditing, additionalStyles }: Object) => {
  const [editorState, setEditorState] = useState(() => {
    if (G.isString(value)) return G.createEditorState(value);

    return value;
  });

  const ref = useRef();

  const handleSaveChanges = useCallback(() => {
    const value = G.convertHtmlToString(editorState);

    if (R.isEmpty(R.trim(R.replace(/(<([^>]+)>)/ig, '', value)))) return;

    setIsEditing(false);

    setValue(value);
  }, [editorState]);

  useLayoutEffect(() => {
    ref.current.focus();

    setEditorState(G.moveFocusToEnd(editorState));
  }, []);

  return (
    <Fragment>
      <EditorWrapper
        bg='white'
        width='100%'
        css={additionalStyles}
      >
        <Editor
          stripPastedStyles={true}
          toolbar={toolbarSettings}
          editorState={editorState}
          onEditorStateChange={setEditorState}
          editorRef={(editorRef: Object) => ref.current = editorRef}
        />
      </EditorWrapper>
      <FormFooter2
        submitAction={handleSaveChanges}
        boxStyles={{ width: 220, mt: 15 }}
        cancelAction={() => setIsEditing(false)}
        cancelBtnStyles={{ width: 100, height: 25, fontSize: 13 }}
        submitBtnStyles={{ width: 100, height: 25, fontSize: 13 }}
        submitBtnText={G.getWindowLocale(...G.ifElse(
          G.isNotNilAndNotEmpty(value),
          ['actions:edit', 'Edit'],
          ['actions:add', 'Add'],
        ))}
      />
    </Fragment>
  );
};

const Comment = ({ comment, handleComments }: Object) => {
  const [editMode, setEditMode] = useState(false);

  const { text, guid, author, createdDate, lastModifiedDate } = comment;

  const { guid: userGuid, profilePhotoUrl } = author;

  const { fullText, avatarText } = G.getUserInfo(author);

  return (
    <Fragment>
      <Flex width='100%' justifyContent='space-between'>
        <Flex alignItems='flex-start'>
          <Avatar
            isAvatar={true}
            userGuid={userGuid}
            fullText={fullText}
            url={profilePhotoUrl}
            avatarText={avatarText}
            avatarAdditionalStyles={{
              ...avatarAdditionalStyles,
              border: G.ifElse(R.isNil(profilePhotoUrl), '1px solid', 'none'),
            }}
          />
          <Box ml={10} fontSize={12} color='greyMatterhorn'>
            {fullText}
            <Box mt='5px' color='#7D828C'>
              {G.checkAndConvertMomentInstanceOrStringToFormattedDate(
                G.ifElse(G.isNotNil(lastModifiedDate), lastModifiedDate, createdDate),
                GC.DEFAULT_DATE_TIME_FORMAT,
              )}
              {
                G.notEquals(createdDate, lastModifiedDate) &&
                <Span ml={10}>
                  {G.getWindowLocale('titles:edited', 'Edited')}
                </Span>
              }
            </Box>
          </Box>
        </Flex>
        <Flex>
          <Box cursor='pointer' onClick={() => setEditMode(true)}>
            {I.pencil(darkGreyColor)}
          </Box>
          <Box ml={10} cursor='pointer' onClick={() => handleComments('delete', {}, guid)}>
            {I.trash(darkGreyColor)}
          </Box>
        </Flex>
      </Flex>
      <Box my={15} color='greyMatterhorn'>
        {
          editMode ? (
            <EditorComponent
              value={text}
              setIsEditing={setEditMode}
              setValue={(text: string) => handleComments(
                'put',
                { data: R.assoc(GC.FIELD_TEXT, text, comment) },
                guid,
              )}
              additionalStyles={css`
                & .DraftEditor-root {
                  overflow-y: auto;
                  max-height: 350px;

                  ${scrollableContainerCss4px}
                }
              `}
            />
          ) : (
            <Box wordBreak='break-word'>
              {parse(text)}
            </Box>
          )
        }
      </Box>
    </Fragment>
  );
};

const AddComment = ({ editMode, setEditMode, handleComments }: Object) => (
  <Box my={15} px={15}>
    {
      editMode ? (
        <EditorComponent
          value=''
          editMode={true}
          setIsEditing={setEditMode}
          setValue={(comment: string) => handleComments('post', { data: comment, plainText: true })}
          additionalStyles={css`
            & .DraftEditor-root {
              height: 100px;
              overflow-y: auto;

              ${scrollableContainerCss4px}
            }
          `}
        />
      ) : (
        <Flex
          pl={15}
          bg='white'
          height={36}
          width='100%'
          cursor='text'
          color='#999999'
          borderRadius='4px'
          border='1px solid'
          borderColor='#E4E4E4'
          onClick={() => setEditMode(true)}
        >
          {G.getWindowLocale('titles:add-comment', 'Add Comment')}
        </Flex>
      )
    }
  </Box>
);

const Comments = (props: Object) => {
  const { taskGuid, comments, setActivities } = props;

  const [addCommentMode, setAddCommentMode] = useState(false);

  const handleComments = useCallback(async (method: string, options: Object, commentGuid: string) => {
    const operations = {
      get: (prev: Object, data: Object) => data,
      post: (prev: Object, data: Object) => R.prepend(data, prev),
      put: (prev: Object, data: Object) => {
        const index = R.findIndex(R.propEq(G.getGuidFromObject(data), GC.FIELD_GUID), prev);

        return R.assoc(index, data, prev);
      },
      delete: (prev: Object) => {
        const index = R.findIndex(R.propEq(commentGuid, GC.FIELD_GUID), prev);

        return R.remove(index, 1, prev);
      },
    };

    const endpoints = {
      put: endpointsMap.taskComment,
      post: endpointsMap.taskComment,
      get: endpointsMap.taskCommentList,
      delete: endpointsMap.taskCommentByGuid(commentGuid),
    };

    const res = await sendRequest(method, R.prop(method, endpoints), { ...options, params: { taskGuid } });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      setActivities((prev: Object) => {
        const newComments = R.call(R.prop(method, operations), prev.comments, data);

        return R.assoc('comments', newComments, prev);
      });
    } else {
      G.handleFailResponseSimple(res, 'handleComments fail');
    }
  }, [taskGuid]);

  useEffect(() => {
    if (R.isNil(comments)) handleComments('get');
  }, [comments, handleComments]);

  if (R.isNil(comments)) return null;

  return (
    <Fragment>
      <Box
        mr={10}
        overflowY='auto'
        p='15px 5px 0 15px'
        css={scrollableContainerCss4px}
        height={`calc(100% - ${G.ifElse(addCommentMode, '310px', '130px')})`}
      >
        {
          comments.map((item: string, index: number) => (
            <Comment
              key={index}
              comment={item}
              handleComments={handleComments}
            />
          ))
        }
      </Box>
      <AddComment
        editMode={addCommentMode}
        handleComments={handleComments}
        setEditMode={setAddCommentMode}
      />
    </Fragment>
  );
};

const Attachment = (props: Object) => {
  const { attachment, handleAttachments, handleDownloadOrPreviewAttachment } = props;

  const { author, guid, createdDate, originalFileName } = attachment;

  const { fullText } = G.getUserInfo(author);

  return (
    <Box
      p='7px'
      mb={15}
      fontSize={12}
      borderRadius='4px'
      border='1px solid'
      borderColor='#E4E4E4'
      color='greyMatterhorn'
      justifyContent='space-between'
    >
      <Flex justifyContent='space-between'>
        <TextComponent
          withEllipsis={true}
          maxWidth='calc(100% - 80px)'
        >
          {originalFileName}
        </TextComponent>
        <Flex width={70} justifyContent='space-between'>
          <Box cursor='pointer' onClick={() => handleDownloadOrPreviewAttachment({ guid })}>
            {I.eye(darkGreyColor)}
          </Box>
          <Box cursor='pointer' onClick={() => handleDownloadOrPreviewAttachment({ guid, actionType: 'download' })}>
            {I.downloadDocument(darkGreyColor)}
          </Box>
          <AuthWrapper PC={[PC.TASK_WRITE]}>
            <Box cursor='pointer' onClick={() => handleAttachments('delete', {}, guid)}>
              {I.trash(darkGreyColor)}
            </Box>
          </AuthWrapper>
        </Flex>
      </Flex>
      <TextComponent mt='5px' color='#7D828C'>
        {fullText}, {G.checkAndConvertMomentInstanceOrStringToFormattedDate(createdDate, GC.DEFAULT_DATE_TIME_FORMAT)}
      </TextComponent>
    </Box>
  );
};

const AddAttachment = ({ handleAttachments }: Object) => (
  <Dropzone
    className='drop-zone'
    onDrop={(files: Array) => handleAttachments(
      'post',
      {
        data: G.makeDataForDocument({ [GC.FIELD_DOCUMENT_UPLOAD]: R.head(files) }),
      },
    )}
  >
    {({ getRootProps, getInputProps }: Object) => (
      <Flex
        {...getRootProps()}
        m={15}
        px={15}
        bg='white'
        height={60}
        color='#2B5293'
        cursor='pointer'
        borderRadius='4px'
        textAlign='center'
        border='1px dashed'
        wordBreak='break-word'
        justifyContent='center'
        width='calc(100% - 30px)'
        borderColor='rgba(43, 82, 147, 0.5)'
      >
        <input {...getInputProps()} />
        Drag and Drop or select file from your Computer
      </Flex>
    )}
  </Dropzone>
);

const Attachments = (props: Object) => {
  const {
    taskGuid,
    openLoader,
    closeLoader,
    attachments,
    setActivities,
  } = props;

  const handleAttachments = useCallback(async (method: string, options: Object, attachmentGuid: string) => {
    openLoader();

    const operations = {
      get: (prev: Object, data: Object) => data,
      post: (prev: Object, data: Object) => R.prepend(data, prev),
      delete: (prev: Object) => {
        const index = R.findIndex(R.propEq(attachmentGuid, GC.FIELD_GUID), prev);

        return R.remove(index, 1, prev);
      },
    };

    const endpoints = {
      post: endpointsMap.taskAttachment,
      get: endpointsMap.taskAttachmentList,
      delete: endpointsMap.taskAttachmentByGuid(attachmentGuid),
    };

    const res = await sendRequest(method, R.prop(method, endpoints), { ...options, params: { taskGuid } });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      setActivities((prev: Object) => {
        const attachments = R.call(R.prop(method, operations), prev.attachments, data);

        return R.assoc('attachments', attachments, prev);
      });
    } else {
      G.handleFailResponseSimple(res, 'handleAttachments fail');
    }

    closeLoader();
  }, [taskGuid]);

  useEffect(() => {
    if (R.isNil(attachments)) handleAttachments('get');
  }, [attachments, handleAttachments]);

  const handleDownloadOrPreviewAttachment = useCallback(async ({ guid, actionType }: Object) => {
    openLoader();

    const options = {
      params: { taskGuid },
      resType: 'arraybuffer',
    };

    const endpoint = endpointsMap.taskAttachmentByGuid(guid);

    const res = await sendRequestWithQSParamsSerializer('get', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      if (R.equals(actionType, 'download')) {
        G.saveFileFromResponse(res);
      } else {
        G.openFileInWindowFromArrayBufferResponse(res);
      }
    } else {
      G.handleFailResponseSimple(res);
    }

    closeLoader();
  }, [taskGuid]);

  return (
    <Fragment>
      <Box
        mr={10}
        overflowY='auto'
        p='15px 5px 0 15px'
        height='calc(100% - 154px)'
        css={scrollableContainerCss4px}
      >
        {
          R.or(attachments, []).map((item: string, index: number) => (
            <Attachment
              key={index}
              attachment={item}
              handleAttachments={handleAttachments}
              handleDownloadOrPreviewAttachment={handleDownloadOrPreviewAttachment}
            />
          ))
        }
      </Box>
      <AuthWrapper has={[PC.TASK_WRITE]}>
        <AddAttachment handleAttachments={handleAttachments} />
      </AuthWrapper>
    </Fragment>
  );
};

const AuditItem = ({
  date,
  title,
  userGuid,
  fullText,
  oldValue,
  newValue,
  avatarText,
  hasChanges,
  profilePhotoUrl,
}: Object) => (
  <Box mb={15}>
    <Flex>
      <Avatar
        isAvatar={true}
        userGuid={userGuid}
        fullText={fullText}
        url={profilePhotoUrl}
        avatarText={avatarText}
        avatarAdditionalStyles={{
          ...avatarAdditionalStyles,
          border: G.ifElse(R.isNil(profilePhotoUrl), '1px solid', 'none'),
        }}
      />
      <Box ml={10} fontSize={12} color='greyMatterhorn' wordBreak='break-word'>
        {title}
        <Box mt='5px' color='#7D828C'>
          {date}
        </Box>
      </Box>
    </Flex>
    {
      hasChanges &&
      <Flex mt={15}>
        <Box maxWidth='50%' wordBreak='break-all'>
          {oldValue}
        </Box>
        <Box m={10} transform='rotate(90deg)'>
          {I.arrowUp(greyMatterhornColor)}
        </Box>
        <Box maxWidth='50%' wordBreak='break-all'>
          {newValue}
        </Box>
      </Flex>
    }
  </Box>
);

const auditStatusMap = {
  CREATE: G.getWindowLocale('titles:created', 'Created'),
  UPDATE: G.getWindowLocale('titles:updated', 'Updated'),
};

const Audit = (props: Object) => {
  const {
    audit,
    users,
    taskGuid,
    openLoader,
    closeLoader,
    setActivities,
  } = props;

  useEffect(() => {
    if (R.isNotNil(audit)) return;

    const handleGetAudit = async () => {
      openLoader();

      const options = {
        data: {
          pageable: false,
          [GC.FIELD_OBJECT_GUID]: taskGuid,
          [GC.FIELD_TYPE]: GC.AUDIT_TYPE_TASK,
          [GC.CURRENT_BRANCH]: G.getAmousCurrentBranchGuidFromWindow(),
        },
      };

      const res = await sendRequest('post', endpointsMap.auditList, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const makeChangedFieldsItem = (value: any, fieldName: string) => {
          if (G.isNilOrEmpty(value)) return G.getWindowLocale('titles:none', 'None');

          if (R.equals(fieldName, 'Description')) {
            return R.replace(/(<([^>]+)>)/ig, '', value);
          }

          if (R.includes(fieldName, ['Start Date', 'Due Date'])) {
            return G.checkAndConvertMomentInstanceOrStringToFormattedDate(value, GC.DEFAULT_DATE_FORMAT);
          }

          return value;
        };

        const audit = R.map((item: Object) => {
          const { date, status, modifiedBy } = item;

          const indexedUsers = R.indexBy(R.prop(GC.FIELD_USER_LOGIN_ID), users);

          const author = R.propOr({ [GC.FIELD_USER_LOGIN_ID]: modifiedBy }, modifiedBy, indexedUsers);

          const changedFields = R.compose(
            R.map(({ oldValue, newValue, fieldName }: Object) => ({
              fieldName,
              oldValue: makeChangedFieldsItem(oldValue, fieldName),
              newValue: makeChangedFieldsItem(newValue, fieldName),
            })),
            R.reject(({ oldValue, newValue, fieldName }: Object) => {
              if (R.includes(fieldName, ['Last Modified Date'])) return true;

              if (R.equals(oldValue, newValue)) return true;

              return false;
            }),
            R.propOr([], 'changedFields'),
          )(item);

          return {
            author,
            status,
            changedFields,
            date: G.checkAndConvertMomentInstanceOrStringToFormattedDate(date, GC.DEFAULT_DATE_TIME_FORMAT),
          };
        }, data.results);

        setActivities(R.assoc('audit', audit));
      } else {
        G.handleFailResponseSimple('handleGetAudit fail', true, res);
      }

      closeLoader();
    };

    handleGetAudit();
  }, []);

  if (G.isNilOrEmpty(audit)) return null;

  return (
    <Box
      mr={10}
      overflowY='auto'
      p='15px 5px 0 15px'
      height='calc(100% - 50px)'
      css={scrollableContainerCss4px}
    >
      {
        audit.map((item: Object) => {
          const { date, author, status, changedFields } = item;

          const { guid: userGuid, profilePhotoUrl } = author;

          const { fullText, avatarText } = G.getUserInfo(author);

          const defaultProps = {
            date,
            userGuid,
            fullText,
            avatarText,
            profilePhotoUrl,
          };

          if (R.equals(status, 'CREATE')) {
            return (
              <AuditItem
                {...defaultProps}
                key={date}
                title={`${fullText} ${G.getWindowLocale('titles:created-task', 'Created Task')}`}
              />
            );
          }

          return changedFields.map(({ oldValue, newValue, fieldName }: Object, index: number) => (
            <AuditItem
              {...defaultProps}
              key={index}
              hasChanges={true}
              newValue={newValue}
              oldValue={oldValue}
              fieldName={fieldName}
              title={`${fullText} ${R.propOr(status, status, auditStatusMap)} ${fieldName}`}
            />
          ));
        })
      }
    </Box>
  );
};

const tabs = [
  { text: G.getWindowLocale('titles:comments', 'Comments') },
  { text: G.getWindowLocale('titles:history', 'History') },
  { text: G.getWindowLocale('titles:attachments', 'Attachments') },
];

const Activities = (props: Object) => {
  const {
    users,
    taskGuid,
    openLoader,
    closeLoader,
    currentUserSettings,
    activitiesMaxHeight,
  } = props;

  const [activeTab, setActiveTab] = useState(0);
  const [activities, setActivities] = useState({});

  const tabsProps = {
    textColor: 'inherit',
    scrollButtons: 'auto',
    indicatorColor: blackColor,
    style: { minHeight: '26px', borderBottom: `1px solid ${G.getTheme('colors.#E4E4E4')}` },
    TabIndicatorProps: {
      sx: {
        height: 3,
        backgroundColor: blackColor,
      },
    },
  };

  const map = [
    <Comments
      key={0}
      taskGuid={taskGuid}
      setActivities={setActivities}
      comments={R.prop('comments', activities)}
      currentUserSettings={currentUserSettings}
    />,
    <Audit
      key={1}
      users={users}
      taskGuid={taskGuid}
      openLoader={openLoader}
      closeLoader={closeLoader}
      setActivities={setActivities}
      audit={R.prop('audit', activities)}
    />,
    <Attachments
      key={2}
      taskGuid={taskGuid}
      openLoader={openLoader}
      closeLoader={closeLoader}
      setActivities={setActivities}
      attachments={R.prop('attachments', activities)}
    />,
  ];

  return (
    <Box
      width={550}
      bg='#F6F6F6'
      borderLeft='1px solid'
      // NOTE: important
      borderColor='#E4E4E4'
      maxHeight={activitiesMaxHeight}
    >
      <TabsMui
        tabs={tabs}
        tabsProps={tabsProps}
        activeMuiTab={activeTab}
        setActiveMuiTab={setActiveTab}
      />
      {R.prop(activeTab, map)}
    </Box>
  );
};

export default Activities;
