import * as R from 'ramda';
import React from 'react';
// components
import { Table } from '../../../../components/table';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { RelativeBox } from '../../../../ui';
//////////////////////////////////////////////////

const tableSettings = {
  useMainColors: true,
  checkBoxCellWidth: 0,
  expandableItems: false,
  allowSelectItems: false,
  maxHeight: 'fit-content',
};

const settings = {
  [GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES_RATE]: {
    width: 110,
    name: 'titles:rate',
  },
  [GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES_VALUE_FROM]: {
    width: 110,
    name: 'titles:index-from',
  },
  [GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES_VALUE_TO]: {
    width: 110,
    name: 'titles:index-to',
  },
};

const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(settings)),
};

const FuelIndexVariableList = (props: Object) => (
  <RelativeBox ml={25} zIndex='0'>
    <Table
      report={report}
      columnSettings={settings}
      tableSettings={tableSettings}
      itemList={R.pathOr([], ['parentProps', GC.FIELD_ACCESSORIAL_FUEL_INDEX_VARIABLES], props)}
    />
  </RelativeBox>
);

export default FuelIndexVariableList;
