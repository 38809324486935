import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// common
import { updateReportFromReportListSuccess } from '../../common/actions';
// report-common
import { getReportReducers } from '../../report-common';
// feature work-order
import * as A from './actions';
//////////////////////////////////////////////////

const fleetProfileInitSettings = {
  fleetProfileType: null,
  fleetProfileGuid: null,
  fleetProfileGuidType: null,
};

const initial = {
  fleetProfileSettings: fleetProfileInitSettings,
};

const {
  setReports,
  initialState,
  setUsedReport,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemSuccess,
  getItemListSuccess,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  setInitialStateOmitReport,
  handleUpdateReportFromReportListSuccess,
} = getReportReducers(initial);

const setFleetProfileSettings = (state: Object, settings: Object = fleetProfileInitSettings) => (
  P.$set('fleetProfileSettings', settings, state)
);

export default createReducer({
  // report common
  [A.setReports]: setReports,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.deleteItemSuccess]: deleteItemSuccess,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.setInitialStateOmitReport]: setInitialStateOmitReport,
  [updateReportFromReportListSuccess]: handleUpdateReportFromReportListSuccess,
  // report common
  [A.setFleetProfileSettings]: setFleetProfileSettings,
}, initialState);
