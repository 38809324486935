import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ActionsElement } from '../../../components/actions-element';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { SelectDropdownForm } from '../../../forms/forms/select-dropdown-form';
// icons
import * as I from '../../../svgs';
// feature toll charges
import { makeSelectTruckGuid, makeSelectDriverGuid } from '../selectors';
import { updateTruckTollChargeRequest, updateDriverTollChargeRequest } from '../actions';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  truckGuid: makeSelectTruckGuid(state),
  driverGuid: makeSelectDriverGuid(state),
});

const enhance = compose(
  connect(mapStateToProps, { updateDriverTollChargeRequest, updateTruckTollChargeRequest }),
  withHandlers({
    handleUpdateDriver: (props: Object) => () => {
      const {
        guid,
        openModal,
        closeModal,
        driverList,
        closeFixedPopup,
        tollChargeDriverGuid,
        updateDriverTollChargeRequest,
      } = props;

      if (G.isFunction(closeFixedPopup)) closeFixedPopup();

      const driverOptions = R.compose(
        R.map(({ guid, lastName, firstName }: Object) => (
          {
            [GC.FIELD_VALUE]: guid,
            [GC.FIELD_LABEL]: `${firstName} ${lastName}`,
          }
        )),
        R.values,
      )(driverList);

      const initialValues = { [GC.FIELD_DRIVER_GUID]: R.or(tollChargeDriverGuid, null) };

      const updateDriverProps = {
        initialValues,
        fieldWidth: 300,
        optionRequired: true,
        options: driverOptions,
        cancelAction: closeModal,
        fieldLabel: G.getWindowLocale('titles:select-driver', 'Select Driver'),
        submitAction: (driverGuid: string) => updateDriverTollChargeRequest({ guid, driverGuid }),
      };

      const component = <SelectDropdownForm {...updateDriverProps} />;

      const modal = {
        p: 15,
        component,
        options: { title: G.getWindowLocale('titles:update-driver', 'Update Driver') },
      };

      openModal(modal);
    },
    handleUpdateTruck: (props: Object) => () => {
      const {
        guid,
        openModal,
        truckList,
        closeModal,
        closeFixedPopup,
        tollChargeTruckGuid,
        updateTruckTollChargeRequest,
      } = props;

      G.callFunction(closeFixedPopup);

      const truckOptions = R.compose(
        R.map(({ guid, unitId }: Object) => (
          {
            [GC.FIELD_VALUE]: guid,
            [GC.FIELD_LABEL]: unitId,
          }
        )),
        R.values,
      )(truckList);

      const initialValues = { [GC.FIELD_TRUCK_GUID]: R.or(tollChargeTruckGuid, null) };

      const updateTruckProps = {
        initialValues,
        fieldWidth: 300,
        optionRequired: true,
        options: truckOptions,
        cancelAction: closeModal,
        fieldLabel: G.getWindowLocale('titles:select-truck', 'Select Truck'),
        submitAction: (truckGuid: string) => updateTruckTollChargeRequest({guid, truckGuid}),
      };

      const component = <SelectDropdownForm {...updateTruckProps} />;

      const modal = {
        p: 15,
        component,
        options: { title: G.getWindowLocale('titles:update-truck', 'Update Truck') },
      };

      openModal(modal);
    },
  }),
  pure,
);

const getOptions = ({ truckGuid, driverGuid, handleUpdateTruck, handleUpdateDriver }: Object) => {
  const updateDriverRowAction = {
    permissions: null,
    action: handleUpdateDriver,
    frontIcon: I.driver(G.getTheme('icons.iconColor')),
    text: G.getWindowLocale('titles:update-driver', 'Update Driver'),
  };

  const updateTruckRowAction = {
    permissions: null,
    action: handleUpdateTruck,
    frontIcon: I.truck(G.getTheme('icons.iconColor')),
    text: G.getWindowLocale('titles:update-truck', 'Update Truck'),
  };

  if (G.isNotNilAndNotEmpty(truckGuid)) return R.of(Array, updateDriverRowAction);

  if (G.isNotNilAndNotEmpty(driverGuid)) return R.of(Array, updateTruckRowAction);

  return [updateDriverRowAction, updateTruckRowAction];
};

export const RowActions = enhance((props: Object) => <ActionsElement options={getOptions(props)} />);
