import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// components
import { Table } from '../../components/table';
import { TitlePanel } from '../../components/title-panel';
import { EditReport } from '../../components/edit-report';
import { ConfirmComponent } from '../../components/confirm';
import { PageActions } from '../../components/page-actions';
import { withPromptModal } from '../../components/edit-report/hocs';
import { transformPropDataFromSelectToString } from '../../components/edit-report/helpers';
// common
import { openModal, closeModal } from '../../components/modal/actions';
// features
import PC from '../permission/role-permission';
import { AuthWrapper } from '../permission/index';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// forms
import { SelectDropdownForm } from '../../forms/forms/select-dropdown-form';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { accountingIntegrationOptions } from '../../helpers/options';
// hocs
import { withFixedPopover } from '../../hocs';
// icons
import * as I from '../../svgs';
// report-common
import { reportEnhancer } from '../../report-common';
// utilities
import routesMap from '../../utilities/routes';
// ui
import { ListWrapper, IconWrapper, ZOrderWrapper } from '../../ui';
// feature service-vendor
import RowActions from './components/row-actions';
import { FILTER_PARAMS } from './settings/filter-params';
import { tableSettings } from './settings/table-settings';
import { columnSettings } from './settings/column-settings';
import CreateServiceVendorForm from './components/create-service-vendor-form';
import {
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectPageVisited,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectSelectedList,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
import {
  selectItem,
  setReports,
  setUsedReport,
  setInitialState,
  cleanQuickFilter,
  deleteItemRequest,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
  sendListToIntegrationRequest,
} from './actions';
//////////////////////////////////////////////////

const enhance = compose(
  withFixedPopover,
  reportEnhancer,
  withHandlers({
    handleSendListToIntegration: (props: Object) => (guid: string) => {
      const {
        openModal,
        closeModal,
        closeFixedPopup,
        serviceVendorList,
        sendListToIntegrationRequest,
      } = props;

      G.callFunction(closeFixedPopup);
      const guids = G.getGuidsFromStringOrSelectedList(guid, serviceVendorList);

      if (G.isNilOrEmpty(guids)) {
        const message = G.getWindowLocale('messages:select-service-vendor', 'Please, select a Service Vendor!');

        return G.showToastrMessageSimple('info', message);
      }

      const submitAction = (integrationType: string) =>
        sendListToIntegrationRequest({ guids, integrationType });

      const componentProps = {
        submitAction,
        fieldWidth: 300,
        optionRequired: true,
        cancelAction: closeModal,
        options: accountingIntegrationOptions(),
        fieldLabel: G.getWindowLocale('titles:integration-type', 'Integration Type'),
      };

      const modal = {
        p: 15,
        options: { width: 330 },
        component: <SelectDropdownForm {...componentProps} />,
      };

      openModal(modal);
    },
    handleEdit: () => (guid: string) => {
      G.goToRoute(routesMap.editServiceVendor(guid));
    },
    handleRemove: (props: Object) => (guid: string) => {
      const { openModal, selectedList, deleteItemRequest } = props;

      const selectedListToUse = G.ifElse(
        G.isString(guid),
        R.of(Array, guid),
        selectedList,
      );

      if (G.isNilOrEmpty(selectedListToUse)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      const txtLocaleArr = G.ifElse(
        G.isString(guid),
        ['messages:delete-confirmation-text-service-vendor', 'Are you sure you want delete service vendor?'],
        ['messages:confirm-delete-entities', 'Are you sure you want to delete these entities?'],
      );

      const component = <ConfirmComponent textLocale={G.getWindowLocale(...txtLocaleArr)} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              action: () => deleteItemRequest(selectedListToUse),
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ],
        },
      };

      openModal(modal);
    },
    handleCreateServiceVendor: ({ openModal }: Object) => () => {
      const component = <CreateServiceVendorForm />;

      const modal = {
        p: 15,
        component,
        options: {
          width: 430,
          height: 'auto',
          title: G.getWindowLocale('actions:create-vendor', 'Create Vendor'),
        },
      };

      openModal(modal);
    },
  }),
  withPromptModal(FILTER_PARAMS),
  withHandlers({
    handleClickEditIcon: (props: Object) => ({ currentTarget }: Object, item: Object) => {
      const {
        openModal,
        closeModal,
        handleEdit,
        handleRemove,
        openFixedPopup,
        closeFixedPopup,
        handleSendListToIntegration,
      } = props;

      return (
        openFixedPopup({
          position: 'right',
          el: currentTarget,
          content: (
            <RowActions
              openModal={openModal}
              closeModal={closeModal}
              guid={G.getGuidFromObject(item)}
              handleSendListToIntegration={handleSendListToIntegration}
              handleEdit={() => {
                closeFixedPopup();
                handleEdit(G.getGuidFromObject(item));
              }}
              handleRemove={() => {
                closeFixedPopup();
                handleRemove(G.getGuidFromObject(item));
              }}
            />
          ),
        })
      );
    },
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const modalContent = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          onReportSet={() => getItemListRequest()}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
        />
      );

      const modal = {
        component: modalContent,
        options: {
          version: 2,
          height: 'auto',
          maxWidth: '98vw',
          width: 'fit-content',
        },
      };

      openModal(modal);
    },
  }),
  branch(
    ({ initialDataLoaded, selectedReport }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    totalCount,
    selectItem,
    pagination,
    reportList,
    handleEdit,
    filterParams,
    handleRemove,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    serviceVendorList,
    getItemListRequest,
    handleClickEditIcon,
    handleTableTitleFilter,
  } = props;

  if (R.not(selectedReport)) return null;

  const renderRightStickedComponent = (item: Object) => (
    <AuthWrapper has={[PC.SERVICE_VENDOR_WRITE, PC.ROLE_LIUBANCHYK_SUPER_ADMIN]}>
      {
        R.or(
          G.getIsAmousUserSuperAdminFromWindow(),
          R.equals(G.getBranchGuidFromObject(item), G.getAmousCurrentBranchGuidFromWindow()),
        ) && (
          <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, item)}>
            {I.threeDots()}
          </IconWrapper>
        )
      }
    </AuthWrapper>
  );

  const itemList = R.values(serviceVendorList);

  const allChecked = G.isAllChecked(itemList);

  const actionButtons = [
    {
      iconName: 'pencil',
      action: handleEdit,
    },
    {
      iconName: 'trash',
      action: handleRemove,
      permissions: [PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
    },
  ];

  const data = {
    loading,
    itemList,
    allChecked,
    totalCount,
    pagination,
    actionButtons,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    report: selectedReport,
    handleTableTitleFilter,
    onOptionClick: selectItem,
    useSearchableColumns: true,
    withResizableColumns: true,
    renderRightStickedComponent,
    useNewTitleFilterInputs: true,
    filterProps: R.indexBy(
      R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(FILTER_PARAMS),
    ),
    handleLoadMoreEntities: G.ifElse(
      loading,
      () => {},
      getItemListRequest,
    ),
    tableSettings: G.getTableSettingsWithMaxHeightByConditions({
      reportList,
      filterParams,
      tableSettings,
      selectedReport,
    }),
  };

  return <Table {...data} />;
};

const ServiceVendorList = (props: Object) => {
  const {
    handleRemove,
    selectedList,
    exportReportDataRequest,
    handleCreateServiceVendor,
    handleSendListToIntegration,
  } = props;

  const whiteColor = G.getTheme('colors.white');

  const listActions = [
    {
      type: 'massAction',
      action: handleRemove,
      icon: I.trash(whiteColor, 20, 20),
      permissions: [PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
      text: G.getWindowLocale('actions:delete', 'Delete'),
    },
    {
      type: 'massAction',
      icon: I.gear(whiteColor, 25, 25),
      action: handleSendListToIntegration,
      permissions: [PC.SERVICE_VENDOR_WRITE],
      text: G.getWindowLocale('actions:send-to-integration', 'Send to Integration'),
    },
  ];

  return (
    <ListWrapper p={15}>
      <ZOrderWrapper zIndex='2'>
        <TitlePanel
          {...props}
          withCount={true}
          noExportable={true}
          popperWithCount={true}
          filterProps={FILTER_PARAMS}
          hiddenRightFilterInfo={false}
          type={GC.SERVICE_VENDOR_REPORT}
          handleExportReportFile={exportReportDataRequest}
          title={G.getWindowLocale('titles:service-vendors', 'Service Vendors')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex='1'>
        {renderTable(props)}
      </ZOrderWrapper>
      <AuthWrapper has={[PC.SERVICE_VENDOR_WRITE, PC.ROLE_LIUBANCHYK_SUPER_ADMIN]}>
        <PageActions
          listActions={listActions}
          count={R.length(selectedList)}
          shadowColor={G.getTheme('colors.light.grey')}
          mainAction={{
            action: handleCreateServiceVendor,
            text: G.getWindowLocale('actions:add-service-vendor', 'Add Service Vendor'),
          }}
        />
      </AuthWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  pageVisited: makeSelectPageVisited(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  selectedList: makeSelectSelectedList(state),
  serviceVendorList: makeSelectItemList(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  selectItem,
  setReports,
  closeModal,
  setUsedReport,
  setInitialState,
  cleanQuickFilter,
  deleteItemRequest,
  setTableTitleSort,
  getItemListRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
  sendListToIntegrationRequest,
})(enhance(ServiceVendorList));
