import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import {
  ITEM_TEMPERATURE_OPTIONS,
  DEFAULT_WEIGHT_UOM_OPTIONS,
  DEFAULT_DISTANCE_UOM_OPTIONS,
  DEFAULT_CONTRACT_RATE_UNIT_OPTIONS,
  DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  DEFAULT_DRIVER_COMPENSATION_RATE_TYPE_OPTIONS,
} from '../../../../helpers/options';
//////////////////////////////////////////////////

const getDisplayedValue = (options: Object, value: string) => R.compose(
  R.path([GC.FIELD_LABEL]),
  R.find(R.propEq(value, GC.FIELD_VALUE)),
)(options);

export const compensationSettings = {
  [GC.FIELD_DRIVER_COMPENSATION_NAME]: {
    width: 200,
    name: 'titles:name',
  },
  [GC.FIELD_TEMPLATE_TYPE]: {
    width: 200,
    fieldType: 'enumLocale',
    name: 'titles:template-type',
  },
  [GC.FIELD_DRIVER_COMPENSATION_EFFECTIVE_DATE]: {
    width: 200,
    filterType: 'date',
    name: 'titles:effective-date',
  },
  [GC.FIELD_DRIVER_COMPENSATION_EXPIRATION_DATE]: {
    width: 200,
    filterType: 'date',
    name: 'titles:expiration-date',
  },
  ...G.getAddressPointFieldsByType(GC.FIELD_ORIGIN),
  ...G.getAddressPointFieldsByType(GC.FIELD_DESTINATION),
  [GC.FIELD_CHARGE_RATE]: {
    width: 200,
    name: 'titles:rate',
    filterType: 'number',
  },
  [GC.FIELD_CURRENCY]: {
    width: 200,
    name: 'titles:currency',
    filterType: 'selectMultiple',
    options: GC.CURRENCY_OPTIONS,
  },
  [GC.FIELD_CHARGE_RATE_TYPE]: {
    width: 200,
    name: 'titles:rate-type',
    filterType: 'selectMultiple',
    options: DEFAULT_DRIVER_COMPENSATION_RATE_TYPE_OPTIONS,
    customComponent: (row: Object) => getDisplayedValue(
      DEFAULT_DRIVER_COMPENSATION_RATE_TYPE_OPTIONS,
      R.path(['data', GC.FIELD_CHARGE_RATE_TYPE], row),
    ),
  },
  [GC.FIELD_CHARGE_RATE_UNIT]: {
    width: 200,
    name: 'titles:rate-unit',
    filterType: 'selectMultiple',
    options: R.filter(R.prop(GC.FIELD_VALUE), DEFAULT_CONTRACT_RATE_UNIT_OPTIONS),
    customComponent: (row: Object) => getDisplayedValue(
      DEFAULT_CONTRACT_RATE_UNIT_OPTIONS,
      R.path(['data', GC.FIELD_CHARGE_RATE_UNIT], row),
    ),
  },
  [GC.FIELD_CHARGE_MIN_RATE]: {
    width: 200,
    filterType: 'number',
    name: 'titles:rate-minimum',
  },
  [GC.FIELD_CHARGE_MAX_RATE]: {
    width: 200,
    filterType: 'number',
    name: 'titles:rate-maximum',
  },
  [GC.GRC.MODE_DISPLAYED_VALUE]: {
    width: 200,
    sortable: false,
    name: 'titles:mode',
  },
  [GC.FIELD_TRANSIT_DAYS]: {
    width: 200,
    filterType: 'number',
    name: 'titles:transit-days',
  },
  [GC.GRC.SERVICE_TYPE_DISPLAYED_VALUE]: {
    width: 200,
    sortable: false,
    name: 'titles:service-type',
  },
  [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_FROM]: {
    width: 200,
    filterType: 'number',
    name: 'titles:distance-range-from',
  },
  [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_RANGE_TO]: {
    width: 200,
    filterType: 'number',
    name: 'titles:distance-range-to',
  },
  [GC.FIELD_DRIVER_COMPENSATION_DISTANCE_OUM]: {
    width: 200,
    filterType: 'selectMultiple',
    name: 'titles:distance-range-uom',
    options: DEFAULT_DISTANCE_UOM_OPTIONS,
    customComponent: (row: Object) => getDisplayedValue(
      DEFAULT_DISTANCE_UOM_OPTIONS,
      R.path(['data', GC.FIELD_DRIVER_COMPENSATION_DISTANCE_OUM], row),
    ),
  },
  [GC.FIELD_MIN_WEIGHT]: {
    width: 200,
    filterType: 'number',
    name: 'titles:min-weight',
  },
  [GC.FIELD_MAX_WEIGHT]: {
    width: 200,
    filterType: 'number',
    name: 'titles:max-weight',
  },
  [GC.FIELD_WEIGHT_UOM]: {
    width: 200,
    name: 'titles:weight-uom',
    filterType: 'selectMultiple',
    options: DEFAULT_WEIGHT_UOM_OPTIONS,
    customComponent: (row: Object) => getDisplayedValue(
      DEFAULT_WEIGHT_UOM_OPTIONS,
      R.path(['data', GC.FIELD_WEIGHT_UOM], row),
    ),
  },
  [GC.FIELD_MIN_TEMPERATURE]: {
    width: 200,
    filterType: 'number',
    name: 'titles:min-temperature',
  },
  [GC.FIELD_MAX_TEMPERATURE]: {
    width: 200,
    filterType: 'number',
    name: 'titles:max-temperature',
  },
  [GC.FIELD_TEMPERATURE_UOM]: {
    width: 200,
    filterType: 'selectMultiple',
    name: 'titles:temperature-uom',
    options: ITEM_TEMPERATURE_OPTIONS,
  },
  [GC.FIELD_MIN_QUANTITY]: {
    width: 200,
    filterType: 'number',
    name: 'titles:min-quantity',
  },
  [GC.FIELD_MAX_QUANTITY]: {
    width: 200,
    filterType: 'number',
    name: 'titles:max-quantity',
  },
  [GC.FIELD_PACKAGE_TYPE]: {
    width: 200,
    name: 'titles:package-type',
    filterType: 'selectMultiple',
    options: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
    customComponent: (row: Object) => getDisplayedValue(
      DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
      R.path(['data', GC.FIELD_PACKAGE_TYPE], row),
    ),
  },
  [GC.FIELD_GET_FUEL_FROM_ORDER]: {
    width: 200,
    fieldType: 'boolean',
    filterType: 'boolean',
    name: 'titles:get-fuel-from-order',
  },
  [GC.FIELD_ADD_ADDITIONAL_CHARGES_FROM_ORDER]: {
    width: 200,
    fieldType: 'boolean',
    filterType: 'boolean',
    name: 'titles:add-additional-charges-from-order',
  },
  [GC.FIELD_CREATED_BY]: {
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    fieldType: 'date',
    filterType: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    name: 'titles:last-modified-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    fieldType: 'date',
    filterType: 'date',
    name: 'titles:last-modified-date',
  },
};
