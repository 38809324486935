import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
// features
import { AuthWrapper } from '../../features/permission';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

const AfterActionWrapper = styled.div`
  cursor: pointer;
  padding-right: 10px;
  color: ${() => G.getTheme('common.linkColor')};
`;

const renderAfterActionText = (afterActionText: any) => {
  if (R.is(Function, afterActionText)) {
    return afterActionText();
  }

  return afterActionText;
};

const AfterAction = (props: Object) => (
  <AuthWrapper has={props.afterActionHasPerm}>
    <AfterActionWrapper onClick={props.afterAction}>
      {renderAfterActionText(props.afterActionText)}
    </AfterActionWrapper>
  </AuthWrapper>
);

export default AfterAction;
