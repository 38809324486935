import * as R from 'ramda';
import { useFormik } from 'formik';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
// components
import { InfoPair } from '../../../components/info-pair';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Flex, ActionButton } from '../../../ui';
// feature task-management
import { fieldSettings, defaultValues, validationSchema } from './settings';
import {
  makeSubTypeOptions,
  makeOptionsForSelect,
  makeValuesForRequest,
  getEntityListByEntityTypeRequest,
  getEntityListBySubObjectTypeRequest,
} from '../helpers';
//////////////////////////////////////////////////

const OpenProfile = ({ handleClick }: Object) => (
  <ActionButton
    ml={15}
    width={90}
    height={16}
    fontSize={11}
    type='button'
    minWidth='unset'
    borderRadius='3px'
    onClick={handleClick}
  >
    {G.getWindowLocale('actions:open-profile', 'Open Profile')}
  </ActionButton>
);

const useForm = (props: Object) => {
  const {
    entities,
    taskTypes,
    openLoader,
    closeLoader,
    setEntities,
    submitAction,
    taskStatuses,
    initialValues,
    handleOpenDetails,
    withoutExpandedContainer,
    groupedByTaskTypesTaskStatusMap,
    setEntityListByEntityTypeSuccess,
  } = props;

  const [subObjects, setSubObjects] = useState({});

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: G.setInitialFormikValues(defaultValues, initialValues),
    onSubmit: (values: Object) => {
      const requestValues = makeValuesForRequest({ values, entities, taskTypes, taskStatuses, initialValues });

      submitAction(requestValues);
    },
  });

  const { values: { type, objectType, taskObject, subObjectType, assigneeGuid } } = formik;

  const subObjectByType = R.path([objectType, subObjectType, taskObject], subObjects);

  const subObjectOptions = useMemo(() => makeSubTypeOptions({
    objectType,
    taskObject,
    subObjectType,
    entities: subObjectByType,
  }), [subObjectByType]);

  const optionsForSelect = useMemo(
    () => makeOptionsForSelect({
      type,
      entities,
      taskTypes,
      objectType,
      taskStatuses,
      initialValues,
      handleOpenDetails,
      groupedByTaskTypesTaskStatusMap,
    }),
    [
      type,
      entities,
      taskTypes,
      objectType,
      taskStatuses,
      subObjectType,
      handleOpenDetails,
      groupedByTaskTypesTaskStatusMap,
    ],
  );

  const handleGetEntityListByEntityTypeRequest = useCallback((entityType: string) => getEntityListByEntityTypeRequest({
    entities,
    entityType,
    openLoader,
    closeLoader,
    setEntities,
    setEntityListByEntityTypeSuccess,
  }), [entities, setEntities]);

  const handleGetEntityListBySubObjectTypeRequest = useCallback(() => getEntityListBySubObjectTypeRequest({
    subObjects,
    openLoader,
    closeLoader,
    setSubObjects,
    subObjectType,
    entityType: objectType,
    entityGuid: taskObject,
  }), [subObjects, objectType, taskObject, setSubObjects, subObjectType]);

  useEffect(() => {
    handleGetEntityListByEntityTypeRequest(GC.FIELD_ASSIGNEE);
  }, []);

  useEffect(() => {
    if (R.and(R.isNotNil(objectType), G.isNotNilAndNotEmpty(entities))) {
      handleGetEntityListByEntityTypeRequest(objectType);
    }
  }, [entities, objectType]);

  const subObjectPath = G.ifElse(
    G.isAllNotNilOrNotEmpty([objectType, taskObject, subObjectType]),
    `${objectType}${taskObject}${subObjectType}`,
    '',
  );

  useEffect(() => {
    handleGetEntityListBySubObjectTypeRequest();
  }, [subObjectPath]);

  const taskObjectAdditionalLabelComponent = G.isAllTrue(
    R.not(withoutExpandedContainer),
    G.isNotNil(taskObject),
    G.notEquals(objectType, GC.TASK_MANAGEMENT_OBJECT_TYPE_GENERAL),
  ) ? (
    <OpenProfile handleClick={() => handleOpenDetails(taskObject, objectType)} />
  ) : null;

  return {
    formik,
    optionsForSelect: { ...optionsForSelect, subObjectOptions },
    fields: R.assocPath([7, 'additionalLabelComponent'], taskObjectAdditionalLabelComponent, fieldSettings),
  };
};

const TaskForm = (props: Object) => {
  const { disabledSubObjectFields } = props;

  const { formik, fields, optionsForSelect } = useForm(props);

  const completedBy = R.path(['values', GC.FIELD_COMPLETED_BY], formik);
  const completedDate = R.path(['values', GC.FIELD_COMPLETED_DATE], formik);

  return (
    <form id='task-form' onSubmit={formik.handleSubmit}>
      <Fieldset2
        {...formik}
        {...optionsForSelect}
        handlers={{
          handleDisableSubObjectFields: () => disabledSubObjectFields,
        }}
        fields={fields}
        fieldsWrapperStyles={{
          width: 550,
          px: R.propOr(0, 'px', props),
          pt: R.propOr(15, 'pt', props),
          justifyContent: 'space-between',
        }}
      />
      {
        G.isNotNil(completedBy) &&
        <Flex flexWrap='wrap'>
          <InfoPair
            py='0px'
            lineHeight='unset'
            textMaxWidth={300}
            text={completedBy}
            width='max-content'
            margin='0 15px 25px 15px'
            label={G.getWindowLocale('titles:completed-by', 'Completed By')}
          />
          <InfoPair
            py='0px'
            lineHeight='unset'
            width='max-content'
            margin='0 15px 25px 15px'
            label={G.getWindowLocale('titles:completed-date', 'Completed Date')}
            text={R.isNotNil(completedBy) ? G.convertInstanceToDefaultDateFormat(completedDate) : null}
          />
        </Flex>
      }
    </form>
  );
};

export default TaskForm;
