import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withHandlers, renderNothing } from 'react-recompose';
// components
import { Table } from '../../../components/table';
import { TitlePanel } from '../../../components/title-panel';
import { EditReport } from '../../../components/edit-report';
import { getConfirmModal } from '../../../components/confirm';
import { withPromptModal } from '../../../components/edit-report/hocs';
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import { transformPropDataFromSelectToString } from '../../../components/edit-report/helpers';
// features
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { makeSelectCurrentUserSettingsFields } from '../../profile/selectors';
import { setExpandedContainerOptions } from '../../expanded-container/actions';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withFixedPopover } from '../../../hocs';
import { reportEnhancer } from '../../../report-common';
// ui
import { ListWrapper, ZOrderWrapper, MainActionButton } from '../../../ui';
// feature task-management
import Task from '../task';
import TitleTabs from '../components/title-tabs';
import { tableSettings, columnSettings, FILTER_PARAMS } from './settings';
import {
  makeSelectItemList,
  makeSelectEntities,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectObjectGuid,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectAssigneeGuid,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
  makeSelectTaskTypesTaskStatuses,
  makeSelectGroupedByTaskTypesTaskStatusMap,
} from '../selectors';
import {
  setReports,
  setUsedReport,
  cleanQuickFilter,
  deleteItemRequest,
  setTableTitleSort,
  getItemListRequest,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  createOrUpdateTaskRequest,
  changeDefaultReportRequest,
  setEntityListByEntityTypeSuccess,
} from '../actions';
//////////////////////////////////////////////////

const enhance = compose(
  withFixedPopover,
  reportEnhancer,
  withPromptModal(FILTER_PARAMS),
  withHandlers({
    handleCreateOrUpdateTask: (props: Object) => (_: any, { guid, taskNumber }: Object = {}) => {
      const {
        entities,
        openModal,
        closeModal,
        openLoader,
        objectGuid,
        closeLoader,
        currentUserSettings,
        taskTypeTaskStatuses,
        createOrUpdateTaskRequest,
        setExpandedContainerOptions,
        groupedByTaskTypesTaskStatusMap,
        setEntityListByEntityTypeSuccess,
      } = props;

      const initialValues = G.ifElse(
        R.and(R.isNil(guid), R.isNotNil(objectGuid)),
        {
          [GC.FIELD_TASK_OBJECT]: objectGuid,
          [GC.FIELD_OBJECT_TYPE]: GC.TASK_MANAGEMENT_OBJECT_TYPE_DRIVER,
        },
      );

      const component = (
        <Task
          {...taskTypeTaskStatuses}
          taskGuid={guid}
          entities={entities}
          closeModal={closeModal}
          openLoader={openLoader}
          closeLoader={closeLoader}
          initialValues={initialValues}
          currentUserSettings={currentUserSettings}
          withoutExpandedContainer={R.isNotNil(objectGuid)}
          createOrUpdateTaskRequest={createOrUpdateTaskRequest}
          setExpandedContainerOptions={setExpandedContainerOptions}
          groupedByTaskTypesTaskStatusMap={groupedByTaskTypesTaskStatusMap}
          setEntityListByEntityTypeSuccess={setEntityListByEntityTypeSuccess}
        />
      );

      const title = R.isNil(guid) ?
        G.getWindowLocale('titles:create-task', 'Create Task') :
        `${G.getWindowLocale('titles:task-number', 'Task Number')}: ${taskNumber}`;

      const modal = {
        p: '0px',
        component,
        options: {
          title,
          movable: false,
          overflow: 'auto',
          maxHeight: '90vh',
        },
      };

      openModal(modal);
    },
    handleTableTitleFilter: G.handleTableTitleFilter,
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          hasIssues={true}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          updateReportRequest={updateReportRequest}
          createReportRequest={createReportRequest}
          onReportSet={() => getItemListRequest(true)}
        />
      );

      const modal = G.getDefaultReportModal(component);

      openModal(modal);
    },
    handleDelete: ({ openModal, closeModal, deleteItemRequest }: Object) => (guid: string) => {
      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        submitAction: () => deleteItemRequest(guid),
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        text: G.getWindowLocale('messages:confirm-delete-entity', 'Are you sure you want to delete this entity?'),
      });

      openModal(modalContent);
    },
  }),
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    itemList,
    totalCount,
    selectItem,
    pagination,
    reportList,
    handleDelete,
    filterParams,
    selectedReport,
    tableMaxHeight,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    openedFromFleetProfile,
    handleTableTitleFilter,
    handleCreateOrUpdateTask,
  } = props;

  if (R.not(selectedReport)) return null;

  const actionButtons = [
    {
      permissions: null,
      iconName: 'pencil',
      action: handleCreateOrUpdateTask,
    },
    {
      iconName: 'trash',
      action: handleDelete,
      permissions: [PC.TASK_WRITE],
    },
  ];

  const tableSettingsToUse = G.isTrue(openedFromFleetProfile)
  ? R.assoc('maxHeight', tableMaxHeight, tableSettings)
  : G.getTableSettingsWithMaxHeightByConditions({
    reportList,
    filterParams,
    tableSettings,
    selectedReport,
  });

  const data = {
    itemList,
    totalCount,
    pagination,
    actionButtons,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    handleTableTitleFilter,
    report: selectedReport,
    onOptionClick: selectItem,
    useSearchableColumns: true,
    useNewTitleFilterInputs: true,
    tableSettings: tableSettingsToUse,
    handleLoadMoreEntities: getItemListRequest,
    loading: R.and(loading, G.isNilOrEmpty(itemList)),
    filterProps: R.indexBy(
      R.prop(GC.FIELD_VALUE),
      transformPropDataFromSelectToString(FILTER_PARAMS),
    ),
  };

  return <Table {...data} />;
};

const CreateTask = ({ handleCreateTask }: Object) => (
  <AuthWrapper has={[PC.TASK_WRITE]}>
    <MainActionButton
      ml={20}
      height={30}
      width={130}
      onClick={() => handleCreateTask()}
    >
      {G.getWindowLocale('actions:create-task', 'Create Task')}
    </MainActionButton>
  </AuthWrapper>
);

const TaskManagementReport = (props: Object) => {
  const {
    pl,
    title,
    height,
    minHeight,
    maxHeight,
    totalCount,
    assigneeGuid,
    handleCreateOrUpdateTask,
  } = props;

  return (
    <ListWrapper pl={pl} p={15} height={height} minHeight={minHeight} maxHeight={maxHeight}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          height='auto'
          withCount={true}
          noExportable={true}
          popperWithCount={true}
          filterProps={FILTER_PARAMS}
          hiddenRightFilterInfo={false}
          type={GC.TASK_MANAGEMENT_REPORT}
          title={R.isNil(title) ? G.getWindowLocale('titles:task-management-report', 'Task Management Report') : title}
          additionalComponent={
            R.isNotNil(assigneeGuid) ? <CreateTask handleCreateTask={handleCreateOrUpdateTask} /> : null
          }
          customTitleComponent={
            R.isNil(assigneeGuid) ? (
              <TitleTabs totalCount={totalCount} handleCreateTask={handleCreateOrUpdateTask} />
            ) : null
          }
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        {renderTable(props)}
      </ZOrderWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  entities: makeSelectEntities(state),
  loading: makeSelectListLoading(state),
  objectGuid: makeSelectObjectGuid(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  selectedReport: makeSelectUsedReport(state),
  assigneeGuid: makeSelectAssigneeGuid(state),
  filterParams: makeSelectFilterParams(state),
  requestPending: makeSelectReportStatus(state),
  reportList: makeSelectAvailableReports(state),
  titleSortValues: makeSelectTitleSortValues(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  taskTypeTaskStatuses: makeSelectTaskTypesTaskStatuses(state),
  currentUserSettings: makeSelectCurrentUserSettingsFields(state),
  groupedByTaskTypesTaskStatusMap: makeSelectGroupedByTaskTypesTaskStatusMap(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  setReports,
  openLoader,
  closeLoader,
  setUsedReport,
  cleanQuickFilter,
  deleteItemRequest,
  setTableTitleSort,
  getItemListRequest,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  createOrUpdateTaskRequest,
  changeDefaultReportRequest,
  setExpandedContainerOptions,
  setEntityListByEntityTypeSuccess,
})(enhance(TaskManagementReport));
