import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// common
import { updateReportFromReportListSuccess } from '../../common/actions';
// features
import {
  deleteGeoFencingZoneSuccess,
  toggleGeoFencingZoneDetails,
  receivedGeoFencingZoneSuccess,
  receivedGeoFencingZoneListSuccess,
} from '../geo-fencing-zone/rating/reducer';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportReducers } from '../../report-common';
// feature service-vendor
import * as A from './actions';
//////////////////////////////////////////////////

export const initial = {
  configs: {},
  profile: {},
  documentList: [],
  branchConfigs: {},
  documentsFilter: null,
  geofencingZoneList: [],
  integrationInfoList: [],
  integrationAuditList: [],
  collapsedGroup: {
    profile: true,
    documents: true,
    payToLocation: true,
    geofencingZone: true,
    primaryLocation: true,
    integrationInfo: true,
    integrationAudit: true,
  },
};

const {
  setReports,
  selectItem,
  initialState,
  setUsedReport,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemSuccess,
  getItemListSuccess,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  setInitialStateOmitReport,
  handleUpdateReportFromReportListSuccess,
} = getReportReducers(initial);

// configs
const getServiceVendorConfigsSuccess = (state: Object, data: Object) => (
  P.$set('configs', G.mapConfigValuesByName(data), state)
);

// service vendor
const updateServiceVendorSuccess = (state: Object, data: Object) => (
  P.$set('profile', data, state)
);

const toggleServiceVendorFormGroup = (state: Object, groupName: string) => (
  P.$toggle(`collapsedGroup.${groupName}`, state)
);

const getServiceVendorSuccess = (state: Object, data: Object) => (
  P.$set('profile', data, state)
);

const sendListToIntegrationSuccess = (state: Object, data: Object) => P.$set(
  'itemList',
  R.map(
    (entity: Object) => {
      const guid = G.getGuidFromObject(entity);
      const branches = R.pathOr(null, [guid], data);

      if (R.isNil(branches)) return entity;

      return R.assoc(GC.GRC.INTEGRATION_INFO_EXTERNAL_DIVISION_NAME, R.prop(guid, data), entity);
    },
    state.serviceVendorList,
  ),
  state,
);

const getServiceVendorIntegrationListSuccess = (state: Object, data: Object) => (
  P.$set('integrationInfoList', data, state)
);

const getServiceVendorIntegrationAuditListSuccess = (state: Object, data: Object) => (
  P.$set('integrationAuditList', data, state)
);

const getServiceVendorDocListSuccess = (state: Object, data: Array) => (
  P.$set('documentList', data, state)
);

const createOrUpdateServiceVendorDocSuccess = (state: Object, { data, isUpdate }: Object) => {
  if (isUpdate) {
    const { documentList } = state;

    const index = R.findIndex(R.propEq(G.getGuidFromObject(data), GC.FIELD_GUID), documentList);

    return P.$set(`documentList.${index}`, data, state);
  }

  return P.$add('documentList', data, state);
};

const deleteServiceVendorDocSuccess = (state: Object, index: number) => (
  P.$drop(`documentList.${index}`, state)
);

const setDocumentsFilter = (state: Object, filter: string) => P.$set('documentsFilter', filter, state);

const getUserGeneralListByServiceVendorBranchGuidSuccess = (state: Object, data: Object) => {
  const mapped = R.map(({ guid, ...user }: Object) => ({
    [GC.FIELD_VALUE]: guid,
    [GC.FIELD_LABEL]: R.prop('fullText', G.getUserInfo(user)),
  }), R.or(data, []));

  return P.$set('userGeneralList', mapped, state);
};

export default createReducer({
  [A.setReports]: setReports,
  [A.selectItem]: selectItem,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.deleteItemSuccess]: deleteItemSuccess,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.getServiceVendorSuccess]: getServiceVendorSuccess,
  [A.setInitialStateOmitReport]: setInitialStateOmitReport,
  [updateReportFromReportListSuccess]: handleUpdateReportFromReportListSuccess,
  [A.getUserGeneralListByServiceVendorBranchGuidSuccess]: getUserGeneralListByServiceVendorBranchGuidSuccess,
  // configs
  [A.getServiceVendorConfigsSuccess]: getServiceVendorConfigsSuccess,
  // service vendor
  [A.setDocumentsFilter]: setDocumentsFilter,
  [A.updateServiceVendorSuccess]: updateServiceVendorSuccess,
  [A.toggleServiceVendorFormGroup]: toggleServiceVendorFormGroup,
  [A.sendListToIntegrationSuccess]: sendListToIntegrationSuccess,
  [A.deleteServiceVendorDocSuccess]: deleteServiceVendorDocSuccess,
  [A.getServiceVendorDocListSuccess]: getServiceVendorDocListSuccess,
  [A.createOrUpdateServiceVendorDocSuccess]: createOrUpdateServiceVendorDocSuccess,
  [A.getServiceVendorIntegrationListSuccess]: getServiceVendorIntegrationListSuccess,
  [A.getServiceVendorIntegrationAuditListSuccess]: getServiceVendorIntegrationAuditListSuccess,
  // geofencing zone
  [A.deleteGeoFencingZoneSuccess]: deleteGeoFencingZoneSuccess,
  [A.toggleGeoFencingZoneDetails]: toggleGeoFencingZoneDetails,
  [A.receivedGeoFencingZoneSuccess]: receivedGeoFencingZoneSuccess,
  [A.receivedGeoFencingZoneListSuccess]: receivedGeoFencingZoneListSuccess,
}, initialState);
