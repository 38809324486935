import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { compose } from 'react-recompose';
// components
import { ChargeFormFooter } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, scrollableContainerCss4px } from '../../../ui';
// feature work-order
import { MultiValueLabel } from '../settings/field-settings';
import { CostsSection, validationSchemaCostsObject } from './costs-section';
//////////////////////////////////////////////////

const numberValidation = G.yupStringNotRequired.max(40, G.getShouldBeFromToLocaleTxt(0, 40));

const validationSchema = Yup.object()
  .shape(validationSchemaCostsObject)
  .shape({
    [GC.FIELD_CURRENCY]: G.yupStringRequired,
    [GC.FIELD_INVOICE_NUMBER]: numberValidation,
    [GC.FIELD_ESTIMATE_NUMBER]: numberValidation,
    [GC.FIELD_DESCRIPTION]: G.yupStringNotRequired.max(2000, G.getShouldBeFromToLocaleTxt(0, 2000)),
  });

const invoiceInputWrapperStyles = { mb: 25, width: 220 };

const getInputWrapperStylesWithDisplay = (typeParam: string) => R.assoc(
  'display',
  ({ values: { type } }: Object) => G.ifElse(R.equals(type, typeParam), 'block', 'none'),
  invoiceInputWrapperStyles,
);

const invoiceFieldsInputWrapperStyles = getInputWrapperStylesWithDisplay(GC.INVOICE_TYPE_COST);
const estimateFieldsInputWrapperStyles = getInputWrapperStylesWithDisplay(GC.INVOICE_TYPE_ESTIMATE);

const firstInvoiceFieldSettings = [
  {
    type: 'select',
    fieldName: GC.FIELD_TYPE,
    label: ['titles:type', 'Type'],
    inputWrapperStyles: invoiceInputWrapperStyles,
    options: [
      {
        value: GC.INVOICE_TYPE_COST,
        label: G.getWindowLocale('titles:cost', 'Cost'),
      },
      {
        value: GC.INVOICE_TYPE_ESTIMATE,
        label: G.getWindowLocale('titles:estimate', 'Estimate'),
      },
    ],
  },
  {
    isMulti: true,
    type: 'reactSelect',
    useMenuPortalTarget: true,
    fieldName: GC.FIELD_ISSUE_GUIDS,
    components: { MultiValueLabel },
    label: ['titles:issues', 'Issues'],
    options: 'invoiceServiceIssueOptions',
    inputWrapperStyles: { mb: 25, width: 'calc(65% - 2px)' },
  },
];

const secondInvoiceFieldSettings = [
  {
    type: 'text',
    fieldName: GC.FIELD_INVOICE_NUMBER,
    label: ['titles:invoice-number', 'Invoice #'],
    inputWrapperStyles: invoiceFieldsInputWrapperStyles,
  },
  {
    isClearable: true,
    type: 'datePicker',
    fieldName: GC.FIELD_INVOICE_DATE,
    label: ['titles:invoice-date', 'Invoice Date'],
    inputWrapperStyles: invoiceFieldsInputWrapperStyles,
  },
  {
    type: 'text',
    fieldName: GC.FIELD_ESTIMATE_NUMBER,
    label: ['titles:estimate-number', 'Estimate #'],
    inputWrapperStyles: estimateFieldsInputWrapperStyles,
  },
  {
    isClearable: true,
    type: 'datePicker',
    fieldName: GC.FIELD_ESTIMATE_DATE,
    label: ['titles:estimate-date', 'Estimate Date'],
    inputWrapperStyles: estimateFieldsInputWrapperStyles,
  },
  {
    type: 'select',
    isRequired: true,
    fieldName: GC.FIELD_CURRENCY,
    options: GC.CURRENCY_OPTIONS,
    label: ['titles:currency', 'Currency'],
    inputWrapperStyles: invoiceInputWrapperStyles,
  },
  {
    type: 'textarea',
    fieldName: GC.FIELD_DESCRIPTION,
    label: ['titles:description', 'Description'],
    inputWrapperStyles: { mb: 20, width: '100%' },
  },
];

const defaultValues = {
  [GC.FIELD_ISSUE_GUIDS]: [],
  [GC.FIELD_DESCRIPTION]: null,
  [GC.FIELD_INVOICE_DATE]: null,
  [GC.FIELD_ESTIMATE_DATE]: null,
  [GC.FIELD_INVOICE_NUMBER]: null,
  [GC.FIELD_ESTIMATE_NUMBER]: null,
  [GC.FIELD_TYPE]: GC.INVOICE_TYPE_COST,
  [GC.FIELD_CURRENCY]: R.or(
    G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DEFAULT_CURRENCY),
    GC.DEFAULT_UI_CURRENCY,
  ),
};

const formEnhance = compose(
  withFormik({
    validationSchema,
    handleSubmit: (values: Object, { props: { submitAction } }: Object) => submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(defaultValues, initialValues),
  }),
);

const fieldsWrapperStyles = { px: 15, justifyContent: 'space-between' };

export const InvoiceForm = formEnhance((props: Object) => {
  const { handleSubmit, invoiceServiceIssueOptions } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Box overflow='auto' maxHeight='calc(87vh - 50px)' css={scrollableContainerCss4px}>
        <Fieldset2
          {...G.getFormikProps(props)}
          fields={firstInvoiceFieldSettings}
          invoiceServiceIssueOptions={invoiceServiceIssueOptions}
          fieldsWrapperStyles={{ ...fieldsWrapperStyles, pt: 25 }}
        />
        <Fieldset2
          {...G.getFormikProps(props)}
          fields={secondInvoiceFieldSettings}
          fieldsWrapperStyles={fieldsWrapperStyles}
        />
        <CostsSection {...props} />
      </Box>
      <ChargeFormFooter />
    </form>
  );
});
