import * as R from 'ramda';
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
// common
import { closeModal } from '../../../../components/modal/actions';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { Box, Flex, AbsoluteBox } from '../../../../ui';
// feature template-inspection
import {
  makeSelectActiveSection,
  makeSelectCurrentTemplate,
} from '../selectors';
import {
  setActiveSection,
  updateTemplateFields,
  setCurrentInspectionTemplate,
  submitInspectionTemplateRequest,
} from '../actions';
import Header from './header';
import * as H from '../helpers';
import BuilderSandbox from './builder-sandbox';
import ComponentsPanel from './components-panel';
import { FooterActions } from './action-buttons';
//////////////////////////////////////////////////

const TemplateBuilder = () => {
  const dispatch = useDispatch();

  const [isValid, setIsValid] = useState(false);

  const activeSection = useSelector(makeSelectActiveSection());
  const currentTemplate = useSelector(makeSelectCurrentTemplate());

  const viewOnly = R.propEq(false, 'editable', currentTemplate);

  const cancelAction = useCallback(() => {
    dispatch(closeModal());
    dispatch(setActiveSection(0));
    dispatch(setCurrentInspectionTemplate(null));
  }, []);

  const submitAction = useCallback(() => {
    dispatch(submitInspectionTemplateRequest(currentTemplate));
  }, [currentTemplate]);

  useEffect(() => {
    if (viewOnly) {
      setIsValid(false);

      return;
    }
    // Maybe move validation to reducer to avoid unnecessary second validation call
    const updateTemplate = H.isTemplateInvalid(currentTemplate);

    setIsValid(R.not(updateTemplate));

    if (R.and(
      updateTemplate,
      G.notEquals(updateTemplate, currentTemplate),
    )) dispatch(updateTemplateFields(updateTemplate));
  }, [viewOnly, currentTemplate]);

  return (
    <Box>
      <Header viewOnly={viewOnly} currentTemplate={currentTemplate} />
      <Flex p='0 20px' justifyContent='space-between' height='calc(100vh - 203px)'>
        <BuilderSandbox viewOnly={viewOnly} currentTemplate={currentTemplate} activeSection={activeSection} />
        <ComponentsPanel viewOnly={viewOnly} currentTemplate={currentTemplate} />
      </Flex>
      <AbsoluteBox
        p={15}
        bottom={0}
        width='100%'
        borderTop='1px solid'
        borderColor={G.getTheme('colors.dark.lightGrey')}
      >
        <FooterActions
          disabled={R.not(isValid)}
          cancelAction={cancelAction}
          submitAction={submitAction}
        />
      </AbsoluteBox>
    </Box>
  );
};

export default TemplateBuilder;
