import * as R from 'ramda';
import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const store = ({ containerTypes }: Object) => containerTypes;

const makeSelectItemList = () => createSelector(
  store,
  ({ itemList }: Object) => R.sortBy(R.prop('index'), R.values(itemList)),
);

const makeSelectTotalCount = () => createSelector(
  store,
  ({ totalCount }: Object) => totalCount,
);

const makeSelectListLoading = () => createSelector(
  store,
  ({ loading }: Object) => loading,
);

export {
  makeSelectItemList,
  makeSelectTotalCount,
  makeSelectListLoading,
};

