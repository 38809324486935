import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature roles
import { groupNameLocale } from './constants';
/////////////////////////////////////////////////////

export const getAllAvailablePermissions = (permissions: Array) => R.filter(
  ({ active, disabled, uiType }: Object) => G.isAllTrue(
    active,
    R.not(disabled),
    R.equals(uiType, 'toggle'),
  ),
  permissions,
);

export const getAllActivePermissionsChecked = R.compose(
  R.all(G.isTrue),
  R.map(R.prop('active')),
  R.values,
);

export const getAllGroupPermissionsChecked = ({ values }: Object, permissions: Array) => {
  const allAvailablePermissions = getAllAvailablePermissions(permissions);
  const allAvailablePermissionsNames = R.map((item: Object) => R.prop('permissionName', item), allAvailablePermissions);
  const allAvailablePermissionsWrite = (item: string) => R.and(
    R.has(item, values),
    R.equals(R.prop(item, values), GC.FIELD_ROLE_USER_PERMISSION_WRITE),
  );
  const allGroupPermissionsChecked = R.all(allAvailablePermissionsWrite, allAvailablePermissionsNames);

  return allGroupPermissionsChecked;
};

export const getSelectedList = R.compose(
  R.map((item: Object) => item.guid),
  R.filter((item: Object) => G.isTrue(item.selected)),
);

export const mapWithSelectedFalseAndIndexByGuid = R.compose(
  R.indexBy(R.prop('guid')),
  R.map((item: Object) => R.assoc('selected', false, item)),
);

export const getGroupNameLocale = (groupName: string) => G.getWindowLocale(
  R.path([groupName], groupNameLocale), groupName,
);

export const getAvailableAndDefaultUserPermissions = (defaultPermissions: Object, availablePermissions: Object) => {
  if (R.and(G.isNilOrEmpty(defaultPermissions), G.isNilOrEmpty(availablePermissions))) return [];
  const defaultUserPermissions = R.map((item: Object) =>
    R.assoc('defaultPermission', true, item),
    R.or(defaultPermissions, []),
  );
  const availableUserPermissions = R.map((item: Object) =>
    R.assoc('defaultPermission', false, item),
    R.or(availablePermissions, []),
  );
  return R.compose(
    R.uniqBy(R.prop(GC.FIELD_ROLE_PERMISSION_NAME)),
    R.concat(defaultUserPermissions),
  )(availableUserPermissions);
};
