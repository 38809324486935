// helpers/constants
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

// TODO: with all fields after added to API
export const cloTemplateSettings = {
  [GC.FIELD_TEMPLATE_NAME]: {
    name: 'titles:template-name',
  },
  [GC.FIELD_TEMPLATE_CREATED_BY]: {
    width: 250,
    name: 'titles:created-by',
  },
  [GC.FIELD_TEMPLATE_CREATED_DATE]: {
    width: 250,
    fieldType: 'date',
    filterType: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_NUMBER_OF_LOADS]: {
    filterType: 'number',
    name: 'titles:number-of-loads',
  },
  [GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS]: {
    width: 250,
    name: 'titles:special-instructions',
  },
  [GC.FIELD_HOT]: {
    fieldType: 'boolean',
    filterType: 'boolean',
    name: 'titles:hot-load',
  },
  [GC.GRC.RATE_TOTAL]: {
    filterType: 'number',
    name: ['titles:rate', 'titles:total'],
  },
  [GC.GRC.RATE_CURRENCY]: {
    name: ['titles:rate', 'titles:currency'],
  },
  [GC.GRC.BILL_TO_LOCATION_NAME]: {
    name: 'titles:bill-to-name',
  },
  [GC.GRC.BILL_TO_ADDRESS1]: {
    name: ['titles:bill-to', 'titles:address1'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_LOCATION_TYPE]: {
    name: ['titles:first-event', 'titles:location-type'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_NAME]: {
    name: ['titles:first-event', 'titles:location-name'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_TEMPLATE_ID]: {
    width: 250,
    name: ['titles:first-event', 'titles:template-id'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_ADDRESS1]: {
    width: 250,
    name: ['titles:first-event', 'titles:address1'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_CITY]: {
    width: 250,
    name: ['titles:first-event', 'titles:city'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_STATE]: {
    width: 250,
    name: ['titles:first-event', 'titles:state'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_COUNTRY]: {
    width: 250,
    name: ['titles:first-event', 'titles:country'],
  },
  [GC.GRC.FIRST_EVENT_LOCATION_ZIP]: {
    width: 250,
    name: ['titles:first-event', 'titles:zip'],
  },
  [GC.GRC.FIRST_EVENT_COMPLETE_DATE]: {
    width: 250,
    fieldType: 'date',
    filterType: 'date',
    name: ['titles:first-event', 'titles:complete-date'],
  },
  [GC.GRC.FIRST_EVENT_PICKUP_NUMBER]: {
    width: 250,
    name: ['titles:first-event', 'titles:pick-up-number'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_LOCATION_TYPE]: {
    width: 250,
    name: ['titles:last-event', 'titles:location-type'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_TEMPLATE_ID]: {
    width: 250,
    searchable: true,
    name: ['titles:last-event', 'titles:template-id'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_NAME]: {
    width: 250,
    name: ['titles:last-event', 'titles:location-name'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_ADDRESS1]: {
    width: 250,
    name: ['titles:last-event', 'titles:address1'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_CITY]: {
    width: 250,
    name: ['titles:last-event', 'titles:city'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_STATE]: {
    width: 250,
    searchable: true,
    name: ['titles:last-event', 'titles:state'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_COUNTRY]: {
    width: 250,
    name: ['titles:last-event', 'titles:country'],
  },
  [GC.GRC.LAST_EVENT_LOCATION_ZIP]: {
    width: 250,
    name: ['titles:last-event', 'titles:zip'],
  },
};
