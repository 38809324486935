import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
// common
import { openModal, closeModal } from '../../../../components/modal/actions';
// icons
import * as I from '../../../../svgs';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import {
  Box,
  Flex,
  Grid,
  Text,
  IconWrapper,
} from '../../../../ui';
// feature template-inspection
import { IconBox } from '../ui';
import { ChooseIcon } from './action-buttons';
import { updateComponentFields } from '../actions';
import iconsConfig from '../settings/component-configs/component-icons-config';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.dark.blue');
const removeColor = G.getTheme('colors.dark.yellow');
const lightColor = G.getTheme('colors.dark.lightGrey');
const shadowColor = G.getTheme('colors.boxShadowLightGrey');

const IconModal = ({
  component,
  applyAction,
}: Object) => {
  const selectedIcon = R.prop('iconName', component);

  return (
    <Flex gap={12} flexDirection='column'>
      <Text>{G.getWindowLocale('titles:inspection-select-icon', 'Please, select any icon')}</Text>
      <Grid gridGap={12} gridTemplateColumns='repeat(8, 64px)'>
        {R.map(({iconName, Icon}: Object) => {
          const isActive = R.equals(selectedIcon, iconName);

          const borderColor = G.ifElse(
            isActive,
            iconColor,
            lightColor,
          );

          const title = G.ifElse(
            isActive,
            G.getWindowLocale('titles:inspection-remove-icon', 'Remove icon'),
            G.getWindowLocale('titles:inspection-choose-icon', 'Choose icon'),
          );

          return (
            <IconBox
              p={5}
              title={title}
              key={iconName}
              borderRadius={4}
              cursor='pointer'
              border='2px solid'
              isActive={isActive}
              position='relative'
              data-icon={iconName}
              borderColor={borderColor}
              onClick={() => applyAction(iconName)}
            >
              <IconWrapper
                width={50}
                height={50}
                cursor='pointer'
              >
                {Icon(iconColor, '100%')}
              </IconWrapper>
              <Flex backgroundColor={shadowColor}>
                {I.remove(removeColor, 20, 20)}
              </Flex>
            </IconBox>
          );
        }, iconsConfig)}
      </Grid>
    </Flex>
  );
};

const ComponentIcon = ({
  action,
  component,
}: Object) => {
  const iconName = R.prop('iconName', component);
  const Icon = R.prop('Icon', R.find(R.propEq(iconName, 'iconName'), iconsConfig));

  return (
    <Box
      p={1}
      borderRadius={2}
      cursor='pointer'
      onClick={action}
      border='1px solid'
      borderColor={iconColor}
      title={G.getWindowLocale('titles:inspection-choose-another-icon', 'Choose another icon')}
    >
      <IconWrapper
        width={20}
        height={20}
        cursor='pointer'
      >
        {Icon(iconColor, '100%')}
      </IconWrapper>
    </Box>
  );
};

const IconInHeader = ({
  component,
}: Object) => {
  const dispatch = useDispatch();

  const iconName = R.prop('iconName', component);

  const handleSelectIcon = useCallback((selectedIcon: string) => {
    dispatch(updateComponentFields({
      ...component,
      iconName: G.ifElse(R.equals(selectedIcon, iconName), null, selectedIcon),
    }));

    dispatch(closeModal());
  }, [component, iconName]);

  const handleAddIcon = useCallback(() => {
    const modal = {
      p: '10px 15px',
      component: <IconModal component={component} applyAction={handleSelectIcon} />,
      options: {
        width: '620px',
        movable: false,
        height: '600px',
        withCloseIcon: true,
        outsideCloseButton: true,
        name: G.getWindowLocale('titles:inspection-icons-modal', 'Inspection Icons'),
      },
    };

    dispatch(openModal(modal));
  }, [component, handleSelectIcon]);

  return (
    <Box>
      { R.isNil(iconName) && <ChooseIcon action={handleAddIcon} /> }
      { G.isNotNil(iconName) && <ComponentIcon component={component} action={handleAddIcon} /> }
    </Box>
  );
};

export default IconInHeader;
