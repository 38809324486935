// constants
import { ENUMS } from '../../../constants/enums';
//////////////////////////////////////////////////

export const MAX_NAME_LENGTH = 100;

export const DIVIDER = 'DIVIDER';

// inspection types
export const INSPECTION_TYPES = [
  ENUMS.ENUM_INSPECTION_TYPE_GENERAL,
  ENUMS.ENUM_INSPECTION_TYPE_PRE_TRIP,
  ENUMS.ENUM_INSPECTION_TYPE_POST_TRIP,
];
// components
export const INSPECTION_TEXT = 'TEXT';
export const INSPECTION_DATE = 'DATE';
export const INSPECTION_TIME = 'TIME';
export const INSPECTION_METER = 'METER';
export const INSPECTION_PHOTO = 'PHOTO';
export const INSPECTION_RADIO = 'RADIO';
export const INSPECTION_NUMBER = 'NUMBER';
export const INSPECTION_GENERAL = 'GENERAL';
export const INSPECTION_DROPDOWN = 'DROPDOWN';
export const INSPECTION_CHECKBOX = 'CHECKBOX';
export const INSPECTION_SIGNATURE = 'SIGNATURE';
export const INSPECTION_PASS_FAIL = 'PASS_FAIL';
// truck sections
export const INSPECTION_TRUCK_BACK = 'TRUCK_BACK';
export const INSPECTION_TRUCK_LEFT = 'TRUCK_LEFT';
export const INSPECTION_TRUCK_FRONT = 'TRUCK_FRONT';
export const INSPECTION_TRUCK_RIGHT = 'TRUCK_RIGHT';
// trailer sections
export const INSPECTION_TRAILER_BACK = 'TRAILER_BACK';
export const INSPECTION_TRAILER_LEFT = 'TRAILER_LEFT';
export const INSPECTION_TRAILER_FRONT = 'TRAILER_FRONT';
export const INSPECTION_TRAILER_RIGHT = 'TRAILER_RIGHT';
export const INSPECTION_TRAILER_INTERIOR = 'TRAILER_INTERIOR';

export const VEHICLE_SECTION_TYPES = [
  INSPECTION_TRUCK_BACK,
  INSPECTION_TRUCK_LEFT,
  INSPECTION_TRUCK_FRONT,
  INSPECTION_TRUCK_RIGHT,
  INSPECTION_TRAILER_BACK,
  INSPECTION_TRAILER_LEFT,
  INSPECTION_TRAILER_FRONT,
  INSPECTION_TRAILER_RIGHT,
  INSPECTION_TRAILER_INTERIOR,
];

// UI statuses
export const INSPECTION_NAME_STATUS_EDIT = 'EDIT';
export const INSPECTION_NAME_STATUS_EMPTY = 'EMPTY';
export const INSPECTION_NAME_STATUS_FILLED = 'FILLED';

export const INSPECTION_TEMPLATE_COPY = 'COPY';
export const INSPECTION_TEMPLATE_EDIT = 'EDIT';
export const INSPECTION_TEMPLATE_VIEW = 'VIEW';

// Option field keys
export const INSPECTION_RADIOS = 'RADIOS';
export const INSPECTION_CHECKBOXES = 'CHECKBOXES';
export const INSPECTION_DATE_FIELD = 'DATE_FIELD';
export const INSPECTION_PASSING_RANGE = 'PASSING_RANGE';
export const INSPECTION_PASS_FAIL_VALUES = 'PASS_FAIL_VALUES';
export const INSPECTION_ENABLE_NA_OPTION = 'ENABLE_NA_OPTION';
export const INSPECTION_DROPDOWN_CHOICES = 'DROPDOWN_CHOICES';
export const INSPECTION_REQUIRE_SECONDARY_METER = 'REQUIRE_SECONDARY_METER';
export const INSPECTION_REQUIRE_PHOTO_VERIFICATION = 'REQUIRE_PHOTO_VERIFICATION';
export const INSPECTION_REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL = 'REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL';
export const INSPECTION_REQUIRE_PHOTO_OR_COMMENT_FOR_PASS = 'REQUIRE_PHOTO_OR_COMMENT_FOR_PASS';

// Option types
export const INSPECTION_OPTION_TYPE_RADIO = 'OPTION_TYPE_RADIO';
export const INSPECTION_OPTION_TYPE_DATE_ONLY = 'OPTION_TYPE_DATE_ONLY';
export const INSPECTION_OPTION_TYPE_DATE_TIME = 'OPTION_TYPE_DATE_TIME';

export const INSPECTION_OPTION_TYPE_RANGE = 'OPTION_TYPE_RANGE';
export const INSPECTION_OPTION_TYPE_MINIMUM = 'OPTION_TYPE_MINIMUM_VALUE';
export const INSPECTION_OPTION_TYPE_MAXIMUM = 'OPTION_TYPE_MAXIMUM_VALUE';

export const INSPECTION_OPTION_TYPE_CHECKBOX = 'OPTION_TYPE_CHECKBOX';

export const INSPECTION_OPTION_NA_VALUE = 'OPTION_TYPE_NA_VALUE';
export const INSPECTION_OPTION_TYPE_INPUTS = 'OPTION_TYPE_INPUTS';
export const INSPECTION_OPTION_PASS_VALUE = 'OPTION_TYPE_PASS_VALUE';
export const INSPECTION_OPTION_FAIL_VALUE = 'OPTION_TYPE_FAIL_VALUE';

export const INSPECTION_OPTION_TYPE_DROPDOWN = 'OPTION_TYPE_DROPDOWN';

export const INSPECTION_OPTION_TYPE_CHECKBOXES = 'OPTION_TYPE_CHECKBOXES';

// Vehicle types
export const INSPECTION_TRUCK = 'TRUCK';
export const INSPECTION_TRAILER = 'TRAILER';

// Truck component types
const truckComponentTypes = {
  // Front Truck Components
  IDENTIFICATION_LIGHTS: 'IDENTIFICATION_LIGHTS',
  PASSENGER_SIDE_CLEARANCE_LIGHT: 'PASSENGER_SIDE_CLEARANCE_LIGHT',
  DRIVER_SIDE_CLEARANCE_LIGHT: 'DRIVER_SIDE_CLEARANCE_LIGHT',
  WINDSHIELD: 'WINDSHIELD',
  PASSENGER_SIDE_WINDSHIELD_WIPER: 'PASSENGER_SIDE_WINDSHIELD_WIPER',
  DRIVER_SIDE_WINDSHIELD_WIPER: 'DRIVER_SIDE_WINDSHIELD_WIPER',
  PASSENGER_SIDE_MIRROR: 'PASSENGER_SIDE_MIRROR',
  DRIVER_SIDE_MIRROR: 'DRIVER_SIDE_MIRROR',
  HOOD: 'HOOD',
  PASSENGER_SIDE_HEADLIGHT: 'PASSENGER_SIDE_HEADLIGHT',
  DRIVER_SIDE_HEADLIGHT: 'DRIVER_SIDE_HEADLIGHT',
  GRILLE: 'GRILLE',
  FRONT_PASSENGER_SIDE_TURN_SIGNAL: 'FRONT_PASSENGER_SIDE_TURN_SIGNAL',
  FRONT_DRIVER_SIDE_TURN_SIGNAL: 'FRONT_DRIVER_SIDE_TURN_SIGNAL',
  FRONT_PASSENGER_SIDE_MARKER_LIGHT: 'FRONT_PASSENGER_SIDE_MARKER_LIGHT',
  FRONT_DRIVER_SIDE_MARKER_LIGHT: 'FRONT_DRIVER_SIDE_MARKER_LIGHT',
  PASSENGER_SIDE_FOG_LIGHT: 'PASSENGER_SIDE_FOG_LIGHT',
  DRIVER_SIDE_FOG_LIGHT: 'DRIVER_SIDE_FOG_LIGHT',
  FRONT_BUMPER: 'FRONT_BUMPER',
  // Back Truck Components
  DRIVER_SIDE_REFLECTIVE_TAPE_CAB: 'DRIVER_SIDE_REFLECTIVE_TAPE_CAB',
  PASSENGER_SIDE_REFLECTIVE_TAPE_CAB: 'PASSENGER_SIDE_REFLECTIVE_TAPE_CAB',
  HEADACHE_RACK: 'HEADACHE_RACK',
  FIFTH_WHEEL: 'FIFTH_WHEEL',
  DRIVER_SIDE_REVERSE_LIGHT: 'DRIVER_SIDE_REVERSE_LIGHT',
  PASSENGER_SIDE_REVERSE_LIGHT: 'PASSENGER_SIDE_REVERSE_LIGHT',
  DRIVER_SIDE_REFLECTIVE_TAPE: 'DRIVER_SIDE_REFLECTIVE_TAPE',
  REAR_DRIVER_SIDE_TURN_SIGNAL: 'REAR_DRIVER_SIDE_TURN_SIGNAL',
  REAR_PASSENGER_SIDE_TURN_SIGNAL: 'REAR_PASSENGER_SIDE_TURN_SIGNAL',
  PASSENGER_SIDE_REFLECTIVE_TAPE: 'PASSENGER_SIDE_REFLECTIVE_TAPE',
  DRIVER_SIDE_BRAKE_TAIL_LIGHT_REFLECTORS: 'DRIVER_SIDE_BRAKE_TAIL_LIGHT_REFLECTORS',
  PASSENGER_SIDE_BRAKE_TAIL_LIGHT_REFLECTORS: 'PASSENGER_SIDE_BRAKE_TAIL_LIGHT_REFLECTORS',
  REAR_END_PROTECTION: 'REAR_END_PROTECTION',
  REAR_DRIVER_SIDE_MUD_FLAP: 'REAR_DRIVER_SIDE_MUD_FLAP',
  REAR_PASSENGER_SIDE_MUD_FLAP: 'REAR_PASSENGER_SIDE_MUD_FLAP',
  // Right Truck Components
  PASSENGER_SIDE_PILLAR_A: 'PASSENGER_SIDE_PILLAR_A',
  PASSENGER_SIDE_PILLAR_B: 'PASSENGER_SIDE_PILLAR_B',
  PASSENGER_SIDE_SLEEPER_WINDOW: 'PASSENGER_SIDE_SLEEPER_WINDOW',
  PASSENGER_SIDE_DOOR_GLASS: 'PASSENGER_SIDE_DOOR_GLASS',
  PASSENGER_SIDE_DOOR_HANDLE: 'PASSENGER_SIDE_DOOR_HANDLE',
  PASSENGER_SIDE_SLEEPER_COMPARTMENT_BODY: 'PASSENGER_SIDE_SLEEPER_COMPARTMENT_BODY',
  PASSENGER_SIDE_DOOR: 'PASSENGER_SIDE_DOOR',
  PASSENGER_SIDE_FENDER: 'PASSENGER_SIDE_FENDER',
  PASSENGER_SIDE_FUEL_TANK_AND_LINES: 'PASSENGER_SIDE_FUEL_TANK_AND_LINES',
  REAR_PASSENGER_SIDE_REAR_DRIVE_AXLE_WHEELS_RIMS_VALVE_CAPS:
    'REAR_PASSENGER_SIDE_REAR_DRIVE_AXLE_WHEELS_RIMS_VALVE_CAPS',
  REAR_PASSENGER_SIDE_FRONT_DRIVE_AXLE_WHEELS_RIMS_VALVE_CAPS:
    'REAR_PASSENGER_SIDE_FRONT_DRIVE_AXLE_WHEELS_RIMS_VALVE_CAPS',
  FRONT_PASSENGER_SIDE_WHEEL_RIM_VALVE_CAP: 'FRONT_PASSENGER_SIDE_WHEEL_RIM_VALVE_CAP',
  PASSENGER_SIDE_BATTERY_BOX_COVER: 'PASSENGER_SIDE_BATTERY_BOX_COVER',
  REAR_PASSENGER_SIDE_REAR_DRIVE_AXLE_TIRES: 'REAR_PASSENGER_SIDE_REAR_DRIVE_AXLE_TIRES',
  REAR_PASSENGER_SIDE_FRONT_DRIVE_AXLE_TIRES: 'REAR_PASSENGER_SIDE_FRONT_DRIVE_AXLE_TIRES',
  FRONT_PASSENGER_SIDE_TIRE: 'FRONT_PASSENGER_SIDE_TIRE',
  // Left Truck Components
  ROOF: 'ROOF',
  DRIVER_SIDE_PILLAR_A: 'DRIVER_SIDE_PILLAR_A',
  DRIVER_SIDE_PILLAR_B: 'DRIVER_SIDE_PILLAR_B',
  DRIVER_SIDE_SLEEPER_WINDOW: 'DRIVER_SIDE_SLEEPER_WINDOW',
  DRIVER_SIDE_DOOR_GLASS: 'DRIVER_SIDE_DOOR_GLASS',
  DRIVER_SIDE_DOOR_HANDLE: 'DRIVER_SIDE_DOOR_HANDLE',
  DRIVER_SIDE_DOOR: 'DRIVER_SIDE_DOOR',
  DRIVER_SIDE_SLEEPER_COMPARTMENT_BODY: 'DRIVER_SIDE_SLEEPER_COMPARTMENT_BODY',
  DRIVER_SIDE_FENDER: 'DRIVER_SIDE_FENDER',
  DRIVER_SIDE_FUEL_TANK_AND_LINES: 'DRIVER_SIDE_FUEL_TANK_AND_LINES',
  FRONT_DRIVER_SIDE_WHEEL_RIM_VALVE_CAP: 'FRONT_DRIVER_SIDE_WHEEL_RIM_VALVE_CAP',
  REAR_DRIVER_SIDE_FRONT_DRIVE_AXLE_WHEELS_RIMS_VALVE_CAPS:
    'REAR_DRIVER_SIDE_FRONT_DRIVE_AXLE_WHEELS_RIMS_VALVE_CAPS',
  REAR_DRIVER_SIDE_REAR_DRIVE_AXLE_WHEELS_RIMS_VALVE_CAPS:
    'REAR_DRIVER_SIDE_REAR_DRIVE_AXLE_WHEELS_RIMS_VALVE_CAPS',
  DRIVER_SIDE_BATTERY_BOX_COVER: 'DRIVER_SIDE_BATTERY_BOX_COVER',
  FRONT_DRIVER_SIDE_TIRE: 'FRONT_DRIVER_SIDE_TIRE',
  REAR_DRIVER_SIDE_FRONT_DRIVE_AXLE_TIRES: 'REAR_DRIVER_SIDE_FRONT_DRIVE_AXLE_TIRES',
  REAR_DRIVER_SIDE_REAR_DRIVE_AXLE_TIRES: 'REAR_DRIVER_SIDE_REAR_DRIVE_AXLE_TIRES',
};

// Trailer component types
const trailerComponentTypes = {
  // Front Trailer Components
  PASSENGER_SIDE_TOP_CORNER: 'PASSENGER_SIDE_TOP_CORNER',
  DRIVER_SIDE_TOP_CORNER: 'DRIVER_SIDE_TOP_CORNER',
  REGISTRATION_BOX: 'REGISTRATION_BOX',
  SEVEN_WAY_GLAD_HANDS: 'SEVEN_WAY_GLAD_HANDS',
  K_BRACE: 'K_BRACE',
  PASSENGER_SIDE_LANDING_GEAR_LEG: 'PASSENGER_SIDE_LANDING_GEAR_LEG',
  DRIVER_SIDE_LANDING_GEAR_LEG: 'DRIVER_SIDE_LANDING_GEAR_LEG',
  // Back Trailer Components
  REAR_DRIVER_SIDE_CLEARANCE_LIGHT: 'REAR_DRIVER_SIDE_CLEARANCE_LIGHT',
  REAR_MIDDLE_LEFT_CLEARANCE_LIGHT: 'REAR_MIDDLE_LEFT_CLEARANCE_LIGHT',
  REAR_MIDDLE_CENTRAL_CLEARANCE_LIGHT: 'REAR_MIDDLE_CENTRAL_CLEARANCE_LIGHT',
  REAR_MIDDLE_RIGHT_CLEARANCE_LIGHT: 'REAR_MIDDLE_RIGHT_CLEARANCE_LIGHT',
  REAR_PASSENGER_SIDE_CLEARANCE_LIGHT: 'REAR_PASSENGER_SIDE_CLEARANCE_LIGHT',
  DRIVER_SIDE_DOOR_HINGES: 'DRIVER_SIDE_DOOR_HINGES',
  PASSENGER_SIDE_DOOR_HINGES: 'PASSENGER_SIDE_DOOR_HINGES',
  DOOR_HANDLES: 'DOOR_HANDLES',
  REAR_SIDE_REFLECTIVE_TAPE: 'REAR_SIDE_REFLECTIVE_TAPE',
  REAR_DRIVER_SIDE_TURN_SIGNAL: 'REAR_DRIVER_SIDE_TURN_SIGNAL',
  REAR_PASSENGER_SIDE_TURN_SIGNAL: 'REAR_PASSENGER_SIDE_TURN_SIGNAL',
  REAR_DRIVER_SIDE_LOWER_CORNER: 'REAR_DRIVER_SIDE_LOWER_CORNER',
  DRIVER_SIDE_BRAKE_TAIL_LIGHT: 'DRIVER_SIDE_BRAKE_TAIL_LIGHT',
  PASSENGER_SIDE_BRAKE_TAIL_LIGHT: 'PASSENGER_SIDE_BRAKE_TAIL_LIGHT',
  REAR_PASSENGER_SIDE_LOWER_CORNER: 'REAR_PASSENGER_SIDE_LOWER_CORNER',
  REAR_LICENSE_PLATE_AND_LICENSE_PLATE_LIGHT: 'REAR_LICENSE_PLATE_AND_LICENSE_PLATE_LIGHT',
  BUMPER: 'BUMPER',
  REAR_DRIVER_SIDE_MUDFLAP: 'REAR_DRIVER_SIDE_MUDFLAP',
  REAR_PASSENGER_SIDE_MUDFLAP: 'REAR_PASSENGER_SIDE_MUDFLAP',
  // Interior Trailer Components
  INNER_ROOF: 'INNER_ROOF',
  DRIVER_SIDE_INNER_DOOR: 'DRIVER_SIDE_INNER_DOOR',
  DRIVER_SIDE_INNER_PANEL: 'DRIVER_SIDE_INNER_PANEL',
  REAR_INNER_PANEL: 'REAR_INNER_PANEL',
  PASSENGER_SIDE_INNER_PANEL: 'PASSENGER_SIDE_INNER_PANEL',
  PASSENGER_SIDE_INNER_DOOR: 'PASSENGER_SIDE_INNER_DOOR',
  TRAILER_FLOOR_BED: 'TRAILER_FLOOR_BED',
  // Right Trailer Components
  REAR_PASSENGER_SIDE_PANEL: 'REAR_PASSENGER_SIDE_PANEL',
  FRONT_PASSENGER_SIDE_PANEL: 'FRONT_PASSENGER_SIDE_PANEL',
  REAR_PASSENGER_SIDE_TIRES_HUBCAPS: 'REAR_PASSENGER_SIDE_TIRES_HUBCAPS',
  FRONT_PASSENGER_SIDE_TIRES_HUBCAPS: 'FRONT_PASSENGER_SIDE_TIRES_HUBCAPS',
  REAR_PASSENGER_SIDE_WHEELS_AND_RIMS: 'REAR_PASSENGER_SIDE_WHEELS_AND_RIMS',
  FRONT_PASSENGER_SIDE_WHEELS_AND_RIMS: 'FRONT_PASSENGER_SIDE_WHEELS_AND_RIMS',
  REAR_PASSENGER_SIDE_TIRES: 'REAR_PASSENGER_SIDE_TIRES',
  FRONT_PASSENGER_SIDE_TIRES: 'FRONT_PASSENGER_SIDE_TIRES',
  // Left Trailer Components
  ROOF: 'ROOF',
  FRONT_DRIVER_SIDE_PANEL: 'FRONT_DRIVER_SIDE_PANEL',
  REAR_DRIVER_SIDE_PANEL: 'REAR_DRIVER_SIDE_PANEL',
  CRANK_HANDLE: 'CRANK_HANDLE',
  BELLY_AIR_LINES: 'BELLY_AIR_LINES',
  FRONT_DRIVER_SIDE_TIRES_HUBCAPS: 'FRONT_DRIVER_SIDE_TIRES_HUBCAPS',
  REAR_DRIVER_SIDE_TIRES_HUBCAPS: 'REAR_DRIVER_SIDE_TIRES_HUBCAPS',
  FRONT_DRIVER_SIDE_WHEELS_AND_RIMS: 'FRONT_DRIVER_SIDE_WHEELS_AND_RIMS',
  REAR_DRIVER_SIDE_WHEELS_AND_RIMS: 'REAR_DRIVER_SIDE_WHEELS_AND_RIMS',
  FRONT_DRIVER_SIDE_TIRES: 'FRONT_DRIVER_SIDE_TIRES',
  REAR_DRIVER_SIDE_TIRES: 'REAR_DRIVER_SIDE_TIRES',
};

const allComponentTypes = {
  [INSPECTION_TRUCK]: truckComponentTypes,
  [INSPECTION_TRAILER]: trailerComponentTypes,
};

const valueTypeMap = {
  value: 'value',
  valueMap: 'valueMap',
  valueList: 'valueList',
  additionalValueMap: 'additionalValueMap',
};

export {
  valueTypeMap,
  allComponentTypes,
  truckComponentTypes,
  trailerComponentTypes,
};
