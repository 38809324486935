import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setInitialState = createAction('setInitialState');
// report
export const selectItem = createAction('selectItem');
export const setReports = createAction('setReports');
export const setListType = createAction('setListType');
export const setUsedReport = createAction('setUsedReport');
export const setReportType = createAction('setReportType');
export const setListLoading = createAction('setListLoading');
export const cleanQuickFilter = createAction('cleanQuickFilter');
export const setReportPending = createAction('setReportPending');
export const setTableTitleSort = createAction('setTableTitleSort');
export const getItemListRequest = createAction('getItemListRequest');
export const getItemListSuccess = createAction('getItemListSuccess');
export const setTableTitleFilter = createAction('setTableTitleFilter');
export const createReportRequest = createAction('createReportRequest');
export const updateReportRequest = createAction('updateReportRequest');
export const setGlobalFilterValue = createAction('setGlobalFilterValue');
export const setQuickFilterParams = createAction('setQuickFilterParams');
export const resetListAndPagination = createAction('resetListAndPagination');
export const exportReportDataRequest = createAction('exportReportDataRequest');
export const toggleTemplateItemDetails = createAction('toggleTemplateItemDetails');
export const changeDefaultReportRequest = createAction('changeDefaultReportRequest');
export const getAvailableReportsRequest = createAction('getAvailableReportsRequest');
// reference
export const createReferenceRequest = createAction('createReferenceRequest');
// container types
export const getContainerTypeListSuccess = createAction('getContainerTypeListSuccess');
// shared accessorials
export const getAccessorialConfigsSuccess = createAction('getAccessorialConfigsSuccess');
export const restoreInheritedSharedAccessorialsRequest = createAction('restoreInheritedSharedAccessorialsRequest');
// route template
export const updateRouteTemplateRequest = createAction('updateRouteTemplateRequest');
export const createRouteFromTemplateRequest = createAction('createRouteFromTemplateRequest');
// compensation
export const createCompensationTemplateRequest = createAction('createCompensationTemplateRequest');
export const getCompensationTemplateListRequest = createAction('getCompensationTemplateListRequest');
// template
export const createTemplateItemRequest = createAction('createTemplateItemRequest');
export const createTemplateItemSuccess = createAction('createTemplateItemSuccess');
export const removeTemplateItemsSuccess = createAction('removeTemplateItemsSuccess');
export const removeTemplateItemsRequest = createAction('removeTemplateItemsRequest');
export const changeTemplateItemStatusRequest = createAction('changeTemplateItemStatusRequest');
export const changeTemplateItemStatusSuccess = createAction('changeTemplateItemStatusSuccess');
export const createOrUpdateTemplateItemRequest = createAction('createOrUpdateTemplateItemRequest');
export const createOrUpdateTemplateItemSuccess = createAction('createOrUpdateTemplateItemSuccess');
// configs
export const getBranchConfigsRequest = createAction('getBranchConfigsRequest');
export const getBranchConfigsSuccess = createAction('getBranchConfigsSuccess');
