import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// helpers
import * as G from '../../../helpers';
// forms
import { Fieldset2 } from '../../../forms';
// feature sequence
import {
  defaultValues,
  fieldSettings,
  validationSchema,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

export const enhance = compose(
  withFormik({
    validationSchema: Yup.object().shape(validationSchema),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(R.filter(G.isNotNilAndNotEmpty, values)),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultValues,
      initialValues,
    ),
  }),
  pure,
);

const SequenceTypeFormComponent = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={fieldSettings}
      fieldsWrapperStyles={{ mt: 15 }}
    />
    <FormFooter2 />
  </form>
);

export default enhance(SequenceTypeFormComponent);
