import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// components
import { Table } from '../../components/table';
import { TitlePanel } from '../../components/title-panel';
import { EditReport } from '../../components/edit-report';
import { PageActions } from '../../components/page-actions';
import { ConfirmComponent } from '../../components/confirm';
import { withPromptModal } from '../../components/edit-report/hocs';
import { closeModal, openModal } from '../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../components/edit-report/helpers';
// features
import { AuthWrapper } from '../permission';
import PC from '../permission/role-permission';
import SelectRoles from '../profile/components/select-roles';
import { makeSelectCurrentUserGuid } from '../auth/selectors';
import { makeSelectRoleAvailableList } from '../role/selectors';
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import ReferenceFormComponent from '../reference/components/reference-form';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import { makeSelectAvailableReferenceTypesByScope } from '../reference/selectors';
// forms
import CreateUserForm from '../../forms/forms/create-user-form';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover } from '../../hocs';
// report-common
import { reportEnhancer } from '../../report-common';
// icons
import * as I from '../../svgs';
// ui
import { Box, ListWrapper, ZOrderWrapper } from '../../ui';
// utilities
import routesMap from '../../utilities/routes';
// feature user
import { FILTER_PARAMS } from './settings/filter-params';
import ElementActionsComponent from './components/element-actions';
import { columnSettings, tableSettings } from './settings/column-settings';
import {
  makeSelectItemList,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectUsedReport,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectSelectedUserList,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
import {
  setReports,
  selectItem,
  setUsedReport,
  moveUserRequest,
  setInitialState,
  cleanQuickFilter,
  deleteItemRequest,
  setTableTitleSort,
  getItemListRequest,
  assignRolesRequest,
  revokeRolesRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  createNewUserRequest,
  setQuickFilterParams,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
  createUsersReferenceRequest,
} from './actions';
//////////////////////////////////////////////////

const concatCheckedItemsToGuidsArr = (items: Array) => (
  R.reduce((acc: Array, item: Object) => (
    G.ifElse(
      R.equals(item.checked, true),
      R.append(item.guid, acc),
      acc,
    )
  ), [], items)
);

const enhance = compose(
  withFixedPopover,
  reportEnhancer,
  withHandlers({
    handleClickAssignRevokeRole: (props: Object) => (type: string) => {
      const {
        userList,
        openModal,
        closeModal,
        selectedList,
        revokeRolesRequest,
        assignRolesRequest,
        rolesAvailableList,
      } = props;

      if (R.isEmpty(selectedList)) {
        G.showToastrMessageSimple('info', G.getWindowLocale('messages:users:select-user', 'Please, select users'));

        return;
      }

      const selectedUserTypes = R.compose(
        R.uniq,
        R.map((item: Object) => R.path([item, GC.FIELD_USER_TYPE], userList)),
      )(selectedList);

      if (R.gt(R.length(selectedUserTypes), 1)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:one-user-type', 'Users should be the same user type'),
        );
      }

      const roles = R.compose(
        R.values(),
        R.filter((item: Object) => R.propEq(R.head(selectedUserTypes), GC.FIELD_TYPE, item)),
      )(rolesAvailableList);

      const content = (
        <SelectRoles
          items={roles}
          handleCancel={closeModal}
          handleSave={(items: Array) => {
            const guids = concatCheckedItemsToGuidsArr(items);
            G.ifElse(
              R.equals(type, 'assign'),
              assignRolesRequest,
              revokeRolesRequest,
            )({ rolesGuids: guids, usersGuids: selectedList });
            closeModal();
          }}
        />
      );

      const modal = {
        p: '0px',
        component: content,
        options: {
          width: 440,
          height: 'auto',
          controlButtons: [],
        },
      };

      openModal(modal);
    },
    handleClickCreateUser: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        currentBranchGuid,
        createNewUserRequest,
      } = props;

      const roleType = G.ifElse(
        G.isCurrentBranchTypeCustomer(),
        GC.USER_ROLE_TYPE_CUSTOMER,
        GC.USER_ROLE_TYPE_GENERAL,
      );

      const userTypeLocale = G.getWindowLocale(...G.ifElse(
        G.isCurrentBranchTypeCustomer(),
        ['titles:customer', 'Customer'],
        ['titles:general', 'General'],
      ));

      const title = `${G.getWindowLocale('titles:create-user', 'Create User')} (${userTypeLocale})`;

      const component = (
        <CreateUserForm
          roleType={roleType}
          closeModal={closeModal}
          submitAction={createNewUserRequest}
          currentBranchGuid={currentBranchGuid}
          initialValues={{ [GC.FIELD_TYPE]: roleType }}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title,
          width: '400px',
          maxHeight: '80vh',
          overflow: 'visible',
        },
      };

      openModal(modal);
    },
    handleAddReference: (props: Object) => () => {
      const { selectedList, createUsersReferenceRequest, openModal } = props;

      if (R.isEmpty(selectedList)) {
        G.showToastrMessageSimple('info', G.getWindowLocale('messages:users:select-user', 'Please, select users'));
        return;
      }

      const content = (
        <ReferenceFormComponent
          scope={GC.REF_SCOPE_NAME_USER}
          submitAction={(values: Object) => createUsersReferenceRequest({ values, selectedList })}
        />
      );

      const modal = G.createAddReferenceModalOptions(content);

      openModal(modal);
    },
    handleClickEditIcon: (props: Object) => (event: Object, user: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: event.currentTarget,
        content: (
          <ElementActionsComponent
            user={user}
            openModal={props.openModal}
            closeModal={props.closeModal}
            currentUserGuid={props.currentUserGuid}
            deleteItemRequest={props.deleteItemRequest}
          />
        ),
      })
    ),
    handleRemove: (props: Object) => (event: Object, user: Object) => {
      const { openModal, closeModal, deleteItemRequest } = props;

      const content = (
        <ConfirmComponent
          name={R.prop(GC.FIELD_USER_LOGIN_ID, user)}
          textLocale={G.getWindowLocale('messages:before:remove')}
        />
      );

      const modal = {
        component: content,
        options: {
          width: 600,
          height: 'auto',
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                closeModal();
                deleteItemRequest(G.getGuidFromObject(user));
              },
            },
          ],
        },
      };

      openModal(modal);
    },
    handleEdit: ({ currentUserGuid }: string) => (event: Object, user: Object) => {
      const guid = G.getGuidFromObject(user);

      const route = G.ifElse(
        R.equals(guid, currentUserGuid),
        routesMap.profile,
        routesMap.getUserSettingsRoute(guid),
      );

      G.goToRoute(route);
    },
  }),
  withPromptModal(FILTER_PARAMS),
  withHandlers({
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getItemListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const modalContent = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getItemListRequest(true)}
        />
      );

      const modal = {
        component: modalContent,
        options: {
          version: 2,
          height: 'auto',
          maxWidth: '98vw',
          width: 'fit-content',
        },
      };

      openModal(modal);
    },
  }),
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  pure,
);

export const renderTable = (props: Object) => {
  const {
    loading,
    userList,
    totalCount,
    pagination,
    selectItem,
    reportList,
    handleEdit,
    handleRemove,
    filterParams,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getItemListRequest,
    handleClickEditIcon,
    handleTableTitleFilter,
  } = props;

  if (R.not(selectedReport)) return null;

  const itemList = R.values(userList);

  const renderRowActions = (user: Object, handleClickEditIcon: Function) => (
    <AuthWrapper has={[PC.USER_WRITE, PC.ROLE_LIUBANCHYK_SUPER_ADMIN]}>
      <Box px={12} cursor='pointer' onClick={(event: Object) => handleClickEditIcon(event, user)}>
        {I.threeDots()}
      </Box>
    </AuthWrapper>
  );

  const allChecked = G.isAllChecked(itemList);

  const getPermissions = (props: Object) => G.ifElse(
    R.propEq(GC.USER_TYPE_DRIVER, GC.FIELD_USER_TYPE, props),
    [],
    [PC.USER_WRITE, PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
  );

  const actionButtons = [
    {
      iconName: 'pencil',
      action: handleEdit,
      permissions: [
        PC.USER_WRITE,
        PC.ROLE_LIUBANCHYK_SUPER_ADMIN,
      ],
    },
    {
      getPermissions,
      iconName: 'trash',
      action: handleRemove,
    },
  ];

  const data = {
    loading,
    itemList,
    allChecked,
    pagination,
    totalCount,
    actionButtons,
    columnSettings,
    titleSortValues,
    tableTitleFilters,
    hasSelectable: true,
    handleTableTitleFilter,
    report: selectedReport,
    onOptionClick: selectItem,
    useSearchableColumns: true,
    withResizableColumns: true,
    useNewTitleFilterInputs: true,
    renderRightStickedComponent: (user: Object) => renderRowActions(user, handleClickEditIcon),
    filterProps: R.indexBy(
      R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(FILTER_PARAMS),
    ),
    handleLoadMoreEntities: G.ifElse(
      loading,
      () => {},
      getItemListRequest,
    ),
    tableSettings: G.getTableSettingsWithMaxHeightByConditions({
      reportList,
      filterParams,
      tableSettings,
      selectedReport,
    }),
  };

  return <Table {...data} />;
};

const getListActions = (handleAddReference: Function, handleClickAssignRevokeRole: Function) => [
  {
    type: 'massAction',
    permissions: [PC.USER_WRITE],
    action: () => handleClickAssignRevokeRole('assign'),
    text: G.getWindowLocale('actions:assign-role', 'Assign Role'),
    icon: I.assignRole(G.getTheme('colors.light.mainLight'), 20, 20),
  },
  {
    type: 'massAction',
    permissions: [PC.USER_WRITE],
    action: () => handleClickAssignRevokeRole('revoke'),
    text: G.getWindowLocale('actions:revoke-role', 'Revoke Role'),
    icon: I.minusInRound(G.getTheme('colors.light.mainLight'), 25, 25),
  },
  {
    type: 'massAction',
    action: handleAddReference,
    permissions: [PC.USER_REFERENCES_WRITE],
    icon: I.plusRound(G.getTheme('colors.light.mainLight'), 25, 25),
    text: G.getWindowLocale('actions:add-reference', 'Add Reference'),
  },
];

const UserComponent = (props: Object) => {
  const {
    selectedList,
    handleAddReference,
    getItemListRequest,
    handleClickCreateUser,
    handleClickAssignRevokeRole,
  } = props;

  return (
    <ListWrapper p={15} bgColor={G.getTheme('pages.layOutBgColor')}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          withCount={true}
          type={GC.USER_REPORT}
          popperWithCount={true}
          filterProps={FILTER_PARAMS}
          hiddenRightFilterInfo={false}
          reportOptions={props.reportsList}
          getItemListRequest={getItemListRequest}
          title={G.getWindowLocale('titles:user', 'User')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        {renderTable(props)}
      </ZOrderWrapper>
      <AuthWrapper has={[PC.USER_WRITE]}>
        <PageActions
          count={R.length(selectedList)}
          shadowColor={G.getTheme('colors.light.grey')}
          listActions={getListActions(handleAddReference, handleClickAssignRevokeRole)}
          mainAction={{
            action: handleClickCreateUser,
            text: G.getWindowLocale('actions:create-new-user', 'Create New User'),
          }}
        />
      </AuthWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  userList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  pageVisited: makeSelectPageVisited(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  reportList: makeSelectAvailableReports(state),
  requestPending: makeSelectReportStatus(state),
  selectedList: makeSelectSelectedUserList(state),
  currentUserGuid: makeSelectCurrentUserGuid(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
  rolesAvailableList: makeSelectRoleAvailableList(state),
  refList: makeSelectAvailableReferenceTypesByScope(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  setReports,
  selectItem,
  closeModal,
  setUsedReport,
  setInitialState,
  moveUserRequest,
  cleanQuickFilter,
  deleteItemRequest,
  setTableTitleSort,
  assignRolesRequest,
  getItemListRequest,
  revokeRolesRequest,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  createNewUserRequest,
  setQuickFilterParams,
  resetListAndPagination,
  exportReportDataRequest,
  changeDefaultReportRequest,
  createUsersReferenceRequest,
})(enhance(UserComponent));
