import React from 'react';
import * as R from 'ramda';
// feature template/route
import { ScheduleRoutesTab } from './schedule-routes-tab';
import { ScheduleRecurringTab } from './schedule-recurring-tab';
//////////////////////////////////////////////////

const ScheduleActiveTab = (props: Object) => {
  const { activeTab } = props;

  if (R.equals(activeTab, 0)) {
    return <ScheduleRoutesTab {...props} />;
  }

  // TODO: check if we have to push this
  return <ScheduleRecurringTab {...props} />;
};

export default ScheduleActiveTab;
