import * as R from 'ramda';
import { css } from 'styled-components';
import { components } from 'react-select';
import { OuterClick } from 'react-outer-click';
import React, { useRef, useState, useMemo, useEffect, useCallback } from 'react';
// components
import { TextComponent } from '../../../../components/text';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { ReactSelect } from '../../../../ui';
// feature task-management
import { getEntityListByEntityTypeRequest } from '../../helpers';
//////////////////////////////////////////////////

const ValueContainer = (props: Object) => {
  const { children, getValue } = props;

  const input = R.last(children);
  const inputValue = R.path(['props', GC.FIELD_VALUE], input);
  const text = R.join(', ', R.map(R.prop(GC.FIELD_LABEL), getValue()));

  return (
    <components.ValueContainer {...props}>
      {
        G.isNilOrEmpty(inputValue) &&
        <TextComponent
          pl='5px'
          title={text}
          fontSize={12}
          maxWidth={170}
          display='block'
          position='absolute'
          withEllipsis={true}
        >
          {text}
        </TextComponent>
      }
      {G.isNilOrEmpty(text) && R.head(children)}
      {input}
    </components.ValueContainer>
  );
};

const AssigneeFilter = (props: Object) => {
  const {
    entities,
    openLoader,
    closeLoader,
    additionalFilterValue,
    handleSetAdditionalFilter,
    setEntityListByEntityTypeSuccess,
  } = props;

  const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);

  const ref = useRef();

  const assigneeOptions = R.map((item: Object) => {
    const { fullText } = G.getUserInfo(item);

    const value = G.getGuidFromObject(item);

    return { value, label: fullText };
  }, R.propOr([], GC.FIELD_ASSIGNEE, entities));

  const defaultValue = useMemo(() => R.map(
    (item: string) => R.find(R.propEq(item, GC.FIELD_VALUE), assigneeOptions),
    R.or(additionalFilterValue, []),
  ), [assigneeOptions, additionalFilterValue]);

  const handleCloseMenu = (event: Object) => {
    if (R.includes('task_board_item', event.target.classList)) {
      ref.current.blur();
    }
  };

  const handleChange = useCallback((value: Object, { action }: Object) => {
    let newOptions = assigneeOptions;

    if (G.isNotNilAndNotEmpty(value)) {
      newOptions = R.compose(
        R.uniqBy(R.prop(GC.FIELD_VALUE)),
        R.concat(value),
      )(assigneeOptions);
    }

    setValue(value);
    setOptions(newOptions);

    if (R.equals(action, 'clear')) handleSetAdditionalFilter([]);
  }, [assigneeOptions]);

  useEffect(() => {
    getEntityListByEntityTypeRequest({
      entities,
      openLoader,
      closeLoader,
      setEntities: () => {},
      entityType: GC.FIELD_ASSIGNEE,
      setEntityListByEntityTypeSuccess,
    });
  }, []);

  useEffect(() => {
    setOptions(assigneeOptions);
  }, [R.prop(GC.FIELD_ASSIGNEE, entities)]);

  if (G.isNilOrEmpty(assigneeOptions)) return null;

  return (
    <OuterClick
      onOuterClick={handleCloseMenu}
      css={css`
        order: 3;
        width: 220px;
        margin: 0 20px;
      `}
    >
      <ReactSelect
        height={28}
        innerRef={ref}
        isMulti={true}
        options={options}
        isClearable={true}
        onChange={handleChange}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        defaultValue={defaultValue}
        components={{ ValueContainer }}
        placeholder={G.getWindowLocale('titles:filter-by-assignee', 'Filter by Assignee')}
        onMenuClose={() => handleSetAdditionalFilter(R.map(R.prop(GC.FIELD_VALUE), value))}
      />
    </OuterClick>
  );
};

export default AssigneeFilter;
