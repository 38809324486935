import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { PhotoshopPicker } from 'react-color';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  lifecycle,
  withState,
  withHandlers,
  renderNothing,
  withPropsOnChange,
} from 'react-recompose';
// components
import { FormFooter2, FormGroupTitleComponent } from '../../../components';
// features
import { makeSelectCurrentUserName } from '../../auth/selectors';
import { makeSelectCurrentBranch } from '../../branch/selectors';
import { makeSelectInitialDataLoadedStatus } from '../../permission/selectors';
// helpers/constants
import * as G from '../../../helpers';
// forms
import { Checkbox } from '../../../forms';
// ui
import { Box, Flex, RelativeFlex, AbsoluteBox } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature styling
import { makeSelectStylingConfigs } from '../selectors';
import { setStylingRequest, updateStylingRequest } from '../actions';
import { StylingImage, DropzoneAction, DropzoneWrapper, ColorPickerWrapper } from '../ui';
//////////////////////////////////////////////////

export const enhance = compose(
  withState('stylingState', 'setStylingState', {}),
  withHandlers({
    handleClickSave: (props: Object) => () => {
      const { stylingState, currentBranch, stylingSettings, setStylingRequest, updateStylingRequest } = props;

      const withoutChanges = R.and(
        G.isNotNilAndNotEmpty(stylingSettings),
        R.equals(
          stylingState,
          R.mergeRight(stylingState, stylingSettings),
        ),
      );

      if (withoutChanges) return;

      G.ifElse(
        R.equals(G.getBranchGuidFromObject(stylingSettings), G.getGuidFromObject(currentBranch)),
        updateStylingRequest,
        setStylingRequest,
      )(stylingState);
    },
    handleSetStylingProp: ({ stylingState, setStylingState }: Object) => (prop: string, value: any) =>
      setStylingState(R.assoc(prop, value, stylingState)),
    handleCleanStylingLogoProps: ({ stylingState, setStylingState }: Object) => () =>
      setStylingState(R.mergeRight(
        stylingState,
        { logo: null, logoUrl: null, logoFile: null },
      )),
    handleSetImage: ({ stylingState, setStylingState }: Object) => (event: Object) => {
      const logoFile = R.head(event);

      const imageType = /image.*/;

      if (logoFile.type.match(imageType)) {
        const reader = new window.FileReader();

        reader.onload = () => (
          setStylingState({
            ...stylingState,
            logoFile,
            logoUrl: G.getPropFromObject('result', reader),
          })
        );

        reader.readAsDataURL(logoFile);
      }
    },
    handleClickCancel: ({ history }: Object) => () => history.goBack(),
  }),
  lifecycle({
    componentDidMount() {
      const { setStylingState, stylingSettings, currentBranch } = this.props;

      if (R.equals(G.getBranchGuidFromObject(stylingSettings), G.getGuidFromObject(currentBranch))) {
        setStylingState(stylingSettings);
      }
    },
  }),
  withPropsOnChange(['stylingSettings'], ({ stylingSettings, setStylingState }: Object) =>
    setStylingState((prev: Object) => R.mergeRight(prev, stylingSettings)),
  ),
  branch(
    ({ initialDataLoaded }: Object) => R.not(initialDataLoaded),
    renderNothing,
  ),
  pure,
);

export const DropzoneActions = ({ handleSetImage, handleCleanStylingLogoProps }: Object) => (
  <AbsoluteBox top={-15} zIndex={11} right={-10}>
    <DropzoneAction>
      <Dropzone className='dropzone' onDrop={handleSetImage}>
        {({ getRootProps, getInputProps }: Object) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <img alt='edit' src={G.composeImgLink('dark', 'edit')} />
          </div>
        )}
      </Dropzone>
    </DropzoneAction>
    <DropzoneAction ml={10} onClick={handleCleanStylingLogoProps}>
      <img alt='clean' src={G.composeImgLink('light', 'trash')} />
    </DropzoneAction>
  </AbsoluteBox>
);

export const DropzoneSection = (props: Object) => {
  const { stylingState, handleSetImage, handleCleanStylingLogoProps } = props;

  const { logoUrl } = stylingState;

  return (
    <DropzoneWrapper mt={15} withLogo={G.isNotNil(logoUrl)}>
      <Dropzone className='drop-zone' onDrop={handleSetImage}>
        {({ getRootProps, getInputProps }: Object) => (
          <AbsoluteBox
            {...getRootProps()}
            width='100%'
            height='100%'
            justifyContent='center'
            opacity={G.ifElse(G.isNotNilAndNotEmpty(logoUrl), 0)}
          >
            <input {...getInputProps()} />
            <span>{G.getWindowLocale('actions:upload', 'Upload')}</span>
          </AbsoluteBox>
        )}
      </Dropzone>
      {
        G.isNotNilAndNotEmpty(logoUrl)
        && (
          <Flex
            width='100%'
            height='100%'
            justifyContent='center'
          >
            <StylingImage size='90%' bgImage={logoUrl} />
          </Flex>
        )
      }
      {
        G.isNotNil(logoUrl) &&
        <DropzoneActions handleSetImage={handleSetImage} handleCleanStylingLogoProps={handleCleanStylingLogoProps} />
      }
    </DropzoneWrapper>
  );
};

export const SelectLogoPanel = (props: Object) => {
  const { stylingState, handleSetImage, handleSetStylingProp, handleCleanStylingLogoProps } = props;

  const { copyDown } = stylingState;

  return (
    <Box width={360} p='15px 15px 0px'>
      <RelativeFlex ml={10}>
        {G.getWindowLocale('titles:logo', 'Logo')}
      </RelativeFlex>
      <Flex>
        <DropzoneSection
          stylingState={stylingState}
          handleSetImage={handleSetImage}
          handleCleanStylingLogoProps={handleCleanStylingLogoProps}
        />
        <RelativeFlex mx={20}>
          {G.getWindowLocale('titles:copy-down', 'Copy Down')}
          <Checkbox
            ml={10}
            type='checkbox'
            checked={copyDown}
            onClick={({ currentTarget }: Object) => (
              handleSetStylingProp('copyDown', G.getPropFromObject('checked', currentTarget))
            )}
          />
        </RelativeFlex>
      </Flex>
    </Box>
  );
};

export const SelectBgColor = ({ title, propName, stylingState, handleSetStylingProp }: Object) => (
  <Box pt={15}>
    <ColorPickerWrapper colorToShow={R.pathOr(G.getTheme('colors.light.mainLight'), [propName], stylingState)}>
      <Box width='max-content'>
        <RelativeFlex ml={25}>
          {title}
        </RelativeFlex>
      </Box>
      <PhotoshopPicker
        onChangeComplete={({ hex }: Object) => handleSetStylingProp(propName, hex)}
        color={R.pathOr(G.getTheme('colors.light.mainLight'), [propName], stylingState)}
      />
    </ColorPickerWrapper>
  </Box>
);

export const StylingSectionComponent = (props: Object) => {
  const {
    stylingState,
    handleSetImage,
    handleClickSave,
    handleClickCancel,
    handleSetStylingProp,
    handleCleanStylingLogoProps,
  } = props;

  const selectLogoProps = { stylingState, handleSetImage, handleSetStylingProp, handleCleanStylingLogoProps };

  const { glassEffect } = stylingState;

  return (
    <Box>
      <FormGroupTitleComponent
        mb='0px'
        text={G.getWindowLocale('titles:styling-settings', 'Styling Settings')}
      />
      <Flex
        p={15}
        pt='0px'
        flexWrap='wrap'
        maxWidth={1150}
        alignItems='flex-start'
        justifyContent='flex-start'
      >
        <SelectLogoPanel {...selectLogoProps} />
        <SelectBgColor
          propName='logoBgColor'
          stylingState={stylingState}
          handleSetStylingProp={handleSetStylingProp}
          title={G.getWindowLocale('titles:logo-background-color', 'Logo Background Color')}
        />
        <RelativeFlex>
          <SelectBgColor
            propName='backgroundColor'
            stylingState={stylingState}
            handleSetStylingProp={handleSetStylingProp}
            title={G.getWindowLocale('titles:background-color', 'Background Color')}
          />
          <AbsoluteBox right={35} bottom={30}>
            {G.getWindowLocale('titles:glass-effect', 'Glass Effect')}
            <Checkbox
              ml={10}
              type='checkbox'
              checked={glassEffect}
              onClick={({ currentTarget }: Object) => (
                handleSetStylingProp('glassEffect', G.getPropFromObject('checked', currentTarget))
              )}
            />
          </AbsoluteBox>
        </RelativeFlex>
        <SelectBgColor
          propName='textColor'
          stylingState={stylingState}
          handleSetStylingProp={handleSetStylingProp}
          title={G.getWindowLocale('titles:text-color', 'Text Color')}
        />
      </Flex>
      <FormFooter2
        submitAction={handleClickSave}
        cancelAction={handleClickCancel}
        boxStyles={{
          pr: 20,
          pl: 70,
          left: 0,
          bottom: 0,
          zIndex: 12,
          height: 60,
          width: '100%',
          position: 'fixed',
          borderTop: '1px solid',
          bg: G.getTheme('colors.white'),
          borderColor: G.getTheme('forms.actionsFooter.borderTopColor'),
        }}
      />
    </Box>
  );
};

const mapStateToProps = (state: Object) => (createStructuredSelector({
  userName: makeSelectCurrentUserName(state),
  currentBranch: makeSelectCurrentBranch(state),
  stylingSettings: makeSelectStylingConfigs(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
}));

export default connect(mapStateToProps, {
  setStylingRequest,
  updateStylingRequest,
})(enhance(StylingSectionComponent));
