import * as R from 'ramda';
import React, { memo, Fragment } from 'react';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, IconWrapper, SectionsDivider, scrollableContainerCss4px } from '../../../ui';
// feature work-order
import { getInvoiceServiceIssues } from '../helpers';
//////////////////////////////////////////////////

const darkGreyColor = G.getTheme('colors.#7D828C');
const lightGreyColor = G.getTheme('colors.lightGrey');

const RowActions = ({ handleEditInvoice, handleRemoveInvoice }: Object) => (
  <Flex width={35} justifyContent='space-between'>
    <IconWrapper cursor='pointer' onClick={handleEditInvoice}>
      {I.pencil(darkGreyColor)}
    </IconWrapper>
    <IconWrapper cursor='pointer' onClick={handleRemoveInvoice}>
      {I.trash(darkGreyColor)}
    </IconWrapper>
  </Flex>
);

const InvoiceInfoPair = ({ text, maxWidth }: Object) => (
  <TextComponent
    mr={10}
    title={text}
    display='block'
    withEllipsis={true}
    color={darkGreyColor}
    maxWidth={R.or(maxWidth, 170)}
  >
    {text}
  </TextComponent>
);

const TotalRow = ({ costs, titleLocaleArr, currencySymbol }: Object) => {
  const total = G.calculateTotal(costs);
  const title = `${currencySymbol} ${total}`;

  return (
    <Flex my='5px' color={darkGreyColor}>
      <Box mr='5px' width={60}>{G.getWindowLocale(...titleLocaleArr)}:</Box>
      <TextComponent
        title={title}
        maxWidth={300}
        display='block'
        withEllipsis={true}
      >
        {title}
      </TextComponent>
    </Flex>
  );
};

const typeTitlesMap = {
  [GC.INVOICE_TYPE_ESTIMATE]: ['titles:estimate', 'Estimate'],
  [GC.INVOICE_TYPE_COST]: ['titles:cost-invoice', 'Cost / Invoice'],
};

export const InvoiceInfo = memo((props: Object) => {
  const { invoice, serviceIssueList, handleRemoveInvoice, handleAddOrEditInvoice } = props;

  const {
    guid,
    type,
    fees,
    parts,
    total,
    labors,
    currency,
    invoiceDate,
    estimateDate,
    invoiceNumber,
    estimateNumber,
  } = invoice;

  const currencySymbol = G.getCurrencySymbol(currency);

  const isCostType = R.equals(type, GC.INVOICE_TYPE_COST);
  const date = G.ifElse(isCostType, invoiceDate, estimateDate);
  const number = G.ifElse(isCostType, invoiceNumber, estimateNumber);
  const serviceIssues = getInvoiceServiceIssues(guid, serviceIssueList, false);

  const invoiceNumberText = `#: ${number}`;
  const invoiceDateText = `${G.getWindowLocale('titles:date', 'Date')}: ${date}`;
  const invoiceTotalText = `${G.getWindowLocale('titles:total', 'Total')}: ${currencySymbol} ${total}`;
  const issueIds = G.transformArrayOfObjectsToStringByPropName(serviceIssues, ', ', GC.FIELD_ISSUE_ID);

  return (
    <Box
      mb={10}
      width='100%'
      flexShrink={0}
      borderRadius='6px'
      background={G.getTheme('colors.white')}
    >
      <Flex
        px={15}
        height={32}
        width='100%'
        color={darkGreyColor}
        borderTopLeftRadius='6px'
        borderTopRightRadius='6px'
        justifyContent='space-between'
        bg={G.getTheme('colors.borderGray')}
      >
        <Flex>
          <Box mr='5px'>
            {G.getWindowLocale(...G.getPropFromObject(type, typeTitlesMap))}
          </Box>
          {G.isNotNilAndNotEmpty(number) && <InvoiceInfoPair text={invoiceNumberText} />}
          {G.isNotNilAndNotEmpty(date) && <InvoiceInfoPair maxWidth={120} text={invoiceDateText} />}
        </Flex>
        <RowActions
          handleRemoveInvoice={() => handleRemoveInvoice(guid)}
          handleEditInvoice={() => handleAddOrEditInvoice(invoice)}
        />
      </Flex>
      <Box px={15}>
        <TotalRow
          costs={labors}
          currencySymbol={currencySymbol}
          titleLocaleArr={['titles:labor', 'Labor']}
        />
        <TotalRow
          costs={parts}
          currencySymbol={currencySymbol}
          titleLocaleArr={['titles:parts', 'Parts']}
        />
        <TotalRow
          costs={fees}
          currencySymbol={currencySymbol}
          titleLocaleArr={['titles:fees', 'Fees']}
        />
      </Box>
      {
        G.isNotNilAndNotEmpty(serviceIssues) &&
        <Fragment>
          <SectionsDivider borderColor={lightGreyColor} />
          <Flex px={15} my='8px' color={darkGreyColor} alignItems='flex-start'>
            <Box mr='5px' width={60} flexShrink={0} color={G.getTheme('colors.light.mainRed')}>
              {G.getWindowLocale('titles:issues', 'Issues')}:
            </Box>
            <Box maxHeight={100} overflowY='auto' css={scrollableContainerCss4px}>{issueIds}</Box>
          </Flex>
        </Fragment>
      }
      <SectionsDivider borderColor={lightGreyColor} />
      <Flex
        p='8px 15px'
        fontWeight={700}
        textTransform='uppercase'
        justifyContent='flex-end'
        color={G.getTheme('colors.light.blue')}
      >
        {invoiceTotalText}
      </Flex>
    </Box>
  );
});
