import * as R from 'ramda';
import moment from 'moment';
// components
import { reportConditions } from '../../components/filter/settings';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

export const operationName = (operations: Array = [], { timeUnit, operation }: Object) => {
  const name = operations.find(({ value }: Object) => (
    R.equals(value, R.or(operation, timeUnit))
  ));

  return G.getOrElse(name, 'name', '');
};

export const setFilterInitialValues = (
  fields: Array = [],
  prefix: string,
  availableFields: Array = [],
) => {
  const params = {};

  fields.forEach((field: Object, i: number) => {
    const { to, from, next, last, dataType, propertyName } = field;

    const param = availableFields.find(({ value }: Object) => R.or(
      R.and(
        value.includes('.'),
        value.includes(
          G.ifElse(
            R.equals(dataType, GC.FIELD_REFERENCE),
            field[GC.FIELD_REFERENCE_NAME],
            propertyName,
          ),
        ),
      ),
      R.equals(
        value,
        G.ifElse(
          R.equals(dataType, GC.FIELD_REFERENCE),
          field[GC.FIELD_REFERENCE_NAME],
          propertyName,
        ),
      )),
    );

    const paramName = `${prefix}-${R.inc(i)}`;
    const paramField = `field-${R.inc(i)}`;
    const paramOperation = `operation-${R.inc(i)}`;
    const paramValue = `value-${R.inc(i)}`;

    params[paramName] = {
      [paramField]: G.ifElse(
        R.isEmpty(field[`${
          G.ifElse(
            R.equals(dataType, GC.FIELD_REFERENCE),
            GC.FIELD_REFERENCE,
            'property',
          )
          }Name`]),
        '',
        G.getOrElse(param, 'name', '')),
      [paramOperation]: operationName(reportConditions()[dataType], field),
      [paramValue]: field[`${dataType}Value`],
    };

    if (R.equals(dataType, 'date')) {
      const valueFrom = `value-from-${R.inc(i)}`;
      const valueTo = `value-to-${R.inc(i)}`;
      const valueLast = `value-last-${R.inc(i)}`;
      const valueNext = `value-next-${R.inc(i)}`;

      params[paramName] = R.mergeRight(
        params[paramName],
        {
          [valueLast]: last,
          [valueNext]: next,
          [valueFrom]: G.ifElse(
            G.isNotNilAndNotEmpty(from),
            moment(from).format(GC.DEFAULT_DATE_TIME_FORMAT),
            '',
          ),
          [valueTo]: G.ifElse(
            G.isNotNilAndNotEmpty(to),
            moment(to).format(GC.DEFAULT_DATE_TIME_FORMAT),
            '',
          ),
        },
      );
    }
  });

  return params;
};

export const renderReportBorderColor = ({ error, touched }: Object) => {
  if (R.and(error, touched)) {
    return G.getTheme('forms.inputs.borderColorErr');
  }

  return G.getTheme('colors.dark.grey');
};
