import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { PageTabs } from '../../../components/page-tabs';
import { PageTitle } from '../../../components/page-title';
import { makeSelectLoader } from '../../../components/loader/selectors';
import { openModal, closeModal } from '../../../components/modal/actions';
// features
import Audit2 from '../../audit2';
import { makeSelectDocumentTypes, makeSelectAvailableDocumentTypes } from '../../configurations/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { PageWrapper, RelativeBox, PageHeaderWrapper } from '../../../ui';
// feature role
import { withEditUserPermissions } from '../hocs';
import UserPermissionsComponent from './user-permissions';
import {
  makeSelectRoleList,
  makeSelectFormStore,
  makeSelectEditRoleInitialValues,
  makeSelectRolePermissionsGroupsList,
} from '../selectors';
import {
  updateRoleRequest,
  addNewRoleRequest,
  resetUserPermissions,
  handleToggleGroupRequest,
  getRoleSelectedDocumentTypes,
  toggleGroupPermissionsRequest,
  toggleSinglePermissionRequest,
  getUserPermissionsByRoleTypeRequest,
} from '../actions';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => (createStructuredSelector({
  loader: makeSelectLoader(state),
  rolesList: makeSelectRoleList(state),
  formStore: makeSelectFormStore(state),
  documentTypes: makeSelectDocumentTypes(state),
  initialValues: makeSelectEditRoleInitialValues(state),
  availableDocumentTypes: makeSelectAvailableDocumentTypes(state),
  permissionsGroupsList: makeSelectRolePermissionsGroupsList(state),
}));

const tabs = (activeTab: string, setActiveTab: Function) => ([
  {
    action: () => setActiveTab('general'),
    label: G.getWindowLocale('titles:detail-page', 'Detail Page'),
    frontIcon: I.searchDocument(G.getThemeByCond(
      R.equals(activeTab, 'general'),
      'icons.iconColor',
      'icons.iconColorGrey',
    )),
  },
]);

const AuditTab = ({ roleName, requestOptions }: Object) => (
  <div>
    <PageHeaderWrapper>
      <PageTitle title={`${G.getWindowLocale('titles:audit', 'Audit')}: ${roleName}`} />
    </PageHeaderWrapper>
    <Audit2 useFullScreen={true} requestOptions={requestOptions} />
  </div>
);

const renderTab = (props: Object) => {
  const { activeTab, initialValues } = props;

  const requestOptions = {
    [GC.FIELD_TYPE]: GC.AUDIT_TYPE_ROLE,
    [GC.FIELD_OBJECT_GUID]: G.getGuidFromObject(initialValues),
  };

  const tabs = {
    audit: <AuditTab roleName={R.prop(GC.FIELD_NAME, initialValues)} requestOptions={requestOptions} />,
    general: <UserPermissionsComponent {...props} editMode={true} title={G.getWindowLocale('titles:edit', 'Edit')} />,
  };

  return tabs[activeTab];
};

const EditRoleComponent = (props: Object) => (
  <PageWrapper>
    <RelativeBox>
      <PageTabs
        zI={14}
        height={70}
        withAudit={true}
        isLabelExist={true}
        activeTab={props.activeTab}
        setActiveTab={props.setActiveTab}
        tabs={tabs(props.activeTab, props.setActiveTab)}
      />
    </RelativeBox>
    {renderTab(props)}
  </PageWrapper>
);

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  updateRoleRequest,
  addNewRoleRequest,
  resetUserPermissions,
  handleToggleGroupRequest,
  getRoleSelectedDocumentTypes,
  toggleGroupPermissionsRequest,
  toggleSinglePermissionRequest,
  getUserPermissionsByRoleTypeRequest,
})(withEditUserPermissions(EditRoleComponent));
