import * as R from 'ramda';
import { space } from 'styled-system';
import styled from 'styled-components';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const ColorPickerWrapper = styled.div`
  & .photoshop-picker {
    width: 360px !important;
    background: none !important;
    box-shadow: none !important;
    & > div {
      display: none;
    }

    & > .flexbox-fix > div:first-child {
      border-color: #979797 !important;
      border-radius: 2px;
      border-width: 1px !important;
      height: 150px !important;
      width: 150px !important;
    }

    & > .flexbox-fix > div:nth-child(2) {
      border-radius: 2px;
      margin: 0 !important;
      width: 20px !important;
      height: 150px !important;
      border-width: 1px !important;
      margin-left: 20px !important;
      border-color: #979797 !important;
    }

    & > .flexbox-fix > div:last-child {
      display: flex;
      align-items: center;
      margin: 0 !important;
      width: 110px !important;
      margin-left: 20px !important;
      & > div > div:nth-child(2) {
        margin: 0 !important;
      }
    }

    & .flexbox-fix .flexbox-fix:first-child {
      & > div:first-child {
        display: none;
      }

      & > div:last-child {
        & div {
          display: none;
        }

        & > div:last-child {
          display: block;
          padding: 0 !important;

          & > div:nth-child(9) {
            display: block;
            &:after {
              left: 0;
              top: -40px;
              width: 30px;
              content: '';
              height: 30px;
              border-radius: 5px;
              position: absolute;
              border: 1px solid #353535;
              background-color: ${({ colorToShow }: Object) => colorToShow};
            }
          }
        }
      }
    }
  }
`;

export const DropzoneWrapper = styled.div`
  ${space}

  border: 1px;
  width: 150px;
  height: 150px;
  display: flex;
  border-radius: 2px;
  position: relative;
  align-items: center;
  justify-content: center;

  border-color: ${G.getTheme('colors.light.darkGrey')};
  background-image: url(${({ bgImage }: Object) => bgImage});
  border-style: ${({ withLogo }: Object) => G.ifElse(R.equals(withLogo, true), 'solid', 'dashed')};

  & .drop-zone {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 11;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    position: absolute !important;
    & > p {
      display: ${({ withLogo }: Object) => G.ifElse(R.equals(withLogo, true), 'none', 'block')};
    }
  }
`;

export const DropzoneAction = styled.div`
  ${space}

  width: 30px;
  height: 30px;
  display: flex;
  border: 1px solid;
  border-radius: 50%;
  align-items: center;
  background-color: white;
  justify-content: center;

  border-color: ${G.getTheme('colors.light.darkGrey')};

  & .dropzone {
    position: static !important;
  }
`;

export const StylingPreviewWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;

  justify-content: ${({ jc }: Object) => jc};
  height: ${({ height }: Object) => height || '100px'};
  background-color: ${({ bgColor }: Object) => bgColor};
  background-image: url(${({ backgroundImage }: Object) => backgroundImage});
  background-size: ${({ bs }: Object) => bs};

  & p {
    margin: 15px;
  }
`;

export const StylingImage = styled.div`
  background-repeat: no-repeat;
  background-position: center center;

  width: ${({ size, width }: Object) => width || size};
  height: ${({ size, height }: Object) => height || size};
  background-size: ${({ bs }: Object) => bs || 'contain'};
  background-image: url(${({ bgImage }: Object) => bgImage});
`;
