import * as R from 'ramda';
import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const store = (state: Object) => state.templateReport;

const makeSelectUsedReport = () => createSelector(
  store,
  ({ usedReport }: Object) => usedReport,
);

const makeSelectAvailableReports = () => createSelector(
  store,
  ({ availableReports }: Object) => availableReports,
);

const makeSelectGlobalFilterValue = () => createSelector(
  store,
  ({ globalFilterValue }: Object) => globalFilterValue,
);

const makeSelectPagination = () => createSelector(
  store,
  (templateReport: Object) => R.path([templateReport.listType, 'pagination'], templateReport),
);

const makeSelectFilterParams = () => createSelector(
  store,
  ({ filterParams }: Object) => filterParams,
);

const makeSelectReportType = () => createSelector(
  store,
  ({ reportType }: Object) => reportType,
);

const makeSelectItemList = () => createSelector(
  store,
  (templateReport: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
    R.pathOr([], [templateReport.listType, 'itemList']),
  )(templateReport),
);

const makeSelectTotalCount = () => createSelector(
  store,
  (templateReport: Object) => R.path([templateReport.listType, 'totalCount'], templateReport),
);

const makeSelectListLoading = () => createSelector(
  store,
  ({ loading }: Object) => loading,
);

const makeSelectReportStatus = () => createSelector(
  store,
  ({ reportPending }: Object) => reportPending,
);

const makeSelectSelectedList = () => createSelector(
  store,
  (templateReport: Object) => R.compose(
    R.keys,
    R.filter(({ selected }: Object) => selected),
    R.pathOr([], [templateReport.listType, 'itemList']),
  )(templateReport),
);

const makeSelectTableTitleFilters = () => createSelector(
  store,
  (templateReport: Object) => R.path([templateReport.listType, 'tableTitleFilters'], templateReport),
);

const makeSelectTitleSortValues = () => createSelector(
  store,
  (templateReport: Object) => R.path([templateReport.listType, 'titleSortValues'], templateReport),
);

const makeSelectListType = () => createSelector(
  store,
  ({ listType }: Object) => listType,
);

const makeSelectContainerTypes = () => createSelector(
  store,
  R.pathOr([], ['containerTypes']),
);

const makeSelectAccessorialConfigs = () => createSelector(
  store,
  ({ accessorialConfigs }: Object) => accessorialConfigs,
);

const makeSelectBranchConfigs = () => createSelector(
  store,
  ({ branchConfigs }: Object) => branchConfigs,
);

const makeSelectAdditionalFilters = () => createSelector(
  store,
  ({ additionalFilters }: Object) => additionalFilters,
);

export {
  makeSelectListType,
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectReportType,
  makeSelectListLoading,
  makeSelectFilterParams,
  makeSelectReportStatus,
  makeSelectSelectedList,
  makeSelectBranchConfigs,
  makeSelectContainerTypes,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
  makeSelectGlobalFilterValue,
  makeSelectAdditionalFilters,
  makeSelectAccessorialConfigs,
};
