import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// features
import { receivedSwitchBranchSuccess } from '../branch/actions';
// feature styling
import * as Actions from './actions';
//////////////////////////////////////////////////

export const initialState = {
  mainStylingSettings: {
    logo: null,
    textColor: null,
    backgroundColor: null,
  },
  stylingSettings: {
    logo: null,
    textColor: null,
    backgroundColor: null,
  },
  stylingConfigSettings: {
    logo: null,
    textColor: null,
    backgroundColor: null,
  },
  splashScreenSettings: {
    logo: null,
    backgroundImage: null,
  },
  splashScreenConfigSettings: {
    logo: null,
    backgroundImage: null,
  },
};

export const getStylingSuccess = (state: Object, data: Object) => (
  P.$all(
    P.$set('stylingSettings', data),
    P.$set('stylingConfigSettings', initialState.stylingConfigSettings),
    state,
  )
);

export const getInitialStylingSuccess = (state: Object, data: Object) => (
  P.$set('mainStylingSettings', data, state)
);

export const getStylingByBranchSuccess = (state: Object, data: Object) => (
  P.$set('stylingConfigSettings', data, state)
);

export const getSplashScreenSuccess = (state: Object, data: Object) => (
  P.$set('splashScreenSettings', data, state)
);

export const getSplashScreenByBranchSuccess = (state: Object, data: Object) => (
  P.$set('splashScreenConfigSettings', data, state)
);

export const clearSplashScreenConfigSettings = (state: Object) => (
  P.$set('splashScreenConfigSettings', initialState.splashScreenConfigSettings, state)
);

export const setStylingSuccess = (state: Object, data: Object) => (
  P.$all(
    P.$set('stylingSettings', data),
    P.$set('stylingConfigSettings', data),
    state,
  )
);

export const setSplashScreenSuccess = (state: Object, data: Object) => (
  P.$all(
    P.$set('splashScreenSettings', data),
    P.$set('splashScreenConfigSettings', data),
    state,
  )
);

export const updateStylingSuccess = (state: Object, data: Object) => (
  P.$all(
    P.$set('stylingSettings', data),
    P.$set('stylingConfigSettings', data),
    state,
  )
);

export const updateSplashScreenSettingsSuccess = (state: Object, data: Object) => (
  P.$all(
    P.$set('splashScreenSettings', data),
    P.$set('splashScreenConfigSettings', data),
    state,
  )
);

export default createReducer({
  [Actions.getStylingSuccess]: getStylingSuccess,
  [Actions.setStylingSuccess]: setStylingSuccess,
  [Actions.updateStylingSuccess]: updateStylingSuccess,
  [Actions.getSplashScreenSuccess]: getSplashScreenSuccess,
  [Actions.setSplashScreenSuccess]: setSplashScreenSuccess,
  [Actions.getInitialStylingSuccess]: getInitialStylingSuccess,
  [receivedSwitchBranchSuccess]: clearSplashScreenConfigSettings,
  [Actions.getStylingByBranchSuccess]: getStylingByBranchSuccess,
  [Actions.getSplashScreenByBranchSuccess]: getSplashScreenByBranchSuccess,
  [Actions.updateSplashScreenSettingsSuccess]: updateSplashScreenSettingsSuccess,
}, initialState);
