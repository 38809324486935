import { createLogic } from 'redux-logic';
import { clearRolesStateAfterSwitchBranch } from './actions';
// features
import { receivedSwitchBranchSuccess } from '../branch/actions';
//////////////////////////////////////////////////

export const handleSwitchBranchLogic = createLogic({
  type: receivedSwitchBranchSuccess,
  validate({ getState, action }: Object, allow: void) {
    allow(action);
  },
  process({ getState, action }: Object, dispatch: void, done: void) {
    dispatch(clearRolesStateAfterSwitchBranch());
    done();
  },
});

export default [
  handleSwitchBranchLogic,
];
