import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../../helpers';
// icons
import * as I from '../../../../../svgs';
// feature template-inspection
import * as C from '../../constants';
import {
  textConfig,
  dateConfig,
  timeConfig,
  meterConfig,
  photoConfig,
  numberConfig,
  radiosConfig,
  dropdownConfig,
  passFailConfig,
  checkboxesConfig,
} from './components-config';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.dark.blue');

const APS = R.prop('allComponentTypes', C);
const TUP = R.prop(C.INSPECTION_TRUCK, APS);
const TAP = R.prop(C.INSPECTION_TRAILER, APS);

const availableComponents = [
  C.INSPECTION_GENERAL,
  C.DIVIDER,
  C.INSPECTION_CHECKBOX,
  C.INSPECTION_PASS_FAIL,
  C.INSPECTION_RADIO,
  // TODO: uncomment after Mobile implementation
  // C.INSPECTION_DROPDOWN,
  // C.INSPECTION_TEXT,
  // C.INSPECTION_NUMBER,
  // C.INSPECTION_DATE,
  // C.INSPECTION_TIME,
  // C.INSPECTION_PHOTO,
  // C.INSPECTION_METER,
  C.DIVIDER,
  C.INSPECTION_TRUCK_FRONT,
  C.INSPECTION_TRUCK_BACK,
  C.INSPECTION_TRUCK_RIGHT,
  C.INSPECTION_TRUCK_LEFT,
  C.INSPECTION_TRAILER_FRONT,
  C.INSPECTION_TRAILER_BACK,
  C.INSPECTION_TRAILER_INTERIOR,
  C.INSPECTION_TRAILER_RIGHT,
  C.INSPECTION_TRAILER_LEFT,
];

const componentsSettings = {
  [C.INSPECTION_GENERAL]: {
    standalone: true,
    onlyForSection: false,
    icon: I.dispatchTemplate(iconColor, 16, 15),
    name: G.getWindowLocale('titles:inspection-section', 'Section'),
  },
  [C.INSPECTION_DATE]: {
    config: dateConfig,
    onlyForSection: true,
    icon: I.calendar(iconColor, 16, 15),
    name: G.getWindowLocale('titles:inspection-date', 'Date'),
  },
  [C.INSPECTION_TIME]: {
    config: timeConfig,
    onlyForSection: true,
    icon: I.clock(iconColor, 16, 15),
    name: G.getWindowLocale('titles:inspection-time', 'Time'),
  },
  [C.INSPECTION_TEXT]: {
    config: textConfig,
    onlyForSection: true,
    icon: I.comment(iconColor, 16, 15),
    name: G.getWindowLocale('titles:inspection-text', 'Text'),
  },
  [C.INSPECTION_NUMBER]: {
    config: numberConfig,
    onlyForSection: true,
    icon: I.symbol('#', iconColor, 16, 15),
    name: G.getWindowLocale('titles:inspection-number', 'Number'),
  },
  [C.INSPECTION_PASS_FAIL]: {
    onlyForSection: true,
    config: passFailConfig,
    icon: I.checkMarkInRound(iconColor, 16, 15),
    name: G.getWindowLocale('titles:inspection-pass-fail', 'Pass / Fail'),
  },
  [C.INSPECTION_PHOTO]: {
    config: photoConfig,
    onlyForSection: true,
    icon: I.eye2(iconColor, 16, 15),
    name: G.getWindowLocale('titles:inspection-photo', 'Photo'),
  },
  [C.INSPECTION_DROPDOWN]: {
    onlyForSection: true,
    config: dropdownConfig,
    icon: I.list1(iconColor, 16, 15),
    name: G.getWindowLocale('titles:inspection-dropdown', 'Dropdown'),
  },
  [C.INSPECTION_CHECKBOX]: {
    onlyForSection: true,
    config: checkboxesConfig,
    icon: I.checkMark(iconColor, 16, 15),
    name: G.getWindowLocale('titles:inspection-checkbox', 'Checkbox'),
  },
  [C.INSPECTION_RADIO]: {
    onlyForSection: true,
    config: radiosConfig,
    icon: I.radio(iconColor, 16, 15),
    name: G.getWindowLocale('titles:inspection-radio', 'Radio'),
  },
  [C.INSPECTION_METER]: {
    config: meterConfig,
    onlyForSection: true,
    icon: I.speedometer(iconColor, 16, 15),
    name: G.getWindowLocale('titles:inspection-meter', 'Meter'),
  },
  // Truck sections
  [C.INSPECTION_TRUCK_FRONT]: {
    standalone: true,
    onlyForSection: false,
    icon: I.truckLoad(iconColor, 16, 15),
    vehicleType: C.INSPECTION_TRUCK,
    activeVehicleComponentType: TUP.IDENTIFICATION_LIGHTS,
    name: G.getWindowLocale('titles:inspection-truck-front', 'Truck Front'),
  },
  [C.INSPECTION_TRUCK_BACK]: {
    standalone: true,
    onlyForSection: false,
    icon: I.truckLoad(iconColor, 16, 15),
    vehicleType: C.INSPECTION_TRUCK,
    activeVehicleComponentType: TUP.DRIVER_SIDE_REFLECTIVE_TAPE_CAB,
    name: G.getWindowLocale('titles:inspection-truck-back', 'Truck Back'),
  },
  [C.INSPECTION_TRUCK_RIGHT]: {
    standalone: true,
    onlyForSection: false,
    icon: I.truck(iconColor, 16, 15),
    vehicleType: C.INSPECTION_TRUCK,
    activeVehicleComponentType: TUP.PASSENGER_SIDE_PILLAR_A,
    name: G.getWindowLocale('titles:inspection-truck-right', 'Truck Right'),
  },
  [C.INSPECTION_TRUCK_LEFT]: {
    standalone: true,
    onlyForSection: false,
    icon: I.truck(iconColor, 16, 15),
    activeVehicleComponentType: TUP.ROOF,
    vehicleType: C.INSPECTION_TRUCK,
    name: G.getWindowLocale('titles:inspection-truck-left', 'Truck Left'),
  },
  // Trailer sections
  [C.INSPECTION_TRAILER_FRONT]: {
    standalone: true,
    onlyForSection: false,
    icon: I.trailer(iconColor, 16, 15),
    vehicleType: C.INSPECTION_TRAILER,
    activeVehicleComponentType: TAP.PASSENGER_SIDE_TOP_CORNER,
    name: G.getWindowLocale('titles:inspection-trailer-front', 'Trailer Front'),
  },
  [C.INSPECTION_TRAILER_BACK]: {
    standalone: true,
    onlyForSection: false,
    icon: I.trailer(iconColor, 16, 15),
    vehicleType: C.INSPECTION_TRAILER,
    activeVehicleComponentType: TAP.REAR_DRIVER_SIDE_CLEARANCE_LIGHT,
    name: G.getWindowLocale('titles:inspection-trailer-back', 'Trailer Back'),
  },
  [C.INSPECTION_TRAILER_INTERIOR]: {
    standalone: true,
    onlyForSection: false,
    icon: I.trailer(iconColor, 16, 15),
    vehicleType: C.INSPECTION_TRAILER,
    activeVehicleComponentType: TAP.INNER_ROOF,
    name: G.getWindowLocale('titles:inspection-trailer-interior', 'Trailer Interior'),
  },
  [C.INSPECTION_TRAILER_RIGHT]: {
    standalone: true,
    onlyForSection: false,
    icon: I.trailer(iconColor, 16, 15),
    vehicleType: C.INSPECTION_TRAILER,
    activeVehicleComponentType: TAP.REAR_PASSENGER_SIDE_PANEL,
    name: G.getWindowLocale('titles:inspection-trailer-right', 'Trailer Right'),
  },
  [C.INSPECTION_TRAILER_LEFT]: {
    standalone: true,
    onlyForSection: false,
    icon: I.trailer(iconColor, 16, 15),
    activeVehicleComponentType: TAP.ROOF,
    vehicleType: C.INSPECTION_TRAILER,
    name: G.getWindowLocale('titles:inspection-trailer-left', 'Trailer Left'),
  },
};

export {
  componentsSettings,
  availableComponents,
};
