import React from 'react';
import * as R from 'ramda';
import { withFormik, FieldArray, setNestedObjectValues } from 'formik';
import { pure, compose, lifecycle, withHandlers } from 'react-recompose';
// components
import { Tabs, withTabs } from '../../../../components/tabs';
import { FormFooter2 } from '../../../../components/form-footer';
import { LocalLoader } from '../../../../components/local-loader';
import { AddressBlock } from '../../../../components/address-block';
// features
import { Fieldset } from '../../../new-do/forms/formik/fieldset';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withAsyncRefTypes, withAsyncInitialDataOnDidMount } from '../../../../hocs';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex, StickedBox, RelativeFlex } from '../../../../ui';
// feature template/route
import {
  getNewRow,
  getCopyRow,
  getRouteFields,
  containerFields,
  getAllowedOptions,
  getReferenceFields,
  getValidationSchema,
  getNewReferenceFields,
} from '../settings/create-routes-settings';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.dark.blue');
const greyColor = G.getTheme('colors.dark.grey');
const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

const getTabs = (routes: Array, errors: Array, touched: Array) => G.mapIndexed(
  (route: Object, i: number) => ({
    text: `${G.getWindowLocale('titles:route', 'Route')} ${R.inc(i)}`,
    hasError: R.and(G.isNotNil(R.prop(i, errors)), G.isNotNil(R.prop(i, touched))),
  }),
  routes,
);

const Header = (props: Object) => (
  <StickedBox
    top='0px'
    p='10px 0'
    height={36}
    zIndex={100}
    display='block'
    bg={G.getTheme('modal.bgColor')}
  >
    <Flex>
      <Box mx='8px'>
        {G.getWindowLocale('actions:add-route', 'Add Route')}
      </Box>
      {
        R.lt(props.count, 25) &&
        <Box
          mx='5px'
          cursor='pointer'
          onClick={() => props.push(getNewRow(props.clos))}
        >
          {I.plusRound(blueColor)}
        </Box>
      }
    </Flex>
  </StickedBox>
);

const AddReference = ({ push }: Object) => (
  <Flex ml={25}>
    <Box mx='8px'>
      {G.getWindowLocale('titles:references', 'Reference')}
    </Box>
    <Box
      mx='5px'
      cursor='pointer'
      onClick={() => push(getNewReferenceFields())}
    >
      {I.plusRound(blueColor)}
    </Box>
  </Flex>
);

const ItemRow = (props: Object) => {
  const {
    remove,
    itemIndex,
    arrayLength,
    referenceTypeOptions,
  } = props;

  const zIndex = R.add(11, R.subtract(arrayLength, itemIndex));

  return (
    <RelativeFlex mt={25} zIndex={zIndex}>
      <Box>
        <Flex
          mx={10}
          display='flex'
          cursor='pointer'
          alignItems='center'
          onClick={() => remove(itemIndex)}
          title={G.getWindowLocale('titles:remove', 'Remove')}
        >
          {I.trash(blueColor)}
        </Flex>
      </Box>
      <Fieldset
        {...props}
        calcZIndex={true}
        fieldsetType='array'
        rowMapIndex={itemIndex}
        fields={getReferenceFields(props)}
        referenceTypes={referenceTypeOptions}
        allowedValues={getAllowedOptions(props)}
      />
    </RelativeFlex>
  );
};

const LoadInfo = ({ data, index, loadTitle }: Object) => {
  const events = R.pathOr([], [GC.FIELD_LOAD_STOPS], data);

  return (
    <Box width={300} color={greyMatterhornColor}>
      <Box mb='4px' fontSize={14} fontWeight='bold'>{loadTitle} {index}</Box>
      <Box mb='4px' fontSize={13} fontWeight='bold'>
        {G.getWindowLocale('titles:first-event', 'First Event')}:
      </Box>
      <AddressBlock width={300} location={R.pathOr({}, [GC.FIELD_LOCATION], R.head(events))} />
      <Box my='4px' fontSize={13} fontWeight='bold'>
        {G.getWindowLocale('titles:last-event', 'Last Event')}:
      </Box>
      <AddressBlock width={300} location={R.pathOr({}, [GC.FIELD_LOCATION], R.last(events))} />
    </Box>
  );
};

const getTels = ({ data }: Object) => {
  const { tels, events } = data;

  return R.map(
    (tel: Object) => R.assoc(
      GC.FIELD_LOAD_STOPS,
      R.filter(
        R.propEq(G.getGuidFromObject(tel), GC.FIELD_TEL_GUID),
        events,
      ),
      tel,
    ),
    tels,
  );
};

const RouteRow = (props: Object) => {
  const {
    clos,
    field,
    values,
    allowCopy,
    routeIndex,
    allowRemove,
    formikArrayProps,
    asyncInitialData,
    handleSetActiveTab,
    referenceTypeOptions,
  } = props;

  const copyText = G.getWindowLocale('titles:copy-route', 'Copy Route');
  const removeText = G.getWindowLocale('titles:remove-route', 'Remove Route');
  const closLength = R.length(clos);

  return (
    <Box>
      <Flex ml='8px' alignItems='end'>
        <Flex ml='5px' mr={20} alignItems='start' flexDirection='column'>
          {
            allowRemove &&
            <Flex
              mb='3px'
              cursor='pointer'
              color={blueColor}
              title={removeText}
              alignItems='center'
              onClick={() => {
                formikArrayProps.remove(routeIndex);
                handleSetActiveTab(0);
              }}
            >
              <Box mr='6px'>{I.trash(blueColor)}</Box>
              {removeText}
            </Flex>
          }
          {
            allowCopy &&
            <Flex
              cursor='pointer'
              title={copyText}
              color={blueColor}
              alignItems='center'
              onClick={() => formikArrayProps.push(getCopyRow(R.path(['routes', routeIndex], values)))}
            >
              <Box mr='6px'>{I.copy(blueColor)}</Box>
              {copyText}
            </Flex>
          }
        </Flex>
        <Fieldset
          {...props}
          labelFontSize={12}
          fields={getRouteFields(routeIndex)}
        />
      </Flex>
      {
        clos.map((clo: Object, cloIndex: number) => {
          const { guid, containers } = clo;

          return (
            <RelativeFlex
              py='8px'
              key={cloIndex}
              flexDirection='column'
              alignItems='flex-start'
              borderColor={greyColor}
              borderBottom='1px solid'
              zIndex={R.subtract(closLength, cloIndex)}
            >
              <FieldArray
                name={`routes.${routeIndex}.${guid}.${GC.FIELD_REFERENCES}`}
                render={(formikRefArrayProps: Object) => (
                  <Flex
                    py='8px'
                    zIndex={1}
                    alignItems='flex-start'
                  >
                    <LoadInfo
                      data={clo}
                      index={R.inc(cloIndex)}
                      loadTitle={G.getWindowLocale('titles:clo', 'Order')}
                    />
                    <Box minWidth={485}>
                      <AddReference {...props} push={formikRefArrayProps.push} />
                      <Box px={15} zIndex='0'>
                        {
                          R.gt(R.length(R.path([guid, GC.FIELD_REFERENCES], field)), 0) &&
                          R.path([guid, GC.FIELD_REFERENCES], field).map((item: string, j: number) => (
                            <ItemRow
                              {...props}
                              {...formikRefArrayProps}
                              key={j}
                              item={item}
                              itemIndex={j}
                              cloGuid={guid}
                              routeIndex={routeIndex}
                              referenceTypeOptions={referenceTypeOptions}
                              arrayName={`routes.${routeIndex}.${guid}.${GC.FIELD_REFERENCES}`}
                              arrayLength={R.length(R.path([guid, GC.FIELD_REFERENCES], field))}
                            />
                          ))
                        }
                      </Box>
                    </Box>
                  </Flex>
                )}
              />
              {
                G.isNotNilAndNotEmpty(containers) &&
                <FieldArray
                  key={`${routeIndex}${guid}`}
                  name={`routes.${routeIndex}.${guid}.${GC.FIELD_CONTAINERS}`}
                  render={(formikContainerArrayProps: Object) => (
                    <Box ml={20} zIndex='0' width='calc(100% - 20px)'>
                      <Box color={greyMatterhornColor} fontWeight='bold' textAlign='center'>
                        {G.getWindowLocale('titles:containers', 'Containers')}
                      </Box>
                      {
                        containers.map(({ weightUom, containerNumber, fullContainerWeight }: any, i: number) => (
                          <Flex pt={15} key={i}>
                            <Box mb={12} fontSize={12} color={greyMatterhornColor}>
                              {`${R.or(containerNumber, '')} ${R.or(fullContainerWeight, '')} ${R.or(weightUom, '')}`}
                            </Box>
                            <Fieldset
                              {...props}
                              {...formikContainerArrayProps}
                              itemIndex={i}
                              rowMapIndex={i}
                              calcZIndex={true}
                              fieldsetType='array'
                              fields={containerFields}
                              handleChangeInput={props.handleChangeInput}
                              fieldsWrapperStyles={{ px: 0, pb: 15, ml: 'auto' }}
                              arrayName={`routes.${routeIndex}.${guid}.${GC.FIELD_CONTAINERS}`}
                              arrayLength={R.length(R.path([guid, GC.FIELD_CONTAINERS], field))}
                            />
                          </Flex>
                        ))
                      }
                    </Box>
                  )}
                />
              }
            </RelativeFlex>
          );
        })
      }
      {
        G.isNilOrEmpty(clos) &&
        getTels(asyncInitialData).map((tel: Object, i: number) => (
          <Flex
            key={i}
            pt={16}
            alignItems='flex-start'
          >
            <LoadInfo
              data={tel}
              index={R.inc(i)}
              loadTitle={G.getWindowLocale('titles:tel', 'Trip')}
            />
          </Flex>
        ))
      }
    </Box>
  );
};

// NOTE: check commonSubmit ScheduleRoutesTab
const commonSubmit = (props: Object) => {
  const { clos, values, submitAction, templateGuid, referenceTypes, shouldGoToRouteBuilder } = props;

  const cloGuids = R.map(G.getGuidFromObject, clos);
  const indexedRefTypes = G.indexByGuid(R.pathOr([], [GC.REF_SCOPE_NAME_CLO], referenceTypes));

  const createDtos = R.map(
    (routeData: Object) => {
      const cloData = R.compose(
        R.values,
        R.mapObjIndexed(({ references, containers }: Array, cloGuid: string) => ({
          cloGuid,
          containers,
          references: R.map(
            (ref: Object) => R.assoc(
              GC.FIELD_NAME,
              R.path([R.prop(GC.FIELD_REFERENCE_TYPE_GUID, ref), GC.FIELD_NAME], indexedRefTypes),
              ref,
            ),
            references,
          ),
        })),
        R.pick(cloGuids),
      )(routeData);

      return {
        cloData,
        [GC.FIELD_START_DATE]: G.createLocalDateTimeFromInstanceOrISOString(
          R.prop(GC.FIELD_START_DATE, routeData),
          GC.DEFAULT_DATE_TIME_FORMAT,
        ),
      };
    },
    R.prop('routes', values),
  );

  if (R.equals(R.length(createDtos), 1)) {
    return submitAction({
      templateGuid,
      singleRoute: true,
      shouldGoToRouteBuilder,
      createDto: R.head(createDtos),
    });
  }

  submitAction({
    createDtos,
    templateGuid,
    singleRoute: false,
    shouldGoToRouteBuilder: false,
  });
};

const enhance = compose(
  withAsyncRefTypes,
  withTabs,
  withFormik({
    displayName: 'CREATE_ROUTES_FORM',
    validationSchema: ({ clos }: Object) => getValidationSchema(clos),
    handleSubmit: (values: Object, { props }: Object) => {
      const { closeModal } = props;

      commonSubmit({ ...props, values, shouldGoToRouteBuilder: true });
      closeModal();
    },
    mapPropsToValues: ({ clos }: Object) => ({ routes: [getNewRow(clos)] }),
  }),
  withHandlers({
    handleSetActiveTabAndFieldTouched: (props: Object) => (tab: number) => {
      const { activeTab, setFieldTouched, handleSetActiveTab } = props;

      handleSetActiveTab(tab);
      setFieldTouched(`routes.${activeTab}.${GC.FIELD_START_DATE}`, true, true);
    },
    handleSaveWithoutClose: (props: Object) => () => {
      const { errors, values, setTouched } = props;

      if (G.isNotNilAndNotEmpty(errors)) return setTouched(setNestedObjectValues(values, true));

      commonSubmit(props);
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getRefTypesRequest(this.props.branchGuid, GC.REF_SCOPE_NAME_CLO);
      this.props.validateForm();
    },
  }),
  pure,
);

const Form = enhance((props: Object) => {
  const {
    values,
    errors,
    touched,
    activeTab,
    handleSubmit,
    referenceTypes,
    handleSaveWithoutClose,
    handleSetActiveTabAndFieldTouched,
  } = props;

  const referenceTypeOptions = G.getOptionsFromDataArrayByPath(
    [GC.FIELD_NAME],
    [GC.FIELD_GUID],
    R.pathOr([], [GC.REF_SCOPE_NAME_CLO], referenceTypes),
  );

  const routes = R.pathOr([], ['routes'], values);

  const actionButtons = [{
    action: handleSaveWithoutClose,
    displayText: G.getWindowLocale('actions:save', 'Save'),
    buttonStyles: {
      mr: 16,
      ml: 'auto',
      bgColor: 'none',
      background: 'none',
      border: '1px solid',
      borderRadius: '5px',
      textTransform: 'uppercase',
      textColor: G.getTheme('colors.dark.blue'),
      borderColor: G.getTheme('colors.dark.blue'),
    },
  }];

  return (
    <form onSubmit={handleSubmit}>
      <FieldArray
        name='routes'
        render={(formikArrayProps: Object) => (
          <Box width={825}>
            <Header {...props} count={R.length(routes)} push={formikArrayProps.push} />
            <Tabs
              activeTab={activeTab}
              handleClickTab={handleSetActiveTabAndFieldTouched}
              wrapperStyles={{ maxWidth: 825, overflow: 'auto' }}
              tabs={getTabs(routes, R.pathOr([], ['routes'], errors), R.pathOr([], ['routes'], touched))}
            />
            <Flex
              px={15}
              zIndex='0'
              overflow='auto'
              minHeight={300}
              id='route_wrapper'
              alignItems='flex-start'
              maxHeight='calc(100vh - 180px)'
            >
              <RouteRow
                {...props}
                key={activeTab}
                routeIndex={activeTab}
                field={R.prop(activeTab, routes)}
                formikArrayProps={formikArrayProps}
                allowCopy={R.lt(R.length(routes), 25)}
                allowRemove={R.gt(R.length(routes), 1)}
                referenceTypeOptions={referenceTypeOptions}
              />
            </Flex>
          </Box>
        )}
      />
      <FormFooter2
        boxStyles={{ p: 15 }}
        actionButtons={actionButtons}
        submitBtnStyles={{ width: 140 }}
        submitBtnText={G.getWindowLocale('actions:save-and-close', 'Save And Close')}
      />
    </form>
  );
});

const getClos = ({ data }: Object) => {
  if (G.isNilOrEmpty(data)) return [];

  return R.map(
    (clo: Object) => R.mergeRight(clo, {
      [GC.FIELD_CONTAINERS]: R.compose(
        R.filter(({ loadGuid }: Object) => R.propEq(loadGuid, GC.FIELD_GUID, clo)),
        R.pathOr([], [GC.FIELD_CONTAINERS]),
      )(data),
      [GC.FIELD_LOAD_STOPS]: R.compose(
        R.sortBy(R.prop(GC.FIELD_CLO_EVENT_INDEX)),
        R.filter(R.propEq(G.getGuidFromObject(clo), GC.FIELD_CLO_GUID)),
        R.pathOr([], [GC.FIELD_LOAD_STOPS]),
      )(data),
    }),
  )(R.pathOr([], [GC.SYSTEM_LIST_CLOS], data));
};

const CreateRoutesForm = withAsyncInitialDataOnDidMount((props: Object) => (
  <LocalLoader localLoaderOpen={R.pathOr(true, ['asyncInitialData', 'loading'], props)}>
    <Form {...props} clos={getClos(props.asyncInitialData)} />
  </LocalLoader>
));

export {
  getClos,
  getTels,
  getTabs,
  CreateRoutesForm,
};
