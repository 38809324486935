import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  lifecycle,
  withState,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// components
import { Table } from '../../components/table';
import { TitlePanel } from '../../components/title-panel';
import { EditReport } from '../../components/edit-report';
import { ConfirmComponent } from '../../components/confirm';
import { PageActions } from '../../components/page-actions';
import { withPromptModal } from '../../components/edit-report/hocs';
import { openModal, closeModal } from '../../components/modal/actions';
// features
import { AuthWrapper } from '../permission';
import PC from '../permission/role-permission';
import { makeSelectCurrentUserName } from '../auth/selectors';
import { makeSelectRoleAvailableList } from '../role/selectors';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import { getAllAvailableRefTypesByScopeRequest } from '../reference/actions';
import { getGroupByOptions, getSummaryOptions } from '../dispatch-board-new/settings';
// forms
import { SelectDropdownForm } from '../../forms/forms/select-dropdown-form';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover } from '../../hocs';
// icons
import * as I from '../../svgs';
// ui
import {
  ListWrapper,
  IconWrapper,
  ZOrderWrapper,
} from '../../ui';
// feature report-format
import ReportActions from './components/row-actions';
import { tableSettings } from './settings/table-settings';
import { columnSettings } from './settings/column-settings';
import { paramsByType, reportTypeLocaleMap } from './settings/filter-props';
import {
  selectItem,
  setReports,
  setUsedReport,
  cleanQuickFilter,
  createReportRequest,
  updateReportRequest,
  deleteReportRequest,
  setQuickFilterParams,
  getReportDataRequest,
  setIgnorePromptStatus,
  resetListAndPagination,
  getListOfReportsRequest,
  exportReportDataRequest,
  updateReportOwnerRequest,
  changeDefaultReportRequest,
} from './actions';
import {
  makeSelectUsedReport,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectReportsList,
  makeSelectFilterProps,
  makeSelectReportStatus,
  makeSelectListLoading,
  makeSelectSelectedList,
  makeSelectRenderColumn,
  makeSelectAvailableReports,
  makeSelectQuickFilteredParams,
} from './selectors';
/////////////////////////////////////////////////////////

const enhance = compose(
  withFixedPopover,
  withState('originalReport', 'setOriginalReport', null),
  withState('reportFromPrompt', 'setReportFromPrompt', null),
  withHandlers({
    handleListRequest: ({ getListOfReportsRequest }: Object) => () => (getListOfReportsRequest()),
    getQuickFilteredListRequest: ({
      getListOfReportsRequest,
      resetListAndPagination,
    }: Object) => () => {
      resetListAndPagination();
      getListOfReportsRequest(true);
    },
    handleCleanFilter: ({ cleanQuickFilter, getListOfReportsRequest, resetListAndPagination }: Object) => () => {
      cleanQuickFilter();
      resetListAndPagination();
      getListOfReportsRequest(true);
    },
    handleSelectReport: (props: Object) => (reportGuid: string) => {
      const selectedReport = R.find(R.propEq(reportGuid, 'guid'), props.reportList);
      props.setUsedReport(selectedReport);
      props.setIgnorePromptStatus(false);
      props.getListOfReportsRequest();
    },
    handleChangeReportParams: (props: Object) => (data: Object) => (
      props.setReportFromPrompt(data)
    ),
    handleClickEditIcon: (props: Object) => (e: Object, report: Object, callback: Function) => (
      props.openFixedPopup({
        position: 'right',
        el: e.currentTarget,
        content: (
          <ReportActions
            prefix='list'
            parent={report}
            report={report}
            callback={callback}
            rolesList={props.rolesList}
            openModal={props.openModal}
            closeModal={props.closeModal}
            setSelected={props.setSelected}
            userLoginId={props.userLoginId}
            setUsedReport={props.setUsedReport}
            requestPending={props.requestPending}
            closeFixedPopup={props.closeFixedPopup}
            updateReportRequest={props.updateReportRequest}
            createReportRequest={props.createReportRequest}
            deleteReportRequest={props.deleteReportRequest}
            getReportDataRequest={props.getReportDataRequest}
            getAllAvailableRefTypesByScopeRequest={props.getAllAvailableRefTypesByScopeRequest}
          />
        ),
      })
    ),
    handleSetUsedReport: (props: Object) => () => {
      const {
        setUsedReport,
        selectedReport,
        setPromptStatus,
        reportFromPrompt,
        setOriginalReport,
        getListOfReportsRequest,
      } = props;

      if (R.and(
        G.isNotNilAndNotEmpty(reportFromPrompt),
        G.notEquals(selectedReport, reportFromPrompt),
      )) {
        setOriginalReport(selectedReport);
        setUsedReport(reportFromPrompt);
        getListOfReportsRequest(true);
      }

      setPromptStatus(false);
    },
    handleChangeOwner: (props: Object) => () => {
      if (R.isEmpty(props.selectedList)) {
        return G.showToastrMessageSimple(
          'info',
          G.getWindowLocale('messages:select-item', 'Please, select at least one item'),
        );
      }

      const reqBody = {
        elements: props.selectedList,
      };
      const component = (
        <ConfirmComponent
          textLocale={G.getWindowLocale(
            'messages:report-change-owner-confirmation-text',
            'Are you sure you want be owner for selected reports?',
          )}
        />
      );
      const modal = {
        component,
        options: {
          width: 500,
          height: 150,
          controlButtons: [
            {
              type: 'button',
              margin: '0 12px 0 0',
              name: G.getWindowLocale('actions:save', 'Save'),
              action: () => {
                props.updateReportOwnerRequest(reqBody);
                props.closeModal();
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleCreateReport: (props: Object) => (type: string) => {
      const { openModal, requestPending, createReportRequest, updateReportRequest } = props;

      let loadType = null;
      let fields = paramsByType[type];

      if (G.isFunction(fields)) fields = fields();

      const isRouteReport = R.includes(type, [GC.ROUTE_TEL_REPORT, GC.ROUTE_CLO_REPORT]);

      if (isRouteReport) loadType = R.toUpper(type.slice(-3));

      const modalContent = (
        <EditReport
          fields={fields}
          hideUseBtn={true}
          requestPending={requestPending}
          showReportSummary={isRouteReport}
          disableSetReportFields={isRouteReport}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          usedReport={{ type, name: 'Draft Report' }}
          groupByOptions={R.and(isRouteReport, getGroupByOptions(loadType))}
          summaryOptions={R.and(isRouteReport, getSummaryOptions(loadType))}
        />
      );

      const modal = {
        component: modalContent,
        options: {
          version: 2,
          height: 'auto',
          maxWidth: '98vw',
          width: 'fit-content',
        },
      };

      openModal(modal);
    },
  }),
  withPromptModal(),
  withHandlers({
    handleSelectNewReport: (props: Object) => () => {
      const { openModal, closeModal, handleCreateReport } = props;

      const options = R.compose(
        R.sortBy(R.prop('label')),
        R.map((value: string) => ({
          value,
          label: G.getWindowLocale(R.prop(value, reportTypeLocaleMap)),
        })),
        R.keys,
      )(reportTypeLocaleMap);

      const submitAction = (type: string) => {
        closeModal();
        handleCreateReport(type);
      };

      const component = (
        <SelectDropdownForm
          options={options}
          submitAction={submitAction}
          fieldLabel={G.getWindowLocale('titles:select-report', 'Select Report')}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title: G.getWindowLocale('titles:create-report', 'Create Report'),
        },
      };

      openModal(modal);
    },
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        createReportRequest,
        updateReportRequest,
        getListOfReportsRequest,
      } = props;

      const modalContent = (
        <EditReport
          fields={fields}
          setReport={setUsedReport}
          usedReport={selectedReport}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getListOfReportsRequest(true)}
        />
      );
      const modal = {
        component: modalContent,
        options: {
          version: 2,
          height: 'auto',
          maxWidth: '98vw',
          width: 'fit-content',
        },
      };

      openModal(modal);
    },
  }),
  branch(
    (props: Object) => R.or(
      R.not(props.initialDataLoaded),
      G.isNilOrEmpty(props.selectedReport),
    ),
    renderNothing,
  ),
  lifecycle({
    componentWillUnmount() {
      const { originalReport, setUsedReport, selectedReport } = this.props;

      if (R.equals(R.path([GC.FIELD_GUID], originalReport), R.path([GC.FIELD_GUID], selectedReport))) {
        setUsedReport(originalReport);
      }
    },
  }),
  pure,
);

const renderTable = (props: Object) => {
  const {
    loading,
    allReports,
    totalCount,
    selectItem,
    pagination,
    reportList,
    filterParams,
    selectedReport,
    handleClickEditIcon,
    getListOfReportsRequest,
  } = props;

  if (R.not(selectedReport)) return null;

  const itemList = R.values(allReports);
  const allChecked = G.isAllChecked(itemList);
  const elementActionsComponent = (report: Object) => (
    <AuthWrapper has={[PC.REPORT_WRITE]}>
      <IconWrapper px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, report)}>
        {I.threeDots()}
      </IconWrapper>
    </AuthWrapper>
  );

  const data = {
    loading,
    itemList,
    allChecked,
    totalCount,
    pagination,
    columnSettings,
    hasSelectable: true,
    report: selectedReport,
    onOptionClick: selectItem,
    withResizableColumns: true,
    renderRightStickedComponent: elementActionsComponent,
    handleLoadMoreEntities: G.ifElse(
      loading,
      () => {},
      getListOfReportsRequest,
    ),
    tableSettings: G.getTableSettingsWithMaxHeightByConditions({
      reportList,
      filterParams,
      tableSettings,
      selectedReport,
    }),
  };

  return <Table {...data} />;
};

export const ReportList = (props: Object) => {
  const {
    reportList,
    selectedList,
    handleChangeOwner,
    handleSelectNewReport,
    getListOfReportsRequest,
  } = props;

  const listActionsOpt = [
    {
      type: 'massAction',
      action: handleChangeOwner,
      permissions: [PC.REPORT_CHANGE_OWNER_EXECUTE],
      icon: I.user(G.getTheme('colors.light.mainLight'), 20, 20),
      text: G.getWindowLocale('actions:set-as-owner', 'Set as Owner'),
    },
  ];

  return (
    <ListWrapper p={15} bgColor={G.getTheme('pages.layOutBgColor')}>
      <ZOrderWrapper zIndex='2'>
        <TitlePanel
          {...props}
          withCount={true}
          popperWithCount={true}
          type={GC.REPORT_REPORT}
          reportOptions={reportList}
          hiddenRightFilterInfo={false}
          getItemListRequest={getListOfReportsRequest}
          title={G.getWindowLocale('titles:report-list', 'Report List')}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex='1'>
        {renderTable(props)}
      </ZOrderWrapper>
      <AuthWrapper has={[PC.REPORT_CHANGE_OWNER_EXECUTE]}>
        <PageActions
          listActions={listActionsOpt}
          count={R.length(selectedList)}
          shadowColor={G.getTheme('colors.light.grey')}
          mainAction={{
            action: handleSelectNewReport,
            permissions: [PC.REPORT_WRITE],
            text: G.getWindowLocale('titles:create-report', 'Create Report'),
          }}
        />
      </AuthWrapper>
    </ListWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  loading: makeSelectListLoading(state),
  totalCount: makeSelectTotalCount(state),
  pagination: makeSelectPagination(state),
  allReports: makeSelectReportsList(state),
  filterProps: makeSelectFilterProps(state),
  selectedList: makeSelectSelectedList(state),
  renderFields: makeSelectRenderColumn(state),
  selectedReport: makeSelectUsedReport(state),
  requestPending: makeSelectReportStatus(state),
  userLoginId: makeSelectCurrentUserName(state),
  rolesList: makeSelectRoleAvailableList(state),
  reportList: makeSelectAvailableReports(state),
  filterParams: makeSelectQuickFilteredParams(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  selectItem,
  setReports,
  setUsedReport,
  cleanQuickFilter,
  createReportRequest,
  updateReportRequest,
  deleteReportRequest,
  getReportDataRequest,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  getListOfReportsRequest,
  updateReportOwnerRequest,
  changeDefaultReportRequest,
  getAllAvailableRefTypesByScopeRequest,
})(enhance(ReportList));
