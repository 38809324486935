import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import {
  getSharedAccessorialAutoApplyToTypeOptions,
  DEFAULT_DRIVER_ASSESSORIAL_RATE_TYPE_OPTIONS,
  DEFAULT_DRIVER_ASSESSORIAL_RATE_UNIT_GROUP_OPTIONS,
} from '../../../../helpers/options';
// forms
import { districtOptions } from '../../../../forms/forms/accessorial-form/field-settings';
//////////////////////////////////////////////////

export const sharedAccessorialSettings = {
  [GC.FIELD_NAME]: {
    name: 'titles:name',
  },
  [GC.FIELD_CREATED_BY]: {
    name: 'titles:created-by',
  },
  // TODO: uncomment when be will be done
  // [GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID]: {
  //   width: 200,
  //   searchable: true,
  //   name: 'titles:accessorial',
  //   customComponent: R.path(['data', GC.FIELD_DISPLAYED_VALUE]),
  // },
  // [GC.FIELD_NON_TAXABLE]: {
  //   width: 150,
  //   type: 'boolean',
  //   searchable: true,
  //   filterType: 'boolean',
  //   name: 'titles:non-taxable',
  // },
  // [GC.FIELD_FUEL_RELATED]: {
  //   width: 100,
  //   type: 'boolean',
  //   filterType: 'boolean',
  //   name: 'titles:fuel-related',
  // },
  [GC.FIELD_CHARGE_RATE]: {
    name: 'titles:rate',
    filterType: 'number',
  },
  [GC.FIELD_CURRENCY]: {
    name: 'titles:currency',
    options: GC.CURRENCY_OPTIONS,
    filterType: 'selectMultiple',
  },
  [GC.FIELD_CHARGE_RATE_TYPE]: {
    name: 'titles:rate-type',
    filterType: 'selectMultiple',
    options: R.drop(1, DEFAULT_DRIVER_ASSESSORIAL_RATE_TYPE_OPTIONS),
  },
  [GC.FIELD_CHARGE_RATE_UNIT]: {
    name: 'titles:rate-unit',
    filterType: 'selectMultiple',
    options: R.compose(
      R.filter(({ value }: Object) => G.isNotEmpty(value)),
      R.uniqBy(R.prop(GC.FIELD_VALUE)),
      R.flatten,
      R.values,
    )(DEFAULT_DRIVER_ASSESSORIAL_RATE_UNIT_GROUP_OPTIONS),
  },
  [GC.FIELD_CHARGE_MIN_RATE]: {
    filterType: 'number',
    name: 'titles:rate-minimum',
  },
  [GC.FIELD_CHARGE_MAX_RATE]: {
    filterType: 'number',
    name: 'titles:rate-maximum',
  },
  [GC.FIELD_MIN_STOPS]: {
    filterType: 'number',
    name: 'titles:min-stops',
  },
  [GC.FIELD_MAX_STOPS]: {
    filterType: 'number',
    name: 'titles:max-stops',
  },
  [GC.FIELD_ACCESSORIAL_DISTRICT]: {
    name: 'titles:district',
    options: districtOptions,
    filterType: 'selectMultiple',
    customComponent: ({ data }: Object) => R.pathOr(
      R.prop(GC.FIELD_ACCESSORIAL_DISTRICT, data),
      [R.prop(GC.FIELD_ACCESSORIAL_DISTRICT, data)],
      GC.districtMap,
    ),
  },
  [GC.FIELD_AUTO_APPLY_TO]: {
    collection: true,
    name: 'titles:auto-apply-to',
    filterType: 'selectMultiple',
    options: getSharedAccessorialAutoApplyToTypeOptions(),
    customComponent: ({ data }: Object) => R.compose(
      R.join(', '),
      R.map((item: string) => R.compose(
        R.path([GC.FIELD_LABEL]),
        R.find(R.propEq(item, GC.FIELD_VALUE)),
      )(getSharedAccessorialAutoApplyToTypeOptions())),
      R.pathOr([], [GC.FIELD_AUTO_APPLY_TO]),
    )(data),
  },
  [GC.FIELD_ACCESSORIAL_USE_FUEL_INDEX]: {
    fieldType: 'boolean',
    filterType: 'boolean',
    name: 'titles:use-fuel-index',
  },
  ...G.getAddressPointFieldsByType(GC.FIELD_ORIGIN),
  ...G.getAddressPointFieldsByType(GC.FIELD_DESTINATION),
};
