import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../../components/text';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import { ITEM_DIMENSIONS_OPTIONS, DEFAULT_WEIGHT_UOM_OPTIONS } from '../../../../helpers/options';
//////////////////////////////////////////////////

const TableCell = ({ text }: Object) => {
  if (G.isNilOrEmpty(text)) return null;

  return (
    <TextComponent
      title={text}
      maxWidth='90%'
      display='block'
      withEllipsis={true}
    >
      {text}
    </TextComponent>
  );
};

export const containerSettings = {
  [GC.FIELD_TEMPLATE_ID]: {
    name: 'titles:template-id',
  },
  [GC.FIELD_CONTAINER_TYPE_GUID]: {
    filterType: 'selectMultiple',
    name: 'titles:container-type',
    options: () => G.mapNameGuidToLabelValue(G.getAmousContainerTypeListFromWindow()),
    customComponent: ({ data }: Object) => {
      const containerTypeGuid = G.getPropFromObject(GC.FIELD_CONTAINER_TYPE_GUID, data);

      if (G.isNilOrEmpty(containerTypeGuid)) return null;

      const getContainerTypeDisplayedValue = R.compose(
        R.pathOr('', [GC.FIELD_NAME]),
        R.find(R.propEq(containerTypeGuid, GC.FIELD_GUID)),
      )(G.getAmousContainerTypeListFromWindow());

      return <TableCell text={getContainerTypeDisplayedValue} />;
    },
  },
  [GC.FIELD_CONTAINER_NUMBER]: {
    name: 'titles:container-number',
  },
  [GC.FIELD_CONTAINER_INITIAL]: {
    name: 'titles:container-initial',
  },
  [GC.FIELD_EMPTY_CONTAINER_WEIGHT]: {
    filterType: 'number',
    name: 'titles:empty-weight',
  },
  [GC.FIELD_FULL_CONTAINER_WEIGHT]: {
    filterType: 'number',
    name: 'titles:full-weight',
  },
  [GC.FIELD_WEIGHT_UOM]: {
    fieldType: 'uomLocale',
    name: 'titles:weight-uom',
    options: DEFAULT_WEIGHT_UOM_OPTIONS,
  },
  [GC.FIELD_WIDTH]: {
    filterType: 'number',
    name: 'titles:width',
  },
  [GC.FIELD_HEIGHT]: {
    filterType: 'number',
    name: 'titles:height',
  },
  [GC.FIELD_LENGTH]: {
    filterType: 'number',
    name: 'titles:length',
  },
  [GC.FIELD_DIMENSIONS_UOM]: {
    fieldType: 'uomLocale',
    filterType: 'selectMultiple',
    name: 'titles:dimensions-uom',
    options: ITEM_DIMENSIONS_OPTIONS,
  },
};
