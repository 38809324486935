import React from 'react';
import * as R from 'ramda';
import { compose, withState, withHandlers } from 'react-recompose';
// components
import { EditReport } from '../../../components/edit-report';
import { ConfirmComponent } from '../../../components/confirm';
import { ActionsElement } from '../../../components/actions-element';
// features
import Audit2 from '../../audit2';
import AuthHoc from '../../permission';
import PC from '../../permission/role-permission';
import { getGroupByOptions, getSummaryOptions } from '../../dispatch-board-new/settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// feature report-format
import { paramsByType } from '../settings/filter-props';
//////////////////////////////////////////////////

const enhance = compose(
  AuthHoc([PC.REPORT_WRITE]),
  withState(GC.FIELD_ROLE_GUIDS, 'setRoleGuids', ({ report }: Object) => (
    G.getPropFromObject(GC.FIELD_ROLE_GUIDS, report)
  )),
  withHandlers({
    handleEditReport: (props: Object) => (usedReport: Object) => {
      const { openModal, requestPending, createReportRequest, updateReportRequest } = props;

      const { type } = usedReport;

      let loadType = null;
      let fields = paramsByType[type];

      if (G.isFunction(fields)) fields = fields();

      const isRouteReport = R.includes(type, [GC.ROUTE_TEL_REPORT, GC.ROUTE_CLO_REPORT]);

      if (isRouteReport) loadType = R.toUpper(type.slice(-3));

      const modalContent = (
        <EditReport
          fields={fields}
          hideUseBtn={true}
          usedReport={usedReport}
          requestPending={requestPending}
          showReportSummary={isRouteReport}
          disableSetReportFields={isRouteReport}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          groupByOptions={R.and(isRouteReport, getGroupByOptions(loadType))}
          summaryOptions={R.and(isRouteReport, getSummaryOptions(loadType))}
        />
      );

      const modal = {
        component: modalContent,
        options: {
          version: 2,
          height: 'auto',
          maxWidth: '98vw',
          width: 'fit-content',
        },
      };

      openModal(modal);
    },
  }),
  withHandlers({
    handleGetReportData: ({ report, closeFixedPopup, handleEditReport, getReportDataRequest }: Object) => () => {
      const { guid } = report;

      closeFixedPopup();
      getReportDataRequest({ guid, modal: handleEditReport });
    },
    handleDelete: (props: Object) => () => {
      const { report, openModal, closeModal, closeFixedPopup, deleteReportRequest } = props;

      closeFixedPopup();

      const component = (
        <ConfirmComponent
          name={R.prop(GC.FIELD_NAME, report)}
          textLocale={G.getWindowLocale('messages:before-remove', 'Are you sure you want to remove')}
        />
      );

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                deleteReportRequest(report);
                closeModal();
              },
            },
          ],
        },
      };

      openModal(modal);
    },
    handleShowAudit: (props: Object) => () => {
      const { report, openModal, closeFixedPopup } = props;

      closeFixedPopup();

      const requestOptions = {
        [GC.FIELD_TYPE]: GC.AUDIT_TYPE_REPORT,
        [GC.FIELD_OBJECT_GUID]: G.getGuidFromObject(report),
      };

      const component = <Audit2 requestOptions={requestOptions} />;

      const modal = {
        p: '0',
        component,
        options: {
          boxShadow: 'none',
          withCloseIcon: true,
          withBorderRadius: true,
          backgroundColor: 'transparent',
          title: `${G.getWindowLocale('titles:audit', 'Audit')}: ${R.prop(GC.FIELD_NAME, report)}`,
        },
      };

      openModal(modal);
    },
  }),
);

const getOptions = (props: Object) => {
  const { report, userLoginId, handleDelete, handleShowAudit, handleGetReportData } = props;

  const iconColor = G.getTheme('icons.iconColor');

  const options = [
    {
      action: handleGetReportData,
      frontIcon: I.pencil(iconColor),
      permissions: [PC.REPORT_WRITE],
      text: G.getWindowLocale('actions:edit', 'Edit'),
    },
    {
      action: handleShowAudit,
      text: G.getWindowLocale('titles:audit', 'Audit'),
      frontIcon: I.renderAuditHistoryIcon(iconColor, 15, 15),
    },
  ];

  if (G.notEquals(userLoginId, G.getPropFromObject(GC.FIELD_OWNER, report))) return options;

  return R.insert(1, {
    action: handleDelete,
    frontIcon: I.trash(iconColor),
    permissions: [PC.REPORT_WRITE],
    text: G.getWindowLocale('actions:delete', 'Delete'),
  }, options);
};

const RowActions = enhance((props: Object) => (<ActionsElement options={getOptions(props)} />));

export default RowActions;
