import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { openModal, closeModal } from '../../../components/modal/actions';
// features
import { makeSelectAvailableDocumentTypes } from '../../configurations/selectors';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { PageWrapper } from '../../../ui';
// feature role
import { withCreateUserPermissions } from '../hocs';
import UserPermissionsComponent from './user-permissions';
import {
  makeSelectRoleType,
  makeSelectFormStore,
  makeSelectPermissions,
  makeSelectDefaultPermissions,
  makeSelectPermissionsGroupsList,
} from '../selectors';
import {
  checkAllRoles,
  addNewRoleRequest,
  resetUserPermissions,
  handleToggleGroupRequest,
  getRoleSelectedDocumentTypes,
  toggleGroupPermissionsRequest,
  toggleSinglePermissionRequest,
  getUserPermissionsByRoleTypeRequest,
} from '../actions';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  roleType: makeSelectRoleType(state),
  formStore: makeSelectFormStore(state),
  permissions: makeSelectPermissions(state),
  defaultPermissions: makeSelectDefaultPermissions(state),
  permissionsGroupsList: makeSelectPermissionsGroupsList(state),
  availableDocumentTypes: makeSelectAvailableDocumentTypes(state),
});

const CreateRole = (props: Object) => (
  <PageWrapper>
    <UserPermissionsComponent
      {...props}
      editMode={G.isCurrentBranchTypeCustomer()}
      title={G.getWindowLocale('titles:create-new-role', 'Create New Role')}
    />
  </PageWrapper>
);

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  checkAllRoles,
  addNewRoleRequest,
  resetUserPermissions,
  handleToggleGroupRequest,
  getRoleSelectedDocumentTypes,
  toggleGroupPermissionsRequest,
  toggleSinglePermissionRequest,
  getUserPermissionsByRoleTypeRequest,
})(withCreateUserPermissions(CreateRole));
