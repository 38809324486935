import * as Yup from 'yup';
import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, lifecycle, withState, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../components/form-footer';
// features
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// forms
import { Fieldset2 } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import {
  ITEM_DIMENSIONS_OPTIONS,
  DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
} from '../../../../helpers/options';
// ui
import { Box } from '../../../../ui';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature template-report
import { makeSelectContainerTypes } from '../selectors';
import { getContainerTypeListSuccess } from '../actions';
//////////////////////////////////////////////////

export const defaultContainerFields = {
  [GC.FIELD_WIDTH]: null,
  [GC.FIELD_HEIGHT]: null,
  [GC.FIELD_LENGTH]: null,
  [GC.FIELD_WEIGHT_UOM]: null,
  [GC.FIELD_TEMPLATE_ID]: null,
  [GC.FIELD_DIMENSIONS_UOM]: null,
  [GC.FIELD_CONTAINER_NUMBER]: null,
  [GC.FIELD_CONTAINER_INITIAL]: null,
  [GC.FIELD_CONTAINER_TYPE_GUID]: null,
  [GC.FIELD_FULL_CONTAINER_WEIGHT]: null,
  [GC.FIELD_EMPTY_CONTAINER_WEIGHT]: null,
};

export const emptyContainerFields = {
  [GC.FIELD_WIDTH]: '',
  [GC.FIELD_HEIGHT]: '',
  [GC.FIELD_LENGTH]: '',
  [GC.FIELD_WEIGHT_UOM]: '',
  [GC.FIELD_TEMPLATE_ID]: '',
  [GC.FIELD_DIMENSIONS_UOM]: '',
  [GC.FIELD_CONTAINER_NUMBER]: '',
  [GC.FIELD_CONTAINER_INITIAL]: '',
  [GC.FIELD_CONTAINER_TYPE_GUID]: '',
  [GC.FIELD_FULL_CONTAINER_WEIGHT]: '',
  [GC.FIELD_EMPTY_CONTAINER_WEIGHT]: '',
};

const containerTypeFieldsToPick = [
  GC.FIELD_WIDTH,
  GC.FIELD_HEIGHT,
  GC.FIELD_LENGTH,
  GC.FIELD_WEIGHT_UOM,
  GC.FIELD_DIMENSIONS_UOM,
  GC.FIELD_EMPTY_CONTAINER_WEIGHT,
];

const inputWrapperStyles1 = { mr: 20, mb: 25, width: 100 };
const inputWrapperStyles2 = { mr: 20, mb: 25, width: 160 };
const inputWrapperStyles3 = { mr: 20, mb: 25, width: 130 };

export const containerFieldSettings = [
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_TEMPLATE_ID,
    inputWrapperStyles: inputWrapperStyles2,
    label: G.getWindowLocale('titles:template-id', 'Template ID'),
  },
  {
    type: 'text',
    fieldName: GC.FIELD_CONTAINER_NUMBER,
    inputWrapperStyles: inputWrapperStyles2,
    label: G.getWindowLocale('titles:container-number', 'Container Number'),
  },
  {
    type: 'text',
    fieldName: GC.FIELD_CONTAINER_INITIAL,
    inputWrapperStyles: { ...inputWrapperStyles2, mr: 0 },
    label: G.getWindowLocale('titles:container-initial', 'Container Initial'),
  },
  {
    type: 'select',
    shouldCustomChange: true,
    customChangeHandler: (event: Object, field: Object, props: Object) => {
      const { values, setValues, containerTypes } = props;

      const value = event.target.value;
      const prevFields = [
        GC.FIELD_GUID,
        GC.BRANCH_GUID,
        GC.FIELD_VERSION,
        GC.FIELD_TEMPLATE_ID,
        GC.FIELD_CONTAINER_NUMBER,
        GC.FIELD_CONTAINER_INITIAL,
      ];

      if (G.isNilOrEmpty(value)) {
        const prev = R.pick(prevFields, values);

        return setValues(R.mergeRight(emptyContainerFields, prev));
      }

      const prev = R.mergeRight(
        emptyContainerFields,
        R.pick(prevFields, values),
      );
      const newValues = R.compose(
        G.mapObjectNullFieldsToEmptyStrings,
        R.pick(containerTypeFieldsToPick),
        R.find(R.propEq(value, GC.FIELD_GUID)),
      )(containerTypes);
      const newValuesWithFullWeight = R.assoc(
        GC.FIELD_FULL_CONTAINER_WEIGHT,
        R.prop(GC.FIELD_EMPTY_CONTAINER_WEIGHT, newValues),
        newValues,
      );
      const newValuesWithContainerType = R.assoc(
        GC.FIELD_CONTAINER_TYPE_GUID,
        value,
        newValuesWithFullWeight,
      );

      return setValues(R.mergeRight(prev, newValuesWithContainerType));
    },
    options: 'containerTypeOptions',
    fieldName: GC.FIELD_CONTAINER_TYPE_GUID,
    inputWrapperStyles: inputWrapperStyles3,
    label: G.getWindowLocale('titles:container-type', 'Container Type'),
  },
  {
    type: 'number',
    inputWrapperStyles: inputWrapperStyles1,
    fieldName: GC.FIELD_EMPTY_CONTAINER_WEIGHT,
    label: G.getWindowLocale('titles:empty-weight', 'Empty Weight'),
  },
  {
    type: 'number',
    inputWrapperStyles: inputWrapperStyles1,
    fieldName: GC.FIELD_FULL_CONTAINER_WEIGHT,
    label: G.getWindowLocale('titles:full-weight', 'Full Weight'),
  },
  {
    type: 'select',
    fieldName: GC.FIELD_WEIGHT_UOM,
    options: DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
    inputWrapperStyles: { ...inputWrapperStyles3, mr: 0 },
    label: G.getWindowLocale('titles:weight-uom', 'Weight UOM'),
  },
  {
    type: 'number',
    fieldName: GC.FIELD_WIDTH,
    inputWrapperStyles: inputWrapperStyles1,
    label: G.getWindowLocale('titles:width', 'Width'),
  },
  {
    type: 'number',
    fieldName: GC.FIELD_HEIGHT,
    inputWrapperStyles: inputWrapperStyles1,
    label: G.getWindowLocale('titles:height', 'Height'),
  },
  {
    type: 'number',
    fieldName: GC.FIELD_LENGTH,
    inputWrapperStyles: inputWrapperStyles1,
    label: G.getWindowLocale('titles:length', 'Length'),
  },
  {
    type: 'select',
    options: ITEM_DIMENSIONS_OPTIONS,
    fieldName: GC.FIELD_DIMENSIONS_UOM,
    inputWrapperStyles: { ...inputWrapperStyles2, mr: 0 },
    label: G.getWindowLocale('titles:dimensions-uom', 'Dimensions UOM'),
  },
];

const ContainerForm = (props: Object) => {
  const { closeModal, handleSubmit, containerTypes } = props;

  const formikProps = G.getFormikProps(props);
  const containerTypeOptions = G.addEmptyOptionToDropDown(G.mapNameGuidToLabelValue(containerTypes));

  return (
    <Box mt={15} width={520}>
      <form onSubmit={handleSubmit}>
        <Fieldset2
          {...formikProps}
          fields={containerFieldSettings}
          containerTypes={containerTypes}
          containerTypeOptions={containerTypeOptions}
        />
        <FormFooter2 closeModal={closeModal} />
      </form>
    </Box>
  );
};

const enhanceDetails = compose(
  withState('containerTemplateDetails', 'setContainerTemplateDetails', null),
  withHandlers({
    getContainerTemplateDetails: (props: Object) => async () => {
      try {
        const { guid, openLoader, closeLoader, setContainerTemplateDetails } = props;

        if (G.isNilOrEmpty(guid)) return;

        openLoader();
        const endpoint = endpointsMap.getContainerTemplateEndpoint(guid);
        const res = await sendRequest('get', endpoint);

        const { data, status } = res;

        if (G.isResponseSuccess(status)) {
          setContainerTemplateDetails(data);
        } else {
          G.handleFailResponseSimple(res);
        }

        closeLoader();
      } catch (error) {
        closeLoader();
        G.catchSendRequestSimple(error, 'getContainerTemplateDetails');
      }
    },
  }),
);

const enhanceContainerTypes = compose(
  withState('containerTypes', 'setContainerTypes', R.prop('containerTypes')),
  withHandlers({
    handleGetContainerTypes: ({ setContainerTypes, getContainerTypeListSuccess }: Object) => async () => {
      const res = await sendRequest('get', endpointsMap.containerTypeList);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setContainerTypes(data);
        getContainerTypeListSuccess(data);
      } else {
        G.handleFailResponseSimple(res);
      }
    },
  }),
  lifecycle({
    componentWillMount() {
      const { getContainerTemplateDetails } = this.props;

      getContainerTemplateDetails();
    },
    componentDidMount() {
      const { containerTypes, handleGetContainerTypes } = this.props;

      if (G.isNotNilAndNotEmpty(containerTypes)) return;

      handleGetContainerTypes();
    },
  }),
  pure,
);

const fieldNotRequired = Yup.string().nullable(true);
const fieldRequired = Yup.string().nullable(true).required(G.getRequiredLocaleTxt());

const getContainerFormValidationSchema = (values: Object, fromDetails: boolean) => {
  const { width, height, length, fullContainerWeight, emptyContainerWeight } = values;

  const templateId = G.ifElse(
    G.isTrue(fromDetails),
    fieldNotRequired,
    fieldRequired,
  );
  let schema = { templateId };

  if (G.isOneNotNilOrNotEmpty([emptyContainerWeight, fullContainerWeight])) {
    schema = R.assoc(GC.FIELD_WEIGHT_UOM, fieldRequired, schema);
  }

  if (G.isOneNotNilOrNotEmpty([width, height, length])) {
    schema = R.assoc(GC.FIELD_DIMENSIONS_UOM, fieldRequired, schema);
  }

  return schema;
};

const formEnhance = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props: Object) => G.setInitialFormikValues(
    defaultContainerFields,
    props.initialValues,
    props.containerTemplateDetails,
  ),
  validationSchema: ({ fromDetails }: Object) => Yup.lazy(
    (values: Object) => Yup.object().shape(
      getContainerFormValidationSchema(values, fromDetails),
    ),
  ),
  handleSubmit: (values: Object, { props }: Object) => {
    const { submitAction } = props;

    submitAction(values);
  },
});

const enhance = compose(
  enhanceDetails,
  enhanceContainerTypes,
  formEnhance,
  pure,
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  containerTypes: makeSelectContainerTypes(state),
});

export default connect(mapStateToProps, {
  openLoader,
  closeLoader,
  getContainerTypeListSuccess,
})(enhance(ContainerForm));
