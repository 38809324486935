import * as R from 'ramda';
import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const store = (state: Object) => state.reports;
const selectFormsStore = (state: Object) => state.form;

const makeSelectReportsList = () => createSelector(
  store,
  ({ reports }: Object) => reports,
);

const makeSelectQuickFilteredParams = () => createSelector(
  store,
  ({ filterParams }: Object) => filterParams,
);

const makeSelectUsedReport = () => createSelector(
  store,
  ({ usedReport }: Object) => usedReport,
);

const makeSelectRequestStatus = () => createSelector(
  store,
  ({ requestPending }: Object) => requestPending,
);

const makeSelectIgnorePrompt = () => createSelector(
  store,
  ({ ignorePrompt }: Object) => ignorePrompt,
);

const makeSelectIsEditPage = () => createSelector(
  store,
  ({ isEditPage }: Object) => isEditPage,
);

const makeSelectUsedReportStatus = () => createSelector(
  store,
  ({ usedReportStatus }: Object) => usedReportStatus,
);

const makeSelectAvailableReports = () => createSelector(
  store,
  ({ availableReports }: Object) => availableReports,
);

const makeSelectRenderColumn = () => createSelector(
  store,
  ({ renderFields }: Object) => renderFields,
);

const makeSelectTotalCount = () => createSelector(
  store,
  ({ totalCount }: Object) => totalCount,
);

const makeSelectFilterProps = () => createSelector(
  store,
  ({ filterProps }: Object) => filterProps,
);

const makeSelectOriginParams = () => createSelector(
  store,
  ({ originReportParam }: Object) => originReportParam,
);

const makeSelectEditRouteReportForm = () => createSelector(
  selectFormsStore,
  (form: Object) => R.pathOr({}, ['EDIT_REPORT_FORM', 'values'], form),
);

const makeSelectPagination = () => createSelector(
  store,
  ({ pagination }: Object) => pagination,
);

const makeSelectListLoading = () => createSelector(
  store,
  ({ loading }: Object) => loading,
);


const makeSelectReportStatus = () => createSelector(
  store,
  ({ reportPending }: Object) => reportPending,
);

const makeSelectSelectedList = () => createSelector(
  store,
  (state: Object) => R.compose(
    R.keys,
    R.filter(({ selected }: Object) => selected),
    R.pathOr([], ['reports']),
  )(state),
);

export {
  store,
  makeSelectUsedReport,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectIsEditPage,
  makeSelectReportsList,
  makeSelectFilterProps,
  makeSelectListLoading,
  makeSelectSelectedList,
  makeSelectReportStatus,
  makeSelectOriginParams,
  makeSelectRenderColumn,
  makeSelectIgnorePrompt,
  makeSelectRequestStatus,
  makeSelectUsedReportStatus,
  makeSelectAvailableReports,
  makeSelectQuickFilteredParams,
  makeSelectEditRouteReportForm,
};
