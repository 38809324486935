import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { serviceVendorTypeOptions } from '../../../helpers/options';
// feature service-vendor
import { createServiceVendorRequest } from '../actions';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 400,
};

const defaultFields = {
  [GC.FIELD_NAME]: null,
  [GC.FIELD_TYPE]: GC.SERVICE_VENDOR_TYPE_GENERAL,
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .max(250, G.getShouldBeLessOrEqualLocaleTxt(250)),
});

const fieldSettings = [
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_NAME,
    label: ['titles:company-name', 'Company Name'],
  },
  {
    type: 'select',
    inputWrapperStyles,
    fieldName: GC.FIELD_TYPE,
    label: ['titles:type', 'Type'],
    options: 'serviceVendorTypeOptions',
  },
];

const CreateServiceVendorForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset2
      {...G.getFormikProps(props)}
      fields={fieldSettings}
      fieldsWrapperStyles={{ mt: 15 }}
      serviceVendorTypeOptions={serviceVendorTypeOptions()}
    />
    <FormFooter2 />
  </form>
);

const enhance = compose(
  connect(null, { createServiceVendorRequest }),
  withFormik({
    validationSchema,
    mapPropsToValues: () => defaultFields,
    handleSubmit: (values: Object, { props }: Object) => props.createServiceVendorRequest(values),
  }),
  pure,
);

export default enhance(CreateServiceVendorForm);

