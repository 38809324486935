// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { serviceVendorTypeOptions } from '../../../helpers/options';
//////////////////////////////////////////////////

const payToLocationTxtLocale = () => G.getWindowLocale('titles:pay-to', 'Pay to');
const primaryLocationTxtLocale = () => G.getWindowLocale('titles:primary-location', 'Primary Location');

export const FILTER_PARAMS = [
  {
    type: 'string',
    value: GC.FIELD_NAME,
    name: G.getWindowLocale('titles:company-name', 'Company Name'),
  },
  {
    value: GC.FIELD_TYPE,
    type: 'selectMultiple',
    options: serviceVendorTypeOptions(),
    name: G.getWindowLocale('titles:type', 'Type'),
    placeholder: {
      text: 'Choose',
      key: 'titles:choose',
    },
  },
  {
    type: 'boolean',
    value: GC.FIELD_ACTIVE,
    name: G.getWindowLocale('titles:status', 'Status'),
  },
  {
    type: 'string',
    collection: true,
    value: `${GC.FIELD_SERVICE_TYPES}.${GC.FIELD_DISPLAYED_VALUE}`,
    name: G.getWindowLocale('titles:service-type', 'Service Type'),
  },
  {
    type: 'string',
    value: GC.FIELD_CARRIER_DBA,
    name: G.getWindowLocale('titles:dba', 'DBA'),
  },
  {
    type: 'string',
    value: GC.FIELD_BRANCH_DOT_BRANCH_NAME,
    name: G.getWindowLocale('titles:owning-branch', 'Owning Branch'),
  },
  {
    type: 'string',
    value: GC.FIELD_ACCOUNTING_EXTERNAL_ID,
    name: G.getWindowLocale('titles:accounting-external-id', 'Accounting External ID'),
  },
  {
    type: 'string',
    value: GC.FIELD_US_DOT_NUMBER,
    name: G.getWindowLocale('titles:usdot-number', 'USDOT Number'),
  },
  {
    type: 'string',
    value: GC.FIELD_MC_NUMBER,
    name: G.getWindowLocale('titles:mc-number', 'MC Numbers'),
  },
  {
    type: 'string',
    value: GC.FIELD_CARRIER_DUNS_NUMBER,
    name: G.getWindowLocale('titles:duns-number', 'D-N-U-S Number'),
  },
  {
    type: 'string',
    value: GC.FIELD_LICENSE_NUMBER,
    name: G.getWindowLocale('titles:license-number', 'License Number'),
  },
  {
    type: 'string',
    value: GC.FIELD_CARRIER_URL,
    name: G.getWindowLocale('titles:url', 'Url'),
  },
  {
    type: 'string',
    value: GC.FIELD_CREATED_BY,
    name: G.getWindowLocale('titles:created-by', 'Created By'),
  },
  {
    type: 'date',
    value: GC.FIELD_CREATED_DATE,
    name: G.getWindowLocale('titles:created-date', 'Created Date'),
  },
  {
    type: 'string',
    value: GC.FIELD_LAST_MODIFIED_BY,
    name: G.getWindowLocale('titles:updated-by', 'Updated By'),
  },
  {
    type: 'date',
    value: GC.FIELD_LAST_MODIFIED_DATE,
    name: G.getWindowLocale('titles:updated-date', 'Updated Date'),
  },
  {
    type: 'string',
    value: GC.GRC.PAY_TO_LOCATION_LOCATION_NAME,
    name: `${payToLocationTxtLocale()}: ${G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PAY_TO_LOCATION_PAY_TYPE,
    name: `${payToLocationTxtLocale()}: ${G.getWindowLocale('titles:type', 'Type')}`,
  },
  {
    type: 'date',
    value: GC.GRC.PAY_TO_LOCATION_START_DATE,
    name: `${payToLocationTxtLocale()}: ${G.getWindowLocale('titles:start-date', 'Start Date')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PAY_TO_LOCATION_ADDRESS,
    name: `${payToLocationTxtLocale()}: ${G.getWindowLocale('titles:address', 'Address')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PAY_TO_LOCATION_ADDRESS2,
    name: `${payToLocationTxtLocale()}: ${G.getWindowLocale('titles:address2', 'Address2')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PAY_TO_LOCATION_COUNTRY,
    name: `${payToLocationTxtLocale()}: ${G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PAY_TO_LOCATION_STATE,
    name: `${payToLocationTxtLocale()}: ${G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PAY_TO_LOCATION_CITY,
    name: `${payToLocationTxtLocale()}: ${G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PAY_TO_LOCATION_ZIP,
    name: `${payToLocationTxtLocale()}: ${G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PRIMARY_LOCATION_LOCATION_NAME,
    name: `${primaryLocationTxtLocale()}: ${G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PRIMARY_LOCATION_ADDRESS1,
    name: `${primaryLocationTxtLocale()}: ${G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PRIMARY_LOCATION_ADDRESS2,
    name: `${primaryLocationTxtLocale()}: ${G.getWindowLocale('titles:address2', 'Address2')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PRIMARY_LOCATION_COUNTRY,
    name: `${primaryLocationTxtLocale()}: ${G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PRIMARY_LOCATION_STATE,
    name: `${primaryLocationTxtLocale()}: ${G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PRIMARY_LOCATION_CITY,
    name: `${primaryLocationTxtLocale()}: ${G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PRIMARY_LOCATION_ZIP,
    name: `${primaryLocationTxtLocale()}: ${G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PRIMARY_LOCATION_CONTACT_NAME,
    name: `${primaryLocationTxtLocale()}: ${G.getWindowLocale('titles:contact-name', 'titles:contact-name')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.PRIMARY_LOCATION_EMAILS_FIELD,
    name: `${primaryLocationTxtLocale()}: ${G.getWindowLocale('titles:emails', 'Emails')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PRIMARY_LOCATION_PHONE,
    name: `${primaryLocationTxtLocale()}: ${G.getWindowLocale('titles:phone', 'titles:phone')}`,
  },
  {
    type: 'string',
    value: GC.GRC.PRIMARY_LOCATION_PHONE_EXTENSION,
    name: `${primaryLocationTxtLocale()}: ${G.getWindowLocale('titles:phone-extension', 'titles:phone-extension')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.INTEGRATION_INFO_INTEGRATION_TYPE,
    name: `${G.getWindowLocale('titles:integration-info', 'Integration Info')}: ${
      G.getWindowLocale('titles:integration-type', 'Integration Type')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.INTEGRATION_INFO_INTEGRATION_ID,
    name: `${G.getWindowLocale('titles:integration-info', 'Integration Info')}: ${
      G.getWindowLocale('titles:integration-id', 'Integration ID')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.INTEGRATION_INFO_EXTERNAL_DIVISION_NAME,
    name: `${G.getWindowLocale('titles:integration-info', 'Integration Info')}: ${
      G.getWindowLocale('titles:division', 'Division')}`,
  },
  {
    type: 'date',
    value: GC.GRC.INTEGRATION_INFO_LAST_MODIFIED_DATE,
    name: `${G.getWindowLocale('titles:integration-info', 'Integration Info')}: ${
      G.getWindowLocale('titles:update-on', 'Updated On')}`,
  },
];
