import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const getSequenceRequest = createAction('getSequenceRequest');
export const getSequenceSuccess = createAction('getSequenceSuccess');
export const updateSequenceRequest = createAction('updateSequenceRequest');
export const updateSequenceSuccess = createAction('updateSequenceSuccess');
export const deleteSequenceRequest = createAction('deleteSequenceRequest');
export const deleteSequenceSuccess = createAction('deleteSequenceSuccess');
export const getSequencesListRequest = createAction('getSequencesListRequest');
export const getSequencesListSuccess = createAction('getSequencesListSuccess');
export const createNewSequenceRequest = createAction('createNewSequenceRequest');
export const createNewSequenceSuccess = createAction('createNewSequenceSuccess');
export const getSequenceNextValRequest = createAction('getSequenceNextValRequest');
export const getSequenceNextValSuccess = createAction('getSequenceNextValSuccess');
export const getSequencesByTypeRequest = createAction('getSequencesByTypeRequest');
export const getSequencesByTypeSuccess = createAction('getSequencesByTypeSuccess');
export const getSequenceTransformersRequest = createAction('getSequenceTransformersRequest');
export const getSequenceTransformersSuccess = createAction('getSequenceTransformersSuccess');
