import { createAction } from 'redux-act';
///////////////////////////////////////////////////////////////////////////////////////////////////

export const getStylingRequest = createAction('getStylingRequest');
export const getStylingSuccess = createAction('getStylingSuccess');
export const setStylingRequest = createAction('setStylingRequest');
export const setStylingSuccess = createAction('setStylingSuccess');
export const updateStylingRequest = createAction('updateStylingRequest');
export const updateStylingSuccess = createAction('updateStylingSuccess');
export const setSplashScreenRequest = createAction('setSplashScreenRequest');
export const setSplashScreenSuccess = createAction('setSplashScreenSuccess');
export const getSplashScreenRequest = createAction('getSplashScreenRequest');
export const getSplashScreenSuccess = createAction('getSplashScreenSuccess');
export const getInitialStylingSuccess = createAction('getInitialStylingSuccess');
export const getStylingByBranchSuccess = createAction('getStylingByBranchSuccess');
export const getSplashScreenByBranchRequest = createAction('getSplashScreenByBranchRequest');
export const getSplashScreenByBranchSuccess = createAction('getSplashScreenByBranchSuccess');
export const updateSplashScreenSettingsRequest = createAction('updateSplashScreenSettingsRequest');
export const updateSplashScreenSettingsSuccess = createAction('updateSplashScreenSettingsSuccess');
