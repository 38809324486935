import React from 'react';
// components
import { PopperWrapper } from '../../../components/popper';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box } from '../../../ui';
//////////////////////////////////////////////////

const PopperContentComponent = (props: Object) => <Box p={15}><Fieldset2 {...props} /></Box>;

export const AddDoctypeComponent = (props: Object) => (
  <PopperWrapper
    type='userPermissions'
    icon={I.arrowDownSimple()}
    filterCount={props.filterCount}
    popperWithCount={props.popperWithCount}
    text={G.getWindowLocale('titles:document-types', 'Document Types')}
    popperContent={
      <PopperContentComponent
        {...G.getFormikProps(props)}
        fields={props.fields}
        availableDocumentTypes={props.options}
      />
    }
  />
);
