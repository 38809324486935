import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../../helpers';
// feature template-inspection
import * as C from '../../constants';
import TruckBack from '../assets/truck-back';
import TruckSide from '../assets/truck-side';
import TruckFront from '../assets/truck-front';
import { RoofPartMarker } from '../assets/markers';
//////////////////////////////////////////////////

const TPS = R.prop('truckComponentTypes', C);

// Truck Front
const truckFrontConfig = {
  image: TruckFront,
  vehicleComponentTypes: {
    [TPS.IDENTIFICATION_LIGHTS]: {
      position: ['46.2%', '22%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-identification-lights',
        'Identification lights'),
    },
    [TPS.PASSENGER_SIDE_CLEARANCE_LIGHT]: {
      position: ['14%', '26%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-clearance-light',
        'Passenger side clearance light'),
    },
    [TPS.DRIVER_SIDE_CLEARANCE_LIGHT]: {
      position: ['78.4%', '26%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-clearance-light',
        'Driver side clearance light'),
    },
    [TPS.WINDSHIELD]: {
      position: ['46%', '38%'],
      name: G.getWindowLocale('titles:inspection-component-type-windshield', 'Windshield'),
    },
    [TPS.PASSENGER_SIDE_WINDSHIELD_WIPER]: {
      position: ['26%', '45%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-windshield-wiper',
        'Passenger side windshield wiper'),
    },
    [TPS.DRIVER_SIDE_WINDSHIELD_WIPER]: {
      position: ['65%', '45%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-windshield-wiper',
        'Driver side windshield wiper'),
    },
    [TPS.PASSENGER_SIDE_MIRROR]: {
      position: ['2%', '47%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-mirror',
        'Passenger side mirror'),
    },
    [TPS.DRIVER_SIDE_MIRROR]: {
      position: ['90%', '47%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-mirror',
        'Driver side mirror'),
    },
    [TPS.HOOD]: {
      position: ['46%', '50.75%'],
      name: G.getWindowLocale('titles:inspection-component-type-hood', 'Hood'),
    },
    [TPS.PASSENGER_SIDE_HEADLIGHT]: {
      position: ['17%', '62%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-headlight',
        'Passenger side headlight'),
    },
    [TPS.DRIVER_SIDE_HEADLIGHT]: {
      position: ['75%', '62%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-headlight',
        'Driver side headlight'),
    },
    [TPS.GRILLE]: {
      position: ['46%', '67%'],
      name: G.getWindowLocale('titles:inspection-component-type-grille', 'Grille'),
    },
    [TPS.FRONT_PASSENGER_SIDE_TURN_SIGNAL]: {
      position: ['7%', '68%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-front-passenger-side-turn-signal',
        'Front passenger side turn signal'),
    },
    [TPS.FRONT_DRIVER_SIDE_TURN_SIGNAL]: {
      position: ['85%', '68%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-front-driver-side-turn-signal',
        'Front driver side turn signal'),
    },
    [TPS.FRONT_PASSENGER_SIDE_MARKER_LIGHT]: {
      position: ['20%', '71%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-front-passenger-side-marker-light',
        'Front passenger side marker light'),
    },
    [TPS.FRONT_DRIVER_SIDE_MARKER_LIGHT]: {
      position: ['73%', '71%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-front-driver-side-marker-light',
        'Front driver side marker light'),
    },
    [TPS.PASSENGER_SIDE_FOG_LIGHT]: {
      position: ['16.5%', '85.75%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-fog-light',
        'Passenger side fog light'),
    },
    [TPS.DRIVER_SIDE_FOG_LIGHT]: {
      position: ['75.5%', '85.75%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-fog-light',
        'Driver side fog light'),
    },
    [TPS.FRONT_BUMPER]: {
      position: ['45.7%', '89%'],
      name: G.getWindowLocale('titles:inspection-component-type-bumper', 'Bumper'),
    },
  },
};

// Truck Back
const truckBackConfig = {
  image: TruckBack,
  vehicleComponentTypes: {
    [TPS.DRIVER_SIDE_REFLECTIVE_TAPE_CAB]: {
      position: ['30.5%', '6%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-reflective-tape-cab',
        'Driver side reflective tape cab'),
    },
    [TPS.PASSENGER_SIDE_REFLECTIVE_TAPE_CAB]: {
      position: ['61.75%', '6%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-reflective-tape-cab',
        'Passenger side reflective tape cab'),
    },
    [TPS.HEADACHE_RACK]: {
      position: ['46%', '26%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-headache-rack',
        'Headache rack'),
    },
    [TPS.FIFTH_WHEEL]: {
      position: ['46%', '64%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-fifth-wheel',
        'Fifth wheel'),
    },
    [TPS.DRIVER_SIDE_REVERSE_LIGHT]: {
      position: ['39.5%', '72.5%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-reverse-light',
        'Driver side reverse light'),
    },
    [TPS.PASSENGER_SIDE_REVERSE_LIGHT]: {
      position: ['53.5%', '72.5%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-reverse-light',
        'Passenger side reverse light'),
    },
    [TPS.DRIVER_SIDE_REFLECTIVE_TAPE]: {
      position: ['11%', '77%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-reflective-tape',
        'Driver side reflective tape'),
    },
    [TPS.REAR_DRIVER_SIDE_TURN_SIGNAL]: {
      position: ['31%', '77%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-driver-side-turn-signal',
        'Rear driver side turn signal'),
    },
    [TPS.REAR_PASSENGER_SIDE_TURN_SIGNAL]: {
      position: ['62%', '77%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-passenger-side-turn-signal',
        'Rear passenger side turn signal'),
    },
    [TPS.PASSENGER_SIDE_REFLECTIVE_TAPE]: {
      position: ['82%', '77%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-reflective-tape',
        'Passenger side reflective tape'),
    },
    [TPS.DRIVER_SIDE_BRAKE_TAIL_LIGHT_REFLECTORS]: {
      position: ['39.5%', '81.75%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-brake-tail-light-reflectors',
        'Driver side brake tail light reflectors'),
    },
    [TPS.PASSENGER_SIDE_BRAKE_TAIL_LIGHT_REFLECTORS]: {
      position: ['53.5%', '81.75%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-brake-tail-light-reflectors',
        'Passenger side brake tail light reflectors'),
    },
    [TPS.REAR_END_PROTECTION]: {
      position: ['46%', '89%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-end-protection',
        'Rear end protection'),
    },
    [TPS.REAR_DRIVER_SIDE_MUD_FLAP]: {
      position: ['21%', '96%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-driver-side-mud-flap',
        'Rear driver side mud flap'),
    },
    [TPS.REAR_PASSENGER_SIDE_MUD_FLAP]: {
      position: ['72%', '96%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-passenger-side-mud-flap',
        'Rear passenger side mud flap'),
    },
  },
};

// Truck Right
const truckRightConfig = {
  image: TruckSide,
  vehicleComponentTypes: {
    [TPS.PASSENGER_SIDE_PILLAR_A]: {
      position: ['73%', '20%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-pillar-a',
        'Passenger side pillar A'),
    },
    [TPS.PASSENGER_SIDE_PILLAR_B]: {
      position: ['59%', '21%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-pillar-b',
        'Passenger side pillar B'),
    },
    [TPS.PASSENGER_SIDE_SLEEPER_WINDOW]: {
      position: ['47.25%', '24%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-sleeper-window',
        'Passenger side sleeper window'),
    },
    [TPS.PASSENGER_SIDE_DOOR_GLASS]: {
      position: ['67%', '35%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-door-glass',
        'Passenger side door glass'),
    },
    [TPS.PASSENGER_SIDE_DOOR_HANDLE]: {
      position: ['60%', '52%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-door-handle',
        'Passenger side door handle'),
    },
    [TPS.PASSENGER_SIDE_SLEEPER_COMPARTMENT_BODY]: {
      position: ['41%', '56%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-sleeper-compartment-body',
        'Passenger side sleeper compartment body'),
    },
    [TPS.PASSENGER_SIDE_DOOR]: {
      position: ['71%', '56%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-door',
        'Passenger side door'),
    },
    [TPS.PASSENGER_SIDE_FENDER]: {
      position: ['80%', '63%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-fender',
        'Passenger side fender'),
    },
    [TPS.PASSENGER_SIDE_FUEL_TANK_AND_LINES]: {
      position: ['46%', '74%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-fuel-tank-and-lines',
        'Passenger side fuel tank and lines'),
    },
    [TPS.REAR_PASSENGER_SIDE_REAR_DRIVE_AXLE_WHEELS_RIMS_VALVE_CAPS]: {
      position: ['3%', '80%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-passenger-side-rear-drive-axle-wheels-rims-valve-caps',
        'Rear passenger side rear drive axle wheels rims valve caps'),
    },
    [TPS.REAR_PASSENGER_SIDE_FRONT_DRIVE_AXLE_WHEELS_RIMS_VALVE_CAPS]: {
      position: ['19%', '80%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-passenger-side-front-drive-axle-wheels-rims-valve-caps',
        'Rear passenger side front drive axle wheels rims valve caps'),
    },
    [TPS.FRONT_PASSENGER_SIDE_WHEEL_RIM_VALVE_CAP]: {
      position: ['74%', '80%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-front-passenger-side-wheel-rim-valve-cap',
        'Front passenger side wheel rim valve cap'),
    },
    [TPS.PASSENGER_SIDE_BATTERY_BOX_COVER]: {
      position: ['62%', '85%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-passenger-side-battery-box-cover',
        'Passenger side battery box cover'),
    },
    [TPS.REAR_PASSENGER_SIDE_REAR_DRIVE_AXLE_TIRES]: {
      position: ['9%', '92%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-passenger-side-rear-drive-axle-tires',
        'Rear passenger side rear drive axle tires'),
    },
    [TPS.REAR_PASSENGER_SIDE_FRONT_DRIVE_AXLE_TIRES]: {
      position: ['24.5%', '92%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-passenger-side-front-drive-axle-tires',
        'Rear passenger side front drive axle tires'),
    },
    [TPS.FRONT_PASSENGER_SIDE_TIRE]: {
      position: ['80%', '92%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-front-passenger-side-tire',
        'Front passenger side tire'),
    },
  },
};

// Truck Left
const truckLeftConfig = {
  flipImage: true,
  image: TruckSide,
  marker: RoofPartMarker,
  markerStyles: { top: -20, left: 0 },
  vehicleComponentTypes: {
    [TPS.ROOF]: {
      position: ['38%', '-13%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-roof',
        'Roof'),
    },
    [TPS.DRIVER_SIDE_PILLAR_A]: {
      position: ['73%', '20%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-pillar-a',
        'Driver side pillar A'),
    },
    [TPS.DRIVER_SIDE_PILLAR_B]: {
      position: ['59%', '21%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-pillar-b',
        'Driver side pillar B'),
    },
    [TPS.DRIVER_SIDE_SLEEPER_WINDOW]: {
      position: ['47.25%', '24%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-sleeper-window',
        'Driver side sleeper window'),
    },
    [TPS.DRIVER_SIDE_DOOR_GLASS]: {
      position: ['67%', '35%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-door-glass',
        'Driver side door glass'),
    },
    [TPS.DRIVER_SIDE_DOOR_HANDLE]: {
      position: ['60%', '52%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-door-handle',
        'Driver side door handle'),
    },
    [TPS.DRIVER_SIDE_DOOR]: {
      position: ['71%', '56%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-door',
        'Driver side door'),
    },
    [TPS.DRIVER_SIDE_SLEEPER_COMPARTMENT_BODY]: {
      position: ['41%', '56%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-sleeper-compartment-body',
        'Driver side sleeper compartment body'),
    },
    [TPS.DRIVER_SIDE_FENDER]: {
      position: ['80%', '63%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-fender',
        'Driver side fender'),
    },
    [TPS.DRIVER_SIDE_FUEL_TANK_AND_LINES]: {
      position: ['46%', '74%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-fuel-tank-and-lines',
        'Driver side fuel tank and lines'),
    },
    [TPS.FRONT_DRIVER_SIDE_WHEEL_RIM_VALVE_CAP]: {
      position: ['74%', '80%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-front-driver-side-wheel-rim-valve-cap',
        'Front driver side wheel rim valve cap'),
    },
    [TPS.REAR_DRIVER_SIDE_FRONT_DRIVE_AXLE_WHEELS_RIMS_VALVE_CAPS]: {
      position: ['19%', '80%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-driver-side-front-drive-axle-wheels-rims-valve-caps',
        'Rear driver side front drive axle wheels rims valve caps'),
    },
    [TPS.REAR_DRIVER_SIDE_REAR_DRIVE_AXLE_WHEELS_RIMS_VALVE_CAPS]: {
      position: ['3%', '80%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-driver-side-rear-drive-axle-wheels-rims-valve-caps',
        'Rear driver side rear drive axle wheels rims valve caps'),
    },
    [TPS.DRIVER_SIDE_BATTERY_BOX_COVER]: {
      position: ['62%', '85%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-driver-side-battery-box-cover',
        'Driver side battery box cover'),
    },
    [TPS.FRONT_DRIVER_SIDE_TIRE]: {
      position: ['80%', '92%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-front-driver-side-tire',
        'Front driver side tire'),
    },
    [TPS.REAR_DRIVER_SIDE_FRONT_DRIVE_AXLE_TIRES]: {
      position: ['24.5%', '92%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-driver-side-front-drive-axle-tires',
        'Rear driver side front drive axle tires'),
    },
    [TPS.REAR_DRIVER_SIDE_REAR_DRIVE_AXLE_TIRES]: {
      position: ['9%', '92%'],
      name: G.getWindowLocale(
        'titles:inspection-component-type-rear-driver-side-rear-drive-axle-tires',
        'Rear driver side rear drive axle tires'),
    },
  },
};

const truckConfigs = {
  [C.INSPECTION_TRUCK_BACK]: truckBackConfig,
  [C.INSPECTION_TRUCK_LEFT]: truckLeftConfig,
  [C.INSPECTION_TRUCK_RIGHT]: truckRightConfig,
  [C.INSPECTION_TRUCK_FRONT]: truckFrontConfig,
};

export default truckConfigs;
