import * as R from 'ramda';
// helpers/constants
import * as G from '../../../../../helpers';
// feature template-inspection
import * as C from '../../constants';
//////////////////////////////////////////////////

const VT = R.prop('valueTypeMap', C);

const defaultComponentFields = {
  name: null,
  expanded: true,
  required: true,
  description: null,
};

const defaultOptionValues = {
  [VT.value]: null,
  valueType: VT.value,
  [VT.valueMap]: null,
  [VT.valueList]: null,
  [VT.additionalValueMap]: null,
};

const inspectionCheckboxForPass = {
  ...defaultOptionValues,
  [VT.value]: false,
  type: C.INSPECTION_OPTION_TYPE_CHECKBOX,
  field: C.INSPECTION_REQUIRE_PHOTO_OR_COMMENT_FOR_PASS,
  label: G.getWindowLocale(
    'titles:inspection-require-photo-or-comment-for-pass',
    'Require photo or comment for "Pass"',
  ),
};

const inspectionCheckboxForFail = {
  ...defaultOptionValues,
  [VT.value]: false,
  type: C.INSPECTION_OPTION_TYPE_CHECKBOX,
  field: C.INSPECTION_REQUIRE_PHOTO_OR_COMMENT_FOR_FAIL,
  label: G.getWindowLocale(
    'titles:inspection-require-photo-or-comment-for-fail',
    'Require photo or comment for "Fail"',
  ),
};

const dateConfig = {
  ...defaultComponentFields,
  type: C.INSPECTION_DATE,
};

const timeConfig = {
  ...defaultComponentFields,
  type: C.INSPECTION_TIME,
};

const textConfig = {
  ...defaultComponentFields,
  type: C.INSPECTION_TEXT,
};

const photoConfig = {
  ...defaultComponentFields,
  type: C.INSPECTION_PHOTO,
};

const numberConfig = {
  ...defaultComponentFields,
  type: C.INSPECTION_NUMBER,
  options: [
    {
      ...defaultOptionValues,
      valueType: VT.valueMap,
      field: C.INSPECTION_PASSING_RANGE,
      type: C.INSPECTION_OPTION_TYPE_RANGE,
      [VT.valueMap]: {
        [C.INSPECTION_OPTION_TYPE_MINIMUM]: null,
        [C.INSPECTION_OPTION_TYPE_MAXIMUM]: null,
      },
      label: G.getWindowLocale('titles:inspection-passing-range', 'Passing Range'),
      renderItems: [
        {
          valueField: C.INSPECTION_OPTION_TYPE_MINIMUM,
          label: G.getWindowLocale('titles:inspection-minimum', 'Minimum'),
        },
        {
          valueField: C.INSPECTION_OPTION_TYPE_MAXIMUM,
          label: G.getWindowLocale('titles:inspection-maximum', 'Maximum'),
        },
      ],
    },
    inspectionCheckboxForPass,
    inspectionCheckboxForFail,
  ],
};

const passFailConfig = {
  ...defaultComponentFields,
  type: C.INSPECTION_PASS_FAIL,
  options: [
    {
      ...defaultOptionValues,
      valueType: VT.valueMap,
      field: C.INSPECTION_PASS_FAIL_VALUES,
      type: C.INSPECTION_OPTION_TYPE_INPUTS,
      [VT.valueMap]: {
        [C.INSPECTION_OPTION_PASS_VALUE]: 'Pass',
        [C.INSPECTION_OPTION_FAIL_VALUE]: 'Fail',
      },
      label: null,
      renderItems: [
        {
          valueField: C.INSPECTION_OPTION_PASS_VALUE,
          label: G.getWindowLocale('titles:inspection-pass-label', 'Pass Label'),
        },
        {
          valueField: C.INSPECTION_OPTION_FAIL_VALUE,
          label: G.getWindowLocale('titles:inspection-fail-label', 'Fail Label'),
        },
      ],
    },
    // TODO: uncomment after Mobile implementation
    // {
    //   ...defaultOptionValues,
    //   [VT.value]: false,
    //   field: C.INSPECTION_ENABLE_NA_OPTION,
    //   type: C.INSPECTION_OPTION_TYPE_CHECKBOX,
    //   [VT.additionalValueMap]: {
    //     [C.INSPECTION_OPTION_NA_VALUE]: 'N/A',
    //   },
    //   label: G.getWindowLocale(
    //     'titles:inspection-enable-na-option',
    //     'Enable N/A option',
    //   ),
    //   renderItems: [
    //     {
    //       inputType: 'text',
    //       valueField: C.INSPECTION_OPTION_NA_VALUE,
    //       label: G.getWindowLocale('titles:inspection-na-label', 'N/A Label'),
    //     },
    //   ],
    // },
    inspectionCheckboxForPass,
    inspectionCheckboxForFail,
  ],
};

const dropdownConfig = {
  ...defaultComponentFields,
  type: C.INSPECTION_DROPDOWN,
  options: [
    {
      ...defaultOptionValues,
      getValue: () => [
        { id: G.generateGuid(), value: null, failItem: false },
      ],
      minCount: 1,
      valueType: VT.valueList,
      field: C.INSPECTION_DROPDOWN_CHOICES,
      type: C.INSPECTION_OPTION_TYPE_DROPDOWN,
      label: G.getWindowLocale('titles:inspection-choices', 'Choices'),
      additionalLabel: G.getWindowLocale('titles:inspection-add-choice', 'Add Choice'),
    },
    inspectionCheckboxForPass,
    inspectionCheckboxForFail,
  ],
};

const checkboxesConfig = {
  ...defaultComponentFields,
  type: C.INSPECTION_CHECKBOX,
  options: [
    {
      ...defaultOptionValues,
      getValue: () => [
        { id: G.generateGuid(), value: null, failItem: false },
      ],
      minCount: 1,
      valueType: VT.valueList,
      field: C.INSPECTION_CHECKBOXES,
      type: C.INSPECTION_OPTION_TYPE_CHECKBOXES,
      label: G.getWindowLocale('titles:inspection-checkboxes', 'Checkboxes'),
      additionalLabel: G.getWindowLocale('titles:inspection-add-checkbox', 'Add Checkbox'),
    },
    inspectionCheckboxForPass,
    inspectionCheckboxForFail,
  ],
};

const radiosConfig = {
  ...defaultComponentFields,
  type: C.INSPECTION_RADIO,
  options: [
    {
      ...defaultOptionValues,
      getValue: () => [
        { id: G.generateGuid(), value: null, failItem: false },
        { id: G.generateGuid(), value: null, failItem: false },
      ],
      minCount: 2,
      valueType: VT.valueList,
      field: C.INSPECTION_RADIOS,
      type: C.INSPECTION_OPTION_TYPE_RADIO,
      label: G.getWindowLocale('titles:inspection-radios', 'Radios'),
      additionalLabel: G.getWindowLocale('titles:inspection-add-radio', 'Add Radio'),
    },
    inspectionCheckboxForPass,
    inspectionCheckboxForFail,
  ],
};

const meterConfig = {
  ...defaultComponentFields,
  type: C.INSPECTION_METER,
  options: [
    {
      ...defaultOptionValues,
      [VT.value]: false,
      type: C.INSPECTION_OPTION_TYPE_CHECKBOX,
      field: C.INSPECTION_REQUIRE_SECONDARY_METER,
      label: G.getWindowLocale(
        'titles:inspection-require-secondary-meter',
        'Require secondary meter',
      ),
    },
    {
      ...defaultOptionValues,
      [VT.value]: false,
      type: C.INSPECTION_OPTION_TYPE_CHECKBOX,
      field: C.INSPECTION_REQUIRE_PHOTO_VERIFICATION,
      label: G.getWindowLocale(
        'titles:inspection-require-photo-verification',
        'Require photo verification',
      ),
    },
  ],
};

export {
  dateConfig,
  timeConfig,
  textConfig,
  meterConfig,
  photoConfig,
  radiosConfig,
  numberConfig,
  dropdownConfig,
  passFailConfig,
  checkboxesConfig,
};
