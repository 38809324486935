import React from 'react';
// components
import { ActionsElement } from '../../../components/actions-element';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
//////////////////////////////////////////////////

const iconColor = G.getTheme('icons.iconColor');

const getOptions = ({ guid, handleEdit, handleRemove, handleSendListToIntegration }: Object) => [
  {
    action: handleEdit,
    frontIcon: I.pencil(iconColor),
    text: G.getWindowLocale('actions:edit', 'Edit'),
  },
  {
    action: handleRemove,
    frontIcon: I.trash(iconColor),
    permissions: [PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
    text: G.getWindowLocale('actions:delete', 'Delete'),
  },
  {
    frontIcon: I.gear(iconColor),
    permissions: [PC.SERVICE_VENDOR_WRITE],
    action: () => handleSendListToIntegration(guid),
    text: G.getWindowLocale('action:send-to-integration', 'Send to Integration'),
  },
];

const RowActions = (props: Object) => <ActionsElement options={getOptions(props)} />;

export default RowActions;
