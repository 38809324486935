import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportSelectors } from '../../report-common';
//////////////////////////////////////////////////

const {
  store,
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} = getReportSelectors('serviceVendor');

const selectFormStore = (state: Object) => state.form;

// service vendor
const makeSelectServiceVendor = () => createSelector(
  store,
  ({ profile }: Object) => R.assoc(
    GC.FIELD_SERVICE_TYPES,
    R.map(
      R.prop(GC.FIELD_DROPDOWN_OPTION_GUID),
      R.pathOr([], [GC.FIELD_SERVICE_TYPES], profile),
    ),
    profile,
  ),
);

const makeSelectServiceVendorGuid = () => createSelector(
  store,
  ({ profile }: Object) => G.getGuidFromObject(profile),
);

const makeSelectServiceVendorBranchGuid = () => createSelector(
  store,
  ({ profile }: Object) => G.getBranchGuidFromObject(profile),
);

const makeSelectCollapsedGroup = () => createSelector(
  store,
  ({ collapsedGroup }: Object) => collapsedGroup,
);

const makeSelectSelectedList = () => createSelector(
  store,
  R.compose(
    R.keys,
    R.filter(({ selected }: Object) => selected),
    R.pathOr([], ['itemList']),
  ),
);

const makeSelectVendorFormValues = () => createSelector(
  selectFormStore,
  (form: Object) => R.pathOr({}, ['EDIT_SERVICE_VENDOR', 'values'], form),
);

const makeSelectIntegrationInfoList = () => createSelector(
  store,
  ({ integrationInfoList }: Object) => integrationInfoList,
);

const makeSelectIntegrationAuditList = () => createSelector(
  store,
  ({ integrationAuditList }: Object) => integrationAuditList,
);

const makeSelectDocumentList = () => createSelector(
  store,
  ({ documentList, documentsFilter }: Object) => {
    if (G.isAnyNilOrEmpty([documentList, documentsFilter])) return documentList;

    return R.filter(
      ({ documentType }: Object) => R.propEq(documentsFilter, GC.FIELD_DROPDOWN_OPTION_GUID, documentType),
      documentList,
    );
  },
);

const makeSelectGeofencingZoneList = () => createSelector(
  store,
  ({ geofencingZoneList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.map(({ addressPoints, ...item }: Object) => ({
      ...item,
      disabled: false,
      zoneAddressPoints: addressPoints,
    })),
    R.values,
  )(geofencingZoneList),
);

const makeSelectUserGeneralList = () => createSelector(
  store,
  ({ userGeneralList }: Object) => userGeneralList,
);

const makeSelectDocumentsFilter = () => createSelector(
  store,
  ({ documentsFilter }: Object) => documentsFilter,
);
// service vendor

// configs
const makeSelectServiceVendorConfigs = () => createSelector(
  store,
  ({ configs }: Object) => configs,
);
// configs

export {
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectPageVisited,
  makeSelectListLoading,
  makeSelectDocumentList,
  makeSelectFilterParams,
  makeSelectReportStatus,
  makeSelectSelectedList,
  makeSelectServiceVendor,
  makeSelectCollapsedGroup,
  makeSelectDocumentsFilter,
  makeSelectUserGeneralList,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectVendorFormValues,
  makeSelectTableTitleFilters,
  makeSelectServiceVendorGuid,
  makeSelectGeofencingZoneList,
  makeSelectIntegrationInfoList,
  makeSelectServiceVendorConfigs,
  makeSelectIntegrationAuditList,
  makeSelectServiceVendorBranchGuid,
};
