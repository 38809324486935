// helpers/constants
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

export const contactSettings = {
  [GC.FIELD_CONTACT_BOOK_FIRST_NAME]: {
    name: 'titles:first-name',
  },
  [GC.FIELD_CONTACT_BOOK_LAST_NAME]: {
    name: 'titles:last-name',
  },
  [GC.FIELD_CONTACT_BOOK_EMAIL]: {
    name: 'titles:email',
  },
  [GC.FIELD_CONTACT_BOOK_PHONE]: {
    name: 'titles:phone-number',
  },
  [GC.FIELD_CONTACT_BOOK_POSITION]: {
    name: 'titles:position',
  },
  [GC.FIELD_CONTACT_BOOK_COMPANY]: {
    name: 'titles:company',
  },
  [GC.FIELD_CONTACT_BOOK_COMMENTS]: {
    width: 400,
    name: 'titles:comments',
  },
  [GC.FIELD_BRANCH_DOT_BRANCH_NAME]: {
    name: 'titles:branch',
  },
};
