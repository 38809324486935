import * as R from 'ramda';
import * as Yup from 'yup';
// features
import {
  getCustomChangeHandler2,
  getDateCustomBlurHandler,
  getTimeCustomBlurHandler,
  earlyCalendarDateTimeDisplayFunc,
  earlyCalendarDateAndTimeDisplayFunc,
} from '../../../new-do/settings/fields-settings';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

const validationSchema = () => Yup.object().shape({
  startDates: Yup.array().of(Yup.object().shape({
    [GC.FIELD_START_DATE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  })),
});

const validationSchemaReferenceObject = {
  [GC.FIELD_REFERENCE_TYPE_GUID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_VALUE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .min(1, G.getShouldBeFromToLocaleTxt(1, 250))
    .max(250, G.getShouldBeFromToLocaleTxt(1, 250)),
};

const validationSchemaContainerObject = {
  [GC.FIELD_CONTAINER_INITIAL]: Yup.string()
    .nullable(true)
    .notRequired(),
  [GC.FIELD_CONTAINER_NUMBER]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
};

const validationSchemaClo = {
  [GC.FIELD_REFERENCES]: Yup.array().of(Yup.object().shape(validationSchemaReferenceObject)),
  [GC.FIELD_CONTAINERS]: Yup.array().of(Yup.object().shape(validationSchemaContainerObject)),
};

const getValidationSchema = (clos: Array) => {
  const config = G.getConfigValueFromStoreOrWindow(GC.CLO_UI_SEPARATE_EVENT_DATE_AND_TIME);
  const routeValidationSchema = {
    [GC.FIELD_START_DATE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
    ...R.compose(
      R.map(() => Yup.object().shape(validationSchemaClo)),
      G.indexByGuid,
    )(clos),
  };

  const routeSchemaToUse = G.ifElse(
    config,
    R.assoc(GC.FIELD_START_TIME, Yup.string().nullable(true).required(G.getRequiredLocaleTxt()), routeValidationSchema),
    routeValidationSchema,
  );

  return Yup.object().shape({ routes: Yup.array().of(Yup.object().shape(routeSchemaToUse))});
};

const commonProps = {
  width: 180,
  isRequired: true,
  useMenuPortalTarget: true,
  inputWrapperStyles: {
    mr: 20,
  },
};

const commonProps2 = {
  width: 150,
  isRequired: true,
  inputWrapperStyles: {
    mr: 20,
  },
};

const getRouteFields = (i: number) => [
  {
    width: 220,
    isRequired: true,
    timeIntervals: 1,
    type: 'datePicker',
    timeSelection: true,
    inputWrapperStyles: {
      zIndex: 13,
      m: '20px 20px 0 0',
      display: earlyCalendarDateTimeDisplayFunc,
    },
    name: 'titles:early-date',
    placeholder: GC.DEFAULT_DATE_TIME_PLACEHOLDER,
    fieldName: `routes.${i}.${GC.FIELD_START_DATE}`,
    label: G.getWindowLocale('titles:start-date', 'Start Date'),
  },
  {
    width: 112,
    isRequired: true,
    type: 'datePicker',
    inputWrapperStyles: {
      zIndex: 13,
      m: '20px 20px 0 0',
      display: earlyCalendarDateAndTimeDisplayFunc,
    },
    shouldCustomBlur: true,
    shouldCustomChange: true,
    placeholder: GC.DEFAULT_DATE_PLACEHOLDER,
    fieldName: `routes.${i}.${GC.FIELD_START_DATE}`,
    label: G.getWindowLocale('titles:start-date', 'Start Date'),
    customBlurHandler: getDateCustomBlurHandler(
      ['routes', i, GC.FIELD_START_TIME],
      `routes.${i}.${GC.FIELD_START_DATE}`,
    ),
    customChangeHandler2: getCustomChangeHandler2(
      ['routes', i, GC.FIELD_START_TIME],
      `routes.${i}.${GC.FIELD_START_DATE}`,
    ),
  },
  {
    width: 110,
    isRequired: true,
    type: 'timeInput',
    shouldCustomBlur: true,
    inputWrapperStyles: {
      mr: 10,
      m: '20px 20px 0 0',
      display: earlyCalendarDateAndTimeDisplayFunc,
    },
    placeholder: GC.DEFAULT_TIME_AM_PLACEHOLDER,
    fieldName: `routes.${i}.${GC.FIELD_START_TIME}`,
    label: G.getWindowLocale('titles:time', 'Time'),
    customBlurHandler: getTimeCustomBlurHandler(['routes', i, GC.FIELD_START_DATE]),
  },
];

const getDefaultFields = () => {
  const config = G.getConfigValueFromStoreOrWindow(GC.CLO_UI_SEPARATE_EVENT_DATE_AND_TIME);

  const currentDateTime = G.getCurrentDayWithTime();

  const defaultFields = {
    [GC.FIELD_START_DATE]: currentDateTime,
  };

  if (config) {
    return {
      ...defaultFields,
      [GC.FIELD_START_DATE]: G.getCurrentDay(),
      [GC.FIELD_START_TIME]: G.getTimeByConfigFormat(currentDateTime),
    };
  }

  return defaultFields;
};

const getNewReferenceFields = () => ({
  uniq: G.generateGuid(),
  [GC.FIELD_VALUE]: null,
  [GC.FIELD_REFERENCE_TYPE_GUID]: null,
});

const getContainerFields = (containerInternalId: string) => ({
  containerInternalId,
  uniq: G.generateGuid(),
  [GC.FIELD_CONTAINER_NUMBER]: null,
  [GC.FIELD_CONTAINER_INITIAL]: null,
});

const mapReferences = ((references: Object) => {
  if (G.isNilOrEmpty(references)) R.of(Array, getNewReferenceFields());

  return R.map(R.assoc('value', null), references);
});

const getNewRow = (clos: Array) => {
  const cloFields = R.reduce(
    (acc: Object, { guid, references, containers }: Object) => {
      const refsToUse = mapReferences(references);

      const containersToUse = R.map(
        ({ containerInternalId }: Object) => getContainerFields(containerInternalId),
        R.or(containers, []),
      );

      return R.assoc(
        guid,
        {
          [GC.FIELD_REFERENCES]: R.map(R.assoc('uniq', G.generateGuid()), refsToUse),
          [GC.FIELD_CONTAINERS]: R.map(R.assoc('uniq', G.generateGuid()), containersToUse),
        },
        acc,
      );
    },
    { uniq: G.generateGuid() },
    clos,
  );

  return R.mergeRight(getDefaultFields(), cloFields);
};

const getCopyRow = R.compose(
    R.mapObjIndexed((value: Object, key: string) => {
      if (G.isAnyTrue(R.equals(key, 'startDate'), R.equals(key, 'startTime'), R.equals(key, 'uniq'))) return value;

      return R.assoc(
        'containers',
        R.map((container: Object) => (
          {...container, [GC.FIELD_CONTAINER_NUMBER]: null, [GC.FIELD_CONTAINER_INITIAL]: null }
        ), G.getPropFromObject('containers', value)),
        value,
      );
    }),
    R.clone,
  );

const getFields = (index: number) => [
  [
    {
      ...commonProps,
      type: 'reactSelect',
      options: 'referenceTypes',
      fieldName: GC.FIELD_REFERENCE_TYPE_GUID,
      label: G.getWindowLocale('titles:name', 'Name'),
      closeMenuOnScroll: R.pathEq('route_wrapper', ['target', 'id']),
    },
    {
      ...commonProps,
      type: 'text',
      fieldName: GC.FIELD_VALUE,
      label: G.getWindowLocale('titles:value', 'Value'),
    },
  ],
  [
    {
      ...commonProps,
      type: 'reactSelect',
      options: 'referenceTypes',
      fieldName: GC.FIELD_REFERENCE_TYPE_GUID,
      label: G.getWindowLocale('titles:name', 'Name'),
      closeMenuOnScroll: R.pathEq('route_wrapper', ['target', 'id']),
    },
    {
      ...commonProps,
      type: 'reactSelect',
      options: 'allowedValues',
      fieldName: GC.FIELD_VALUE,
      label: G.getWindowLocale('titles:value', 'Value'),
      closeMenuOnScroll: R.pathEq('route_wrapper', ['target', 'id']),
    },
  ],
][index];

const getAllowedValues = (props: Object) => {
  const { values, cloGuid, itemIndex, routeIndex, referenceTypes } = props;

  const itemRefTypeGuid = R.path(
    ['routes', routeIndex, cloGuid, GC.FIELD_REFERENCES, itemIndex, GC.FIELD_REFERENCE_TYPE_GUID],
    values,
  );

  return R.path(
    ['allowedValues'],
    R.find(R.propEq(itemRefTypeGuid, GC.FIELD_GUID), R.pathOr([], [GC.REF_SCOPE_NAME_CLO], referenceTypes)),
  );
};

const getAllowedOptions = (props: Object) => {
  const allowedValues = getAllowedValues(props);

  if (G.isNotNilAndNotEmpty(allowedValues)) {
    return R.map(
      (item: string) => ({ label: item, value: item }),
      getAllowedValues(props),
    );
  }

  return [];
};

const getReferenceFields = (props: Object) => {
  const allowedValues = getAllowedValues(props);

  let fields;

  if (G.isNotNilAndNotEmpty(allowedValues)) {
    fields = getFields(1);
  } else {
    fields = getFields(0);
  }

  if (G.isNotNil(R.path(['item', GC.FIELD_GUID], props))) {
    fields = R.assocPath([0, 'disabled'], true, fields);
  }

  return fields;
};

const containerFields = [
  {
    ...commonProps2,
    type: 'text',
    isRequired: false,
    fieldName: GC.FIELD_CONTAINER_INITIAL,
    label: G.getWindowLocale('titles:container-initial', 'Container Initial'),
  },
  {
    ...commonProps2,
    type: 'text',
    fieldName: GC.FIELD_CONTAINER_NUMBER,
    label: G.getWindowLocale('titles:container-number', 'Container Number'),
  },
];

export {
  getFields,
  getNewRow,
  getCopyRow,
  getRouteFields,
  containerFields,
  getDefaultFields,
  validationSchema,
  getAllowedOptions,
  getReferenceFields,
  getContainerFields,
  validationSchemaClo,
  getValidationSchema,
  getNewReferenceFields,
};
