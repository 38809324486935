import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
// components
import { ActionsElement } from '../../../../components/actions-element';
// features
import PC from '../../../permission/role-permission';
import { AuthWrapper } from '../../../permission/index';
// icons
import * as I from '../../../../svgs';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { IconWrapper } from '../../../../ui';
// feature template-inspection
import * as A from '../actions';
import * as C from '../constants';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.dark.blue');

const getOptions = ({
  handleCopyFromTemplate,
}: Object) => ([
  {
    action: handleCopyFromTemplate,
    frontIcon: I.refresh(iconColor, 15, 15),
    // TODO: change inspection permissions later on
    permissions: [PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
    // permissions: [PC.INSPECTION_TEMPLATE_WRITE],
    text: G.getWindowLocale(
      'actions:inspection-copy-from-template',
      'Create copy from template',
    ),
  },
]);

const DotsComponent = ({
  item,
  openFixedPopup,
  closeFixedPopup,
}: Object) => {
  const dispatch = useDispatch();

  const handleCopyFromTemplate = useCallback(() => {
    closeFixedPopup();

    dispatch(A.getInspectionTemplateRequest({
      currentTemplate: item,
      action: C.INSPECTION_TEMPLATE_COPY,
    }));
  }, [item, closeFixedPopup]);

  const handleClickEditIcon = useCallback(({ currentTarget }: Event) => openFixedPopup({
    position: 'right',
    el: currentTarget,
    content: (
      <ActionsElement version={2} options={getOptions({ handleCopyFromTemplate })} />
    ),
  }), [openFixedPopup, handleCopyFromTemplate]);

  // TODO: change inspection permissions later on
  /* <AuthWrapper has={[PC.INSPECTION_TEMPLATE_WRITE]}> */
  return (
    <AuthWrapper has={[PC.ROLE_LIUBANCHYK_SUPER_ADMIN]}>
      <IconWrapper px={12} cursor='pointer' onClick={(e: Event) => handleClickEditIcon(e)}>
        {I.threeDots()}
      </IconWrapper>
    </AuthWrapper>
  );
};

export default DotsComponent;
