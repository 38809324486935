// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const ROLE_BRANCH_CONSTANT = GC.BRANCH_UPPERCASE;
const ROLE_ACTIVATE_BRANCH_CONSTANT = `ACTIVATE_${GC.BRANCH_UPPERCASE}`;
const ROLE_VIEW_INACTIVE_BRANCH_CONSTANT = `VIEW_INACTIVE_${GC.BRANCH_UPPERCASE}`;

export const ROLE_LOCALES = {
  // Carrier
  CARRIER: G.getWindowLocale('titles:carrier', 'Carrier'),
  CREATE_CARRIER: G.getWindowLocale('actions:create-carrier', 'Create Carrier'),
  ACTIVATE_CARRIER: G.getWindowLocale('titles:activate-carrier', 'Activate Carrier'),
  // service vendor
  SERVICE_VENDOR: G.getWindowLocale('titles:service-vendor', 'Service Vendor'),
  // Templates
  CONTACT_BOOK: G.getWindowLocale('titles:contact-book', 'Contact Book'),
  ITEM_TEMPLATE: G.getWindowLocale('titles:item-template', 'Item Template'),
  LOCATION_TEMPLATE: G.getWindowLocale('titles:location-template', 'Location Template'),
  CONTAINER_TEMPLATE: G.getWindowLocale('titles:container-template', 'Container Template'),
  INSPECTION_TEMPLATE: G.getWindowLocale('titles:inspection-template', 'Inspection Template'),
  COMPENSATION_TEMPLATE: G.getWindowLocale('titles:compensation-template', 'Compensation Template'),
  // Branch
  STYLING: G.getWindowLocale('titles:styling', 'Styling'),
  [ROLE_BRANCH_CONSTANT]: G.getWindowLocale('titles:branch', 'Branch'),
  CREDIT_LIMIT: G.getWindowLocale('titles:credit-limit', 'Credit Limit'),
  CREATE_DIVISION: G.getWindowLocale('titles:create-division', 'Create Division'),
  // TODO: check title
  [ROLE_ACTIVATE_BRANCH_CONSTANT]: G.getWindowLocale('titles:activate-branch', 'Activate Branch'),
  [ROLE_VIEW_INACTIVE_BRANCH_CONSTANT]: G.getWindowLocale('titles:view-inactive-branch', 'View Inactive Branch'),
  CHANGE_COMMISSION_ASSIGNMENT: G.getWindowLocale(
    'titles:change-commission-assignment',
    'Change Commission Assignment',
  ),
  // Administration
  ROLES: G.getWindowLocale('titles:roles', 'Roles'),
  IMPORT: G.getWindowLocale('titles:import', 'Import'),
  REPORT: G.getWindowLocale('titles:report', 'Report'),
  SEND_EMAIL: G.getWindowLocale('titles:send-email', 'Send Email'),
  SPLASH_SCREEN: G.getWindowLocale('titles:splash-screen', 'Splash Screen'),
  EXTERNAL_LOAD_BOARD: G.getWindowLocale('titles:load-search', 'Load Search'),
  REFERENCE_TYPE: G.getWindowLocale('titles:reference-type', 'Reference Type'),
  CONFIGURATIONS: G.getWindowLocale('titles:configurations', 'Configurations'),
  DOCUMENT_TEMPLATE: G.getWindowLocale('titles:document-template', 'Document Template'),
  REPORT_CHANGE_OWNER: G.getWindowLocale('titles:report-change-owner', 'Report Change Owner'),
  // Users
  USER: G.getWindowLocale('titles:user', 'User'),
  USER_PROFILE: G.getWindowLocale('titles:user-profile', 'User Profile'),
  SUSPEND_USER: G.getWindowLocale('titles:suspend-user', 'Suspend User'),
  USER_REFERENCES: G.getWindowLocale('titles:user-references', 'User References'),
  // Fleet
  IFTA: G.getWindowLocale('titles:ifta', 'IFTA'),
  FLEET_TRUCK: G.getWindowLocale('titles:fleet-truck', 'Fleet Truck'),
  CARRIER_EDI: G.getWindowLocale('titles:carrier-edi', 'Carrier EDI'),
  TOLL_CHARGE: G.getWindowLocale('titles:toll-charges', 'Toll Charges'),
  DRIVER_HOURS: G.getWindowLocale('titles:driver-hours', 'Driver Hours'),
  FLEET_DRIVER: G.getWindowLocale('titles:fleet-driver', 'Fleet Driver'),
  FLEET_VENDOR: G.getWindowLocale('titles:fleet-vendor', 'Fleet Vendor'),
  CHANGE_TRUCK: G.getWindowLocale('titles:change-truck', 'Change Truck'),
  FLEET_TRAILER: G.getWindowLocale('titles:fleet-trailer', 'Fleet Trailer'),
  FUEL_CARD: G.getWindowLocale('titles:fuel-transactions', 'Fuel Transactions'),
  ENABLE_FUEL_CARD: G.getWindowLocale('titles:enable-fuel-card', 'Enable Fuel Card'),
  AVAILABLE_DRIVER_LIST: G.getWindowLocale('titles:available-drivers', 'Available Drivers'),
  FLEET_TRAILER_SHARING: G.getWindowLocale('titles:fleet-trailer-sharing', 'Fleet Trailer Sharing'),
  FLEET_DRIVER_ACCOUNTING: G.getWindowLocale('titles:fleet-driver-accounting', 'Fleet Driver Accounting'),
  FLEET_VENDOR_ACCOUNTING: G.getWindowLocale('titles:fleet-vendor-accounting', 'Fleet Vendor Accounting'),
  // Route
  // Clo
  CLO: G.getWindowLocale('titles:clo', 'CLO'),
  CLO_EDI: G.getWindowLocale('titles:order-edi', 'Order EDI'),
  CLO_EVENT: G.getWindowLocale('titles:clo-stop', 'CLO Stop'),
  INVOICE_EDI: G.getWindowLocale('titles:invoice-edi', 'Invoice EDI'),
  CLO_BILL_TO: G.getWindowLocale('titles:clo-bill-to', 'CLO Bill To'),
  ORDER_QUOTE: G.getWindowLocale('titles:order-quote', 'Order Quote'),
  CLO_DELETE: G.getWindowLocale('titles:order-delete', 'Order Delete'),
  PUBLIC_API_ORDER: G.getWindowLocale('titles:order-api', 'Order API'),
  CLO_DOCUMENT: G.getWindowLocale('titles:clo-document', 'CLO Document'),
  CLO_ENTERPRISE: G.getWindowLocale('titles:clo-branch', 'Order Branch'),
  CLO_APPROVE: G.getWindowLocale('titles:order-approve', 'Order Approve'),
  CLO_REFERENCE: G.getWindowLocale('titles:clo-reference', 'CLO Reference'),
  CLO_DUPLICATE: G.getWindowLocale('titles:order-duplicate', 'Order Duplicate'),
  CLO_CHAT_MESSAGE: G.getWindowLocale('titles:clo-chat-message', 'CLO Chat Message'),
  CLO_STATUS_MESSAGE: G.getWindowLocale('titles:clo-status-message', 'CLO Status Message'),
  CLO_ADMINISTRATION: G.getWindowLocale('titles:clo-administration', 'CLO Administration'),
  ORDER_QUOTE_OVERWRITE: G.getWindowLocale('titles:order-quote-overwrite', 'Order Quote Overwrite'),
  CLO_CHANGE_PRIMARY_REFERENCE: G.getWindowLocale('titles:order-change-primary-ref', 'Order Change Primary Ref.'),
  // TEL
  TEL: G.getWindowLocale('titles:tel', 'TEL'),
  TEL_EVENT: G.getWindowLocale('titles:tel-stop', 'TEL Stop'),
  TEL_DELETE: G.getWindowLocale('titles:trip-delete', 'Trip Delete'),
  ITEM_DAMAGE: G.getWindowLocale('titles:item-damage', 'Item Damage'),
  TEL_BILL_TO: G.getWindowLocale('titles:tel-bill-to', 'TEL Bill To'),
  TEL_ENTERPRISE: G.getWindowLocale('titles:tel-branch', 'Trip Branch'),
  TEL_DOCUMENT: G.getWindowLocale('titles:tel-document', 'TEL Document'),
  TEL_REFERENCE: G.getWindowLocale('titles:tel-reference', 'TEL Reference'),
  TEL_CHAT_MESSAGE: G.getWindowLocale('titles:tel-chat-message', 'TEL Chat Message'),
  TEL_STATUS_MESSAGE: G.getWindowLocale('titles:tel-status-message', 'TEL Status Message'),
  TEL_ADMINISTRATION: G.getWindowLocale('titles:tel-administration', 'TEL Administration'),
  TEL_CHANGE_PRIMARY_REFERENCE: G.getWindowLocale('titles:trip-change-primary-ref', 'Trip Change Primary Ref.'),
  TEL_RATE_DISPATCH_BY_OVERWRITE: G.getWindowLocale(
    'titles:trip-rate-dispatched-by-overwrite',
    'Trip Rate Dispatched By Overwrite',
  ),
  DISPATCH_ACCEPT: G.getWindowLocale(
    'titles:accept-decline-driver-carrier-rate',
    'Accept/Decline Driver or Carrier Rate',
  ),
  // Analytics
  CHART: G.getWindowLocale('titles:charts', 'Charts'),
  DASHBOARD: G.getWindowLocale('titles:dashboards', 'Dashboards'),
  // Financial
  GL_CODE: G.getWindowLocale('titles:gl-code', 'GL Code'),
  CLO_RATE: G.getWindowLocale('titles:clo-rate', 'Order Rate'),
  RATE_SHOP: G.getWindowLocale('titles:rate-shop', 'Rate Shop'),
  FLEET_RATE: G.getWindowLocale('titles:fleet-rate', 'Fleet Rate'),
  TEL_INVOICE: G.getWindowLocale('titles:tel-invoice', 'Trip Invoice'),
  CLO_INVOICE: G.getWindowLocale('titles:clo-invoice', 'Order Invoice'),
  CARRIER_RATE: G.getWindowLocale('titles:carrier-rate', 'Carrier Rate'),
  SHOW_EARNINGS: G.getWindowLocale('titles:show-earnings', 'Show Earnings'),
  DRIVER_PAYROLL: G.getWindowLocale('titles:driver-payroll', 'Driver Payroll'),
  FLEET_VENDOR_PAYROLL: G.getWindowLocale('titles:vendor-payroll', 'Vendor Payroll!'),
  TEL_FLEET_INVOICE: G.getWindowLocale('titles:tel-fleet-invoice', 'Trip Fleet Invoice'),
  CLO_MASTER_INVOICE: G.getWindowLocale('titles:clo-master-invoice', 'Order Master Invoice'),
  TEL_CARRIER_INVOICE: G.getWindowLocale('titles:tel-carrier-invoice', 'Trip Carrier Invoice'),
  CUSTOM_EXCHANGE_RATE: G.getWindowLocale('titles:custom-exchange-rate', 'Custom Exchange Rate'),
  TEL_INVOICE_OVERWRITE: G.getWindowLocale('titles:tel-invoice-overwrite', 'Trip Invoice Overwrite'),
  CLO_INVOICE_OVERWRITE: G.getWindowLocale('titles:clo-invoice-overwrite', 'Order Invoice Overwrite'),
  DRIVER_PAYROLL_OVERWRITE: G.getWindowLocale('titles:driver-payroll-overwrite', 'Driver Payroll Overwrite'),
  MARGIN_VIOLATION_APPROVAL: G.getWindowLocale('titles:margin-violation-approval', 'Margin Violation Approval'),
  FLEET_VENDOR_PAYROLL_OVERWRITE: `${G.getWindowLocale('titles:vendor-payroll', 'VendorPayroll')} ${
    G.getWindowLocale('titles:overwrite')}`,
  CARRIER_INTEGRATION_ACCOUNT_NUMBER: G.getWindowLocale(
    'titles:carrier-integration-account-number',
    'Carrier Integration Account Number',
  ),
  CHANGE_CARRIER_RATE_PICKUP_NUMBER: G.getWindowLocale(
    'titles:change-carrier-rate-pickup-number',
    'Change Carrier Rate Pickup Number',
  ),
  CHANGE_CARRIER_RATE_TRACKING: G.getWindowLocale(
    'titles:change-carrier-rate-tracking',
    'Change Carrier Rate Tracking',
  ),
  CLO_MASTER_INVOICE_OVERWRITE: G.getWindowLocale(
    'titles:clo-master-invoice-overwrite',
    'CLO Master Invoice Overwrite',
  ),
  // delete
  TEL_INVOICE_DELETE: G.getWindowLocale('titles:trip-invoice-delete', 'Trip Invoice Delete'),
  DRIVER_PAYROLL_DELETE: G.getWindowLocale('titles:driver-payroll-delete', 'Driver Payroll Delete'),
  CLO_INVOICE_DELETE: G.getWindowLocale('titles:customer-invoice-delete', 'Customer Invoice Delete'),
  CLO_MASTER_INVOICE_DELETE: G.getWindowLocale('titles:master-invoice-delete', 'Master Invoice Delete'),
  FLEET_VENDOR_PAYROLL_DELETE: G.getWindowLocale('titles:vendor-payroll-delete', 'Vendor Payroll Delete'),
  // Task Management
  TASK: G.getWindowLocale('titles:task', 'Task'),
  TASK_BOARD: G.getWindowLocale('titles:task-board', 'Task Board'),
  // Claim
  CLAIM: G.getWindowLocale('titles:claim', 'Claim'),
};
