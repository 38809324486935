import React from 'react';
import * as R from 'ramda';
// components
import { ItemsList } from '../../../../components';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { Flex, StyledLink, MainActionButton } from '../../../../ui';
// feature template
import useScheduleRule from '../../recurring-schedule/hooks/use-schedule-rule';
//////////////////////////////////////////////////

// TODO: with proper UI and handlers

const AddComponent = (props: Object) => {
  const { addItem } = props;

  return (
    <MainActionButton
      height={26}
      type='button'
      onClick={addItem}
    >
      {G.getWindowLocale('titles:add-schedule-rule', 'Add Schedule Rule')}
    </MainActionButton>
  );
};

const itemToTextMapper = (item: Object) => {
  const {
    repeat,
    weekNd,
    frequency,
    startDate,
    weeklyDays,
  } = item;

  if (R.equals(frequency, 'weekly')) {
    const days = G.createStringFromArray(weeklyDays, ', ');

    return `Every ${repeat} week(s) on ${days} from ${startDate}`;
  }

  if (R.equals(frequency, 'monthly')) {
    const days = G.createStringFromArray(weeklyDays, ', ');

    const weekNdText = G.createStringFromArray(weekNd, 'nd, ');

    return `Every ${repeat} month(s) on ${weekNdText}nd ${days} from ${startDate}`;
  }

  return `Every ${repeat} day(s) from ${startDate}`;
};

const ScheduleRecurringTab = (props: Object) => {
  const href = 'https://support.route4me.com/repeating-route-templates-for-recurring-schedule-delivery-routes/#create-master';

  return (
    <Flex p={15} alignItems='start' flexDirection='column'>
      <ItemsList
        showActive={true}
        showActions={true}
        listHook={useScheduleRule}
        AddComponent={AddComponent}
        itemToTextMapper={itemToTextMapper}
      />
      {/* <StyledLink my={10} target='blank' href={href}>Create Recurring Schedule Like There</StyledLink>
      <MainActionButton
        height={26}
        type='button'
        onClick={() => null}
      >
        {G.getWindowLocale('titles:add-schedule-exception', 'Add Schedule Exception')}
      </MainActionButton> */}
    </Flex>
  );
};

export {
  ScheduleRecurringTab,
};
