import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  compose,
  lifecycle,
  withState,
  withProps,
  withHandlers,
} from 'react-recompose';
// features
import ItemForm from '../../item/components/item-form';
import LocationForm from '../../location/components/location-form';
// forms
import AccessorialForm from '../../../forms/forms/accessorial-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature template-report
import { itemFormTitleMap } from './settings';
import ContactForm from './components/contact-form';
import ContainerForm from './components/container-form';
import { makeSelectAccessorialConfigs } from './selectors';
import { CompensationTemplateForm } from './components/compensation-form';
import { getAccessorialConfigsSuccess, createOrUpdateTemplateItemRequest } from './actions';
//////////////////////////////////////////////////

const accessorialMapStateToProps = (state: Object) => createStructuredSelector({
  accessorialConfigs: makeSelectAccessorialConfigs(state),
});

const accesorrialsEnhace = compose(
  connect(accessorialMapStateToProps, { getAccessorialConfigsSuccess }),
  withState('sharedAccessorial', 'setSharedAccessorial', {}),
  withState('accessorialConfigs', 'setAccessorialConfigs', R.path(['accessorialConfigs'])),
  withHandlers({
    handleGetAccessorialConfigs: (props: Object) => async () => {
      const { openLoader, closeLoader, setAccessorialConfigs, getAccessorialConfigsSuccess } = props;

      G.callFunction(openLoader());

      const options = {
        params: { [GC.BRANCH_GUID]: G.getAmousCurrentBranchGuidFromWindow() },
      };

      const res = await sendRequest('get', endpointsMap.accessorialsListEndpoint, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setAccessorialConfigs(data);
        getAccessorialConfigsSuccess(data);
      } else {
        G.handleFailResponseSimple(res);
      }

      G.callFunction(closeLoader());
    },
    handleGetSharedAccessorial: (props: Object) => async () => {
      const {
        guid,
        openLoader,
        closeLoader,
        accessorialConfigs,
        setSharedAccessorial,
      } = props;

      G.callFunction(openLoader);
      const endpoint = endpointsMap.getSharedAccessorialByGuid(guid);

      const res = await sendRequest('get', endpoint);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const { origin, destination, assessorialGuid } = data;

        const sharedAccessorial = R.mergeRight(
          R.omit(['origins', 'destinations'], data),
          {
            ...R.pick(
              [GC.FIELD_FUEL_RELATED, GC.FIELD_NON_TAXABLE],
              R.pathOr({}, [assessorialGuid], accessorialConfigs),
            ),
            useGeoFencingZone: R.or(G.isNotNilAndNotEmpty(origin), G.isNotNilAndNotEmpty(destination)),
          },
        );

        setSharedAccessorial(sharedAccessorial);
      } else {
        G.handleFailResponseSimple(res);
      }

      G.callFunction(closeLoader);
    },
  }),
  lifecycle({
    componentWillMount() {
      const { accessorialConfigs, handleGetAccessorialConfigs } = this.props;

      if (G.isNilOrEmpty(accessorialConfigs)) handleGetAccessorialConfigs();
    },
    componentDidMount() {
      const { guid, handleGetSharedAccessorial } = this.props;

      if (G.isNotNilAndNotEmpty(guid)) handleGetSharedAccessorial();
    },
  }),
  withProps(({ sharedAccessorial, accessorialConfigs }: Object) => {
    const accessorialConfigOptions = R.map(({ displayedValue, originalConfigGuid }: Object) => ({
      [GC.FIELD_LABEL]: displayedValue,
      [GC.FIELD_VALUE]: originalConfigGuid,
    }), accessorialConfigs);

    const accessorialConfigList = R.compose(
      R.indexBy(R.prop(GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID)),
      R.map((item: Object) => R.compose(
        R.assoc(GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID, R.prop(GC.FIELD_ORIGINAL_CONFIG_GUID, item)),
        R.pick([
          GC.FIELD_NON_TAXABLE,
          GC.FIELD_FUEL_RELATED,
          GC.FIELD_DISPLAYED_VALUE,
          GC.FIELD_CHARGE_RATE_TYPE,
          GC.FIELD_CHARGE_RATE_UNIT,
        ]),
      )(item)),
    )(accessorialConfigs);

    let initialValues = {};

    if (G.isNotNilAndNotEmpty(sharedAccessorial)) {
      initialValues = R.mergeRight(
        sharedAccessorial,
        R.pick(
          [GC.FIELD_FUEL_RELATED, GC.FIELD_NON_TAXABLE],
          R.pathOr({}, [sharedAccessorial.assessorialGuid], accessorialConfigList),
        ),
      );
    }

    return {
      width: 910,
      initialValues,
      accessorialConfigList,
      accessorialConfigOptions,
      importFuelIndexVariables: true,
      fromSharedAccessorialList: true,
    };
  }),
  pure,
);

const EnhancedAccessorialForm = accesorrialsEnhace(AccessorialForm);

export const withCreateOrUpdateForm = compose(
  connect(null, { createOrUpdateTemplateItemRequest }),
  withHandlers({
    handleCreateOrUpdateTemplateItem: (props: Object) => () => {
      const {
        guid,
        openModal,
        closeModal,
        reportType,
        openLoader,
        closeLoader,
        closeFixedPopup,
        createOrUpdateTemplateItemRequest,
      } = props;

      G.callFunction(closeFixedPopup);

      const componentProps = {
        guid,
        openModal,
        closeModal,
        openLoader,
        closeLoader,
        submitAction: createOrUpdateTemplateItemRequest,
      };

      const componentMap = {
        [GC.CONTACT_BOOK_REPORT]: <ContactForm {...componentProps} />,
        [GC.CONTAINER_TEMPLATE_REPORT]: <ContainerForm {...componentProps} />,
        [GC.ITEM_REPORT]: <ItemForm {...componentProps} reportType={reportType} />,
        [GC.SHARED_ACCESSORIAL_REPORT]: <EnhancedAccessorialForm {...componentProps} />,
        [GC.LOCATION_TEMPLATE_REPORT]: <LocationForm {...componentProps} reportType={reportType} fromTemplate={true} />,
        [GC.COMPENSATION_TEMPLATE_REPORT]: (
          <CompensationTemplateForm
            {...componentProps}
            isTemplateForm={true}
            isEditMode={G.isNotNilAndNotEmpty(guid)}
            asyncEndpoint={endpointsMap.getCompensationTemplateEndpoint(guid)}
          />
        ),
      };

      const component = R.propOr(null, reportType, componentMap);

      const title = G.ifElse(
        G.isNilOrEmpty(guid),
        G.getAddTitle,
        G.getEditTitle,
      )(R.propOr([], reportType, itemFormTitleMap));

      const modalPadding = G.ifElse(R.includes(reportType, [GC.ITEM_REPORT, GC.LOCATION_TEMPLATE_REPORT]), '0', 15);

      const modal = {
        component,
        p: modalPadding,
        options: {
          title,
          width: 'auto',
          height: 'auto',
        },
      };

      return openModal(modal);
    },
  }),
);
