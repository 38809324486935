import * as R from 'ramda';
import * as Yup from 'yup';
import { FieldArray } from 'formik';
import React, { Fragment } from 'react';
// components
import { TextComponent } from '../../../components/text';
import { FormSectionHeader } from '../../../components/form-section-header';
// features
import NormalizedCurrency from '../../../components/charge/normalized-currency';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, StickedFlex } from '../../../ui';
// feature work-order
import { TotalComponent } from './invoices-totals';
import { getRowTotal, getSubTotal, getCostsTotal } from '../helpers';
//////////////////////////////////////////////////

const feesFieldNames = [GC.FIELD_NAME, GC.FIELD_COST];
const laborsFieldNames = [GC.FIELD_NAME, GC.FIELD_COST, GC.FIELD_QUANTITY];
const partsFieldNames = [GC.FIELD_NAME, GC.FIELD_PART_NUMBER, GC.FIELD_COST, GC.FIELD_QUANTITY];

const costsValidationSchema = {
  [GC.FIELD_COST]: G.yupNumberRequired,
  [GC.FIELD_NAME]: G.yupStringRequired,
  [GC.FIELD_QUANTITY]: G.yupNumberRequired,
  [GC.FIELD_PART_NUMBER]: G.yupStringRequired,
};

const getCostArrayValidationShema = (fieldNames: Array) =>
  Yup.array().of(Yup.object().shape(R.pick(fieldNames, costsValidationSchema)));

export const validationSchemaCostsObject = {
  [GC.FIELD_FEES]: getCostArrayValidationShema(feesFieldNames),
  [GC.FIELD_PARTS]: getCostArrayValidationShema(partsFieldNames),
  [GC.FIELD_LABORS]: getCostArrayValidationShema(laborsFieldNames),
};

const defaultCostFields = {
  [GC.FIELD_COST]: '',
  [GC.FIELD_NAME]: '',
  [GC.FIELD_QUANTITY]: 1,
  [GC.FIELD_PART_NUMBER]: '',
};

const getNewCostFields = (fieldNames: string) =>
  R.assoc(GC.FIELD_ID, G.genShortId(), R.pick(fieldNames, defaultCostFields));

const costsInputWrapperStyles = { mr: 20, flexGrow: 1, flexBasis: 85 };

const costFieldSettings = {
  [GC.FIELD_NAME]: {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_NAME,
    label: ['titles:name', 'Name'],
    inputWrapperStyles: { mr: 20, width: 220 },
  },
  [GC.FIELD_COST]: {
    type: 'number',
    isRequired: true,
    fieldName: GC.FIELD_COST,
    label: ['titles:cost', 'Cost'],
    inputWrapperStyles: costsInputWrapperStyles,
  },
  [GC.FIELD_PART_NUMBER]: {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_PART_NUMBER,
    inputWrapperStyles: costsInputWrapperStyles,
    label: ['titles:part-number', 'Part Number'],
  },
  [GC.FIELD_QUANTITY]: {
    type: 'number',
    isRequired: true,
    fieldName: GC.FIELD_QUANTITY,
    label: ['titles:quantity', 'Quantity'],
    inputWrapperStyles: costsInputWrapperStyles,
  },
};

const getFieldSettings = (fieldNames: Array) => R.values(R.pick(fieldNames, costFieldSettings));

const CostRow = (props: Object) => {
  const { values, fields, remove, itemIndex, arrayName, arrayLength, currencySymbol } = props;

  const rowTotal = getRowTotal(
    R.pathOr(0, [arrayName, itemIndex, GC.FIELD_COST], values),
    R.pathOr(1, [arrayName, itemIndex, GC.FIELD_QUANTITY], values),
  );

  const rowTotalText = `${currencySymbol} ${rowTotal}`;

  return (
    <Flex mt={25} justifyContent='space-between'>
      <Flex>
        <Flex mr={10} cursor='pointer' onClick={() => remove(itemIndex)}>
          {I.trash(G.getTheme('colors.dark.blue'))}
        </Flex>
        <Fieldset2
          {...G.getFormikProps(props)}
          {...G.getArrayFormikProps(props)}
          fields={fields}
          arrayName={arrayName}
          arrayLength={arrayLength}
        />
      </Flex>
      <Flex width={165} flexShrink={0} justifyContent='flex-end'>
        <Box
          mr='8px'
          fontWeight={700}
          textTransform='uppercase'
          color={G.getTheme('colors.darkGrey')}
        >
          {G.getWindowLocale('titles:total', 'Total')}:
        </Box>
        <TextComponent
          maxWidth={110}
          display='block'
          fontWeight={700}
          withEllipsis={true}
          title={rowTotalText}
        >
          {rowTotalText}
        </TextComponent>
      </Flex>
    </Flex>
  );
};

const CostFooter = ({ costs, currencySymbol }: Object) => {
  const subTotal = getSubTotal(costs);
  const subTotalText = `${currencySymbol} ${subTotal}`;

  return (
    <Flex px={15} width='100%' flexShrink={0} justifyContent='flex-end'>
      <Box
        mr='8px'
        fontWeight={700}
        textTransform='uppercase'
        color={G.getTheme('colors.darkGrey')}
      >
        {G.getWindowLocale('titles:subtotal', 'Subtotal')}:
      </Box>
      <TextComponent
        maxWidth={300}
        display='block'
        fontWeight={700}
        withEllipsis={true}
        title={subTotalText}
      >
        {subTotalText}
      </TextComponent>
    </Flex>
  );
};

const CostsFieldArray = (props: Object) => {
  const { name, title, values, fieldNames } = props;

  const costs = R.pathOr([], [name], values);
  const currencySymbol = G.getCurrencySymbolFromRate(values);

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers: Object) => (
        <FormSectionHeader
          expanded={true}
          title={G.getWindowLocale(...title)}
          action={() => arrayHelpers.push(getNewCostFields(fieldNames))}
        >
          {
            G.isNotNilAndNotEmpty(costs) &&
            <Fragment>
              <Box px={15} mb={15}>
                {
                  costs.map((_: any, index: number) => (
                    <CostRow
                      {...props}
                      {...arrayHelpers}
                      key={index}
                      arrayName={name}
                      itemIndex={index}
                      arrayLength={R.length(costs)}
                      currencySymbol={currencySymbol}
                      fields={getFieldSettings(fieldNames)}
                    />
                  ))
                }
              </Box>
              <CostFooter costs={costs} currencySymbol={currencySymbol} />
            </Fragment>
          }
        </FormSectionHeader>
      )}
    />
  );
};

const NormalizedTotal = ({ normalizedTotal }: Object) => {
  const { total, currency, exchangeDate } = normalizedTotal;

  const currencySymbol = G.getCurrencySymbol(currency);

  return (
    <TotalComponent
      mr={15}
      maxWidth={270}
      totalText={`${G.getWindowLocale('titles:normalized-total', 'Normalized Total')}: ${
        currencySymbol} ${total} (${exchangeDate})`}
    />
  );
};

const CostsSectionFooter = ({ values, isEditMode }: Object) => {
  const { currency, normalizedTotal } = values;

  const costsTotal = getCostsTotal(values);
  const currencySymbol = G.getCurrencySymbol(currency);

  const showNormalizedTotal = G.isAllTrue(
    isEditMode,
    G.isNotNilAndNotEmpty(normalizedTotal),
    R.not(R.pathEq(currency, [GC.FIELD_CURRENCY], normalizedTotal)),
  );

  return (
    <StickedFlex
      mt={10}
      p='5px'
      bottom='0px'
      width='100%'
      justifyContent='center'
      borderBottom='1px solid'
      bg={G.getTheme('colors.whiteGrey')}
      borderColor={G.getTheme('colors.light.darkGrey')}
    >
      {
        showNormalizedTotal && <NormalizedTotal normalizedTotal={normalizedTotal} />
      }
      <TotalComponent
        maxWidth={150}
        totalText={`${G.getWindowLocale('titles:total', 'Total')}: ${currencySymbol} ${costsTotal}`}
      />
      <NormalizedCurrency
        p='0 0 0 15px'
        total={costsTotal}
        sourceCurrency={currency}
        title={G.getWindowLocale('titles:current-normalized-total', 'Current Normalized Total')}
      />
    </StickedFlex>
  );
};

const costsSettings = [
  {
    name: GC.FIELD_LABORS,
    fieldNames: laborsFieldNames,
    title: ['titles:labor', 'Labor'],
  },
  {
    name: GC.FIELD_PARTS,
    fieldNames: partsFieldNames,
    title: ['titles:parts', 'Parts'],
  },
  {
    name: GC.FIELD_FEES,
    fieldNames: feesFieldNames,
    title: ['titles:fees', 'Fees'],
  },
];

export const CostsSection = (props: Object) => (
  <Fragment>
    {
      costsSettings.map((setting: Object) => (
        <CostsFieldArray {...props} {...setting} key={G.getPropFromObject(GC.FIELD_NAME, setting)} />
      ))
    }
    <CostsSectionFooter {...props} />
  </Fragment>
);
