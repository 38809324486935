import * as R from 'ramda';
import React, { Fragment } from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ItemsList } from '../../../../components';
import { ScheduleRouteEvents } from '../../../../components/events';
// forms
import AppointmentsTime from '../../../../forms/forms/appointments-form/appointments-time';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withConnectModalAndLoaderActions } from '../../../../hocs';
// ui
import { Box, Flex } from '../../../../ui';
// feature template
import { TITLES_MAP } from '../../constants';
//////////////////////////////////////////////////

const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

// eslint-disable-next-line arrow-body-style
const useCarriers = (items: Array) => {
  return { items };
};

const itemToTextMapper = (item: Object, data: Object) => {
  const {
    currency,
    commitmentCount,
    carrierAssignment,
    normalizedTotalOrTotal,
  } = item;

  const { name, mcNumber, usDotNumber } = carrierAssignment;

  const { dispatchType } = data;

  const textItems = [
    {
      label: null,
      value: name,
    },
    {
      value: mcNumber,
      label: TITLES_MAP.mc,
    },
    {
      value: usDotNumber,
      label: TITLES_MAP.dot,
    },
    {
      label: TITLES_MAP.rate,
      value: `${normalizedTotalOrTotal} ${currency}`,
    },
  ];

  let defaultText = R.compose(
    G.createStringFromArrayCurried(', '),
    R.map(({ label, value }: Object) => {
      if (G.isNilOrEmpty(value)) return null;

      if (G.isNilOrEmpty(label)) return value;

      return `${label}: ${value}`;
    }),
  )(textItems);

  if (R.equals(dispatchType, GC.DISPATCH_TYPE_COMMIT)) {
    defaultText = `${defaultText} ${TITLES_MAP.commitments}: ${R.or(commitmentCount, 0)}`;
  }

  if (R.equals(dispatchType, GC.DISPATCH_TYPE_ROLL)) {
    defaultText = `${defaultText} ${TITLES_MAP.roll}`;
  }

  return defaultText;
};

const getCarriers = (data: Object) => {
  const { carrierRate, carrierRates } = data;

  if (G.isNotNilAndNotEmpty(carrierRates)) return carrierRates;

  if (G.isNotNilAndNotEmpty(carrierRate)) return [carrierRate];

  return null;
};

const withAppointmentsTime = compose(
  withConnectModalAndLoaderActions,
  withHandlers({
    handleSetAppointmentTime: (props: Object) => (event: Object) => {
      const { values, activeTab, dayOfWeek, openModal, closeModal, setFieldValue } = props;

      const pickArray = [
        GC.FIELD_GUID,
        GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME,
        GC.FIELD_LOAD_APPOINTMENT_LATE_TIME,
      ];

      const eventGuid = G.getGuidFromObject(event);

      const appointmentsFromRoute = R.path([dayOfWeek, activeTab, 'eventData', eventGuid], values);

      const initialValues = G.isNotNilAndNotEmpty(appointmentsFromRoute) ?
        appointmentsFromRoute : R.pick(pickArray, event);

      const submitAction = (data: Object) => {
        const { guid } = data;

        const routes = R.assocPath([activeTab, 'eventData', guid], data, values[dayOfWeek]);

        setFieldValue(dayOfWeek, routes);

        G.callFunction(closeModal);
      };

      const modalContent = (
        <AppointmentsTime
          submitAction={submitAction}
          initialValues={initialValues}
        />
      );

      const modal = G.getDefaultModalOptions(
        modalContent,
        G.getWindowLocale('titles:appointments-time', 'Appointments Time'),
      );

      openModal(modal);
    },
  }),
  pure,
);

const LoadInfo = withAppointmentsTime((props: Object) => {
  const {
    data,
    clos,
    index,
    values,
    realRoute,
    activeTab,
    dayOfWeek,
    loadTitle,
    setFieldValue,
    handleSetAppointmentTime,
  } = props;

  const { guid, events, enterpriseGuid, enterpriseName } = data;

  const carriers = getCarriers(data);

  return (
    <Box width='100%' color={greyMatterhornColor}>
      <Box mb={10} fontSize={14} fontWeight='bold'>{`${loadTitle} ${index} - ${enterpriseName}`}</Box>
      <ScheduleRouteEvents
        loads={clos}
        events={events}
        loadGuid={guid}
        values={values}
        dayOfWeek={dayOfWeek}
        activeTab={activeTab}
        showEditStopsBtn={false}
        showEditStopIcon={false}
        branchGuid={enterpriseGuid}
        loadType={GC.LOAD_TYPE_TEL}
        setFieldValue={setFieldValue}
        showSetAppointmentTime={realRoute}
        handleSetAppointmentTime={handleSetAppointmentTime}
      />
      {
        carriers &&
        <Fragment>
          <Box ml={15} mt={15} fontSize={14} fontWeight='bold'>{G.getWindowLocale('titles:carriers', 'Carriers')}</Box>
          <ItemsList
            listHook={useCarriers}
            initialItems={carriers}
            itemToTextMapper={(item: Object) => itemToTextMapper(item, data)}
          />
        </Fragment>
      }
    </Box>
  );
});

const getTels = (props: Object) => {
  const { values, activeTab, dayOfWeek, asyncInitialData } = props;

  const { data: { tels, events } } = asyncInitialData;

  return R.map(
    (tel: Object) => R.assoc(
      GC.FIELD_LOAD_STOPS,
      R.compose(
        R.map((event: Object) => {
          const eventGuid = G.getGuidFromObject(event);

          const appointmentsFromRoute = R.path([dayOfWeek, activeTab, 'eventData', eventGuid], values);

          if (G.isNotNilAndNotEmpty(appointmentsFromRoute)) return { ...event, ...appointmentsFromRoute };

          return event;
        }),
        R.filter(R.propEq(G.getGuidFromObject(tel), GC.FIELD_TEL_GUID)),
      )(events),
      tel,
    ),
    tels,
  );
};

const RouteTrips = (props: Object) => {
  const { clos, values, activeTab, dayOfWeek, realRoute, setFieldValue } = props;

  const tels = getTels(props);

  return (
    <Box>
      {
        tels.map((tel: Object, i: number) => (
          <Flex
            key={i}
            pt={16}
            alignItems='flex-start'
          >
            <LoadInfo
              data={tel}
              clos={clos}
              values={values}
              index={R.inc(i)}
              realRoute={realRoute}
              activeTab={activeTab}
              dayOfWeek={dayOfWeek}
              setFieldValue={setFieldValue}
              loadTitle={G.getWindowLocale('titles:tel', 'Trip')}
            />
          </Flex>
        ))
      }
    </Box>
  );
};

export {
  RouteTrips,
};
