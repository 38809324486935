import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { useCallback } from 'react';
import { createStructuredSelector } from 'reselect';
// components
import { Table } from '../../../components/table';
import { TextComponent } from '../../../components/text';
import { EditReport } from '../../../components/edit-report';
import { ConfirmComponent } from '../../../components/confirm';
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import Audit2 from '../../audit2';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { PageWrapper } from '../../../ui';
// feature task-management
import TitleTabs from '../components/title-tabs';
import { FILTER_PARAMS } from '../report/settings';
import {
  makeSelectAvailableTaskBoards,
  makeSelectTaskTypesTaskStatuses,
} from '../selectors';
import {
  getTaskBoardRequest,
  setSelectedTaskBoard,
  removeTaskBoardRequest,
  updateTaskStatusRequest,
  createOrUpdateTaskRequest,
  setDefaultTaskBoardRequest,
  createOrUpdateTaskBoardRequest,
  setEntityListByEntityTypeSuccess,
} from '../actions';
//////////////////////////////////////////////////

export const tableSettings = {
  cellFontSize: 13,
  titleFontSize: 14,
  tableRowHeight: 40,
  titleRowHeight: 45,
  allowEditBtn: false,
  moreBtnCellWidth: 50,
  allowSelectAll: false,
  checkBoxCellWidth: 80,
  expandableItems: false,
  allowSelectItems: false,
  maxHeight: 'calc(100vh - 145px)',
  checkBoxCellJustifyContent: 'unset',
};

export const columnSettings = {
  [GC.FIELD_BRANCH_DOT_BRANCH_NAME]: {
    width: 200,
    name: 'titles:branch-name',
    customComponent: (row: Object) => {
      const title = G.getBranchNameFromWindowByGuid(R.path(['data', GC.FIELD_BRANCH_GUID], row));

      return (
        <TextComponent title={title} maxWidth='100%' withEllipsis={true}>
          {title}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_NAME]: {
    width: 150,
    name: 'titles:name',
  },
  [GC.FIELD_DESCRIPTION]: {
    width: 300,
    name: 'titles:description',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:last-modified-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:last-modified-date',
  },
};

const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(columnSettings)),
};

const useHandlers = ({
  openModal,
  getTaskBoardRequest,
  setSelectedTaskBoard,
  removeTaskBoardRequest,
  createOrUpdateTaskBoardRequest,
  taskTypesTaskStatuses: { taskStatuses },
}: Object) => {
  const handleEdit = useCallback((guid: string, selectedTaskBoard: Object) => {
    const fields = R.map(
      ({ name, guid }: Object) => ({ name, value: guid, statusGuid: guid, type: 'string' }),
      R.or(taskStatuses, []),
    );

    const makeBoard = (board: Object) => {
      const { fields, defaultReport } = board;

      const columns = R.map(({ name, sequence }: Object) => ({ sequence, statusGuid: name }), fields);

      return R.mergeRight(
        R.omit(['fields', 'defaultReport'], board),
        { columns, defaultBoard: defaultReport },
      );
    };

    const createOrUpdatedBoardRequest = (board: Object) =>
      createOrUpdateTaskBoardRequest(makeBoard(board));

    const reportFields = R.map(({ guid, freezed, statusGuid }: Object) => ({
      guid,
      freezed,
      [GC.FIELD_NAME]: statusGuid,
    }), R.propOr([], 'columns', selectedTaskBoard));

    const usedReport = R.mergeRight(
      selectedTaskBoard,
      { fields: reportFields, owner: R.prop(GC.FIELD_CREATED_BY, selectedTaskBoard) },
    );

    const component = (
      <EditReport
        fields={fields}
        requestPending={false}
        usedReport={usedReport}
        disableSortByFields={true}
        useAdditionalFilters={true}
        additionalFilters={FILTER_PARAMS}
        onReportSet={getTaskBoardRequest}
        createReportRequest={createOrUpdatedBoardRequest}
        updateReportRequest={createOrUpdatedBoardRequest}
        addFieldText={G.getWindowLocale('titles:add-status', 'Add Status')}
        setReport={(board: Object) => setSelectedTaskBoard(makeBoard(board))}
        editReportText={G.getWindowLocale('titles:edit-board', 'Edit Board')}
        addFieldsText={G.getWindowLocale('titles:add-statuses', 'Add Statuses')}
        updateReportText={G.getWindowLocale('titles:update-board', 'Update Board')}
        createNewReportText={G.getWindowLocale('titles:create-new-board', 'Create New Board')}
      />
    );

    const modal = G.getDefaultReportModal(component);

    openModal(modal);
  }, [taskStatuses]);

  const handleRemove = useCallback((guid: string, { name }: Object) => {
    const component = (
      <ConfirmComponent
        name={name}
        textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
      />
    );


    const modal = {
      component,
      options: {
        width: 600,
        controlButtons: [
          {
            type: 'button',
            action: () => removeTaskBoardRequest(guid),
            name: G.getWindowLocale('actions:delete', 'Delete'),
          },
        ],
      },
    };
    openModal(modal);
  });

  const handleOpenAudit = useCallback((_: any, { guid, name }: Object) => {
    const requestOptions = ({
      [GC.FIELD_OBJECT_GUID]: guid,
      [GC.FIELD_TYPE]: GC.AUDIT_TYPE_REPORT,
    });

    const component = <Audit2 requestOptions={requestOptions} />;

    const modal = {
      p: '0',
      component,
      options: {
        boxShadow: 'none',
        withCloseIcon: true,
        withBorderRadius: true,
        backgroundColor: 'transparent',
        title: `${G.getWindowLocale('titles:audit', 'Audit')}: ${name}`,
      },
    };

    openModal(modal);
  });

  return { handleEdit, handleRemove, handleOpenAudit };
};

const TaskManagementBoardList = (props: Object) => {
  const { itemList } = props;

  const { handleEdit, handleRemove, handleOpenAudit } = useHandlers(props);

  // TODO: check permissions
  const actionButtons = [
    {
      iconName: 'pencil',
      action: handleEdit,
      permissions: [PC.TASK_BOARD_WRITE],
    },
    {
      iconName: 'trash',
      action: handleRemove,
      permissions: [PC.TASK_BOARD_WRITE],
    },
    {
      permissions: null,
      iconSize: [16, 16],
      action: handleOpenAudit,
      iconName: 'renderAuditHistoryIcon',
    },
  ];

  const data = {
    report,
    itemList,
    tableSettings,
    actionButtons,
    columnSettings,
  };

  return (
    <PageWrapper pt={30} pl={65}>
      <TitleTabs />
      <Table {...data} />;
    </PageWrapper>
  );
};


const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectAvailableTaskBoards(state),
  taskTypesTaskStatuses: makeSelectTaskTypesTaskStatuses(state),
});

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  openLoader,
  closeLoader,
  getTaskBoardRequest,
  setSelectedTaskBoard,
  removeTaskBoardRequest,
  updateTaskStatusRequest,
  createOrUpdateTaskRequest,
  setDefaultTaskBoardRequest,
  createOrUpdateTaskBoardRequest,
  setEntityListByEntityTypeSuccess,
})(TaskManagementBoardList);
