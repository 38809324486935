import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////

const {
  setReports,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  deleteItemSuccess,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
} = getReportActions();

// configs
const getTaskTypeListSuccess = createAction('getTaskTypeListSuccess');
const getTaskTypeListRequest = createAction('getTaskTypeListRequest');
const getTaskStatusListSuccess = createAction('getTaskStatusListSuccess');
const getTaskStatusListRequest = createAction('getTaskStatusListRequest');
const getGroupedByTaskTypesTaskStatusMapRequest = createAction('getGroupedByTaskTypesTaskStatusMapRequest');
const getGroupedByTaskTypesTaskStatusMapSuccess = createAction('getGroupedByTaskTypesTaskStatusMapSuccess');
  // item
const updateTaskStatusSuccess = createAction('updateTaskStatusSuccess');
const updateTaskStatusRequest = createAction('updateTaskStatusRequest');
const createOrUpdateTaskRequest = createAction('createOrUpdateItemRequest');
const createOrUpdateTaskSuccess = createAction('createOrUpdateTaskSuccess');
// entities
const setObjectGuid = createAction('setObjectGuid');
const setAssigneeGuid = createAction('setAssigneeGuid');
const setEntityListByEntityTypeSuccess = createAction('setEntityListByEntityTypeSuccess');
// board
const getTaskBoardRequest = createAction('getTaskBoardRequest');
const removeTaskFromBoard = createAction('removeTaskFromBoard');
const getTaskBoardSuccess = createAction('getTaskBoardSuccess');
const setSelectedTaskBoard = createAction('setSelectedTaskBoard');
const removeTaskBoardRequest = createAction('removeTaskBoardRequest');
const removeTaskBoardSuccess = createAction('removeTaskBoardSuccess');
const setTaskBoardListLoading = createAction('setTaskBoardListLoading');
const getTaskBoardSummarySuccess = createAction('getTaskBoardSummarySuccess');
const setDefaultTaskBoardRequest = createAction('setDefaultTaskBoardRequest');
const setTaskBoardGlobalFilterValue = createAction('setTaskBoardGlobalFilterValue');
const getAvailableTaskBoardsRequest = createAction('getAvailableTaskBoardsRequest');
const getAvailableTaskBoardsSuccess = createAction('getAvailableTaskBoardsSuccess');
const createOrUpdateTaskBoardRequest = createAction('createOrUpdateTaskBoardRequest');
const resetTaskBoardListAndPagination = createAction('resetTaskBoardListAndPagination');
const setTaskBoardAdditionalFilterValue = createAction('setTaskBoardAdditionalFilterValue');
const createOrUpdateTaskBoardItemSuccess = createAction('createOrUpdateTaskBoardItemSuccess');

export {
  // report
  setReports,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  deleteItemSuccess,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  // configs
  getTaskTypeListSuccess,
  getTaskTypeListRequest,
  getTaskStatusListSuccess,
  getTaskStatusListRequest,
  getGroupedByTaskTypesTaskStatusMapSuccess,
  getGroupedByTaskTypesTaskStatusMapRequest,
  // item
  updateTaskStatusRequest,
  updateTaskStatusSuccess,
  createOrUpdateTaskSuccess,
  createOrUpdateTaskRequest,
  // entities
  setObjectGuid,
  setAssigneeGuid,
  setEntityListByEntityTypeSuccess,
  // board
  removeTaskFromBoard,
  getTaskBoardSuccess,
  getTaskBoardRequest,
  setSelectedTaskBoard,
  removeTaskBoardRequest,
  removeTaskBoardSuccess,
  setTaskBoardListLoading,
  getTaskBoardSummarySuccess,
  setDefaultTaskBoardRequest,
  getAvailableTaskBoardsRequest,
  setTaskBoardGlobalFilterValue,
  getAvailableTaskBoardsSuccess,
  createOrUpdateTaskBoardRequest,
  resetTaskBoardListAndPagination,
  setTaskBoardAdditionalFilterValue,
  createOrUpdateTaskBoardItemSuccess,
};
