import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature template-report
import * as A from './actions';
//////////////////////////////////////////////////

const initialPagination = { limit: 20, offset: 0 };

const initialState = {
  listType: null,
  reportType: null,
  filterParams: {},
  usedReport: null,
  reportPending: false,
  containerTypes: null,
  accessorialConfigs: [],
  availableReports: null,
  globalFilterValue: null,
};

const initialListState = {
  totalCount: 0,
  itemList: null,
  titleSortValues: {},
  tableTitleFilters: {},
  pagination: initialPagination,
};

const setInitialState = (state: Object, { listType, reportType }: Object) => {
  if (R.equals(listType, 'listUp')) {
    return {
      ...initialState,
      listType,
      reportType,
      listUp: initialListState,
      listDown: initialListState,
    };
  }

  return {
    ...initialState,
    listType,
    reportType,
    list: initialListState,
  };
};

// report
const getItemListSuccess = (state: Object, data: Object) => {
  const { results, totalCount } = data;
  const { listType, reportType } = state;

  const pagination = R.path([listType, 'pagination'], state);
  const itemList = R.pathOr({}, [listType, 'itemList'], state);
  const newOffset = R.sum(R.values(pagination));
  const offsetToUse = G.ifElse(
    R.gt(totalCount, newOffset),
    newOffset,
    totalCount,
  );
  const indexAdditional = R.length(R.values(itemList));
  const currentBranchGuid = G.getAmousCurrentBranchGuidFromWindow();
  const list = R.compose(
    R.mergeRight(itemList),
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) => R.mergeRight(
      item,
      {
        selected: false,
        expanded: false,
        index: R.add(index, indexAdditional),
        [GC.FIELD_GUID]: G.ifElse(
          R.equals(reportType, GC.CLO_TEMPLATE_REPORT),
          G.getPropFromObject(GC.FIELD_TEMPLATE_GUID, item),
          G.getGuidFromObject(item),
        ),
        editable: G.ifElse(
          R.includes(reportType, [GC.SHARED_ACCESSORIAL_REPORT]),
          R.propEq(currentBranchGuid, GC.BRANCH_GUID, item),
          true,
        ),
        disabled: G.ifElse(
          R.equals(listType, 'listUp'),
          G.notEquals(G.getBranchGuidFromObject(item), currentBranchGuid),
          false,
        ),
      },
    )),
  )(results);

  return P.$all(
    P.$set(`${listType}.itemList`, list),
    P.$set(`${listType}.totalCount`, totalCount),
    P.$set(`${listType}.pagination.offset`, offsetToUse),
    state,
  );
};

const resetListAndPagination = (state: Object) => (
  P.$all(
    P.$set(`${state.listType}.totalCount`, 0),
    P.$set(`${state.listType}.itemList`, null),
    P.$set(`${state.listType}.pagination.offset`, 0),
    state,
  )
);

const selectItem = (state: Object, id: Object) => {
  const { listType } = state;

  if (R.equals(id, 'all')) {
    const value = R.compose(
      R.not,
      R.all(R.prop('selected')),
      R.reject(R.prop('disabled')),
      R.values,
    )(R.pathOr([], [listType, 'itemList'], state));
    const itemList = R.map((item: Object) => {
      const { disabled } = item;

      return R.assoc('selected', G.ifElse(disabled, false, value), item);
    }, R.pathOr([], [listType, 'itemList'], state));

    return P.$set(`${listType}.itemList`, itemList, state);
  }

  return P.$toggle(`${listType}.itemList.${id}.selected`, state);
};

const setUsedReport = (state: Object, data: Object) => {
  const { listType } = state;

  if (R.includes(listType, ['listUp', 'listDown'])) {
    return P.$all(
      P.$set('usedReport', data),
      P.$set('reportPending', false),
      P.$set('globalFilterValue', null),
      P.$set('listUp', initialListState),
      P.$set('listDown', initialListState),
      state,
    );
  }

  return P.$all(
    P.$set('usedReport', data),
    P.$set('reportPending', false),
    P.$set('list', initialListState),
    P.$set('globalFilterValue', null),
    state,
  );
};

export const cleanQuickFilter = (state: Object) => (
  P.$set('filterParams', {}, state)
);

const toggleTemplateItemDetails = (state: Object, { guid }: Object) => (
  P.$toggle(`${state.listType}.itemList.${guid}.expanded`, state)
);

const setTableTitleSort = (state: Object, data: Object) => {
  const { listType } = state;

  const titleSortValues = R.path([listType, 'titleSortValues'], state);
  const sortValues = G.getTableTitleSortValues(titleSortValues, data);

  return P.$set(`${listType}.titleSortValues`, sortValues, state);
};

const setTableTitleFilter = (state: Object, data: Object) => {
  const { listType } = state;

  const titleFilterValues = R.path([listType, 'tableTitleFilters'], state);
  const filterValues = G.getTableTitleFilterValues(titleFilterValues, data);

  return P.$set(`${listType}.tableTitleFilters`, filterValues, state);
};

const setReportPending = (state: Object) => P.$set('reportPending', true, state);

const setListType = (state: Object, listType: string) => P.$set('listType', listType, state);

const setListLoading = (state: Object, loading: boolean) => P.$set('loading', loading, state);

const setReports = (state: Object, reports: Array) => P.$set('availableReports', reports, state);

const setReportType = (state: Object, reportType: boolean) => P.$set('reportType', reportType, state);

const setGlobalFilterValue = (state: Object, data: string) => P.$set('globalFilterValue', data, state);

const setQuickFilterParams = (state: Object, filterParams: Object) => P.$set('filterParams', filterParams, state);
// report

// template
const removeTemplateItemsSuccess = (state: Object, guids: Array) => {
  const { listType } = state;

  const count = R.length(guids);
  const itemList = R.omit(guids, R.path([listType, 'itemList'], state));
  const totalCount = R.subtract(R.path([listType, 'totalCount'], state), count);
  const offset = R.subtract(R.path([listType, 'pagination', 'offset'], state), count);

  return P.$all(
    P.$set(`${listType}.itemList`, itemList),
    P.$set(`${listType}.totalCount`, totalCount),
    P.$set(`${listType}.pagination.offset`, offset),
    state,
  );
};

const changeTemplateItemStatusSuccess = (state: Object, guid: string) => (
  P.$toggle(`${state.listType}.itemList.${guid}.active`, state)
);

const createOrUpdateTemplateItemSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const { listType, reportType } = state;

  const itemList = R.path([listType, 'itemList'], state);

  let newData = data;

  if (R.equals(reportType, GC.LOCATION_TEMPLATE_REPORT)) {
    newData = R.assoc(
      `${GC.FIELD_LOCATION_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`,
      R.path([GC.FIELD_LOCATION_TYPE, GC.FIELD_DISPLAYED_VALUE], data),
      data,
    );
  }

  if (R.equals(reportType, GC.COMPENSATION_TEMPLATE_REPORT)) {
    const getZoneFields = (zoneName: string, data: Object) => {
      const zone = R.pathOr({}, [zoneName], data);

      return {
        [`${zoneName}.${GC.FIELD_GEO_FENCING_ZONE_CITIES}`]: R.pathOr(null, [GC.FIELD_GEO_FENCING_ZONE_CITIES], zone),
        [`${zoneName}.${GC.FIELD_GEO_FENCING_ZONE_STATES}`]: R.pathOr(null, [GC.FIELD_GEO_FENCING_ZONE_STATES], zone),
        [`${zoneName}.${GC.FIELD_GEO_FENCING_ZONE_COUNTRIES}`]:
          R.pathOr(null, [GC.FIELD_GEO_FENCING_ZONE_COUNTRIES], zone),
        [`${zoneName}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_CODES}`]:
          R.pathOr(null, [GC.FIELD_GEO_FENCING_ZONE_ZIP_CODES], zone),
        [`${zoneName}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO}`]:
          R.pathOr('', [GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO], zone),
        [`${zoneName}.${GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM}`]:
          R.pathOr('', [GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM], zone),
      };
    };

    newData = R.mergeRight(
      data,
      {
        [`${GC.FIELD_MODE}.${GC.FIELD_DISPLAYED_VALUE}`]: R.path([GC.FIELD_MODE, GC.FIELD_DISPLAYED_VALUE], data),
        [`${GC.FIELD_SERVICE_TYPE}.${GC.FIELD_DISPLAYED_VALUE}`]: R.path(
          [GC.FIELD_SERVICE_TYPE, GC.FIELD_DISPLAYED_VALUE],
          data,
        ),
        ...getZoneFields(GC.FIELD_ORIGIN, data),
        ...getZoneFields(GC.FIELD_DESTINATION, data),
      },
    );
  }

  if (R.has(guid, itemList)) {
    return P.$set(`${listType}.itemList.${guid}`, R.mergeRight(G.getPropFromObject(guid, itemList), newData), state);
  }

  return P.$all(
    P.$set(`${listType}.totalCount`, R.inc(R.path([listType, 'totalCount'], state))),
    P.$set(`${listType}.pagination.offset`, R.inc(R.path([listType, 'pagination', 'offset'], state))),
    P.$set(
      `${listType}.itemList.${guid}`,
      R.mergeRight(newData, { selected: false, expanded: false, index: R.length(R.values(itemList)) }),
    ),
    state,
  );
};

// container types
const getContainerTypeListSuccess = (state: Object, data: Object) => (
  P.$set('containerTypes', data, state)
);

// accessorial configs
const getAccessorialConfigsSuccess = (state: Object, data: Object) => (
  P.$set('accessorialConfigs', data, state)
);

// configs
const getBranchConfigsSuccess = (state: Object, data: Object) => {
  const { configs, dropdowns } = data;

  const dropdownOptions = R.compose(
    R.reduce((acc: Object, dropdownName: string) => R.assoc(
      dropdownName,
      G.createOptionsFromDropdownConfigWithGuidOrParentGuid({ dropdowns }, dropdownName, true),
      acc,
    ), {}),
    R.keys,
  )(dropdowns);

  return P.$set('branchConfigs', { configs, dropdownOptions }, state);
};

export default createReducer({
  // report
  [A.selectItem]: selectItem,
  [A.setReports]: setReports,
  [A.setListType]: setListType,
  [A.setReportType]: setReportType,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setReportPending]: setReportPending,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.setGlobalFilterValue]: setGlobalFilterValue,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.toggleTemplateItemDetails]: toggleTemplateItemDetails,
  // item
  [A.removeTemplateItemsSuccess]: removeTemplateItemsSuccess,
  [A.changeTemplateItemStatusSuccess]: changeTemplateItemStatusSuccess,
  [A.createOrUpdateTemplateItemSuccess]: createOrUpdateTemplateItemSuccess,
  // container types
  [A.getContainerTypeListSuccess]: getContainerTypeListSuccess,
  // accessorial configs
  [A.getAccessorialConfigsSuccess]: getAccessorialConfigsSuccess,
  // configs
  [A.getBranchConfigsSuccess]: getBranchConfigsSuccess,
}, initialState);
