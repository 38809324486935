import * as R from 'ramda';
import { put, call } from 'redux-saga/effects';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature sockets-v2
import * as A from '../actions';
import * as C from '../constants';
//////////////////////////////////////////////////

const messageTypes = R.prop('statisticMessageType', C);

function* handleStatisticMessageReceived(actionPart: string, payload: Object) {
  const data = R.or(payload, {});

  switch (actionPart) {
    case messageTypes.import:
      if (R.not(R.pathEq(GC.STATUS_IN_PROGRESS, [GC.FIELD_STATUS], data))) {
        const message = R.compose(
          R.join(', '),
          R.values,
          R.mapObjIndexed((item: string, key: string) => `${G.toTitleCase(key)}: ${item}`),
          R.filter(G.isNotZero),
          R.pick(['created', 'updated', 'failed', 'errors']),
        )(data);

        yield put(A.saveImportListSuccess(data));

        yield call(G.showToastrMessage, 'info', message);
      }
      break;
    default:
  }
}

export default handleStatisticMessageReceived;
