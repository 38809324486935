import * as Yup from 'yup';
import * as R from 'ramda';
import { withFormik, setNestedObjectValues } from 'formik';
import { pure, compose, lifecycle, withState, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import routesMap from '../../utilities/routes';
// feature role
import { getAllAvailablePermissions, getAllGroupPermissionsChecked } from './helpers';
import { defaultRoleFields, roleValidationSchemaObject } from './settings/field-settings';
//////////////////////////////////////////////////

const validationSchema = () => Yup.object().shape(roleValidationSchemaObject);

const handleChangeToggleInput = ({ setFieldValue }: Object) => (event: Object) => {
  const id = R.path(['currentTarget', 'id'], event);
  const checked = R.path(['currentTarget', 'checked'], event);
  const permissionAction = G.ifElse(
    checked,
    GC.FIELD_ROLE_USER_PERMISSION_WRITE,
    GC.FIELD_ROLE_USER_PERMISSION_READ,
  );
  setFieldValue(id, permissionAction);
};

const handleChangeRoleType = (props: Object) => (event: Object) => {
  const { setValues, initialValues, getUserPermissionsByRoleTypeRequest } = props;

  const value = R.path(['currentTarget', 'value'], event);
  setValues(R.assoc(GC.FIELD_TYPE, value, initialValues));

  if (G.isNotNilAndNotEmpty(value)) {
    getUserPermissionsByRoleTypeRequest({ [GC.FIELD_ROLE_ROLE_TYPE]: value });
  }
};

const handleClickCancel = ({ resetUserPermissions }: Object) => (event: Object) => {
  event.preventDefault();
  resetUserPermissions();
  G.goToRoute(routesMap.rolesPage);
};

const handleChangeAllToggleInputs = (props: Object) => (permissions: Array) => {
  const { values, setValues } = props;

  const allAvailablePermissions = getAllAvailablePermissions(permissions);
  const allGroupPermissionsChecked = getAllGroupPermissionsChecked(props, permissions);
  const permissionAction = G.ifElse(
    allGroupPermissionsChecked,
    GC.FIELD_ROLE_USER_PERMISSION_READ,
    GC.FIELD_ROLE_USER_PERMISSION_WRITE,
  );
  const newValues = R.compose(
    R.map(() => permissionAction),
    R.indexBy(R.prop('permissionName')),
  )(allAvailablePermissions);
  setValues(R.mergeRight(values, newValues));
};

export const withCreateUserPermissions = compose(
  withFormik({
    mapPropsToValues: () => defaultRoleFields,
    validationSchema,
    handleSubmit: (values: Object, { props, setSubmitting }: Object) => {
      let data = R.compose(
        R.assoc('setSubmitting', setSubmitting),
        R.pick([GC.FIELD_NAME, GC.FIELD_TYPE, GC.FIELD_ROLE_AVAILABLE_DOCUMENT_TYPE_GUIDS]),
      )(values);

      const rolePermissions = R.compose(
        R.map((item: Object) => ({ [GC.FIELD_ROLE_PERMISSION_NAME]: item, action: R.prop(item, values) })),
        R.keys(),
        R.omit([GC.FIELD_NAME, GC.FIELD_TYPE, GC.FIELD_ROLE_AVAILABLE_DOCUMENT_TYPE_GUIDS]),
      )(values);

      data = R.assoc(GC.FIELD_ROLE_ROLE_PERMISSIONS, rolePermissions, data);

      props.addNewRoleRequest(data);
    },
  }),
  withHandlers({
    handleChangeToggleInput,
    handleChangeAllToggleInputs,
    handleClickCancel,
    handleChangeRoleType,
    handleToggleSinglePermission: (props: Object) => ({ active, permission }: Object) => {
      const {
        values,
        setValues,
        setFieldValue,
        toggleSinglePermissionRequest,
      } = props;

      const { defaultAction, permissionName } = permission;

      if (G.isTrue(active)) {
        setFieldValue(permissionName, defaultAction);
      } else {
        setValues(R.dissoc(permissionName, values));
      }

      toggleSinglePermissionRequest({ permission, form: 'create' });
    },
    handleToggleGroupPermissions: (props: Object) => ({ active, groupName, permissions }: Object) => {
      const {
        values,
        setValues,
        toggleGroupPermissionsRequest,
      } = props;

      if (G.isTrue(active)) {
        const newValues = R.mapObjIndexed((item: Object) => R.path(['defaultAction'], item), permissions);

        setValues(R.mergeRight(newValues, values));
      } else {
        setValues(R.omit(R.keys(permissions), values));
      }

      toggleGroupPermissionsRequest({
        active,
        groupName,
        form: 'create',
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { setFieldValue, checkAllRoles, getUserPermissionsByRoleTypeRequest } = this.props;

      checkAllRoles(false);

      if (G.isCurrentBranchTypeCustomer()) {
        setFieldValue(GC.FIELD_TYPE, GC.BRANCH_TYPE_ENUM_CUSTOMER);
        getUserPermissionsByRoleTypeRequest({ [GC.FIELD_ROLE_ROLE_TYPE]: GC.BRANCH_TYPE_ENUM_CUSTOMER });
      }
    },
  }),
  pure,
);

export const withEditUserPermissions = compose(
  withState('activeTab', 'setActiveTab', 'general'),
  withFormik({
    enableReinitialize: true,
    validationSchema: () => Yup.object().shape(roleValidationSchemaObject),
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultRoleFields,
      props.initialValues,
    ),
    handleSubmit: (values: Object, { props, setSubmitting }: Object) => {
      const rolePermissions = R.compose(
        R.map((item: Object) => ({ [GC.FIELD_ROLE_PERMISSION_NAME]: item, action: R.prop(item, values) })),
        R.keys(),
        R.filter((item: Object) => R.includes(
          item,
          [
            GC.FIELD_ROLE_USER_PERMISSION_READ,
            GC.FIELD_ROLE_USER_PERMISSION_WRITE,
            GC.FIELD_ROLE_USER_PERMISSION_EXECUTE,
          ],
        )),
      )(values);
      const data = R.compose(
        R.mergeRight({ setSubmitting, rolePermissions }),
        R.pick([
          GC.FIELD_GUID,
          GC.FIELD_TYPE,
          GC.FIELD_NAME,
          GC.BRANCH_GUID,
          GC.FIELD_VERSION,
          GC.FIELD_ROLE_AVAILABLE_DOCUMENT_TYPE_GUIDS,
        ]),
      )(values);
      props.updateRoleRequest(data);
    },
  }),
  withHandlers({
    handleChangeToggleInput,
    handleChangeAllToggleInputs,
    handleChangeRoleType,
    handleClickSaveAsNew: (props: Object) => () => {
      const { values, errors, setTouched, addNewRoleRequest } = props;

      if (G.isNotNilAndNotEmpty(errors)) return setTouched(setNestedObjectValues(values, true));

      const rolePermissions = R.compose(
        R.map((item: Object) => ({ [GC.FIELD_ROLE_PERMISSION_NAME]: item, action: R.prop(item, values) })),
        R.keys(),
        R.filter((item: Object) => R.includes(
          item,
          [
            GC.FIELD_ROLE_USER_PERMISSION_READ,
            GC.FIELD_ROLE_USER_PERMISSION_WRITE,
            GC.FIELD_ROLE_USER_PERMISSION_EXECUTE,
          ],
        )),
      )(values);
      const data = R.compose(
        R.assoc(GC.FIELD_ROLE_ROLE_PERMISSIONS, rolePermissions),
        R.pick([GC.FIELD_TYPE, GC.FIELD_NAME, GC.FIELD_ROLE_AVAILABLE_DOCUMENT_TYPE_GUIDS]),
      )(values);
      addNewRoleRequest(data);
    },
    handleClickCancel,
    handleToggleSinglePermission: (props: Object) => ({ active, permission }: Oject) => {
      const { values, setValues, setFieldValue, toggleSinglePermissionRequest } = props;

      const { defaultAction, permissionName } = permission;

      if (G.isTrue(active)) {
        setFieldValue(permissionName, defaultAction);
      } else {
        setValues(R.dissoc(permissionName, values));
      }

      toggleSinglePermissionRequest({ permission, form: 'edit' });
    },
    handleToggleGroupPermissions: (props: Object) => ({ active, groupName, permissions }: Object) => {
      const { values, setValues, toggleGroupPermissionsRequest } = props;

      if (G.isTrue(active)) {
        const newValues = R.mapObjIndexed((item: Object) => R.path(['defaultAction'], item), permissions);

        setValues(R.mergeRight(newValues, values));
      } else {
        setValues(R.omit(R.keys(permissions), values));
      }

      toggleGroupPermissionsRequest({
        active,
        groupName,
        form: 'edit',
      });
    },
    handleClickToggleGroup: ({ handleToggleGroupRequest }: Object) => (groupGuid: string) =>
      handleToggleGroupRequest({ groupGuid, form: 'edit' }),
  }),
  pure,
);
