import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectStylingStore = (state: Object) => state.styling;

const makeSelectStyling = () => createSelector(
  selectStylingStore,
  ({ stylingSettings }: Object) => stylingSettings,
);

const makeSelectMainStyling = () => createSelector(
  selectStylingStore,
  ({ mainStylingSettings }: Object) => mainStylingSettings,
);

const makeSelectStylingConfigs = () => createSelector(
  selectStylingStore,
  ({ stylingConfigSettings }: Object) => stylingConfigSettings,
);

const makeSelectSplashScreenSettings = () => createSelector(
  selectStylingStore,
  ({ splashScreenSettings }: Object) => splashScreenSettings,
);

const makeSelectSplashScreenConfigs = () => createSelector(
  selectStylingStore,
  ({ splashScreenConfigSettings }: Object) => splashScreenConfigSettings,
);

export {
  selectStylingStore,
  makeSelectStyling,
  makeSelectMainStyling,
  makeSelectStylingConfigs,
  makeSelectSplashScreenConfigs,
  makeSelectSplashScreenSettings,
};
