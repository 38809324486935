import React from 'react';
import * as R from 'ramda';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { Label } from '../../../components/label';
import { ConfirmComponent } from '../../../components/confirm';
import { FormGroupTable } from '../../../components/form-group-table';
// forms
import { DocumentForm } from '../../../forms/forms/document-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, ReactSelect } from '../../../ui';
// feature service-vendor
import { documentColumnSettings } from '../settings/column-settings';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleOpenDocument: (props: Object) => ({ document, documentTypes }: Object) => {
      const { openModal, initialValues, createOrUpdateServiceVendorDocRequest } = props;

      let documentInitialValues = {};

      if (G.isNotNilAndNotEmpty(document)) {
        const { documentType, documentFilename } = document;

        documentInitialValues = {
          ...document,
          [GC.FIELD_DOCUMENT_UPLOAD]: documentFilename,
          [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: G.getDropdownOptionGuidFromObject(documentType),
        };
      }

      const component = (
        <DocumentForm
          documentTypes={documentTypes}
          initialValues={documentInitialValues}
          submitAction={(values: Object) => createOrUpdateServiceVendorDocRequest(R.assoc(
            GC.FIELD_PRIMARY_OBJECT_GUID,
            G.getGuidFromObject(initialValues),
            values,
          ))}
        />
      );

      const title = G.getWindowLocale(...G.ifElse(
        G.isNilOrEmpty(document),
        ['titles:add-doc', 'Add Document'],
        ['titles:edit-doc', 'Edit Document'],
      ));

      const modal = {
        p: 15,
        component,
        options: { title, width: 430 },
      };

      openModal(modal);
    },
    handleDeleteDocument: (props: Object) => ({ guid, documentType }: Object) => {
      const { openModal, closeModal, deleteServiceVendorDocRequest } = props;

      const component = (
        <ConfirmComponent
          name={G.getDisplayedValueFromObject(documentType)}
          textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
        />
      );

      const modal = {
        component,
        options: {
          width: 600,
          height: 'auto',
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                deleteServiceVendorDocRequest(guid);
                closeModal();
              },
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const getAdditionaColumnActions = ({ downloadServiceVendorDocRequest }: Object) => {
  const getVisibility = ({ fileUri }: Object) => G.ifElse(G.isNilOrEmpty(fileUri), 'hidden', 'visible');

  return [
    {
      getVisibility,
      icon: I.downloadDocument(),
      title: G.getWindowLocale('titles:download-file', 'Download File'),
      handleClick: ({ documentFilename, primaryObjectGuid }: Object) => downloadServiceVendorDocRequest({
        primaryObjectGuid,
        [GC.FIELD_FILE_NAME]: documentFilename,
      }),
    },
    {
      icon: I.eye(),
      getVisibility,
      title: G.getWindowLocale('titles:click-for-preview', 'Click For Preview'),
      handleClick: ({ documentFilename, primaryObjectGuid }: Object) => downloadServiceVendorDocRequest({
        isPreview: true,
        primaryObjectGuid,
        [GC.FIELD_FILE_NAME]: documentFilename,
      }),
    },
  ];
};

const DocumentsTitlePanel = (props: Object) => {
  const {
    isOpened,
    documentsFilter,
    documentTypes = [],
    handleOpenDocument,
    setDocumentsFilter,
    handleToggleFormGroup,
  } = props;

  const whiteColor = G.getTheme('colors.white');

  return (
    <Flex pl={15} pr={16} minHeight={30} color={whiteColor} bg={G.getTheme('colors.mainBlue')}>
      <Flex width='max-content'>
        <Label endIcon={I.plusRound(whiteColor)} endAction={() => handleOpenDocument({ documentTypes })}>
          {G.getWindowLocale('titles:documents', 'Documents')}
        </Label>
        {
          isOpened &&
          <ReactSelect
            height={30}
            value={documentsFilter}
            options={R.drop(1, documentTypes)}
            onChange={(option: Object) => setDocumentsFilter(R.pathOr(null, [GC.FIELD_VALUE], option))}
            additionalStyles={{
              container: (baseStyles: Object) => ({
                ...baseStyles,
                width: 230,
                margin: '5px 10px',
              }),
              menu: (baseStyles: Object) => ({
                ...baseStyles,
                zIndex: 11,
                color: G.getTheme('colors.black'),
              }),
            }}
          />
        }
      </Flex>
      <Box width='100%' onClick={() => handleToggleFormGroup(GC.FIELD_DOCUMENTS)}>
        <Box ml='auto' width='max-content' transform={G.ifElse(isOpened, 'rotate(180deg)')}>
          {I.arrowDownSimple(whiteColor)}
        </Box>
      </Box>
    </Flex>
  );
};

export const Documents = enhance((props: Object) => {
  const {
    configs,
    documentList,
    collapsedGroup,
    handleOpenDocument,
    handleDeleteDocument,
    downloadServiceVendorDocRequest,
  } = props;

  const documentTypes = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    configs, GC.SERVICE_VENDOR_DOCUMENT_TYPE, true,
  );

  const isOpened = G.getPropFromObject(GC.FIELD_DOCUMENTS, collapsedGroup);

  return (
    <FormGroupTable
      isOpened={isOpened}
      tableColumnWidth={100}
      entities={documentList}
      fields={collapsedGroup}
      groupName={GC.FIELD_DOCUMENTS}
      handleDeleteRow={handleDeleteDocument}
      entitiesFields={documentColumnSettings}
      handleEditRow={(document: Object) => handleOpenDocument({ document, documentTypes })}
      additionalColumnActions={getAdditionaColumnActions({ downloadServiceVendorDocRequest })}
      customPanel={() => <DocumentsTitlePanel {...props} isOpened={isOpened} documentTypes={documentTypes} />}
    />
  );
});
