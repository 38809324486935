import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// common
import { updateReportFromReportListSuccess } from '../../common/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportReducers } from '../../report-common';
// feature task-management
import * as A from './actions';
//////////////////////////////////////////////////

const initial = {
  taskBoardPagination: { offset: 0, limit: 15 },
};

const {
  setReports,
  initialState,
  setUsedReport,
  setListLoading,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemSuccess,
  getItemListSuccess,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  setInitialStateOmitReport,
  handleUpdateReportFromReportListSuccess,
} = getReportReducers(initial);

const setInitialState = (state: Object) => R.compose(
  R.mergeRight(initialState),
  R.pick([
    'usedReport',
    'pageVisited',
    'filterParams',
    'titleSortValues',
    'selectedTaskBoard',
    'tableTitleFilters',
    'taskBoardGlobalFilterValue',
    'taskBoardAdditionalFilterValue',
  ]),
)(state);

const createOrUpdateTaskSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const { itemList, totalCount, pagination: { offset } } = state;

  if (R.isNil(itemList)) return state;

  const reportItem = R.compose(
    R.mergeLeft({ ...data, index: R.pathOr(R.length(R.values(itemList)), [guid, 'index'], itemList) }),
    R.reduce((acc: Object, item: string) => R.assoc(item, R.path(R.split('.', item), data), acc), {}),
    R.map(R.prop(GC.FIELD_NAME)),
    R.pathOr([], ['usedReport', 'fields']),
  )(state);

  if (R.isNotNil(R.prop(guid, itemList))) return P.$set(`itemList.${guid}`, reportItem, state);

  return P.$all(
    P.$set(`itemList.${guid}`, reportItem),
    P.$set('totalCount', R.inc(totalCount)),
    P.$set('pagination.offset', R.inc(offset)),
    state,
  );
};

const mapTaskManagementConfig = R.map((item: Object) => R.assoc(GC.FIELD_CONFIG_GUID, G.getGuidFromObject(item), item));

const getTaskTypeListSuccess = (state: Object, data: Object) => (
  P.$set('taskTypes', mapTaskManagementConfig(data), state)
);

const getTaskStatusListSuccess = (state: Object, data: Object) => (
  P.$set('taskStatuses', mapTaskManagementConfig(data), state)
);

const getGroupedByTaskTypesTaskStatusMapSuccess = (state: Object, data: Object) => (
  P.$set('groupedByTaskTypesTaskStatusMap', data, state)
);

const setEntityListByEntityTypeSuccess = (state: Object, data: Object) => {
  const { entities, entityType } = data;

  if (R.isNil(entityType)) {
    return P.$set('entities', R.mergeRight(data, state.entities), state);
  }

  return P.$set(`entities.${entityType}`, entities, state);
};

const setAssigneeGuid = (state: Object, guid: string) => P.$set('assigneeGuid', guid, state);

const setObjectGuid = (state: Object, guid: string) => P.$set('objectGuid', guid, state);

// board
const getTaskBoardSuccess = (state: Object, data: Object) => {
  const limit = R.path(['taskBoardPagination', 'limit'], state);
  const nextBoard = R.concat(R.pathOr([], ['taskBoard'], state), data);
  const boardLength = R.length(nextBoard);
  const offset = R.add(limit, R.pathOr(0, ['taskBoardPagination', 'offset'], state));
  const totalCount = G.ifElse(R.equals(R.length(data), limit), R.inc(boardLength), boardLength);

  return P.$all(
    P.$set('taskBoard', nextBoard),
    P.$set('taskBoardTotalCount', totalCount),
    P.$set('taskBoardPagination.offset', offset),
    state,
  );
};

const setTaskBoardListLoading = (state: Object, loading: Object) => (
  P.$set('taskBoardListLoading', loading, state)
);

const getTaskBoardSummarySuccess = (state: Object, data: Object) => (
  P.$set('taskBoardSummary', R.indexBy(R.prop('taskStatusGuid'), data), state)
);

const getAvailableTaskBoardsSuccess = (state: Object, data: Object) => (
  P.$set('availableTaskBoards', data, state)
);

const setTaskBoardGlobalFilterValue = (state: Object, data: string) => (
  P.$set('taskBoardGlobalFilterValue', data, state)
);

const setTaskBoardAdditionalFilterValue = (state: Object, data: Array) => (
  P.$set('taskBoardAdditionalFilterValue', data, state)
);

const resetTaskBoardListAndPagination = (state: Object) => (
  P.$all(
    P.$set('taskBoard', null),
    P.$set('taskBoardPagination', initial.taskBoardPagination),
    state,
  )
);

const setSelectedTaskBoard = (state: Object, data: Object) => (
  P.$all(
    P.$set('taskBoard', null),
    P.$set('selectedTaskBoard', data),
    P.$set('taskBoardGlobalFilterValue', ''),
    P.$set('taskBoardAdditionalFilterValue', []),
    P.$set('taskBoardPagination', initial.taskBoardPagination),
    state,
  )
);

const updateTaskStatusSuccess = (state: Object, data: Object) => {
  const { taskBoard } = state;

  const {
    taskGuid,
    assigneeGuid,
    dropdownOption,
    taskStatusConfigGuid,
    shouldNotUpdateStatus,
    prevTaskStatusConfigGuid,
  } = data;

  const getPath = (guid: string) => `taskBoardSummary.${guid}.totalTasks`;

  const assigneeIndex = R.findIndex(R.pathEq(assigneeGuid, [GC.FIELD_ASSIGNEE, GC.FIELD_GUID]), taskBoard);
  const taskIndex = R.findIndex(R.propEq(taskGuid, GC.FIELD_GUID), R.pathOr([], [assigneeIndex, 'tasks'], taskBoard));

  const prevTotal = R.dec(R.path(R.split('.', getPath(prevTaskStatusConfigGuid)), state));
  const nextTotal = R.inc(R.pathOr(0, R.split('.', getPath(taskStatusConfigGuid)), state));

  if (shouldNotUpdateStatus) {
    return P.$all(
      P.$set(getPath(taskStatusConfigGuid), nextTotal),
      P.$set(getPath(prevTaskStatusConfigGuid), prevTotal),
      state,
    );
  }

  return P.$all(
    P.$set(getPath(taskStatusConfigGuid), nextTotal),
    P.$set(getPath(prevTaskStatusConfigGuid), prevTotal),
    P.$set(`taskBoard.${assigneeIndex}.tasks.${taskIndex}.${GC.FIELD_STATUS}`, dropdownOption),
    state,
  );
};

const removeTaskFromBoard = (state: Object, { guid, assigneeGuid }: Object) => {
  const { taskBoard } = state;

  const userIndex = R.findIndex(R.pathEq(assigneeGuid, [GC.FIELD_ASSIGNEE, GC.FIELD_GUID]), taskBoard);
  const tasks = R.path([userIndex, 'tasks'], taskBoard);
  const taskIndex = R.findIndex(R.propEq(guid, GC.FIELD_GUID), tasks);

  return P.$drop(`taskBoard.${userIndex}.tasks.${taskIndex}`, state);
};

const createOrUpdateTaskBoardItemSuccess = (state: Object, data: Object) => {
  const { taskBoard } = state;

  const { guid, assignee } = data;

  const taskBoardItem = R.pick(
    [
      GC.FIELD_GUID,
      GC.FIELD_TYPE,
      GC.FIELD_TITLE,
      GC.FIELD_STATUS,
      GC.FIELD_DUE_DATE,
      GC.FIELD_OBJECT_TYPE,
      GC.FIELD_TASK_NUMBER,
      GC.FIELD_OBJECT_NAME,
    ],
    data,
  );

  const userIndex = R.findIndex(R.pathEq(G.getGuidFromObject(assignee), ['assignee', GC.FIELD_GUID]), taskBoard);

  if (R.equals(userIndex, -1)) return P.$add('taskBoard', { assignee, tasks: R.of(Array, taskBoardItem) }, state);

  const tasks = R.path([userIndex, 'tasks'], taskBoard);

  if (R.isNil(tasks)) {
    return P.$set(`taskBoard.${userIndex}.tasks`, R.of(Array, taskBoardItem), state);
  }

  const taskIndex = R.findIndex(R.propEq(guid, GC.FIELD_GUID), tasks);

  if (R.equals(taskIndex, -1)) {
    return P.$add(`taskBoard.${userIndex}.tasks`, taskBoardItem, state);
  }

  return P.$set(`taskBoard.${userIndex}.tasks.${taskIndex}`, taskBoardItem, state);
};

const removeTaskBoardSuccess = (state: Object, guid: string) => (
  P.$set(
    'availableTaskBoards',
    R.reject(R.propEq(guid, GC.FIELD_GUID), R.propOr([], 'availableTaskBoards', state)),
    state,
  )
);

export default createReducer({
  // report
  [A.setReports]: setReports,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.deleteItemSuccess]: deleteItemSuccess,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.createOrUpdateTaskSuccess]: createOrUpdateTaskSuccess,
  [A.setInitialStateOmitReport]: setInitialStateOmitReport,
  [updateReportFromReportListSuccess]: handleUpdateReportFromReportListSuccess,
  // configs
  [A.getTaskTypeListSuccess]: getTaskTypeListSuccess,
  [A.getTaskStatusListSuccess]: getTaskStatusListSuccess,
  [A.getGroupedByTaskTypesTaskStatusMapSuccess]: getGroupedByTaskTypesTaskStatusMapSuccess,
  // entities
  [A.setObjectGuid]: setObjectGuid,
  [A.setAssigneeGuid]: setAssigneeGuid,
  [A.setEntityListByEntityTypeSuccess]: setEntityListByEntityTypeSuccess,
  // board
  [A.removeTaskFromBoard]: removeTaskFromBoard,
  [A.getTaskBoardSuccess]: getTaskBoardSuccess,
  [A.setSelectedTaskBoard]: setSelectedTaskBoard,
  [A.removeTaskBoardSuccess]: removeTaskBoardSuccess,
  [A.updateTaskStatusSuccess]: updateTaskStatusSuccess,
  [A.setTaskBoardListLoading]: setTaskBoardListLoading,
  [A.getTaskBoardSummarySuccess]: getTaskBoardSummarySuccess,
  [A.setTaskBoardGlobalFilterValue]: setTaskBoardGlobalFilterValue,
  [A.getAvailableTaskBoardsSuccess]: getAvailableTaskBoardsSuccess,
  [A.resetTaskBoardListAndPagination]: resetTaskBoardListAndPagination,
  [A.setTaskBoardAdditionalFilterValue]: setTaskBoardAdditionalFilterValue,
  [A.createOrUpdateTaskBoardItemSuccess]: createOrUpdateTaskBoardItemSuccess,
}, initialState);
