import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import routesMap from '../../../utilities/routes';
// ui
import { Box } from '../../../ui';
//////////////////////////////////////////////////

const tableSettings = {
  minHeight: 320,
  cellFontSize: 13,
  titleFontSize: 14,
  tableRowHeight: 40,
  titleRowHeight: 45,
  allowEditBtn: true,
  checkBoxCellWidth: 0,
  searchableTitles: true,
  allowSelectItems: false,
};

const getPayrollNumberCustomComponent = (payrollNumberProp: string) => (props: Object) => {
  const { data, closeModal, setInitialExpandedContainer } = props;

  const payrollNumber = G.getPropFromObject(payrollNumberProp, data);

  if (G.isNilOrEmpty(payrollNumber)) return null;

  return (
    <Box
      cursor='pointer'
      textDecoration='underline'
      color={G.getTheme('colors.dark.blue')}
      onClick={() => {
        if (G.notEquals(G.getCurrentPathname(), GC.ROUTE_PATH_PAYROLLS)) {
          return G.goToRoute(routesMap.driverPayrollList);
        }

        G.callFunction(setInitialExpandedContainer);
        G.callFunction(closeModal);
      }}
    >
      {payrollNumber}
    </Box>
  );
};

const columnSettings = {
  [GC.FIELD_TOLL_CHARGES_SOURCE]: {
    width: 150,
    name: 'titles:source',
  },
  [GC.FIELD_TOLL_CHARGES_AMOUNT]: {
    width: 150,
    name: 'titles:amount',
  },
  [GC.FIELD_CURRENCY]: {
    width: 150,
    name: 'titles:currency',
    options: GC.CURRENCY_OPTIONS_2,
  },
  [GC.FIELD_TOLL_CHARGES_AGENCY]: {
    width: 150,
    name: 'titles:agency',
  },
  [GC.FIELD_TOLL_CHARGES_POSITING_DATE]: {
    width: 220,
    name: 'titles:posting-date',
  },
  [GC.FIELD_PAYROLL_GUID]: {
    width: 200,
    name: 'titles:payroll-number',
    customComponent: getPayrollNumberCustomComponent(GC.FIELD_PAYROLL_NUMBER),
  },
  [GC.FIELD_FUEL_CARDS_VENDOR_PAYROLL_GUID]: {
    width: 200,
    name: 'titles:vendor-payroll-number',
    customComponent: getPayrollNumberCustomComponent(GC.FIELD_VENDOR_PAYROLL_NUMBER),
  },
  [GC.FIELD_TOLL_CHARGES_LICENCE_PLATE]: {
    width: 150,
    name: 'titles:license-plate',
  },
  [GC.FIELD_TOLL_CHARGES_LOCATION_NAME]: {
    width: 200,
    name: 'titles:location-name',
  },
  [GC.FIELD_TOLL_CHARGES_TRANSPONDER_ID]: {
    width: 150,
    name: 'titles:transponder-id',
  },
  [GC.FIELD_TOLL_CHARGES_TRANSACTION_DATE]: {
    width: 220,
    name: 'titles:transaction-date',
  },
  [GC.FIELD_TOLL_CHARGES_TRANSACTION_TYPE]: {
    width: 200,
    name: 'titles:transaction-type',
  },
  [`${GC.SYSTEM_OBJECT_DRIVER}.${GC.FIELD_FIRST_NAME}`]: {
    width: 200,
    name: ['titles:driver', 'titles:first-name'],
    customComponent: ({ data }: Object) => R.or(
      R.pathOr('', [`${GC.SYSTEM_OBJECT_DRIVER}.${GC.FIELD_FIRST_NAME}`], data),
      R.pathOr('', [GC.SYSTEM_OBJECT_DRIVER, GC.FIELD_FIRST_NAME], data),
    ),
  },
  [`${GC.SYSTEM_OBJECT_DRIVER}.${GC.FIELD_LAST_NAME}`]: {
    width: 200,
    name: ['titles:driver', 'titles:last-name'],
    customComponent: ({ data }: Object) => R.or(
      R.pathOr('', [`${GC.SYSTEM_OBJECT_DRIVER}.${GC.FIELD_LAST_NAME}`], data),
      R.pathOr('', [GC.SYSTEM_OBJECT_DRIVER, GC.FIELD_LAST_NAME], data),
    ),
  },
  [`${GC.FIELD_TRUCK}.${GC.FIELD_UNIT_ID}`]: {
    width: 200,
    name: ['titles:truck', 'titles:unit-id'],
    customComponent: ({ data }: Object) => R.or(
      R.pathOr('', [`${GC.FIELD_TRUCK}.${GC.FIELD_UNIT_ID}`], data),
      R.pathOr('', [GC.FIELD_TRUCK, GC.FIELD_TRUCK_UNIT_ID], data),
    ),
  },
  [GC.FIELD_CREATED_DATE]: {
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    type: 'date',
    name: 'titles:updated-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 200,
    name: 'titles:updated-by',
  },
};

export {
  tableSettings,
  columnSettings,
};
