export const tableSettings = {
  rowHeight: 55,
  cellFontSize: 14,
  titleFontSize: 14,
  tableRowHeight: 55,
  titleRowHeight: 55,
  allowEditBtn: true,
  useMainColors: true,
  allowSelectAll: true,
  moreBtnCellWidth: 50,
  checkBoxCellWidth: 90,
  expandableItems: false,
  maxHeight: 'calc(100vh - 145px)',
  checkBoxCellJustifyContent: 'unset',
};
