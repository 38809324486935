import React from 'react';
import * as R from 'ramda';
import * as P from 'plow-js';
// components
import { ConfirmComponent } from '../../components/confirm';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature template-report
import { itemNameMap } from './report/settings';
//////////////////////////////////////////////////

// TODO: check move to global helpers

export const getGuidsFromStringOrSelectedList = (data: any, list: Array) => (
  G.ifElse(
    R.is(String, data),
    () => R.of(Array, data),
    () => R.compose(
      R.map((entity: Object) => R.prop(GC.FIELD_TEMPLATE_GUID, entity)),
      R.filter((entity: Object) => R.prop('selected', entity)),
    )(list),
  )()
);

export const getItemListSuccess = (state: Object, data: Object) => {
  const newItems = data.map((item: Object, index: number) => R.mergeRight(
    item,
    {
      index,
      selected: false,
      [GC.FIELD_GUID]: R.prop(GC.FIELD_TEMPLATE_GUID, item),
    },
  ));
  const list = R.indexBy(R.prop(GC.FIELD_GUID), newItems);
  return P.$all(
    P.$set('itemList', list),
    P.$set('totalCount', R.length(data)),
    state,
  );
};

export const selectItem = (state: Object, id: Object) => {
  const { itemList } = state;
  if (R.equals(id, 'all')) {
    const value = R.not(R.all(
      (item: Object) => R.prop('selected', item),
      R.values(itemList),
    ));
    return P.$set(
      'itemList',
      R.map(
        (item: Object) => R.assoc(
          'selected',
          value,
          item,
        ),
        itemList,
      ),
      state,
    );
  }
  return P.$toggle(`itemList.${id}.selected`, state);
};

export const getRenderModal = (
  component: Object,
  title: string,
  id: string,
) => ({
  component,
  options: {
    title,
    width: '310px',
    height: 'auto',
    controlButtons: [
      {
        id,
        type: 'button',
        margin: '0 12px 0 0',
        name: G.getWindowLocale('actions:save', 'Save'),
      },
    ],
  },
});

export const renderConfirmationModal = (props: Object) => {
  const { entity, action, reportType, closeModal, changeTemplateItemStatusRequest } = props;

  const { guid, active } = entity;

  const locale = {
    activate: {
      button: ['actions:activate', 'Activate'],
      confirm: [
        'messages:activate-confirmation-text',
        'Are you sure you want activate',
      ],
    },
    deactivate: {
      button: ['actions:deactivate', 'Deactivate'],
      confirm: [
        'messages:deactivate-confirmation-text',
        'Are you sure you want deactivate',
      ],
    },
  };

  const component = (
    <ConfirmComponent
      textLocale={G.getWindowLocale(...R.pathOr([], [action, 'confirm'], locale))}
      name={R.pathOr(null, [G.getPropFromObject(reportType, itemNameMap)], entity)}
    />
  );

  const modal = {
    component,
    options: {
      width: 600,
      height: 'auto',
      controlButtons: [
        {
          type: 'button',
          name: G.getWindowLocale(...R.pathOr([], [action, 'button'], locale)),
          action: () => {
            closeModal();
            changeTemplateItemStatusRequest({ guid, active });
          },
        },
      ],
    },
  };

  return modal;
};
