import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////

const {
  setReports,
  selectItem,
  setReportType,
  setUsedReport,
  setListLoading,
  setFilterProps,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  setTableTitleSort,
  deleteItemRequest,
  deleteItemSuccess,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
} = getReportActions();

const resetList = createAction('resetList');
const moveUserRequest = createAction('moveUserRequest');
const assignRolesRequest = createAction('assignRolesRequest');
const assignRolesSuccess = createAction('assignRolesSuccess');
const revokeRolesRequest = createAction('revokeRolesRequest');
const revokeRolesSuccess = createAction('revokeRolesSuccess');
const createNewUserRequest = createAction('createNewUserRequest');
const createNewUserSuccess = createAction('createNewUserSuccess');
const createUsersReferenceRequest = createAction('createUsersReferenceRequest');
const createUsersReferenceSuccess = createAction('createUsersReferenceSuccess');
const clearUsersStateAfterSwitchBranch = createAction('clearUsersStateAfterSwitchBranch');
const getPasswordConfigsForUsersSuccess = createAction('getPasswordConfigsForUsersSuccess');
const getAvailableByTypeRoleListRequest = createAction('getAvailableByTypeRoleListRequest');
const getAvailableByTypeRoleListSuccess = createAction('getAvailableByTypeRoleListSuccess');

export {
  // report
  setReports,
  selectItem,
  setReportType,
  setUsedReport,
  setFilterProps,
  setListLoading,
  setInitialState,
  cleanQuickFilter,
  setReportPending,
  setTableTitleSort,
  deleteItemRequest,
  deleteItemSuccess,
  getItemListRequest,
  getItemListSuccess,
  createReportRequest,
  updateReportRequest,
  setTableTitleFilter,
  setQuickFilterParams,
  setIgnorePromptStatus,
  resetListAndPagination,
  exportReportDataRequest,
  setInitialStateOmitReport,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  // report
  resetList,
  moveUserRequest,
  assignRolesRequest,
  assignRolesSuccess,
  revokeRolesRequest,
  revokeRolesSuccess,
  createNewUserRequest,
  createNewUserSuccess,
  createUsersReferenceRequest,
  createUsersReferenceSuccess,
  clearUsersStateAfterSwitchBranch,
  getPasswordConfigsForUsersSuccess,
  getAvailableByTypeRoleListRequest,
  getAvailableByTypeRoleListSuccess,
};
