import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
// features
import { setInitialRouteGuid } from '../../dispatch-planner/actions';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.dark.blue');

const RouteItem = connect(null, { setInitialRouteGuid })(
  ({ guid, name, title, setInitialRouteGuid }: Object) => {
    const isGuid = G.isNotNilAndNotEmpty(guid);

    const handleClick = () => {
      if (isGuid) {
        setInitialRouteGuid(guid);
        setTimeout(() => G.goToRoute(routesMap.routeBuilder), 0);
      }
    };

    const routeItemProps = {
      p: '4px 8px',
      onClick: handleClick,
      display: 'inline-block',
      title: G.ifElse(isGuid, title),
      color: G.ifElse(isGuid, blueColor),
      cursor: G.ifElse(isGuid, 'pointer', 'default'),
    };

    return (
      <Box {...routeItemProps}>
        {name}
      </Box>
    );
  });

const RouteNameList = (props: Object) => {
  const { parentProps } = props;

  const title = G.getWindowLocale('actions:go-to-route-builder', 'Go To Route Builder');

  return (
    <Box ml={50}>
      <Box p='6px 16px' fontSize={14} bg={G.getTheme('colors.light.lightGrey')}>
        {G.getWindowLocale('titles:route-list', 'Route List')}
      </Box>
      <Box>
        {
          R.pathOr([], ['createdRoutes'], parentProps).map(({ name, guid }: Object, i: number) => (
            <RouteItem
              key={i}
              guid={guid}
              name={name}
              title={title}
            />
          ))
        }
      </Box>
    </Box>
  );
};

export default RouteNameList;
