import * as R from 'ramda';
import parse from 'html-react-parser';
import { css } from 'styled-components';
import { Editor } from 'react-draft-wysiwyg';
import React, { memo, useRef, useState, Fragment, useEffect, useCallback, useLayoutEffect } from 'react';
// components
import { TabsMui } from '../../../components/tabs-mui';
import { TextComponent } from '../../../components/text';
import { FormFooter2 } from '../../../components/form-footer';
import {
  Document,
  tabsProps,
  AddDocument,
  getOperations,
  toolbarSettings,
  renderConfirmationModal,
} from '../../../components/activities';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// utilities
import endpointsMap from '../../../utilities/endpoints';
import { sendRequest, sendRequestWithQSParamsSerializer } from '../../../utilities/http';
// ui
import { Box, Span, Flex, EditorWrapper, MainActionButton, scrollableContainerCss4px } from '../../../ui';
// feature work-order
import { InvoiceInfo } from './invoice-info';
import { InvoiceForm } from './invoice-form';
import { InvoicesTotals } from './invoices-totals';
import { getInvoiceServiceIssues, getInvoiceServiceIssueOptions } from '../helpers';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const darkGreyColor = G.getTheme('colors.#7D828C');
const lightGreyColor = G.getTheme('colors.lightGrey');
const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

const wrapperStyles = {
  mb: 10,
  p: '7px',
  borderRadius: '4px',
  border: '1px solid',
  color: greyMatterhornColor,
  borderColor: lightGreyColor,
};

const Documents = (props: Object) => {
  const { openModal, closeModal, documents, openLoader, closeLoader, setActivities, primaryObjectGuid } = props;

  const handleDocuments = useCallback(async (method: string, options: Object, documentGuid: string) => {
    openLoader();

    const operations = getOperations(documentGuid, true);

    const endpoints = {
      post: endpointsMap.workOrderDocument,
      get: endpointsMap.workOrderDocumentList,
      delete: endpointsMap.getWorkOrderDocumentEndpoint(documentGuid),
    };

    const res = await sendRequest(method, R.prop(method, endpoints), { ...options, params: { primaryObjectGuid } });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      setActivities((prev: Object) => {
        const documents = R.call(R.prop(method, operations), R.prop(GC.FIELD_DOCUMENTS, prev), data);

        return R.assoc(GC.FIELD_DOCUMENTS, documents, prev);
      });
    } else {
      G.handleFailResponseSimple(res, 'handleDocuments fail');
    }

    closeLoader();
  }, [primaryObjectGuid]);

  useEffect(() => {
    if (R.isNil(documents)) handleDocuments('get');
  }, [documents, handleDocuments]);

  const handleDownloadOrPreviewDocument = useCallback(async ({ guid, actionType }: Object) => {
    openLoader();

    const options = {
      params: { guid },
      resType: 'arraybuffer',
    };

    const endpoint = endpointsMap.getWorkOrderDocumentDownloadEndpoint(guid);

    const res = await sendRequestWithQSParamsSerializer('get', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      if (R.equals(actionType, 'download')) {
        G.saveFileFromResponse(res);
      } else {
        G.openFileInWindowFromArrayBufferResponse(res);
      }
    } else {
      G.handleFailResponseSimple(res);
    }

    closeLoader();
  }, [primaryObjectGuid]);

  return (
    <Fragment>
      <Box
        mr={10}
        overflowY='auto'
        p='15px 5px 0 15px'
        height='calc(100% - 139px)'
        css={scrollableContainerCss4px}
      >
        {
          R.or(documents, []).map((document: Object) => (
            <Document
              document={document}
              openModal={openModal}
              closeModal={closeModal}
              handleDocuments={handleDocuments}
              key={G.getGuidFromObject(document)}
              handleDownloadOrPreviewDocument={handleDownloadOrPreviewDocument}
            />
          ))
        }
      </Box>
      <AddDocument handleDocuments={handleDocuments} />
    </Fragment>
  );
};

const footerBtnStyles = { width: 100, height: 25, fontSize: 13 };

const EditorComponent = ({ value, setValue, setIsEditing, additionalStyles }: Object) => {
  const [editorState, setEditorState] = useState(() => {
    if (G.isString(value)) return G.createEditorState(value);

    return value;
  });

  const ref = useRef();

  const handleSaveChanges = useCallback(() => {
    const value = G.convertHtmlToString(editorState);

    if (R.isEmpty(R.trim(R.replace(/(<([^>]+)>)/ig, '', value)))) return;

    setValue(value);
  }, [editorState]);

  useLayoutEffect(() => {
    ref.current.focus();

    setEditorState(G.moveFocusToEnd(editorState));
  }, []);

  return (
    <Fragment>
      <EditorWrapper
        width='100%'
        bg={whiteColor}
        css={additionalStyles}
      >
        <Editor
          stripPastedStyles={true}
          toolbar={toolbarSettings}
          editorState={editorState}
          onEditorStateChange={setEditorState}
          editorRef={(editorRef: Object) => ref.current = editorRef}
        />
      </EditorWrapper>
      <FormFooter2
        submitAction={handleSaveChanges}
        cancelBtnStyles={footerBtnStyles}
        submitBtnStyles={footerBtnStyles}
        boxStyles={{ mt: 15, width: 220 }}
        cancelAction={() => setIsEditing(false)}
      />
    </Fragment>
  );
};

const Note = memo(({ note, openModal, closeModal, handleNotes }: Object) => {
  const [editMode, setEditMode] = useState(false);

  const { guid, text, createdBy, createdDate, lastModifiedBy, lastModifiedDate } = note;

  return (
    <Box {...wrapperStyles} pb='0px'>
      <Flex width='100%' justifyContent='space-between'>
        <Flex alignItems='flex-start'>
          <Flex
            width={30}
            height={30}
            title={createdBy}
            border='1px solid'
            borderRadius='50%'
            justifyContent='center'
            textTransform='uppercase'
            color={greyMatterhornColor}
            borderColor={lightGreyColor}
          >
            {R.take(1, createdBy)}
          </Flex>
          <Box ml={10} fontSize={12} color={greyMatterhornColor}>
            <TextComponent maxWidth={400} display='block' title={createdBy} withEllipsis={true}>
              {createdBy}
            </TextComponent>
            <Flex mt='5px' color={darkGreyColor}>
              {G.convertDateTimeToConfigFormat(G.ifElse(G.isNotNil(lastModifiedDate), lastModifiedDate, createdDate))}
              {
                G.notEquals(createdDate, lastModifiedDate) &&
                <Flex>
                  <Span ml={10}>
                    {G.getWindowLocale('titles:edited', 'Edited')}
                  </Span>
                  <TextComponent ml='5px' maxWidth={250} display='block' withEllipsis={true} title={lastModifiedBy}>
                    ({lastModifiedBy})
                  </TextComponent>
                </Flex>
              }
            </Flex>
          </Box>
        </Flex>
        <Flex>
          <Box cursor='pointer' onClick={() => setEditMode(true)}>
            {I.pencil(darkGreyColor)}
          </Box>
          <Box
            ml={10}
            cursor='pointer'
            onClick={() =>
              renderConfirmationModal({ openModal, closeModal, action: () => handleNotes('delete', {}, guid) })
            }
          >
            {I.trash(darkGreyColor)}
          </Box>
        </Flex>
      </Flex>
      <Box my={15} color={greyMatterhornColor}>
        {
          editMode ? (
            <EditorComponent
              value={text}
              setIsEditing={setEditMode}
              setValue={(text: string) =>
                handleNotes('put', { data: R.assoc(GC.FIELD_TEXT, text, note) }, guid, () => setEditMode(false))
              }
              additionalStyles={css`
                & .DraftEditor-root {
                  overflow-y: auto;
                  max-height: 350px;

                  ${scrollableContainerCss4px}
                }
              `}
            />
          ) : (
            <Box wordBreak='break-word'>
              {parse(text)}
            </Box>
          )
        }
      </Box>
    </Box>
  );
});

const AddNote = ({ editMode, setEditMode, handleNotes, workOrderGuid }: Object) => (
  <Box my={15} px={15}>
    {
      editMode ? (
        <EditorComponent
          value=''
          editMode={true}
          setIsEditing={setEditMode}
          setValue={(text: string) =>
            handleNotes('post', { data: { text, workOrderGuid } }, null, () => setEditMode(false))
          }
          additionalStyles={css`
            & .DraftEditor-root {
              height: 100px;
              overflow-y: auto;

              ${scrollableContainerCss4px}
            }
          `}
        />
      ) : (
        <Flex
          pl={15}
          height={32}
          width='100%'
          cursor='text'
          bg={whiteColor}
          borderRadius='4px'
          border='1px solid'
          color={darkGreyColor}
          borderColor={lightGreyColor}
          onClick={() => setEditMode(true)}
        >
          {G.getWindowLocale('titles:add-note', 'Add Note')}
        </Flex>
      )
    }
  </Box>
);

const Notes = (props: Object) => {
  const { notes, openModal, closeModal, openLoader, closeLoader, setActivities, primaryObjectGuid } = props;

  const [addNoteMode, setAddNoteMode] = useState(false);

  const handleNotes = useCallback(async (
    method: string,
    options: Object,
    noteGuid: string,
    successCallback: Function,
  ) => {
    openLoader();

    const operations = getOperations(noteGuid);

    const endpoints = {
      put: endpointsMap.workOrderNote,
      post: endpointsMap.workOrderNote,
      get: endpointsMap.workOrderNoteList,
      delete: endpointsMap.getWorkOrderNoteEndpoint(noteGuid),
    };

    const res = await sendRequest(
      method,
      R.prop(method, endpoints),
      {
        ...options,
        params: G.ifElse(R.equals(method, 'get'), { [GC.FIELD_WORK_ORDER_GUID]: primaryObjectGuid }, null),
      },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      setActivities((prev: Object) => {
        const newNotes = R.call(R.prop(method, operations), R.prop(GC.FIELD_NOTES, prev), data);

        return R.assoc(GC.FIELD_NOTES, newNotes, prev);
      });

      G.callFunction(successCallback);
    } else {
      G.handleFailResponseSimple(res, 'handleNotes fail');
    }

    closeLoader();
  }, [primaryObjectGuid]);

  useEffect(() => {
    if (R.isNil(notes)) handleNotes('get');
  }, [notes, handleNotes]);

  if (R.isNil(notes)) return null;

  return (
    <Fragment>
      <Box
        mr={10}
        overflowY='auto'
        p='15px 5px 0 15px'
        css={scrollableContainerCss4px}
        height={`calc(100% - ${G.ifElse(addNoteMode, 310, 109)}px)`}
      >
        {
          notes.map((note: Object) => (
            <Note
              note={note}
              openModal={openModal}
              closeModal={closeModal}
              handleNotes={handleNotes}
              key={G.getGuidFromObject(note)}
            />
          ))
        }
      </Box>
      <AddNote
        editMode={addNoteMode}
        handleNotes={handleNotes}
        setEditMode={setAddNoteMode}
        workOrderGuid={primaryObjectGuid}
      />
    </Fragment>
  );
};

const Invoices = (props: Object) => {
  const {
    invoices,
    openModal,
    closeModal,
    entityType,
    openLoader,
    closeLoader,
    setActivities,
    fleetEntityGuid,
    serviceIssueList,
    primaryObjectGuid,
    workOrderIssueGuids,
    serviceIssueOptions,
    handleGetServiceIssues,
    getWorkOrderListRequest,
  } = props;

  const handleInvoices = useCallback(async (
    method: string,
    options: Object,
    invoiceGuid: string,
    successCallback: Function,
  ) => {
    openLoader();

    const operations = getOperations(invoiceGuid);

    const invoiceEndpoint = {
      [GC.FIELD_TRUCK]: endpointsMap.truckWordOrderInvoice,
      [GC.FIELD_TRAILER]: endpointsMap.trailerWordOrderInvoice,
    };

    const endpoints = {
      put: invoiceEndpoint,
      post: invoiceEndpoint,
      get: {
        [GC.FIELD_TRUCK]: endpointsMap.truckWordOrderInvoiceList,
        [GC.FIELD_TRAILER]: endpointsMap.trailerWordOrderInvoiceList,
      },
      delete: {
        [GC.FIELD_TRUCK]: endpointsMap.getTruckWorkOrderInvoiceEndpoint(invoiceGuid),
        [GC.FIELD_TRAILER]: endpointsMap.getTrailerWorkOrderInvoiceEndpoint(invoiceGuid),
      },
    };

    const res = await sendRequest(
      method,
      R.path([method, entityType], endpoints),
      {
        ...options,
        params: G.ifElse(R.equals(method, 'get'), { [GC.FIELD_WORK_ORDER_GUID]: primaryObjectGuid }, null),
      },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      setActivities((prev: Object) => {
        const newInvoices = R.call(R.prop(method, operations), R.prop(GC.SYSTEM_LIST_INVOICES, prev), data);

        return R.assoc(GC.SYSTEM_LIST_INVOICES, newInvoices, prev);
      });

      G.callFunctionWithArgs(
        successCallback,
        { [GC.FIELD_ISSUE_GUIDS]: R.pathOr([], ['data', GC.FIELD_ISSUE_GUIDS], options) },
      );
    } else {
      G.handleFailResponseSimple(res, 'handleInvoices fail');
    }

    closeLoader();
  }, [primaryObjectGuid]);

  const handleAddOrEditInvoice = useCallback((invoice: Object) => {
    const isEditMode = G.isNotNilAndNotEmpty(invoice);
    const invoiceGuid = isEditMode ? G.getGuidFromObject(invoice) : null;

    const invoiceServiceIssueGuids = getInvoiceServiceIssues(invoiceGuid, serviceIssueList);

    const invoiceInitialValues = isEditMode
      ? R.assoc(GC.FIELD_ISSUE_GUIDS, invoiceServiceIssueGuids, invoice)
      : null;

    const invoiceServiceIssueOptions = getInvoiceServiceIssueOptions(
      invoiceGuid,
      workOrderIssueGuids,
      serviceIssueOptions,
    );

    const successCallback = ({ issueGuids }: Object) => {
      if (G.notEquals(issueGuids, invoiceServiceIssueGuids)) {
        handleGetServiceIssues(fleetEntityGuid, primaryObjectGuid);
      }

      getWorkOrderListRequest({ guids: R.of(Array, primaryObjectGuid) });

      closeModal();
    };

    const component = (
      <InvoiceForm
        isEditMode={isEditMode}
        initialValues={invoiceInitialValues}
        invoiceServiceIssueOptions={invoiceServiceIssueOptions}
        submitAction={(values: Object) => handleInvoices(
          G.ifElse(isEditMode, 'put', 'post'),
          { data: R.assoc(GC.FIELD_WORK_ORDER_GUID, primaryObjectGuid, values) },
          null,
          successCallback,
        )}
      />
    );

    const title = G.ifElse(
      isEditMode,
      G.getEditTitle,
      G.getAddTitle,
    )(['titles:cost-invoice-estimate', 'Cost / Invoice / Estimate']);

    const modal = {
      p: '0px',
      component,
      options: { title, width: 780 },
    };

    openModal(modal);
  }, [serviceIssueList, primaryObjectGuid, workOrderIssueGuids]);

  const handleRemoveInvoice = useCallback((invoiceGuid: string) => {
    const successCallback = () => {
      if (G.isNotNilAndNotEmpty(getInvoiceServiceIssues(invoiceGuid, serviceIssueList))) {
        handleGetServiceIssues(fleetEntityGuid, primaryObjectGuid);
      }

      getWorkOrderListRequest({ guids: R.of(Array, primaryObjectGuid) });
    };

    renderConfirmationModal({
      openModal,
      closeModal,
      action: () => handleInvoices('delete', {}, invoiceGuid, successCallback),
    });
  }, [serviceIssueList, primaryObjectGuid]);

  useEffect(() => {
    if (R.isNil(invoices)) handleInvoices('get');
  }, [invoices, handleInvoices]);

  return (
    <Fragment>
      <InvoicesTotals invoices={R.or(invoices, [])} />
      <Box
        mr={10}
        pl={15}
        pr='5px'
        overflowY='auto'
        css={scrollableContainerCss4px}
        height={`calc(100% - ${G.ifElse(G.isNilOrEmpty(invoices), 109, 145)}px)`}
      >
        {
          R.or(invoices, []).map((invoice: Object) => (
            <InvoiceInfo
              invoice={invoice}
              openModal={openModal}
              closeModal={closeModal}
              key={G.getGuidFromObject(invoice)}
              serviceIssueList={serviceIssueList}
              handleRemoveInvoice={handleRemoveInvoice}
              handleAddOrEditInvoice={handleAddOrEditInvoice}
            />
          ))
        }
      </Box>
      <Box my={15} px={15}>
        <MainActionButton
          height={32}
          width='100%'
          onClick={() => handleAddOrEditInvoice()}
        >
          {G.getAddTitle(['titles:cost-invoice-estimate', 'Cost / Invoice / Estimate'])}
        </MainActionButton>
      </Box>
    </Fragment>
  );
};

const tabs = [
  { text: G.getWindowLocale('titles:costs-invoices-estimate', 'Costs / Invoices / Estimate') },
  { text: G.getWindowLocale('titles:notes', 'Notes') },
  { text: G.getWindowLocale('titles:documents', 'Documents') },
];

export const Activities = (props: Object) => {
  const { maxHeight, openModal, closeModal, entityType, openLoader, closeLoader, primaryObjectGuid } = props;

  const [activeTab, setActiveTab] = useState(0);
  const [activities, setActivities] = useState({});

  const commonProps = {
    openModal,
    closeModal,
    openLoader,
    closeLoader,
    setActivities,
    primaryObjectGuid,
  };

  const tabsContentMap = [
    <Invoices
      {...props}
      key={0}
      entityType={entityType}
      setActivities={setActivities}
      invoices={R.prop(GC.SYSTEM_LIST_INVOICES, activities)}
    />,
    <Notes {...commonProps} key={1} notes={R.prop(GC.FIELD_NOTES, activities)} />,
    <Documents {...commonProps} key={2} documents={R.prop(GC.FIELD_DOCUMENTS, activities)} />,
  ];

  return (
    <Box
      width={550}
      maxHeight={maxHeight}
      borderLeft='1px solid'
      borderColor={lightGreyColor}
      bg={G.getTheme('colors.whiteGrey')}
    >
      <TabsMui
        tabs={tabs}
        tabsProps={tabsProps}
        activeMuiTab={activeTab}
        setActiveMuiTab={setActiveTab}
      />
      {R.prop(activeTab, tabsContentMap)}
    </Box>
  );
};
