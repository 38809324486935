import * as Yup from 'yup';
import { useFormik } from 'formik';
import React, { useCallback } from 'react';
// components
import { FormFooter2 } from '../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature template-inspection
import {
  getFields,
  defaultValues,
  getValidationSchemaObject,
  vehicleTypeDropdownOptions,
} from '../settings/create-template-form';
//////////////////////////////////////////////////

const CreateTemplateForm = (props: Object) => {
  const { handleCreateTemplate} = props;

  const onSubmit = useCallback((values: Object) => handleCreateTemplate(values), [handleCreateTemplate]);

  const validationSchema = Yup.lazy((values: Object) => Yup.object().shape(getValidationSchemaObject(values)));

  const formik = useFormik({
    onSubmit,
    validationSchema,
    enableReinitialize: true,
    initialValues: defaultValues,
  });

  const { handleSubmit } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikPropsToFieldset(formik)}
        fields={getFields(defaultValues)}
        fieldsWrapperStyles={{ flexDirection: 'column' }}
        optionsForSelect={{
          [GC.FIELD_VEHICLE_TYPE]: vehicleTypeDropdownOptions,
        }}
      />
      <FormFooter2 />
    </form>
  );
};

export default CreateTemplateForm;
