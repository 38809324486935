import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature template
import * as H from '../helpers';
// feature template/container-type
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  totalCount: 0,
  loading: false,
  itemList: null,
};

const setInitialState = () => (
  initialState
);

const setListLoading = (state: Object, loading: boolean) => (
  P.$set('loading', loading, state)
);

export const setItemList = (state: Object, data: Object) => {
  const newItems = data.map((item: Object, index: number) => R.mergeRight(
    item,
    {
      index,
      selected: false,
    },
  ));
  const list = R.indexBy(R.prop(GC.FIELD_GUID), newItems);

  return P.$all(
    P.$set('itemList', list),
    P.$set('totalCount', R.length(data)),
    state,
  );
};

const getItemListSuccess = (state: Object, data: Object) => setItemList(state, data);

const selectItem = (state: Object, id: Object) => H.selectItem(state, id);

const receivedListItemSuccess = (state: Object, data: Object) => {
  const { itemList } = state;
  const id = R.prop(GC.FIELD_GUID, data);
  const dataToUse = {
    ...data,
    selected: false,
    index: R.length(R.values(itemList)),
  };
  const newList = R.assoc(id, dataToUse, itemList);

  G.setItemToWindow('amousContainerTypeList', R.values(newList));

  return P.$set('itemList', newList, state);
};

const deleteListItemSuccess = (state: Object, guids: Array) => {
  const newList = R.omit(guids, state.itemList);

  G.setItemToWindow('amousContainerTypeList', R.values(newList));

  return P.$set('itemList', newList, state);
};

export default createReducer({
  [A.selectItem]: selectItem,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.deleteListItemSuccess]: deleteListItemSuccess,
  [A.receivedListItemSuccess]: receivedListItemSuccess,
}, initialState);
