import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../components/form-footer';
// forms
import { FieldsetComponent } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { Box } from '../../../../ui';
// feature template/container-type
import {
  containerTypeFieldSettings,
  defaultContainerTypeFields,
  getContainerTypeFormValidationSchema } from '../settings';
//////////////////////////////////////////////////

const enhance = compose(
  withFormik({
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultContainerTypeFields,
      props.initialValues,
    ),
    validationSchema: ({ isEditMode }: Object) => Yup.lazy(
      (values: Object) => Yup.object().shape(
        getContainerTypeFormValidationSchema(isEditMode, values),
      ),
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const { submitAction } = props;

      submitAction(values);
    },
  }),
  pure,
);

const ContainerTypeForm = (props: Object) => {
  const {
    isEditMode,
    closeModal,
    handleSubmit,
    optionsForSelect } = props;

  const formikProps = G.getFormikProps(props);

  return (
    <Box mx='auto' width={270}>
      <form onSubmit={handleSubmit}>
        <FieldsetComponent
          {...formikProps}
          optionsForSelect={optionsForSelect}
          fields={containerTypeFieldSettings}
          handlers={{ handleDisableIntegrationType: () => isEditMode }} />
        <FormFooter boxStyles={{ py: '10px' }} closeModal={closeModal} />
      </form>
    </Box>
  );
};

export default enhance(ContainerTypeForm);
