import { createSelector } from 'reselect';
// report-common
import { getReportSelectors } from '../../report-common';
//////////////////////////////////////////////////

const {
  store,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} = getReportSelectors('tollCharges');

const makeSelectItemList = () => createSelector(
  store,
  ({ itemList }: Object) => itemList,
);

const makeSelectDriverList = () => createSelector(
  store,
  ({ driverList }: Object) => driverList,
);

const makeSelectTruckList = () => createSelector(
  store,
  ({ truckList }: Object) => truckList,
);

const makeSelectTruckGuid = () => createSelector(
  store,
  ({ truckGuid }: Object) => truckGuid,
);

const makeSelectDriverGuid = () => createSelector(
  store,
  ({ driverGuid }: Object) => driverGuid,
);

export {
  makeSelectItemList,
  makeSelectTruckList,
  makeSelectTruckGuid,
  makeSelectDriverGuid,
  makeSelectUsedReport,
  makeSelectDriverList,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
};
