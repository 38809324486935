import * as R from 'ramda';
import { put, call, race, take, select, takeLatest } from 'redux-saga/effects';
// components
import { closeLoader, openLoader } from '../../components/loader/actions';
// features
import { receivedSwitchBranchSuccess } from '../branch/actions';
import { makeSelectCurrentUserBranchGuid } from '../auth/selectors';
import {
  makeSelectCurrentBranch,
  makeSelectCurrentBranchGuid } from '../branch/selectors';
import {
  makeSelectAccessToPagesList,
  makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// utilities
import routesMap from '../../utilities/routes';
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import * as globalActions from '../../common/actions';
// feature styling
import * as A from './actions';
//////////////////////////////////////////////////

export function* getStylingForBranchRequestSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const endpoint = endpointsMap.getStylingForBranchEndpoint(branchGuid);
    const res = yield call(sendRequest, 'get', endpoint);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getStylingSuccess(data));
    } else {
      yield put(A.setStylingSuccess({}));
    }
  } catch (error) {
    yield call(G.handleException, error, 'getStylingForBranchRequestSaga exception');
  }
}

export function* getMainStylingForBranchRequestSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentUserBranchGuid());
    const endpoint = endpointsMap.getStylingForBranchEndpoint(branchGuid);
    const res = yield call(sendRequest, 'get', endpoint);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getInitialStylingSuccess(data));
    } else {
      yield put(A.setStylingSuccess({}));
    }
  } catch (error) {
    yield call(G.handleException, error, 'getMainStylingForBranchRequestSaga exception');
  }
}

export function* getStylingByBranchRequestSaga() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const endpoint = endpointsMap.getStylingForBranchEndpoint(branchGuid);
    const res = yield call(sendRequest, 'get', endpoint);
    const { data, status } = res;
    if (R.and(G.isResponseSuccess(status), G.notEquals(status, 204))) {
      yield put(A.getStylingByBranchSuccess(data));
    } else {
      yield put(A.setStylingSuccess({}));
    }
  } catch (error) {
    yield call(G.handleException, error, 'getStylingByBranchRequestSaga exception');
  }
}

export function* getSplashScreenSaga() {
  try {
    const { dnsPrefix } = yield select(makeSelectCurrentBranch());
    if (R.isNil(dnsPrefix)) return;
    const options = {
      params: {
        dnsPrefix,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.splashScreen, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getSplashScreenSuccess(data));
    } else {
      yield put(A.getSplashScreenSuccess({}));
    }
  } catch (error) {
    yield call(G.handleException, error, 'getSplashScreenSaga exception');
  }
}

export function* getSplashScreenByBranchRequestSaga() {
  try {
    const endpoint = endpointsMap.splashScreenByBranchGuid;
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const params = { [GC.FIELD_BRANCH_GUID]: branchGuid };
    const res = yield call(sendRequest, 'get', endpoint, { params });
    const { data, status } = res;
    if (R.and(G.isResponseSuccess(status), G.notEquals(status, 204))) {
      yield put(A.getSplashScreenByBranchSuccess(data));
    }
  } catch (error) {
    yield call(G.handleException, error, 'getSplashScreenByBranchRequestSaga exception');
  }
}

export function* getInitialSplashScreenSaga() {
  try {
    const dnsPrefix = G.getSplittedUrlLocation('.')[0];
    const params = { dnsPrefix };
    const res = yield call(sendRequest, 'get', endpointsMap.splashScreen, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(globalActions.initialSplashScreenLoadSuccess(data));
    } else {
      yield put(globalActions.initialSplashScreenLoadFail());
      yield call(G.handleFailResponse, res, 'getInitialSplashScreenSaga fail', false, true);
    }
  } catch (error) {
    yield put(globalActions.initialSplashScreenLoadFail());
    yield call(G.handleException, error, 'getInitialSplashScreenSaga exception');
  }
}

export const createSetStylingFormData = (data: Object, branchGuid: string) => {
  const formData = new window.FormData();
  R.forEachObjIndexed((value: any, key: string) => {
    if (G.isNotNil(value)) {
      formData.append(key, value);
    }
  }, data);
  formData.delete('logoUrl');
  formData.delete(GC.FIELD_BRANCH_GUID);
  formData.append(GC.FIELD_BRANCH_GUID, branchGuid);
  formData.delete('version');
  formData.delete('guid');
  return formData;
};

export function* handleSetStylingRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const formData = yield call(createSetStylingFormData, payload, branchGuid);
    const options = {
      data: formData,
    };
    const res = yield call(sendRequest, 'post', endpointsMap.styling, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.setStylingSuccess(data));
      const branchGuid = yield select(makeSelectCurrentUserBranchGuid());
      if (R.equals(branchGuid, R.prop(GC.FIELD_BRANCH_GUID, payload))) {
        yield put(A.getInitialStylingSuccess(data));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleSetStylingRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleSetStylingRequest exception');
  }
}

export const createSetSplashScreenFormData = (data: Object) => {
  const formData = new window.FormData();
  R.forEachObjIndexed((value: any, key: string) => {
    if (G.isNotNil(value)) {
      formData.append(key, value);
    }
  }, R.omit([
    'version',
    'logoUrl',
    'firstBackgroundImageUrl',
    'thirdBackgroundImageUrl',
    'secondBackgroundImageUrl',
  ], data));
  return formData;
};

export function* handleSetSplashScreenRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const formData = yield call(createSetSplashScreenFormData, payload);
    const options = {
      data: formData,
    };
    const res = yield call(sendRequest, 'post', endpointsMap.splashScreen, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.setSplashScreenSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleSetSplashScreenRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleSetSplashScreenRequest exception');
  }
}

export const createUpdateStylingFormData = (data: Object) => {
  const formData = new window.FormData();
  R.forEachObjIndexed((value: any, key: string) => {
    if (G.isNotNil(value)) {
      formData.append(key, value);
    }
  }, data);
  if (G.isNotNil(data.logoFile)) {
    formData.delete('logoUrl');
  }
  return formData;
};

export function* handleUpdateStylingRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const formData = yield call(createUpdateStylingFormData, payload);
    const options = {
      data: formData,
    };
    const res = yield call(sendRequest, 'post', endpointsMap.stylingUpdate, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.updateStylingSuccess(data));
      const branchGuid = yield select(makeSelectCurrentUserBranchGuid());
      if (R.equals(branchGuid, R.prop(GC.FIELD_BRANCH_GUID, payload))) {
        yield put(A.getInitialStylingSuccess(data));
      }
      yield call(G.goToRoute, routesMap.stylingConfig);
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateStylingRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateStylingRequest exception');
  }
}

export const createUpdateSplashScreenFormData = (data: Object) => {
  const formData = new window.FormData();
  R.forEachObjIndexed((value: any, key: string) => {
    if (G.isNotNil(value)) {
      formData.append(key, value);
    }
  }, data);
  if (G.isNotNil(data.logoFile)) {
    formData.delete('logoUrl');
  }
  if (G.isNotNil(data.firstBackgroundFile)) {
    formData.delete('firstBackgroundImageUrl');
  }
  if (G.isNotNil(data.secondBackgroundFile)) {
    formData.delete('secondBackgroundImageUrl');
  }
  if (G.isNotNil(data.thirdBackgroundFile)) {
    formData.delete('thirdBackgroundImageUrl');
  }
  return formData;
};

export function* handleUpdateSplashScreenSettingsRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const formData = yield call(createUpdateSplashScreenFormData, payload);
    const options = {
      data: formData,
    };
    const res = yield call(sendRequest, 'post', endpointsMap.splashScreenUpdate, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.updateSplashScreenSettingsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateSplashScreenSettingsRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateSplashScreenSettingsRequest exception');
  }
}

export function* handlerVisitStylingPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    const accessToPagesList = yield select(makeSelectAccessToPagesList());
    const isAllowed = R.includes(payload.pathname, accessToPagesList);
    const isInitialDataLoaded = yield select(makeSelectInitialDataLoadedStatus());
    if (R.not(isInitialDataLoaded)) {
      yield race({
        success: take(globalActions.initialDataLoadSuccess),
        fail: take(globalActions.initialDataLoadFail),
      });
    }
    if (R.not(isAllowed)) {
      yield put({
        payload: payload.pathname,
        type: GC.CHECK_VISIT_STYLING_PAGE,
      });
      yield take(GC.CHECK_VISIT_STYLING_PAGE_ALLOWED);
    }
    yield put(openLoader({ showDimmer: true }));
    yield call(getStylingByBranchRequestSaga);
    yield call(getSplashScreenByBranchRequestSaga);
    yield put(closeLoader());
    break;
  }
}

function* stylingWatcherSaga() {
  yield takeLatest(GC.CLEAR_STORE, getInitialSplashScreenSaga);
  yield takeLatest(A.setStylingRequest, handleSetStylingRequest);
  yield takeLatest(A.getSplashScreenRequest, getSplashScreenSaga);
  yield takeLatest(A.updateStylingRequest, handleUpdateStylingRequest);
  yield takeLatest(GC.VISIT_STYLING_PAGE, handlerVisitStylingPageSaga);
  yield takeLatest(A.getStylingRequest, getStylingForBranchRequestSaga);
  yield takeLatest(A.setSplashScreenRequest, handleSetSplashScreenRequest);
  yield takeLatest(receivedSwitchBranchSuccess, getStylingForBranchRequestSaga);
  yield takeLatest(globalActions.initialDataLoadSuccess, getInitialSplashScreenSaga);
  yield takeLatest(A.getSplashScreenByBranchRequest, getSplashScreenByBranchRequestSaga);
  yield takeLatest(globalActions.initialDataLoadSuccess, getStylingForBranchRequestSaga);
  yield takeLatest(globalActions.initialDataLoadSuccess, getMainStylingForBranchRequestSaga);
  yield takeLatest(A.updateSplashScreenSettingsRequest,
    handleUpdateSplashScreenSettingsRequest);
}

export default stylingWatcherSaga;
