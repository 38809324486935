import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// features
import { receivedLogOutSuccess } from '../auth/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature report-format
import * as A from './actions';
//////////////////////////////////////////////////

const initialState = {
  reports: {},
  totalCount: 0,
  usedReport: {},
  loading: false,
  filterProps: [],
  filterParams: {},
  isEditPage: false,
  ignorePrompt: false,
  availableReports: [],
  requestPending: false,
  originReportParam: {},
  usedReportStatus: false,
  pagination: {
    limit: 20,
    offset: 0,
  },
  renderFields: {
    columnsArr: [],
    fixedColumnOptions: [],
  },
};

const setEditPage = (state: Object, data: boolean) => (
  P.$set('isEditPage', data, state)
);

const setQuickFilterParams = (state: Object, data: Object) => (
  P.$set('filterParams', data, state)
);

const receivedListOfReportsSuccess = (state: Object, data: Object) => {
  const { reports, pagination } = state;
  const { results, totalCount } = data;
  const list = R.compose(
    R.mergeRight(reports),
    R.indexBy(R.prop(GC.FIELD_GUID)),
    R.map((item: Object) => R.assoc('selected', false, item)),
  )(results);
  const newOffset = R.add(pagination.offset, pagination.limit);

  return P.$all(
    P.$set('reports', list),
    P.$set('pagination.limit', 10),
    P.$set('totalCount', totalCount),
    P.$set(
      'pagination.offset',
      G.ifElse(
        R.gt(totalCount, newOffset),
        newOffset,
        totalCount,
      ),
    ),
    state,
  );
};

const cleanQuickFilter = (state: Object) => (
  P.$set('filterParams', {}, state)
);

const receivedUsedReportSuccess = (state: Object, data: Object) => (
  P.$all(
    P.$set('usedReport', data),
    P.$set('requestPending', false),
    state,
  )
);

const receivedUpdateReportSuccess = (state: Object, data: Object) => (
  P.$set(`reports.${data.guid}`, data, state)
);

const receivedDeleteReportSuccess = (state: Object, guid: string) => (
  P.$all(
    P.$drop(`reports.${guid}`),
    P.$set('pagination.offset', R.subtract(R.path(['pagination', 'offset'], state), 1)),
    P.$set('totalCount', G.ifElse(R.equals(state.totalCount, 0), 0, R.dec(state.totalCount))),
    state,
  )
);

const receivedAvailableReportsSuccess = (state: Object, data: Array) => (
  P.$set('availableReports', data, state)
);

const setUsedReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('reports', {}),
    P.$set('totalCount', 0),
    P.$set('usedReport', data),
    P.$set('reportPending', false),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const cleanUsedReport = (state: Object) => (
  P.$set('usedReport', {}, state)
);

const setUsedReportStatus = (state: Object, data: boolean) => (
  P.$set('usedReportStatus', data, state)
);

const setIgnorePromptStatus = (state: Object, data: boolean) => (
  P.$set('ignorePrompt', data, state)
);

const setRenderColumn = (state: Object, data: Object) => (
  P.$set('renderFields', data, state)
);

const setFilterProps = (state: Object, data: Array) => (
  R.assoc('filterProps', data, state)
);

const setOriginReportParams = (state: Object, data: Object) => (
  P.$set('originReportParam', data, state)
);

const cleanReportStateAction = () => initialState;

const handleReceivedLogOutSuccess = () => initialState;

const selectItem = (state: Object, id: Object) => {
  const { reports } = state;
  if (R.equals(id, 'all')) {
    const value = R.not(R.all(
      (item: Object) => item.selected,
      R.values(reports),
    ));
    return P.$set(
      'reports', R.map(R.assoc('selected', value), reports), state,
    );
  }
  return P.$toggle(`reports.${id}.selected`, state);
};

const resetListAndPagination = (state: Object) => (
  P.$all(
    P.$set('reports', {}),
    P.$set('totalCount', 0),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const setReportPending = (state: Object) => (
  P.$set('reportPending', true, state)
);

const setListLoading = (state: Object, data: boolean) => (
  P.$set('loading', data, state)
);

const setReports = (state: Object, data: Array) => (
  P.$set('availableReports', data, state)
);

export default createReducer({
  [A.setReports]: setReports,
  [A.selectItem]: selectItem,
  [A.setEditPage]: setEditPage,
  [A.setUsedReport]: setUsedReport,
  [A.setFilterProps]: setFilterProps,
  [A.setListLoading]: setListLoading,
  [A.cleanUsedReport]: cleanUsedReport,
  [A.setRenderColumn]: setRenderColumn,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setUsedReportStatus]: setUsedReportStatus,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.setOriginReportParams]: setOriginReportParams,
  [A.setIgnorePromptStatus]: setIgnorePromptStatus,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.cleanReportStateAction]: cleanReportStateAction,
  [receivedLogOutSuccess]: handleReceivedLogOutSuccess,
  [A.receivedUsedReportSuccess]: receivedUsedReportSuccess,
  [A.receivedDeleteReportSuccess]: receivedDeleteReportSuccess,
  [A.receivedUpdateReportSuccess]: receivedUpdateReportSuccess,
  [A.receivedListOfReportsSuccess]: receivedListOfReportsSuccess,
  [A.receivedAvailableReportsSuccess]: receivedAvailableReportsSuccess,
}, initialState);
