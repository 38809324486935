import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
//////////////////////////////////////////////////

export const getEntityListBySubObjectTypeRequest = async (props: Object) => {
  const {
    subObjects,
    entityType,
    entityGuid,
    openLoader,
    closeLoader,
    setSubObjects,
    subObjectType,
  } = props;

  const path = [entityType, subObjectType, entityGuid];

  if (R.or(G.isNotNilAndNotEmpty(R.path(path, subObjects)), G.isAnyNilOrEmpty(path))) return;

  openLoader();

  const endpoints = {
    [GC.TASK_MANAGEMENT_OBJECT_TYPE_TRUCK]: {
      [GC.TASK_MANAGEMENT_SUB_OBJECT_TYPE_EQUIPMENT_SERVICE]: endpointsMap.truckServiceList,
    },
    [GC.TASK_MANAGEMENT_OBJECT_TYPE_TRAILER]: {
      [GC.TASK_MANAGEMENT_SUB_OBJECT_TYPE_EQUIPMENT_SERVICE]: endpointsMap.trailerServiceList,
    },
  };

  const primaryObjectGuidKeyMap = {
    [GC.TASK_MANAGEMENT_OBJECT_TYPE_TRUCK]: GC.FIELD_TRUCK_GUID,
    [GC.TASK_MANAGEMENT_OBJECT_TYPE_TRAILER]: GC.FIELD_TRAILER_GUID,
  };

  const endpoint = R.path([entityType, subObjectType], endpoints);

  const options = {
    params: {
      [R.prop(entityType, primaryObjectGuidKeyMap)]: entityGuid,
    },
  };

  const res = await sendRequest('get', endpoint, options);

  const { data, status } = res;

  if (G.isResponseSuccess(status)) {
    setSubObjects((prev: Object) => R.assocPath(path, data, prev));
  } else {
    G.handleException('getEntityListBySubObjectTypeRequest exception');
  }

  closeLoader();
};

export const getEntityListByEntityTypeRequest = async (props: Object) => {
  const { entities, setEntities, entityType, openLoader, closeLoader, setEntityListByEntityTypeSuccess } = props;

  if (R.or(G.isNotNilAndNotEmpty(R.prop(entityType, entities)), R.isNil(entityType))) return;

  openLoader();

  const endpoints = {
    [GC.FIELD_ASSIGNEE]: endpointsMap.userListForTask,
    [GC.TASK_MANAGEMENT_OBJECT_TYPE_TRUCK]: endpointsMap.listFleetTrucks,
    [GC.TASK_MANAGEMENT_OBJECT_TYPE_GENERAL]: endpointsMap.listUsersGeneral,
    [GC.TASK_MANAGEMENT_OBJECT_TYPE_TRAILER]: endpointsMap.listFleetTrailers,
    [GC.TASK_MANAGEMENT_OBJECT_TYPE_DRIVER]: endpointsMap.listFleetDriversFullName,
    [GC.TASK_MANAGEMENT_OBJECT_TYPE_FLEET_VENDOR]: endpointsMap.fleetVendorListForTask,
  };

  const options = {
    params: { [GC.BRANCH_GUID]: G.getAmousCurrentBranchGuidFromWindow() },
  };

  const res = await sendRequest('get', R.prop(entityType, endpoints), options);

  const { data, status } = res;

  if (G.isResponseSuccess(status)) {
    if (R.equals(entityType, GC.FIELD_ASSIGNEE)) {
      const generalEntities = R.filter(R.propEq(GC.USER_TYPE_GENERAL, GC.FIELD_USER_TYPE), data);

      const driverEntities = R.compose(
        R.indexBy(R.prop(GC.FIELD_USER_LOGIN_ID)),
        R.filter(R.propEq(GC.USER_TYPE_DRIVER, GC.FIELD_USER_TYPE)),
      )(data);

      const newEntities = {
        driverEntities,
        [GC.FIELD_ASSIGNEE]: data,
        [GC.TASK_MANAGEMENT_OBJECT_TYPE_GENERAL]: generalEntities,
      };

      setEntities(R.mergeRight(newEntities));
      setEntityListByEntityTypeSuccess(newEntities);

      return closeLoader();
    }

    if (R.equals(entityType, GC.TASK_MANAGEMENT_OBJECT_TYPE_DRIVER)) {
      const mappedDrivers = R.map((item: Object) => R.assoc(
        'profilePhotoUrl',
        R.path(['driverEntities', item.loginId, 'profilePhotoUrl'], entities),
        item,
      ), data);

      setEntities((prev: Object) => R.assoc(entityType, mappedDrivers, prev));
      setEntityListByEntityTypeSuccess({ entityType, entities: mappedDrivers });

      return closeLoader();
    }

    setEntities((prev: Object) => R.assoc(entityType, data, prev));
    setEntityListByEntityTypeSuccess({ entityType, entities: data });
  } else {
    G.handleException('getEntityListByEntityTypeRequest exception');
  }

  closeLoader();
};

const subObjectTypeOptionsMap = {
  [GC.TASK_MANAGEMENT_OBJECT_TYPE_TRUCK]: [
    {
      value: GC.TASK_MANAGEMENT_SUB_OBJECT_TYPE_EQUIPMENT_SERVICE,
      label: G.getEnumLocale(GC.TASK_MANAGEMENT_SUB_OBJECT_TYPE_EQUIPMENT_SERVICE),
    },
  ],
  [GC.TASK_MANAGEMENT_OBJECT_TYPE_TRAILER]: [
    {
      value: GC.TASK_MANAGEMENT_SUB_OBJECT_TYPE_EQUIPMENT_SERVICE,
      label: G.getEnumLocale(GC.TASK_MANAGEMENT_SUB_OBJECT_TYPE_EQUIPMENT_SERVICE),
    },
  ],
};

export const makeOptionsForSelect = (props: Object) => {
  const {
    type,
    entities,
    objectType,
    taskStatuses,
    initialValues,
    subObjectOptions,
    handleOpenDetails,
    groupedByTaskTypesTaskStatusMap,
  } = props;

  const makeOptionsForSelectFromUserList = R.map((item: Object) => {
    const { fullText, avatarText } = G.getUserInfo(item);

    const value = G.getGuidFromObject(item);

    const action = () => handleOpenDetails(G.getGuidFromObject(item), objectType);

    return R.mergeRight(item, { action, value, fullText, avatarText, label: fullText });
  });

  const assigneeOptions = makeOptionsForSelectFromUserList(R.propOr([], GC.FIELD_ASSIGNEE, entities));
  const driverOptions = makeOptionsForSelectFromUserList(R.propOr([], GC.TASK_MANAGEMENT_OBJECT_TYPE_DRIVER, entities));

  const userGeneralOptions = makeOptionsForSelectFromUserList(
    R.propOr([], GC.TASK_MANAGEMENT_OBJECT_TYPE_GENERAL, entities),
  );

  const vendorOptions = R.map(({ guid, name, profilePhotoUrl }: Object) => ({
    profilePhotoUrl,
    [GC.FIELD_VALUE]: guid,
    [GC.FIELD_LABEL]: name,
    avatarText: R.toUpper(R.pathOr('', [0], name)),
    action: () => handleOpenDetails(guid, objectType),
  }), R.propOr([], GC.TASK_MANAGEMENT_OBJECT_TYPE_FLEET_VENDOR, entities));

  const makeTruckTrailerOptions = R.map(({ guid, unitId, profilePhotoUrl }: Object) => ({
    profilePhotoUrl,
    [GC.FIELD_VALUE]: guid,
    [GC.FIELD_LABEL]: unitId,
    avatarText: R.toUpper(R.pathOr('', [0], unitId)),
    action: () => handleOpenDetails(guid, objectType),
  }));

  const truckOptions = makeTruckTrailerOptions(
    R.propOr([], GC.TASK_MANAGEMENT_OBJECT_TYPE_TRUCK, entities),
  );

  const trailerOptions = makeTruckTrailerOptions(
    R.propOr([], GC.TASK_MANAGEMENT_OBJECT_TYPE_TRAILER, entities),
  );

  const objTypeOptionsMap = {
    [GC.TASK_MANAGEMENT_OBJECT_TYPE_TRUCK]: truckOptions,
    [GC.TASK_MANAGEMENT_OBJECT_TYPE_DRIVER]: driverOptions,
    [GC.TASK_MANAGEMENT_OBJECT_TYPE_TRAILER]: trailerOptions,
    [GC.TASK_MANAGEMENT_OBJECT_TYPE_FLEET_VENDOR]: vendorOptions,
    [GC.TASK_MANAGEMENT_OBJECT_TYPE_GENERAL]: userGeneralOptions,
  };

  const taskTypes = R.map((item: Object) => {
    const { guid, name } = item;

    return R.mergeRight(item, { [GC.FIELD_VALUE]: guid, [GC.FIELD_LABEL]: name });
  }, R.propOr([], ['taskTypes'], props));

  const statusMap = R.propOr([], type, groupedByTaskTypesTaskStatusMap);

  const taskStatusesOptions = R.compose(
    G.mapNameGuidObjectPropsToLabelValueObject,
    R.filter(({ guid, completed }: Object) => {
      if (R.and(completed, G.isNilOrEmpty(R.prop(GC.FIELD_VERSION, initialValues)))) return false;

      return R.includes(guid, statusMap);
    }),
  )(R.or(taskStatuses, []));

  return {
    taskTypes,
    driverOptions,
    assigneeOptions,
    subObjectOptions,
    userGeneralOptions,
    groupedByTaskTypesTaskStatusMap,
    taskStatuses: taskStatusesOptions,
    taskObjectOptions: R.prop(objectType, objTypeOptionsMap),
    subObjectTypeOptions: R.propOr([], objectType, subObjectTypeOptionsMap),
  };
};

export const makeSubTypeOptions = ({ entities, objectType, subObjectType }: Object) => {
  if (G.isAnyNilOrEmpty([entities, objectType, subObjectType])) return [];

  const equipmentServiceMapper = R.map(({ guid, serviceId }: Object) => ({
    [GC.FIELD_VALUE]: guid,
    [GC.FIELD_LABEL]: serviceId,
  }));

  const mappers = {
    [GC.TASK_MANAGEMENT_OBJECT_TYPE_TRUCK]: {
      [GC.TASK_MANAGEMENT_SUB_OBJECT_TYPE_EQUIPMENT_SERVICE]: equipmentServiceMapper,
    },
    [GC.TASK_MANAGEMENT_OBJECT_TYPE_TRAILER]: {
      [GC.TASK_MANAGEMENT_SUB_OBJECT_TYPE_EQUIPMENT_SERVICE]: equipmentServiceMapper,
    },
  };

  const mapper = R.path([objectType, subObjectType], mappers);
  const options = mapper(entities);

  return options;
};

export const makeValuesForRequest = ({ values, entities, taskTypes, taskStatuses, initialValues }: Object) => {
  const { objectType, editorState } = values;

  const makeObjectFromStringByPropName = (propName: string, entities: Array) => R.find(
    R.propEq(propName, GC.FIELD_GUID),
    entities,
  );

  const map = {
    [GC.FIELD_TYPE]: taskTypes,
    [GC.FIELD_STATUS]: taskStatuses,
    [GC.FIELD_TASK_OBJECT]: R.prop(objectType, entities),
    [GC.FIELD_ASSIGNEE]: R.prop(GC.FIELD_ASSIGNEE, entities),
  };

  const requestValues = R.compose(
    R.omit(['comments', 'editorState']),
    R.mergeRight({
      ...values,
      prevStatus: R.prop(GC.FIELD_STATUS, initialValues),
      [GC.FIELD_DESCRIPTION]: G.convertHtmlToString(editorState),
      prevAssigneeGuid: R.prop(GC.FIELD_ASSIGNEE_GUID, initialValues),
    }),
    R.mapObjIndexed((entities: string, propName: string) => makeObjectFromStringByPropName(
      R.prop(propName, values),
      entities,
    )),
  )(map);

  return requestValues;
};

export const makeInitialValues = (values: Object) => ({
  ...values,
  [GC.FIELD_TASK_OBJECT]: values.objectGuid,
  [GC.FIELD_TYPE]: R.path([GC.FIELD_TYPE, GC.FIELD_CONFIG_GUID], values),
  [GC.FIELD_ASSIGNEE]: R.path([GC.FIELD_ASSIGNEE, GC.FIELD_GUID], values),
  [GC.FIELD_STATUS]: R.path([GC.FIELD_STATUS, GC.FIELD_CONFIG_GUID], values),
});
