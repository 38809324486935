import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { USER_ROLE_TYPES } from '../../../helpers/options';
//////////////////////////////////////////////////

const inputWrapperStyles = { width: 230, m: '5px 0 12px 15px' };

export const defaultRoleFields = {
  [GC.FIELD_NAME]: null,
  [GC.FIELD_TYPE]: null,
  [GC.FIELD_ROLE_AVAILABLE_DOCUMENT_TYPE_GUIDS]: null,
};

export const roleValidationSchemaObject = {
  [GC.FIELD_ROLE_AVAILABLE_DOCUMENT_TYPE_GUIDS]: Yup.array().nullable(true),
  [GC.FIELD_NAME]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_TYPE]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
};

export const roleFields = {
  [GC.FIELD_NAME]: {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_NAME,
    label: ['titles:role-name', 'Role Name'],
  },
  [GC.FIELD_TYPE]: {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_TYPE,
    options: USER_ROLE_TYPES,
    label: ['titles:role-type', 'Role Type'],
    customDisabledFunction: 'handleDisableRoleType',
  },
  [GC.FIELD_ROLE_AVAILABLE_DOCUMENT_TYPE_GUIDS]: {
    isMulti: true,
    type: 'reactSelect',
    options: GC.AVAILABLE_DOCUMENT_TYPES,
    inputWrapperStyles: { width: 230, m: '15px 5px' },
    fieldName: GC.FIELD_ROLE_AVAILABLE_DOCUMENT_TYPE_GUIDS,
  },
};
