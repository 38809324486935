/* eslint-disable max-len */
import React from 'react';
//////////////////////////////////////////////////

const RoofPartMarker = () => (
  <svg
    fill='none'
    width='597'
    height='30'
    viewBox='0 0 597 30'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M 250 0 L 300 0 330 30' stroke='black' />
  </svg>
);

export { RoofPartMarker };
