// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

const TITLES_MAP = {
  mc: G.getWindowLocale('titles:mc', 'MC'),
  dot: G.getWindowLocale('titles:dot', 'DOT'),
  rate: G.getWindowLocale('titles:rate', 'Rate'),
  roll: G.getWindowLocale('titles:roll', 'Roll'),
  commitments: G.getWindowLocale('titles:commitments', 'Commitments'),
};

export {
  TITLES_MAP,
};
