import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../../components/confirm';
import { ActionsElement } from '../../../../components/actions-element';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
import { getOrderBySourceRoute, getOrderTemplateRoute } from '../../../../utilities/routes';
// features
import Audit2 from '../../../audit2';
// feature template
import ScheduleRoute from '../../route/components/schedule-route';
import { CreateRoutesForm } from '../../route/components/create-routes-form';
// feature template-report
import { withCreateOrUpdateForm } from '../hocs';
import { getWritePermissionsByReportType } from '../settings';
import {
  createRouteFromTemplateRequest,
  changeTemplateItemStatusRequest,
} from '../actions';
//////////////////////////////////////////////////

const iconColor = G.getTheme('icons.iconColor');

const options = ({
  guid,
  active,
  reportType,
  handleRemove,
  handleShowAudit,
  handleCreateRoute,
  handleChangeStatus,
  handleGoToEditPage,
  handleScheduleRoute,
  handleCreateOrUpdateTemplateItem,
}: Object) => ({
  editPage: {
    action: handleGoToEditPage,
    frontIcon: I.pencil(iconColor),
    text: G.getWindowLocale('actions:edit', 'Edit'),
    permissions: getWritePermissionsByReportType(reportType),
  },
  remove: {
    action: handleRemove,
    frontIcon: I.trash(iconColor),
    text: G.getWindowLocale('actions:delete', 'Delete'),
    permissions: getWritePermissionsByReportType(reportType),
  },
  editForm: {
    frontIcon: I.pencil(iconColor),
    action: handleCreateOrUpdateTemplateItem,
    text: G.getWindowLocale('actions:edit', 'Edit'),
    permissions: getWritePermissionsByReportType(reportType),
  },
  changeStatus: {
    action: handleChangeStatus,
    frontIcon: G.ifElse(
      active,
      I.crossInRound,
      I.checkMark,
    )(iconColor),
    text: G.ifElse(
      active,
      G.getWindowLocale('actions:deactivate', 'Deactivate'),
      G.getWindowLocale('actions:activate', 'Activate'),
    ),
  },
  createClo: {
    frontIcon: I.routesLoads(iconColor),
    permissions: getWritePermissionsByReportType(reportType),
    text: G.getWindowLocale('actions:create-clo', 'Create CLO'),
    action: () => {
      const options = {
        guid,
        [GC.FIELD_SOURCE_TYPE]: GC.CREATE_DO_SOURCE_TYPE_TEMPLATE,
      };

      G.goToRoute(getOrderBySourceRoute(options));
    },
  },
  scheduleRoute: {
    action: handleScheduleRoute,
    frontIcon: I.routesLoads(iconColor, 13, 13),
    permissions: getWritePermissionsByReportType(reportType),
    text: G.getWindowLocale('actions:schedule-route', 'Schedule Route'),
  },
  createRoute: {
    action: handleCreateRoute,
    frontIcon: I.routesLoads(iconColor, 13, 13),
    permissions: getWritePermissionsByReportType(reportType),
    text: G.getWindowLocale('actions:create-route', 'Create Route!'),
  },
  showAudit: {
    action: handleShowAudit,
    text: G.getWindowLocale('titles:audit', 'Audit'),
    frontIcon: I.renderAuditHistoryIcon(iconColor, 13, 13),
  },
});

const actionsToPickMap = {
  [GC.SHARED_ACCESSORIAL_REPORT]: ['editForm', 'remove'],
  [GC.CONTACT_BOOK_REPORT]: ['editForm', 'remove', 'showAudit'],
  [GC.CONTAINER_TEMPLATE_REPORT]: ['editForm', 'remove', 'showAudit'],
  [GC.ITEM_REPORT]: ['editForm', 'changeStatus', 'remove', 'showAudit'],
  [GC.COMPENSATION_TEMPLATE_REPORT]: ['editForm', 'remove', 'showAudit'],
  [GC.CLO_TEMPLATE_REPORT]: ['editPage', 'createClo', 'remove', 'showAudit'],
  [GC.ROUTE_TEMPLATE_REPORT]: ['createRoute', 'scheduleRoute', 'changeStatus', 'remove', 'showAudit'],
  [GC.LOCATION_TEMPLATE_REPORT]: ['editForm', 'changeStatus', 'remove', 'showAudit'],
};

const withChangeStatus = withHandlers({
  handleChangeStatus: (props: Object) => () => {
    const {
      guid,
      active,
      itemName,
      openModal,
      closeFixedPopup,
      changeTemplateItemStatusRequest,
    } = props;

    G.callFunction(closeFixedPopup);
    const action = G.ifElse(G.isTrue(active), 'deactivate', 'activate');

    const locale = {
      activate: {
        button: ['actions:activate', 'Activate'],
        confirm: [
          'messages:activate-confirmation-text',
          'Are you sure you want activate ',
        ],
      },
      deactivate: {
        button: ['actions:deactivate', 'Deactivate'],
        confirm: [
          'messages:deactivate-confirmation-text',
          'Are you sure you want deactivate ',
        ],
      },
    };

    const textLocale = G.getWindowLocale(...R.pathOr([], [action, 'confirm'], locale));
    const textProps = { whiteSpace: 'nowrap', withEllipsis: true, title: `${textLocale} ${itemName}` };

    const component = (
      <ConfirmComponent
        name={itemName}
        textProps={textProps}
        textLocale={G.getWindowLocale(...R.pathOr([], [action, 'confirm'], locale))}
      />
    );

    const modal = {
      component,
      options: {
        width: 600,
        controlButtons: [
          {
            type: 'button',
            action: () => changeTemplateItemStatusRequest({ guid, active }),
            name: G.getWindowLocale(...R.pathOr([], [action, 'button'], locale)),
          },
        ],
      },
    };

    openModal(modal);
  },
});

const withRemoveItem = withHandlers({
  handleRemove: (props: Object) => () => {
    const {
      guid,
      itemName,
      openModal,
      reportType,
      closeFixedPopup,
      removeTemplateItemsRequest,
    } = props;

    G.callFunction(closeFixedPopup);

    const titleArrMap = {
      [GC.ITEM_REPORT]: ['messages:before:remove', 'Are you sure you want to remove'],
      [GC.CONTACT_BOOK_REPORT]: ['messages:delete-contact', 'Do you want to delete this contact'],
      [GC.LOCATION_TEMPLATE_REPORT]: ['messages:before:remove', 'Are you sure you want to remove'],
      [GC.COMPENSATION_TEMPLATE_REPORT]: ['messages:before:remove', 'Are you sure you want to remove'],
      [GC.ROUTE_TEMPLATE_REPORT]: ['messages:delete-confirmation-text-double', 'Are you sure you want to delete'],
      [GC.CLO_TEMPLATE_REPORT]: ['messages:confirm-delete-entity', 'Are you sure you want to delete this entity?'],
      [GC.CONTAINER_TEMPLATE_REPORT]: ['messages:delete-container', 'Are you sure you want to delete Container(s)?'],
      [GC.SHARED_ACCESSORIAL_REPORT]: [
        'messages:delete-shared-accessorial',
        'Do you want to delete this shared accessorial?',
      ],
    };

    const title = G.getWindowLocale(...G.getPropFromObject(reportType, titleArrMap));

    const textProps = {
      withEllipsis: true,
      whiteSpace: 'nowrap',
      title: G.ifElse(G.isNilOrEmpty(itemName), title, `${title} ${itemName}`),
    };

    let data = R.of(Array, guid);

    if (R.equals(reportType, GC.SHARED_ACCESSORIAL_REPORT)) {
      data = { guid, [GC.BRANCH_GUID]: G.getAmousCurrentUserBranchGuidFromWindow() };
    }

    const component = <ConfirmComponent textProps={textProps} name={itemName} textLocale={title} />;

    const modal = {
      component,
      options: {
        width: 600,
        controlButtons: [
          {
            type: 'button',
            action: () => removeTemplateItemsRequest(data),
            name: G.getWindowLocale('actions:delete', 'Delete'),
          },
        ],
      },
    };

    openModal(modal);
  },
});

const withCreateRoute = compose(
  connect(null, { createRouteFromTemplateRequest }),
  withHandlers({
    handleScheduleRoute: (props: Object) => () => {
      const {
        guid,
        entity,
        openModal,
        closeFixedPopup,
      } = props;

      G.callFunction(closeFixedPopup);

      const component = (
        <ScheduleRoute
          templateGuid={guid}
          templateName={entity.name}
          branchGuid={G.getBranchGuidFromObject(entity)}
          asyncEndpoint={endpointsMap.getRouteTemplateByGuid(guid)}
        />
      );

      const modal = G.getFullWindowModalWithContent(component);

      openModal(modal);
    },
    handleCreateRoute: (props: Object) => () => {
      const {
        guid,
        entity,
        openModal,
        closeModal,
        openLoader,
        closeLoader,
        routeChanged,
        closeFixedPopup,
        createRouteFromTemplateRequest,
      } = props;

      G.callFunction(closeFixedPopup);

      if (routeChanged) return;

      const submitAction = (data: Object) => createRouteFromTemplateRequest({
        ...data,
        [GC.FIELD_TEMPLATE_GUID]: guid,
      });

      const component = (
        <CreateRoutesForm
          templateGuid={guid}
          closeModal={closeModal}
          openLoader={openLoader}
          closeLoader={closeLoader}
          submitAction={submitAction}
          branchGuid={G.getBranchGuidFromObject(entity)}
          asyncEndpoint={endpointsMap.getRouteTemplateByGuid(guid)}
        />
      );

      const modal = {
        p: '0px',
        component,
        options: {
          top: 30,
          width: 'auto',
          minWidth: 825,
          maxWidth: 'calc(100vw - 30px)',
        },
      };

      openModal(modal);
    },
  }),
);

const withShowAudit = compose(
  withHandlers({
    handleShowAudit: (props: Object) => () => {
      const { guid, entity, openModal, reportType, closeFixedPopup } = props;

      let templateName;

      switch (reportType) {
        case GC.CLO_TEMPLATE_REPORT:
          templateName = R.pathOr('', [GC.FIELD_TEMPLATE_NAME], entity);
          break;
        case GC.ROUTE_TEMPLATE_REPORT:
        case GC.COMPENSATION_TEMPLATE_REPORT:
          templateName = R.pathOr('', [GC.FIELD_NAME], entity);
          break;
        case GC.LOCATION_TEMPLATE_REPORT:
        case GC.CONTAINER_TEMPLATE_REPORT:
          templateName = R.pathOr('', [GC.FIELD_TEMPLATE_ID], entity);
          break;
        case GC.CONTACT_BOOK_REPORT:
          templateName = R.pathOr('', [GC.FIELD_EMAIL], entity);
          break;
        case GC.ITEM_REPORT:
          templateName = R.pathOr('', [GC.FIELD_ITEM_ID], entity);
          break;
        default:
          templateName = '';
          break;
      }

      G.callFunction(closeFixedPopup);

      const requestOptions = {
        [GC.FIELD_OBJECT_GUID]: guid,
        [GC.FIELD_TYPE]: GC.AUDIT_TYPE_TEMPLATES,
      };

      const component = <Audit2 requestOptions={requestOptions} />;

      const modal = {
        p: '0',
        component,
        options: {
          boxShadow: 'none',
          withCloseIcon: true,
          withBorderRadius: true,
          backgroundColor: 'transparent',
          title: `${G.getWindowLocale('titles:audit', 'Audit')}: ${templateName}`,
        },
      };

      openModal(modal);
    },
  }),
);

const getOptionsByReportType = (props: Object) => R.compose(
  R.values,
  R.pick(G.getPropFromObject(props.reportType, actionsToPickMap)),
)(options(props));

const RowActionsComponent = (props: Object) => <ActionsElement options={getOptionsByReportType(props)} />;

const withRemoveAndEditFormEnhance = compose(
  withRemoveItem,
  withCreateOrUpdateForm,
  pure,
);

const RemoveAndEditFormRowActions = withRemoveAndEditFormEnhance(RowActionsComponent);

const withGoToEditCloTemplatePage = withHandlers({
  handleGoToEditPage: ({ guid, closeFixedPopup }: Object) => () => {
    G.callFunction(closeFixedPopup);

    G.goToRoute(getOrderTemplateRoute(guid));
  },
});

const withCloTemplateRowActions = compose(
  withShowAudit,
  withRemoveItem,
  withGoToEditCloTemplatePage,
  pure,
);

const CloTemplateRowActions = withCloTemplateRowActions(RowActionsComponent);

const withRouteTemplateRowActions = compose(
  connect(null, { changeTemplateItemStatusRequest }),
  withShowAudit,
  withRemoveItem,
  withCreateRoute,
  withChangeStatus,
  pure,
);

const RouteTemplateRowActions = withRouteTemplateRowActions(RowActionsComponent);

const withEditRemoveShowAuditRowActions = compose(
  withShowAudit,
  withRemoveAndEditFormEnhance,
);

const EditRemoveShowAuditRowActions = withEditRemoveShowAuditRowActions(RowActionsComponent);

const withItemAndLocationRowActions = compose(
  connect(null, { changeTemplateItemStatusRequest }),
  withShowAudit,
  withChangeStatus,
  withRemoveAndEditFormEnhance,
  pure,
);

const ItemAndLocationRowActions = withItemAndLocationRowActions(RowActionsComponent);

const RowActions = (props: Object) => {
  const { reportType } = props;

  const componentMap = {
    [GC.ITEM_REPORT]: <ItemAndLocationRowActions {...props} />,
    [GC.CLO_TEMPLATE_REPORT]: <CloTemplateRowActions {...props} />,
    [GC.ROUTE_TEMPLATE_REPORT]: <RouteTemplateRowActions {...props} />,
    [GC.CONTACT_BOOK_REPORT]: <EditRemoveShowAuditRowActions {...props} />,
    [GC.LOCATION_TEMPLATE_REPORT]: <ItemAndLocationRowActions {...props} />,
    [GC.SHARED_ACCESSORIAL_REPORT]: <RemoveAndEditFormRowActions {...props} />,
    [GC.CONTAINER_TEMPLATE_REPORT]: <EditRemoveShowAuditRowActions {...props} />,
    [GC.COMPENSATION_TEMPLATE_REPORT]: <EditRemoveShowAuditRowActions {...props} />,
  };

  return R.propOr(null, reportType, componentMap);
};

export default RowActions;

export {
  withCreateRoute,
};
