import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { StyledLink } from '../../../ui';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.FIELD_NAME]: {
    width: 200,
    name: 'titles:company-name',
  },
  [GC.FIELD_TYPE]: {
    width: 200,
    type: 'enum',
    name: 'titles:type',
  },
  [GC.FIELD_CARRIER_DBA]: {
    width: 200,
    name: 'titles:dba',
  },
  [GC.FIELD_ACCOUNTING_EXTERNAL_ID]: {
    width: 200,
    name: 'titles:accounting-external-id',
  },
  [GC.FIELD_ACTIVE]: {
    width: 150,
    type: 'boolean',
    name: 'titles:status',
  },
  [`${GC.FIELD_SERVICE_TYPES}.${GC.FIELD_DISPLAYED_VALUE}`]: {
    width: 300,
    name: 'titles:service-type',
  },
  [GC.FIELD_ACCOUNT_NUMBER]: {
    width: 200,
    name: 'titles:account-number',
  },
  [GC.FIELD_CARRIER_URL]: {
    width: 300,
    name: 'titles:url',
  },
  [GC.FIELD_US_DOT_NUMBER]: {
    width: 250,
    name: 'titles:usdot-number',
  },
  [GC.FIELD_MC_NUMBER]: {
    width: 250,
    name: 'titles:mc-number',
  },
  [GC.FIELD_CARRIER_DUNS_NUMBER]: {
    width: 250,
    name: 'titles:duns-number',
  },
  [GC.FIELD_LICENSE_NUMBER]: {
    width: 250,
    name: 'titles:license-number',
  },
  [GC.FIELD_BRANCH_DOT_BRANCH_NAME]: {
    width: 250,
    name: 'titles:branch',
  },
  [GC.GRC.PAY_TO_LOCATION_LOCATION_NAME]: {
    width: 200,
    name: ['titles:pay-to', 'titles:name'],
  },
  [GC.FIELD_CREATED_DATE]: {
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    type: 'date',
    name: 'titles:updated-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 200,
    name: 'titles:updated-by',
  },
  [GC.GRC.PAY_TO_LOCATION_START_DATE]: {
    type: 'date',
    name: ['titles:pay-to', 'titles:start-date'],
  },
  [GC.GRC.PAY_TO_LOCATION_LOCATION_NAME]: {
    width: 200,
    name: ['titles:pay-to', 'titles:name'],
  },
  [GC.GRC.PAY_TO_LOCATION_PAY_TYPE]: {
    width: 200,
    type: 'enum',
    name: ['titles:pay-to', 'titles:pay-type'],
  },
  [GC.GRC.PAY_TO_LOCATION_ADDRESS]: {
    width: 200,
    name: ['titles:pay-to', 'titles:address'],
  },
  [GC.GRC.PAY_TO_LOCATION_ADDRESS2]: {
    width: 200,
    name: ['titles:pay-to', 'titles:address2'],
  },
  [GC.GRC.PAY_TO_LOCATION_COUNTRY]: {
    width: 200,
    name: ['titles:pay-to', 'titles:country'],
  },
  [GC.GRC.PAY_TO_LOCATION_STATE]: {
    width: 200,
    name: ['titles:pay-to', 'titles:state'],
  },
  [GC.GRC.PAY_TO_LOCATION_CITY]: {
    width: 200,
    name: ['titles:pay-to', 'titles:city'],
  },
  [GC.GRC.PAY_TO_LOCATION_ZIP]: {
    width: 200,
    name: ['titles:pay-to', 'titles:zip'],
  },
  [GC.GRC.PRIMARY_LOCATION_LOCATION_NAME]: {
    width: 200,
    name: ['titles:primary-location', 'titles:name'],
  },
  [GC.GRC.PRIMARY_LOCATION_ADDRESS1]: {
    width: 200,
    name: ['titles:primary-location', 'titles:address1'],
  },
  [GC.GRC.PRIMARY_LOCATION_ADDRESS2]: {
    width: 200,
    name: ['titles:primary-location', 'titles:address2'],
  },
  [GC.GRC.PRIMARY_LOCATION_COUNTRY]: {
    width: 200,
    name: ['titles:primary-location', 'titles:country'],
  },
  [GC.GRC.PRIMARY_LOCATION_STATE]: {
    width: 200,
    name: ['titles:primary-location', 'titles:state'],
  },
  [GC.GRC.PRIMARY_LOCATION_CITY]: {
    width: 200,
    name: ['titles:primary-location', 'titles:city'],
  },
  [GC.GRC.PRIMARY_LOCATION_ZIP]: {
    width: 200,
    name: ['titles:primary-location', 'titles:zip'],
  },
  [GC.GRC.PRIMARY_LOCATION_CONTACT_NAME]: {
    width: 200,
    name: ['titles:primary-location', 'titles:contact-name'],
  },
  [GC.GRC.PRIMARY_LOCATION_EMAILS_FIELD]: {
    width: 200,
    name: ['titles:primary-location', 'titles:emails'],
  },
  [GC.GRC.PRIMARY_LOCATION_PHONE]: {
    width: 200,
    name: ['titles:primary-location', 'titles:phone'],
  },
  [GC.GRC.PRIMARY_LOCATION_PHONE_EXTENSION]: {
    width: 200,
    name: ['titles:primary-location', 'titles:phone-extension'],
  },
  [GC.GRC.INTEGRATION_INFO_INTEGRATION_TYPE]: {
    width: 300,
    type: 'collectionOfEnums',
    name: ['titles:integration-info', 'titles:integration-type'],
  },
  [GC.GRC.INTEGRATION_INFO_EXTERNAL_DIVISION_NAME]: {
    width: 300,
    name: ['titles:integration-info', 'titles:division'],
  },
  [GC.GRC.INTEGRATION_INFO_LAST_MODIFIED_DATE]: {
    width: 300,
    type: 'date',
    name: ['titles:integration-info', 'titles:update-on'],
  },
  [GC.GRC.INTEGRATION_INFO_INTEGRATION_ID]: {
    width: 300,
    name: ['titles:integration-info', 'titles:integration-id'],
  },
};

export const primaryLocationColumnSettings = {
  [GC.FIELD_LOCATION_NAME]: {
    width: 200,
    name: 'titles:name',
  },
  [GC.FIELD_ADDRESS_1]: {
    width: 200,
    name: 'titles:address1',
  },
  [GC.FIELD_ADDRESS_2]: {
    name: 'titles:address2',
    width: 200,
  },
  [GC.FIELD_CITY]: {
    name: 'titles:city',
    width: 200,
  },
  [GC.FIELD_STATE]: {
    width: 200,
    name: 'titles:state',
  },
  [GC.FIELD_COUNTRY]: {
    width: 200,
    name: 'titles:country',
  },
  [GC.FIELD_ZIP]: {
    width: 150,
    name: 'titles:zip',
  },
  [GC.FIELD_CONTACT_NAME]: {
    width: 200,
    name: 'titles:contact-name',
  },
  [GC.FIELD_PHONE]: {
    width: 200,
    name: 'titles:phone',
  },
  [GC.FIELD_EMAILS]: {
    width: 200,
    name: 'titles:emails',
  },
};

export const documentColumnSettings = {
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: {
    width: 220,
    name: 'titles:document-type',
  },
  [GC.FIELD_DOCUMENT_STATUS]: {
    width: 150,
    uiType: 'enum',
    name: 'titles:status',
  },
  [GC.FIELD_DOCUMENT_FILE_NAME]: {
    width: 300,
    uiField: true,
    name: 'titles:document-name',
    customLogic: (documentFilename: string, { entity: { url } }: Object) => (
      <TextComponent display='block' maxWidth='90%' withEllipsis={true} title={R.or(documentFilename, url)}>
        {
          G.isNotNilAndNotEmpty(url) &&
          <StyledLink
            target='_blank'
            textDecoration='underline'
            href={G.makeURLString(url)}
            color={G.getTheme('colors.light.blue')}
          >
            {url}
          </StyledLink>
        }
        {G.isNotNilAndNotEmpty(documentFilename) && documentFilename}
      </TextComponent>
    ),
  },
  [GC.FIELD_DOCUMENT_DESCRIPTION]: {
    width: 300,
    name: 'titles:description',
  },
  [GC.FIELD_DOCUMENT_SIGNED_DATE]: {
    width: 150,
    name: 'titles:signed-date',
  },
  [GC.FIELD_DOCUMENT_EXPIRATION_DATE]: {
    width: 150,
    name: 'titles:expired-date',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    name: 'titles:created-date',
    customLogic: G.convertDateTimeToConfigFormat,
  },
  [GC.FIELD_CREATED_BY]: {
    width: 100,
    name: 'titles:created-by',
  },
};
