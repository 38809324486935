import React from 'react';
import * as R from 'ramda';
// components
import { Tabs2, withTabs2 } from '../../../components/tabs-mui';
// features
import Audit2 from '../../audit2';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
// feature service-vendor
import GeneralTab from './general-tab';
/////////////////////////////////////////////

const ActiveTab = (props: Object) => {
  const { activeTab, initialValues } = props;

  const tabsMap = [
    <GeneralTab key={0} {...props} />,
    <Audit2
      key={1}
      useFullScreen={true}
      maxHeight='calc(100vh - 215px)'
      requestOptions={{
        [GC.FIELD_TYPE]: GC.AUDIT_TYPE_SERVICE_VENDOR,
        [GC.FIELD_OBJECT_GUID]: G.getGuidFromObject(initialValues),
      }}
    />,
  ];

  return R.pathOr(null, [activeTab], tabsMap);
};

const tabs = [
  { text: G.getWindowLocale('titles:general', 'General') },
  { text: G.getWindowLocale('titles:audit', 'Audit') },
];

const TabsGroup = (props: Object) => {
  const {
    activeTab,
    handleSetActiveTab,
  } = props;

  return (
    <Box
      mt={15}
      minWidth={325}
      overflow='hidden'
      border='1px solid #E0E0E0'
      borderRadius='4px 4px 0px 0px'
      bg={G.getTheme('colors.white')}
    >
      <Tabs2
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={handleSetActiveTab}
        tabStyles={GC.COMMON_MUI_TAB_STYLES}
        tabsStyles={GC.COMMON_MUI_TABS_STYLES}
      />
      <ActiveTab {...props} />
    </Box>
  );
};

export default withTabs2(TabsGroup);
