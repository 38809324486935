import * as R from 'ramda';
import { put, race, call, select, take, takeLatest } from 'redux-saga/effects';
// common
import { initialDataLoadFail, initialDataLoadSuccess } from '../../common/actions';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { getConfigByGroupSaga } from '../configurations/sagas';
import { receivedSwitchBranchSuccess } from '../branch/actions';
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { getAvailableDocumentTypes } from '../configurations/actions';
import {
  makeSelectPermissionsList,
  makeSelectAccessToPagesList,
  makeSelectInitialDataLoadedStatus } from '../permission/selectors';
  // helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// utilities
import routesMap from '../../utilities/routes';
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature roles
import * as A from './actions';
//////////////////////////////////////////////////

export function* getRolesAvailableListSaga() {
  try {
    yield put(openLoader({ showDimmer: true }));
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_CURRENT_BRANCH_GUID]: currentBranchGuid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.listRolesAvailable, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.availableRoleListSuccess(data));
      yield put(closeLoader());
      return true;
    }
    yield put(A.getRoleAvailableListFail());
    yield put(closeLoader());
    return false;
  } catch (error) {
    yield put(closeLoader());
    yield put(A.getRoleAvailableListFail());
    yield call(G.handleException, error, 'getRolesAvailableListSaga exception');
    return false;
  }
}

export function* getRolesListSaga() {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        [GC.FIELD_CURRENT_BRANCH_GUID]: currentBranchGuid,
      },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.listRoles, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getRoleListSuccess(data));
      yield call(getRolesAvailableListSaga);
      return true;
    }
    yield put(A.getRoleListFail());
    return false;
  } catch (error) {
    yield put(A.getRoleListFail());
    yield call(G.handleException, error, 'getRolesListSaga exception');
    return false;
  }
}

export function* getPermissionsGroupsListSaga() {
  try {
    const res = yield call(sendRequest, 'get', endpointsMap.permissionGroupList);
    const { data, status } = res;
    const permissions = yield select(makeSelectPermissionsList());
    if (G.isResponseSuccess(status)) {
      yield put(A.getPermissionsGroupsListSuccess({
        data,
        mapped: permissions,
      }));
    } else {
      yield put(A.getPermissionsGroupsListFail());
    }
  } catch (error) {
    yield put(A.getPermissionsGroupsListFail());
    yield call(G.handleException, error, 'getPermissionsGroupsListSaga exception');
  }
}

function* handleAddNewRoleRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: R.compose(
        R.dissoc('setSubmitting'),
        R.assoc(GC.FIELD_BRANCH_GUID, branchGuid),
      )(payload),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.role, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.resetUserPermissions());
      yield put(A.addNewRoleSuccess(data));
      yield call(G.goToRoute, routesMap.rolesPage);
    } else {
      payload.setSubmitting(false);
      yield put(A.addNewRoleFail());
      yield call(G.handleFailResponse, res, 'handleAddNewRoleRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    payload.setSubmitting(false);
    yield put(A.addNewRoleFail());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAddNewRoleRequestSaga exception');
  }
}

function* handleUpdateRoleRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'put', endpointsMap.role, { data: R.dissoc('setSubmitting', payload) });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.updateRoleSuccess(data));
      yield put(A.resetUserPermissions());
      yield call(G.goToRoute, routesMap.rolesPage);
      yield call(G.showToastrMessage, 'info', 'messages:login:applied');
    } else {
      payload.setSubmitting(false);
      yield put(A.updateRoleFail());
      yield call(G.handleFailResponse, res, 'handleUpdateRoleRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    payload.setSubmitting(false);
    yield put(A.updateRoleFail());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateRoleRequestSaga exception');
  }
}

function* handleRemoveRoleRequestSaga(action: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'delete', endpointsMap.role, { data: action.payload });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.removeRoleSuccess(action.payload));
    } else {
      yield put(A.removeRoleFail());
      yield call(G.showToastrMessage, 'error', data.message);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield put(A.removeRoleFail());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleRemoveRoleRequestSaga exception');
  }
}

function* handleGrandRolesToChildSaga(action: Object) {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const reqData = R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, action.payload);

    const res = yield call(sendRequest, 'post', endpointsMap.grantRolesToChildren, { data: reqData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGrandRolesToChildSaga exception');
  }
}

function* handleUngrandRolesFromChildSaga(action: Object) {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const reqData = R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, action.payload);

    const res = yield call(sendRequest, 'post', endpointsMap.revokeRolesFromChildren, { data: reqData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUngrandRolesFromChildSaga exception');
  }
}

function* handleGetUserPermissionsByRoleTypeRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { roleType, editRoleData } = payload;
    const options = {
      params: { roleType },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.roleTypePermissions, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(editRoleData)) {
        yield put(A.getRolePermissionsGroupsListSuccess(R.mergeRight(data, editRoleData)));
      } else {
        yield put(A.getUserPermissionsByRoleTypeSuccess(data));
      }
    } else {
      yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetUserPermissionsByRoleTypeRequest exception');
  }
}

export function* getRolePermissionsGroupsListSaga({ payload }: Object) {
  try {
    yield call(getPermissionsGroupsListSaga);
    yield put(openLoader({ showDimmer: true }));
    const roleGuid = R.prop(GC.FIELD_GUID, payload);
    const url = yield call(endpointsMap.getRoleEndpoint, roleGuid);
    const res = yield call(sendRequest, 'get', url);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getUserPermissionsByRoleTypeRequest({ roleType: data.type, editRoleData: data }))
    } else {
      yield put(A.getRoleListFail());
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield put(A.getRolePermissionsGroupsListFail());
    yield call(G.handleException, error, 'getRolePermissionsGroupsListSaga exception');
  }
}

function* handleVisitRolesListPageSaga(action: Object) {
  while (true) { // eslint-disable-line
    const route = action.payload;
    const accessToPagesList = yield select(makeSelectAccessToPagesList());
    const isInitialDataLoaded = yield select(makeSelectInitialDataLoadedStatus());
    const isAllowed = R.includes(route, accessToPagesList);
    if (R.not(isInitialDataLoaded)) {
      yield race({
        fail: take(initialDataLoadFail),
        success: take(initialDataLoadSuccess),
      });
    }
    if (R.not(isAllowed)) {
      yield put({ type: GC.CHECK_VISIT_ROLES_LIST_PAGE, payload: route });
      yield take(GC.CHECK_VISIT_ROLES_LIST_PAGE_ALLOWED);
    }
    yield put(A.getRoleListRequest());
    break;
  }
}

function* handleVisitRoleEditSaga(action: Object) {
  while (true) { // eslint-disable-line
    const { route } = action.payload;
    const accessToPagesList = yield select(makeSelectAccessToPagesList());
    const isInitialDataLoaded = yield select(makeSelectInitialDataLoadedStatus());
    const isAllowed = R.includes(route, accessToPagesList);
    if (R.not(isInitialDataLoaded)) {
      yield race({
        fail: take(initialDataLoadFail),
        success: take(initialDataLoadSuccess),
      });
    }
    if (R.not(isAllowed)) {
      yield put({ type: GC.CHECK_VISIT_ROLE_EDIT_PAGE, payload: route });
      yield take(GC.CHECK_VISIT_ROLE_EDIT_PAGE_ALLOWED);
    }
    yield call(getConfigByGroupSaga, GC.COMMUNICATION_CONFIG_GROUP);
    yield put(getAvailableDocumentTypes());
    yield call(getRolePermissionsGroupsListSaga, action);
    break;
  }
}

function* handleVisitRoleNewSaga(action: Object) {
  while (true) { // eslint-disable-line
    const route = action.payload;
    const accessToPagesList = yield select(makeSelectAccessToPagesList());
    const isInitialDataLoaded = yield select(makeSelectInitialDataLoadedStatus());
    const isAllowed = R.includes(route, accessToPagesList);
    if (R.not(isInitialDataLoaded)) {
      yield race({
        fail: take(initialDataLoadFail),
        success: take(initialDataLoadSuccess),
      });
    }
    if (R.not(isAllowed)) {
      yield put({ type: GC.CHECK_VISIT_ROLE_CREATE_PAGE, payload: route });
      yield take(GC.CHECK_VISIT_ROLE_CREATE_PAGE_ALLOWED);
    }
    yield call(getPermissionsGroupsListSaga);
    yield put(getAvailableDocumentTypes());
    break;
  }
}

export function* rolesWatcherSaga() {
  yield takeLatest(A.getRoleListRequest, getRolesListSaga);
  yield takeLatest(receivedSwitchBranchSuccess, getRolesListSaga);
  yield takeLatest(A.addNewRoleRequest, handleAddNewRoleRequestSaga);
  yield takeLatest(A.updateRoleRequest, handleUpdateRoleRequestSaga);
  yield takeLatest(GC.VISIT_ROLE_EDIT_PAGE, handleVisitRoleEditSaga);
  yield takeLatest(A.removeRoleRequest, handleRemoveRoleRequestSaga);
  yield takeLatest(GC.VISIT_ROLE_CREATE_PAGE, handleVisitRoleNewSaga);
  yield takeLatest(A.grandRoleToChildRequest, handleGrandRolesToChildSaga);
  yield takeLatest(GC.VISIT_ROLES_LIST_PAGE, handleVisitRolesListPageSaga);
  yield takeLatest(A.ungrandRoleFromChildRequest, handleUngrandRolesFromChildSaga);
  yield takeLatest(A.getPermissionsGroupsListRequest, getPermissionsGroupsListSaga);
  yield takeLatest(A.getRolePermissionsGroupsListRequest, getRolePermissionsGroupsListSaga);
  yield takeLatest(A.getUserPermissionsByRoleTypeRequest, handleGetUserPermissionsByRoleTypeRequest);
}

export default rolesWatcherSaga;
