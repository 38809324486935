import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withState, withHandlers, renderNothing } from 'react-recompose';
// components
import { Switcher } from '../../components/switcher';
import { PageTitle } from '../../components/page-title';
// features
import { makeSelectCurrentBranch } from '../branch/selectors';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// ui
import { PageWrapper, PageHeaderWrapper } from '../../ui';
// utilities
import * as G from '../../helpers';
// feature styling
import StylingSectionComponent from './components/styling';
import SplashScreenComponent from './components/splash-screen';
import { updateStylingRequest, setStylingRequest, getStylingRequest } from './actions';
//////////////////////////////////////////////////

export const enhance = compose(
  withState('currentTab', 'setCurrentTab', 'styling'),
  withHandlers({
    handleSetCurrentTab: ({ setCurrentTab }: Object) => (tab: string) => setCurrentTab(tab),
  }),
  branch(
    (props: Object) => R.not(props.initialDataLoaded),
    renderNothing,
  ),
  pure,
);

export const multiswitchOptions = () => ([
  {
    width: 80,
    value: 'styling',
    name: G.getWindowLocale('titles:styling', 'Styling'),
  },
  {
    width: 100,
    value: 'splashScreen',
    name: G.getWindowLocale('titles:splash-screen-styling-settings', 'Splash Screen'),
  },
]);

export const StylingComponent = ({ history, currentTab, currentBranch, handleSetCurrentTab }: Object) => (
  <PageWrapper bgColor={G.getTheme('forms.bgColor')}>
    <PageHeaderWrapper aI='center'>
      <PageTitle title={G.getWindowLocale('titles:styling', 'Styling')} />
      {
        G.isNotNil(currentBranch.dnsPrefix) &&
        <Switcher
          ml={10}
          version={2}
          selectedOptionIndex={0}
          onSwitch={handleSetCurrentTab}
          options={multiswitchOptions()}
        />
      }
    </PageHeaderWrapper>
    {
      G.ifElse(
        R.equals(currentTab, 'styling'),
        <StylingSectionComponent history={history} />,
        <SplashScreenComponent history={history} dnsPrefix={currentBranch.dnsPrefix} />,
      )
    }
  </PageWrapper>
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  currentBranch: makeSelectCurrentBranch(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
}));

export default connect(mapStateToProps, {
  setStylingRequest,
  getStylingRequest,
  updateStylingRequest,
})(enhance(StylingComponent));
