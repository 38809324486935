import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withState, lifecycle, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../../components/form-footer';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// forms
import { Fieldset } from '../../../new-do/forms/formik/fieldset';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature template-report
import { defaultFieldStyles } from '../settings';
//////////////////////////////////////////////////

const defaultStyles = {
  ...defaultFieldStyles,
  width: 270,
  inputWrapperStyles: {
    mb: 25,
  },
};

export const defaultContactFields = {
  [GC.FIELD_CONTACT_BOOK_EMAIL]: null,
  [GC.FIELD_CONTACT_BOOK_PHONE]: null,
  [GC.FIELD_CONTACT_BOOK_COMPANY]: null,
  [GC.FIELD_CONTACT_BOOK_COMMENTS]: null,
  [GC.FIELD_CONTACT_BOOK_POSITION]: null,
  [GC.FIELD_CONTACT_BOOK_LAST_NAME]: null,
  [GC.FIELD_CONTACT_BOOK_FIRST_NAME]: null,
};

export const validationSchema = Yup.object().shape({
  [GC.FIELD_CONTACT_BOOK_COMPANY]: Yup.string().nullable(true),
  [GC.FIELD_CONTACT_BOOK_COMMENTS]: Yup.string().nullable(true),
  [GC.FIELD_CONTACT_BOOK_POSITION]: Yup.string().nullable(true),
  [GC.FIELD_CONTACT_BOOK_LAST_NAME]: Yup.string().nullable(true),
  [GC.FIELD_CONTACT_BOOK_FIRST_NAME]: Yup.string().nullable(true),
  [GC.FIELD_CONTACT_BOOK_PHONE]: Yup.string()
    .nullable(true)
    .matches(GC.PHONE_NUMBER_REGEXP, G.getFieldIsNotValidLocaleTxt()),
  [GC.FIELD_CONTACT_BOOK_EMAIL]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .email(G.getShouldBeEmailLocaleTxt()),
});

const fieldSettings = [
  {
    ...defaultStyles,
    type: 'text',
    fieldName: GC.FIELD_CONTACT_BOOK_FIRST_NAME,
    label: G.getWindowLocale('titles:first-name', 'First Name'),
  },
  {
    ...defaultStyles,
    type: 'text',
    fieldName: GC.FIELD_CONTACT_BOOK_LAST_NAME,
    label: G.getWindowLocale('titles:last-name', 'Last Name'),
  },
  {
    ...defaultStyles,
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_CONTACT_BOOK_EMAIL,
    label: G.getWindowLocale('titles:email', 'Email'),
  },
  {
    ...defaultStyles,
    type: 'phoneNumber',
    fieldName: GC.FIELD_CONTACT_BOOK_PHONE,
    label: G.getWindowLocale('titles:phone-number', 'Phone Number'),
  },
  {
    ...defaultStyles,
    type: 'text',
    fieldName: GC.FIELD_CONTACT_BOOK_COMPANY,
    label: G.getWindowLocale('titles:company', 'Company'),
  },
  {
    ...defaultStyles,
    type: 'text',
    fieldName: GC.FIELD_CONTACT_BOOK_POSITION,
    label: G.getWindowLocale('titles:position', 'Position'),
  },
  {
    ...defaultStyles,
    width: '570px',
    type: 'textarea',
    inputWrapperStyles: { mb: 0 },
    fieldName: GC.FIELD_CONTACT_BOOK_COMMENTS,
    label: G.getWindowLocale('titles:comments', 'Comments'),
  },
];

const fieldsWrapperStyles = {
  px: 0,
  mt: 15,
  mb: 20,
  width: 570,
  justifyContent: 'space-between',
};

const enhance = compose(
  withState('initialValues', 'setInitialValues', null),
  withHandlers({
    handleGetContact: ({ guid, setInitialValues }: Object) => async () => {
      const endpoint = endpointsMap.getContact(guid);
      const options = { params: { guid } };

      const res = await sendRequest('get', endpoint, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setInitialValues(data);
      } else {
        G.handleException('error', 'withAsyncGetContact exception');
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { guid, handleGetContact } = this.props;

      if (G.isNotNilAndNotEmpty(guid)) handleGetContact();
    },
  }),
  withFormik({
    validationSchema,
    enableReinitialize: true,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultContactFields,
      initialValues,
    ),
  }),
  pure,
);

const ContactForm = (props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Fieldset
      {...G.getFormikProps(props)}
      fields={fieldSettings}
      fieldsWrapperStyles={fieldsWrapperStyles}
    />
    <FormFooter2 closeModal={props.closeModal} />
  </form>
);

export default enhance(ContactForm);
