import { createAction } from 'redux-act';
// report-common
import { getReportActions } from '../../report-common';
//////////////////////////////////////////////////

const {
  selectItem,
  setListLoading,
  setInitialState,
  getItemListRequest,
  getItemListSuccess,
  resetListAndPagination,
} = getReportActions();

const toggleDetails = createAction('toggleDetails');
const deleteItemsRequest = createAction('deleteItemsRequest');
const deleteItemsSuccess = createAction('deleteItemsSuccess');

export {
  // report
  selectItem,
  setListLoading,
  setInitialState,
  getItemListRequest,
  getItemListSuccess,
  resetListAndPagination,
  // report
  toggleDetails,
  deleteItemsRequest,
  deleteItemsSuccess,
};
