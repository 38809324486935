import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// forms
import { Error } from '../../../../forms/ui';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex, Text, IconWrapper } from '../../../../ui';
// feature template-inspection
import {
  setActiveSection,
  updateSectionFields,
  deleteSectionFromTemplate,
  reorderSectionsInTemplate,
} from '../actions';
import * as H from '../helpers';
import * as C from '../constants';
import VehicleSection from './vehicle-section';
import DeleteComponent from './delete-component';
import SectionComponents from './section-components';
import { SectionUI, MoveHandler, SwitchableFlex } from '../ui';
import { ToggleButton, NameWithEdit } from './option-components';
import { componentsSettings } from '../settings/component-configs/components-settings';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.dark.blue');

const SectionHeader = ({
  index,
  section,
  viewOnly,
  isInactive,
  dragDisabled,
  dragHandleProps,
}: Object) => {
  const dispatch = useDispatch();

  const {
    name,
    type,
    expanded,
    components,
    nameStatus,
    errorMessage,
  } = section;

  const { icon } = R.prop(type, componentsSettings);
  const nameErrorMessage = G.getWindowLocale('titles:inspection-name-section-error', 'Enter section name');

  const setSectionTitle = useCallback((value: string) => {
    dispatch(updateSectionFields({
      ...section,
      name: value,
      nameStatus: G.ifElse(
        G.isNilOrEmpty(value),
        C.INSPECTION_NAME_STATUS_EMPTY,
        C.INSPECTION_NAME_STATUS_FILLED,
      ),
    }));
  }, [section]);

  const handleNameEdit = useCallback(() => {
    if (viewOnly) return;

    dispatch(updateSectionFields({
      ...section,
      nameStatus: C.INSPECTION_NAME_STATUS_EDIT,
    }));
  }, [section, viewOnly]);

  const handleSectionToggle = useCallback(() => {
    dispatch(updateSectionFields({
      ...section,
      expanded: R.not(expanded),
    }));
  }, [section, expanded]);

  const handleMakeSectionActive = useCallback(() => {
    if (viewOnly) return;

    if (isInactive) {
      dispatch(setActiveSection(index));
    }
  }, [index, viewOnly, isInactive]);

  const handleDeleteSection = useCallback(() => dispatch(deleteSectionFromTemplate(section)), [section]);

  const cursor = G.ifElse(isInactive, 'pointer', 'help');

  const iconTitle = G.ifElse(
    isInactive,
    G.getWindowLocale('titles:inspection-section-activate', 'Make section active'),
    R.or(name, G.getWindowLocale('titles:inspection-section-info', 'Section')),
  );

  const removeSectionText = G.getWindowLocale(
    'messages:remove-inspection-section-text',
    'Are you sure you want delete this section?',
  );

  const sectionWithVehicleComponents = H.isVehicleSection(type);

  return (
    <Flex gap={4} width='100%'>
      <MoveHandler disabled={dragDisabled} {...dragHandleProps}>
        {I.moveIcon(iconColor, 16, 15)}
      </MoveHandler>
      { R.or(sectionWithVehicleComponents, G.isNotEmpty(components)) && (
        <ToggleButton expanded={expanded} handleClick={handleSectionToggle} />
      )}
      { errorMessage && <Error mt={2} mr={8} fontSize={16} cursor='help' title={errorMessage}>*</Error> }
      <SwitchableFlex width='100%' disabled={viewOnly} justifyContent='space-between'>
        <Flex gap={8}>
          <IconWrapper
            cursor={cursor}
            title={iconTitle}
            onClick={handleMakeSectionActive}
          >
            {icon}
          </IconWrapper>
          { sectionWithVehicleComponents && (
            <Text>{name}</Text>
          )}
          { R.not(sectionWithVehicleComponents) && (
            <NameWithEdit
              name={name}
              focus={true}
              status={nameStatus}
              maxLength={C.MAX_NAME_LENGTH}
              setInputValue={setSectionTitle}
              errorMessage={nameErrorMessage}
              handleNameEdit={handleNameEdit}
            />
          )}
        </Flex>
        <DeleteComponent
          disabled={viewOnly}
          confirmAction={handleDeleteSection}
          confirmationText={removeSectionText}
          title={G.getWindowLocale('titles:inspection-delete-section', 'Delete Section')}
        />
      </SwitchableFlex>
    </Flex>
  );
};

const Section = ({
  index,
  section,
  viewOnly,
  dragDisabled,
  activeSection,
  dragHandleProps,
}: Object) => {
  const isInactive = R.or(viewOnly, G.notEquals(index, activeSection));
  const expanded = R.prop('expanded', section);
  const components = R.prop('components', section);
  const vehicleSection = H.isVehicleSection(R.prop(GC.FIELD_TYPE, section));

  return (
    <SectionUI inactive={isInactive}>
      <SectionHeader
        index={index}
        section={section}
        viewOnly={viewOnly}
        isInactive={isInactive}
        dragDisabled={dragDisabled}
        dragHandleProps={dragHandleProps}
      />
      {
        G.isAllTrue(
          expanded,
          R.not(vehicleSection),
          G.isNotEmpty(components),
        ) && <SectionComponents section={section} isInactive={isInactive} />
      }
      {
        G.isAllTrue(
          expanded,
          vehicleSection,
        ) && <VehicleSection section={section} isInactive={isInactive} />
      }
    </SectionUI>
  );
};

const BuilderSandbox = ({ viewOnly, currentTemplate, activeSection }: Object) => {
  const dispatch = useDispatch();

  const sections = R.prop('sections', currentTemplate);
  const dragDisabled = R.or(viewOnly, R.equals(R.length(sections), 1));

  const onDragEnd = useCallback(({ source, destination }: Object) => {
    dispatch(reorderSectionsInTemplate({ source, destination }));
  }, []);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable' direction='vertical'>
        {(provided: Object) => (
          <Flex
            p={15}
            gap='10px'
            height='100%'
            overflow='auto'
            flexDirection='column'
            ref={provided.innerRef}
            width='calc(100% - 300px)'
            {...provided.droppableProps}
            backgroundColor={G.getTheme('colors.dark.mainLight')}
          >
            { G.isNotEmpty(sections) &&
              G.mapIndexed((section: Object, index: number) => {
                const id = R.prop('id', section);

                return (
                  <Box width='100%'>
                    <Draggable key={id} width='100%' draggableId={id} index={index}>
                      {(provided: Object) => (
                        H.renderDraggable(provided.draggableProps, (
                          <div
                            width='100%'
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <Section
                              index={index}
                              section={section}
                              viewOnly={viewOnly}
                              key={R.prop('id', section)}
                              dragDisabled={dragDisabled}
                              activeSection={activeSection}
                              dragHandleProps={provided.dragHandleProps}
                            />
                          </div>
                        ))
                      )}
                    </Draggable>
                  </Box>
                );
              }, sections)
            }
            { provided.placeholder }
            { R.isEmpty(sections) && (
              <Text fontSize={16} fontWeight='bold'>
                { G.getWindowLocale('titles:inspection-no-section', 'Please, add any section to template') }
              </Text>
            )}
          </Flex>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default BuilderSandbox;
