import * as R from 'ramda';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withProps, lifecycle, withHandlers } from 'react-recompose';
// forms
import { defaultValues, getValidationSchemaObject } from '../../../../forms/forms/compensation/settings';
import { getRequestData, CompensationForm, handleSetAddressPointValues } from '../../../../forms/forms/compensation';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// hocs
import { withAsyncInitialDataOnDidMount } from '../../../../hocs/with-async-initial-data';
// feature template-report
import { getBranchConfigsRequest } from '../actions';
import { makeSelectBranchConfigs } from '../selectors';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  branchConfigs: makeSelectBranchConfigs(state),
});

const enhanceTemplate = compose(
  connect(mapStateToProps, { getBranchConfigsRequest }),
  withProps(({ branchConfigs }: Object) => ({
    optionsForSelect: {
      [GC.FIELD_MODE]: R.path(['dropdownOptions', GC.GENERAL_MODE_TRANSPORTATION], branchConfigs),
      [GC.FIELD_SERVICE_TYPE]: R.path(['dropdownOptions', GC.GENERAL_TRANSPORTATION_SERVICE_TYPE], branchConfigs),
    },
  })),
  branch(
    ({ isEditMode }: Object) => G.isTrue(isEditMode),
    withAsyncInitialDataOnDidMount,
  ),
  withFormik({
    enableReinitialize: true,
    validationSchema: getValidationSchemaObject,
    handleSubmit: (values: Object, { props: { submitAction } }: Object) => submitAction(getRequestData(values)),
    mapPropsToValues: ({ isEditMode, branchConfigs, asyncInitialData }: Object) => {
      const { data } = R.or(asyncInitialData, {});

      const initialValues = {
        ...data,
        [GC.FIELD_MODE]: R.pathOr('', [GC.FIELD_MODE, GC.FIELD_DROPDOWN_OPTION_GUID], data),
        [GC.FIELD_SERVICE_TYPE]: R.pathOr('', [GC.FIELD_SERVICE_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], data),
        [GC.FIELD_CURRENCY]: R.pathOr(
          G.ifElse(isEditMode, '', G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, branchConfigs)),
          [GC.FIELD_CURRENCY],
          data,
        ),
      };

      return G.setInitialFormikValues(R.assoc(GC.FIELD_TEMPLATE_TYPE, '', defaultValues), initialValues);
    },
  }),
  withHandlers({ handleSetAddressPointValues }),
  lifecycle({
    componentDidMount() {
      const { branchConfigs, getBranchConfigsRequest } = this.props;

      if (G.isNilOrEmpty(branchConfigs)) {
        getBranchConfigsRequest([
          GC.GENERAL_MODE_TRANSPORTATION,
          GC.GENERAL_BRANCH_DEFAULT_CURRENCY,
          GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
        ]);
      }
    },
  }),
  pure,
);

export const CompensationTemplateForm = enhanceTemplate(CompensationForm);
