import * as R from 'ramda';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import React, { useReducer, useCallback } from 'react';
// components
import { SelectMui, FormFooter2, QuickFilterOutsideControl3 } from '../../../../components';
// forms
import { Fieldset2, PureStyledForm } from '../../../../forms';
import { Checkbox } from '../../../../forms/formik/fieldset2/ui';
// features
import { makeSelectExpandedContainerOpened } from '../../../expanded-container/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import { daySelectOptions } from '../../../../helpers/options';
// hooks
import { useModalActions } from '../../../../hooks';
// ui
import { Box, Flex, scrollableContainerCss3px } from '../../../../ui';
//////////////////////////////////////////////////

// TODO: for all file, check with API and approve UI

const lightGreyColor = G.getTheme('colors.light.lightGrey');
const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

const inputWrapperStyles = {
  mb: 30,
  width: 170,
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_START_DATE]: G.yupStringRequired,
});

const defaultValues = {
  [GC.FIELD_ACTIVE]: true,
  [GC.FIELD_END_DATE]: null,
  [GC.FIELD_START_DATE]: null,
  //
  'repeat': 1,
  'weekNd': [],
  'weeklyDays': [],
  'frequency': 'weekly',
};

const fields = [
  {
    type: 'toggle',
    inputWrapperStyles,
    label: ['titles:active'],
    fieldName: GC.FIELD_ACTIVE,
  },
  {
    type: 'radio',
    inputWrapperStyles,
    fieldName: 'frequency',
    label: ['titles:frequency', 'Frequency'],
    radioGroupWrapperStyles: {
      height: 80,
      width: 170,
      alignItems: 'start',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    options: [
      {
        value: 'daily',
        label: G.getWindowLocale('titles:daily', 'Daily'),
      },
      {
        value: 'weekly',
        label: G.getWindowLocale('titles:weekly', 'Weekly'),
      },
      {
        value: 'monthly',
        label: G.getWindowLocale('titles:monthly', 'Monthly'),
      },
    ],
  },
  {
    isRequired: true,
    type: 'datePicker',
    inputWrapperStyles,
    label: ['titles:start-date'],
    fieldName: GC.FIELD_START_DATE,
  },
  {
    type: 'datePicker',
    inputWrapperStyles,
    label: ['titles:end-date'],
    fieldName: GC.FIELD_END_DATE,
  },
];

const frequencyRepeatMap = {
  'daily': G.getWindowLocale('titles:day', 'Day'),
  'weekly': G.getWindowLocale('titles:week', 'Week'),
  'monthly': G.getWindowLocale('titles:month', 'Month'),
};

const DaysSelector = (props: Object) => {
  const { formik } = props;

  const { values: { weeklyDays }, setFieldValue } = formik;

  return (
    <Flex
      alignItems='start'
      flexDirection='column'
    >
      {
        daySelectOptions.map(({ label, value } : Object, index: number) => (
          <Flex key={`${index}-${value}`} mt='5px'>
            <Checkbox
              type='checkbox'
              name={value}
              checked={R.includes(value, weeklyDays)}
              onChange={
                (e: Object) => {
                  const name = G.getEventTargetName(e);
                  const checked = G.getEventTargetChecked(e);

                  if (G.isFalse(checked)) return setFieldValue('weeklyDays', R.without([name], weeklyDays));

                  setFieldValue('weeklyDays', R.append(name, weeklyDays));
                }
              }
            />
            <Box ml={10} color={greyMatterhornColor}>{label}</Box>
          </Flex>
        ))
      }
    </Flex>
  );
};

const WeekNdSelector = (props: Object) => {
  const { formik } = props;

  const { values: { weekNd }, setFieldValue } = formik;

  const options = [
    { value: 1, label: '1nd' },
    { value: 2, label: '2nd' },
    { value: 3, label: '3nd' },
    { value: 4, label: '4nd' },
    { value: 5, label: '5nd' },
  ];

  return (
    <SelectMui
      options={options}
      initialValue={weekNd}
      wrapperStyles={{ mb: 10, width: 165 }}
      label={G.getWindowLocale('titles:week-nd', 'Week :nd')}
      handleChangeCallback={(value: Array) => setFieldValue('weekNd', value)}
    />
  );
};

const FrequencySelector = (props: Object) => {
  const { formik } = props;

  const { values: { frequency } } = formik;

  if (R.equals(frequency, 'weekly')) {
    return <DaysSelector formik={formik} />;
  }

  if (R.equals(frequency, 'monthly')) {
    return (
      <Box>
        <WeekNdSelector formik={formik} />
        <DaysSelector formik={formik} />
      </Box>
    );
  }

  return null;
};

const RuleFrom = (props: Object) => {
  const { addItem, closeModal, updateItem, initialValues } = props;

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: G.setInitialFormikValues(defaultValues, initialValues),
    onSubmit: (values: Object) => {
      closeModal();

      if (G.isNotNilAndNotEmpty(initialValues)) return updateItem(values);

      addItem(values);
    },
  });

  const { values: { repeat, frequency }, setFieldValue } = formik;

  return (
    <PureStyledForm p={15} minWidth={400} onSubmit={formik.handleSubmit}>
      <Flex>
        <Fieldset2
          {...formik}
          fields={fields}
          fieldsWrapperStyles={{ width: 170 }}
        />
        <Flex
          ml={15}
          pl={15}
          height={250}
          alignItems='start'
          flexDirection='column'
          borderLeft={`1px solid ${lightGreyColor}`}
        >
          <Flex mb={15}>
            <QuickFilterOutsideControl3
              type='number'
              outsideValue={repeat}
              allowSingleCharacter={true}
              inputStyles={{ width: 100 }}
              label={G.getWindowLocale('titles:repeat-every', 'Repeat Every')}
              handleSetFilter={(value: string) => setFieldValue('repeat', value)}
            />
            <Box ml={15} color={greyMatterhornColor}>{frequencyRepeatMap[frequency]}</Box>
          </Flex>
          <FrequencySelector formik={formik} />
        </Flex>
      </Flex>
      <FormFooter2 />
    </PureStyledForm>
  );
};

//
const initialState = [];

const reducer = (state: Array, action: Object) => {
  const { type, payload } = action;

  const payloadIdOrGuid = G.getIdOrGuidFromObject(payload);

  switch (type) {
    case 'ADD_ITEM':
      return [...state, payload];
    case 'UPDATE_ITEM':
      return state.map((item: Object) =>
        G.getIdOrGuidFromObject(item) === payloadIdOrGuid ? payload : item,
      );
    case 'REMOVE_ITEM':
      return state.filter((item: Object) => G.getIdOrGuidFromObject(item) !== payloadIdOrGuid);
    default:
      return state;
  }
};
//

const useScheduleRule = () => {
  const dispatch = useDispatch();

  const modalActions = useModalActions();

  const [items, itemsDispatch] = useReducer(reducer, initialState);

  const handleCreateRule = useCallback(() => {
    const addItem = (item: Object) => {
      const newItem = {...item, id: Date.now() };

      itemsDispatch({ type: 'ADD_ITEM', payload: newItem });
    };

    const component = (
      <RuleFrom
        {...modalActions}
        addItem={addItem}
        dispatch={dispatch}
      />
    );

    const modal = {
      p: '0px',
      component,
      options: {
        movable: false,
      },
    };

    modalActions.openModal(modal);
  }, [dispatch]);

  const handleUpdateRule = useCallback((initialValues: Object) => {
    const updateItem = (item: Object) => {
      itemsDispatch({ type: 'UPDATE_ITEM', payload: item });
    };

    const component = (
      <RuleFrom
        {...modalActions}
        dispatch={dispatch}
        updateItem={updateItem}
        initialValues={initialValues}
      />
    );

    const modal = {
      p: '0px',
      component,
      options: {
        movable: false,
      },
    };

    modalActions.openModal(modal);
  }, [dispatch]);


  const removeItem = (item: Object) => {
    itemsDispatch({ type: 'REMOVE_ITEM', payload: item });
  };

  return {
    items,
    removeItem,
    addItem: handleCreateRule,
    updateItem: handleUpdateRule,
  };
};

export default useScheduleRule;
