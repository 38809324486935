import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// common
import { updateReportFromReportListSuccess } from '../../common/actions';
// report-common
import { getReportReducers } from '../../report-common';
// feature user
import * as A from './actions';
//////////////////////////////////////////////////

export const initial = {
  passwordConfigs: null,
  availableByTypeRoleList: null,
};

const {
  setReports,
  selectItem,
  initialState,
  setUsedReport,
  setListLoading,
  setInitialState,
  setReportPending,
  cleanQuickFilter,
  deleteItemSuccess,
  setTableTitleSort,
  getItemListSuccess,
  setTableTitleFilter,
  setQuickFilterParams,
  resetListAndPagination,
  setInitialStateOmitReport,
  handleUpdateReportFromReportListSuccess,
} = getReportReducers(initial);

const getPasswordConfigsForUsersSuccess = (state: Object, configs: Object) => (
  P.$set('passwordConfigs', configs, state)
);

const getAvailableByTypeRoleListSuccess = (state: Object, data: Object) => (
  P.$set('availableByTypeRoleList', data, state)
);

const clearUsersStateAfterSwitchBranch = () => initialState;

const setFilterProps = (state: Object, data: Array) => P.$set('filterProps', data, state);


export default createReducer({
  // report common
  [A.setReports]: setReports,
  [A.selectItem]: selectItem,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setReportPending]: setReportPending,
  [A.deleteItemSuccess]: deleteItemSuccess,
  [A.setTableTitleSort]: setTableTitleSort,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleFilter]: setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.setInitialStateOmitReport]: setInitialStateOmitReport,
  [updateReportFromReportListSuccess]: handleUpdateReportFromReportListSuccess,
  // report common
  [A.setFilterProps]: setFilterProps,
  [A.clearUsersStateAfterSwitchBranch]: clearUsersStateAfterSwitchBranch,
  [A.getPasswordConfigsForUsersSuccess]: getPasswordConfigsForUsersSuccess,
  [A.getAvailableByTypeRoleListSuccess]: getAvailableByTypeRoleListSuccess,
}, initialState);
