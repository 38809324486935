import * as R from 'ramda';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// common
import { openModal, closeModal } from '../../../../components/modal/actions';
// components
import { Table } from '../../../../components/table';
import { ConfirmComponent } from '../../../../components/confirm';
import { transformPropDataFromSelectToString } from '../../../../components/edit-report/helpers';
// features
import PC from '../../../permission/role-permission';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature template-inspection
import * as A from '../actions';
import * as C from '../constants';
import {
  templatesTableSettings,
  inspectionTemplatesColumnSettings,
} from '../settings/templates-settings';
import DotsComponent from './dots-component';
import { makeSelectListType } from '../selectors';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.dark.blue');

const InspectionTemplates = (props: Object) => {
  const {
    loading,
    itemList,
    pagination,
    totalCount,
    filterProps,
    editTemplate,
    selectedReport,
    openFixedPopup,
    titleSortValues,
    closeFixedPopup,
    tableTitleFilters,
    getItemListRequest,
  } = props;

  const dispatch = useDispatch();

  const listType = useSelector(makeSelectListType());

  const handleConfirmAction = useCallback((guid: string) => {
    dispatch(A.deleteItemRequest([guid]));

    dispatch(closeModal());
  }, []);

  const removeTemplateText = G.getWindowLocale(
    'messages:remove-inspection-template',
    'Are you sure you want delete this template?',
  );

  const handleOpenConfirmation = useCallback((guid: string) => {
    const modal = {
      component: <ConfirmComponent textLocale={removeTemplateText} />,
      options: {
        width: 600,
        controlButtons: [
          {
            type: 'button',
            action: () => handleConfirmAction(guid),
            name: G.getWindowLocale('actions:delete', 'Delete'),
          },
        ],
      },
    };

    dispatch(openModal(modal));
  }, []);

  const actionButtons = [
    {
      iconColor,
      ml: 2,
      iconName: 'eye2',
      // TODO: change inspection permissions later on
      permissions: [PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
      // permissions: [PC.INSPECTION_TEMPLATE_READ, PC.INSPECTION_TEMPLATE_WRITE],
      action: (guid: string) => editTemplate(guid, C.INSPECTION_TEMPLATE_VIEW),
      title: G.getWindowLocale('titles:inspection-view-template', 'View Template'),
    },
    {
      iconName: 'pencil',
      // TODO: change inspection permissions later on
      permissions: [PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
      // permissions: [PC.INSPECTION_TEMPLATE_WRITE],
      enableIfEditable: R.equals(listType, 'listUp'),
      action: (guid: string) => editTemplate(guid, C.INSPECTION_TEMPLATE_EDIT),
      title: G.getWindowLocale('titles:inspection-edit-template', 'Edit Template'),
      disableReason: G.getWindowLocale('titles:inspection-template-in-use', 'Template in use'),
    },
    {
      iconName: 'trash',
      enableIfEditable: true,
      action: handleOpenConfirmation,
      // TODO: change inspection permissions later on
      permissions: [PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
      // permissions: [PC.INSPECTION_TEMPLATE_WRITE],
      title: G.getWindowLocale('titles:inspection-delete-template', 'Delete Template'),
      disableReason: G.getWindowLocale('titles:inspection-template-in-use', 'Template in use'),
    },
  ];

  // TODO: change inspection permissions later on
  const noWrite = G.hasNotAmousCurrentUserPermissions(PC.ROLE_LIUBANCHYK_SUPER_ADMIN);
  // const noWrite = G.hasNotAmousCurrentUserPermissions(PC.INSPECTION_TEMPLATE_WRITE);

  const tableSettings = {
    ...templatesTableSettings,
    ...(noWrite && {
      checkBoxCellWidth: 0,
      allowSelectItems: false,
    }),
  };

  const selectItemHandler = useCallback((guid: string) => {
    dispatch(A.selectItem(guid));
  }, []);

  const handleTableTitleFilter = useCallback((data: Object) => {
    G.handleTableTitleFilter2(data, A, dispatch);

    getItemListRequest();
  }, []);

  const data = {
    loading,
    itemList,
    totalCount,
    pagination,
    actionButtons,
    tableSettings,
    titleSortValues,
    tableTitleFilters,
    handleTableTitleFilter,
    report: selectedReport,
    withResizableColumns: true,
    useNewTitleFilterInputs: true,
    onOptionClick: selectItemHandler,
    allChecked: G.isAllChecked(itemList),
    handleLoadMoreEntities: getItemListRequest,
    columnSettings: inspectionTemplatesColumnSettings,
    renderRightStickedComponent: (item: Object) => (
      <DotsComponent
        item={item}
        openFixedPopup={openFixedPopup}
        closeFixedPopup={closeFixedPopup}
      />
    ),
    filterProps: R.indexBy(
      R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(filterProps),
    ),
  };

  return <Table {...data} />;
};

export default InspectionTemplates;
