// feature template-inspection
// import * as C from '../../constants';
import truckSectionConfig from './truck-section-config';
import trailerSectionConfig from './trailer-section-config';
//////////////////////////////////////////////////

const vehicleTypesConfig = {
  ...truckSectionConfig,
  ...trailerSectionConfig,
};

export default vehicleTypesConfig;
