import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../components/modal/actions';
import { closeLoader, openLoader } from '../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportSagas } from '../../report-common';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature toll charges
import * as A from './actions';
import {
  makeSelectTruckGuid,
  makeSelectDriverGuid,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectTotalCount,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

function* handleGetItemListSaga({ payload }: Object) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader({ showDimmer: true }));
    }

    yield put(A.setListLoading(true));

    const availableReports = yield select(makeSelectAvailableReports());
    const reportParams = yield select(makeSelectUsedReport());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals('itemDefault', G.getGuidFromObject(reportParams)),
    )) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const truckGuid = yield select(makeSelectTruckGuid());
    const driverGuid = yield select(makeSelectDriverGuid());
    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());

    const currentBranchGuid = G.getAmousCurrentBranchGuidFromWindow();

    if (G.isNilOrEmpty(reportParams.fields)) {
      const totalCount = yield select(makeSelectTotalCount());

      const data = {
        pagination,
        totalCount,
        results: [],
      };

      yield put(A.setListLoading(false));

      yield put(closeLoader());

      yield put(A.getItemListSuccess({ data }));
    }

    if (G.isTrue(payload)) yield put(openLoader());

    yield put(A.setListLoading(true));

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const guids = R.pathOr(null, ['guids'], payload);

    const { limit, offset } = pagination;

    const reqBody = {
      guids,
      limit,
      offset,
      orderFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    let options = {
      data: G.setSearchCriteria({ reqBody, filterParams: newFilterParams }),
    };

    if (G.isNotNilAndNotEmpty(truckGuid)) {
      const params = { truckGuid };

      options = R.assoc('params', params, options);
    }

    if (G.isNotNilAndNotEmpty(driverGuid)) {
      const params = { driverGuid };

      options = R.assoc('params', params, options);
    }

    const res = yield call(sendRequest, 'post', endpointsMap.tollChargesList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, guids }));

      const payrollGuidList = R.compose(
        R.uniq,
        R.map(R.prop(GC.FIELD_TOLL_CHARGES_PAYROLL_GUID)),
        R.filter(({ payrollGuid }: Object) => G.isNotNilAndNotEmpty(payrollGuid)),
      )(R.or(data.results, []));

      if (G.isNotNilAndNotEmpty(payrollGuidList)) {
        yield put(A.getDriverPayrollNumberListByGuidsRequest(payrollGuidList));
      }

      const vendorPayrollGuidList = R.compose(
        R.uniq,
        R.map(R.prop(GC.FIELD_VENDOR_PAYROLL_GUID)),
        R.filter(({ vendorPayrollGuid }: Object) => G.isNotNilAndNotEmpty(vendorPayrollGuid)),
      )(R.or(data.results, []));

      if (G.isNotNilAndNotEmpty(vendorPayrollGuidList)) {
        yield put(A.getVendorPayrollNumberListByGuidsRequest(vendorPayrollGuidList));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException(error, 'handleGetItemListSaga exception'));
  } finally {
    yield put(closeLoader());
    yield put(A.setListLoading(false));
  }
}

function* updateDriverTollChargeRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { guid, driverGuid } = payload;

    const endpoint = endpointsMap.updateTollChargeDriver(guid);

    const options = {
      params: { driverGuid },
    };

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateTollChargeSuccess(data));

      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'updateDriverTollChargeRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException(error, 'updateDriverTollChargeRequest exception'));
  }
}

function* updateTruckTollChargeRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { guid, truckGuid } = payload;

    const endpoint = endpointsMap.updateTollChargeTruck(guid);

    const options = {
      params: { truckGuid },
    };

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateTollChargeSuccess(data));

      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'updateTruckTollChargeRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException(error, 'updateTruckTollChargeRequest exception'));
  }
}

function* getDriverListRequest() {
  try {
    const currentBranchGuid = G.getAmousCurrentBranchGuidFromWindow();

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: currentBranchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.listFleetDriversFullNameInTree, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.receivedDriverListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getDriverListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException(error, 'getDriverListRequest exception'));
  }
}

function* getTruckListRequest() {
  try {
    const currentBranchGuid = G.getAmousCurrentBranchGuidFromWindow();

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: currentBranchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.listFleetTrucks, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getTruckListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getTruckListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException(error, 'getTruckListRequest exception'));
  }
}

function* getDriverPayrollNumberListByGuidsRequest({ payload }: Object) {
  try {
    const options = {
      data: payload,
    };

    const res = yield call(sendRequest, 'post', endpointsMap.getDriverPayrollListByGuids, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNilOrEmpty(data)) return;

      yield put(A.receivedDriverPayrollNumberListByGuidsSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'getDriverPayrollNumberListByGuidsRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getDriverPayrollNumberListByGuidsRequest exception');
  }
}

function* getVendorPayrollNumberListByGuidsRequest({ payload }: Object) {
  try {
    const options = {
      data: payload,
    };

    const res = yield call(sendRequest, 'post', endpointsMap.vendorPayrollListByGuids, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNilOrEmpty(data)) return;

      yield put(A.receivedVendorPayrollNumberListByGuidsSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'getVendorPayrollNumberListByGuidsRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getVendorPayrollNumberListByGuidsRequest exception');
  }
}

function* handleExportReportDataSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const truckGuid = yield select(makeSelectTruckGuid());
    const driverGuid = yield select(makeSelectDriverGuid());
    const filterParams = yield select(makeSelectFilterParams());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    let searchCriteria = transformSearchCriteriaBeforeReportPost(payload.searchCriteria);

    if (G.isOneNotNilOrNotEmpty([truckGuid, driverGuid])) {
      const filter = {
        collection: false,
        dataType: 'string',
        operation: 'equal',
        stringValue: R.or(truckGuid, driverGuid),
        propertyName: G.ifElse(G.isNilOrEmpty(truckGuid), GC.FIELD_DRIVER_GUID, GC.FIELD_TRUCK_GUID),
      };

      searchCriteria = R.append(filter, searchCriteria);
    }

    const reqBody = {
      searchCriteria,
      fields: payload.fields,
      orderFields: payload.orderFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      [GC.FIELD_REPORT_NAME]: G.getPropFromObject(GC.FIELD_NAME, payload),
    };

    const options = {
      resType: 'arraybuffer',
      params: { format: payload.fileType },
      data: G.setSearchCriteria({ filterParams: newFilterParams, reqBody }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.tollChargeExportReport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handleExportReportDataSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleExportReportDataSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(
  GC.TOLL_CHARGE_REPORT,
  A,
  handleGetItemListSaga,
  { createUpdateReportSuccessCallback },
);

function* visitTollChargesOnFleetPage({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader());
    yield put(A.setInitialState());

    const { truckGuid, driverGuid } = payload;

    yield put(A.setReportPending());
    yield put(A.setTruckGuid(truckGuid));
    yield put(A.setDriverGuid(driverGuid));

    if (G.isNilOrEmpty(truckGuid)) yield call(getTruckListRequest);
    if (G.isNilOrEmpty(driverGuid)) yield call(getDriverListRequest);

    yield call(getDriverListRequest);
    yield call(handleAvailableReportsRequest, { payload: { [GC.REPORT_TYPE]: GC.TOLL_CHARGE_REPORT } });
    yield call(handleGetItemListSaga, { payload: { openLoader: true } });

    yield put(closeLoader());

    break;
  }
}

function* visitTollChargesPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_TOLL_CHARGES_PAGE);
    yield put(openLoader());

    yield put(A.setInitialState());
    yield put(A.setReportPending());

    yield call(getTruckListRequest);
    yield call(getDriverListRequest);
    yield call(handleAvailableReportsRequest, { payload });
    yield call(handleGetItemListSaga, { payload: { openLoader: true } });

    yield put(closeLoader());

    break;
  }
}

function* tollChargesWatcherSaga() {
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(GC.VISIT_TOLL_CHARGES_PAGE, visitTollChargesPageSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.exportReportDataRequest, handleExportReportDataSaga);
  yield takeLatest(A.visitTollChargesOnFleetPage, visitTollChargesOnFleetPage);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(A.updateTruckTollChargeRequest, updateTruckTollChargeRequest);
  yield takeLatest(A.updateDriverTollChargeRequest, updateDriverTollChargeRequest);
  yield takeLatest(A.getDriverPayrollNumberListByGuidsRequest, getDriverPayrollNumberListByGuidsRequest);
  yield takeLatest(A.getVendorPayrollNumberListByGuidsRequest, getVendorPayrollNumberListByGuidsRequest);
}

export default tollChargesWatcherSaga;
