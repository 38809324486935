import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../../components/confirm';
import { ActionsElement } from '../../../components/actions-element';
// icons
import * as I from '../../../svgs';
// feature
import AuthHoc from '../../permission';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// utilities
import routesMap from '../../../utilities/routes';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.dark.blue');
const permissions = [
  PC.ROLES_WRITE,
  PC.ROLE_LIUBANCHYK_SUPER_ADMIN,
];

const enhance = compose(
  AuthHoc(permissions),
  withHandlers({
    handleRemove: (props: Object) => (roles: Object) => {
      props.closeFixedPopup();
      const confirmationContent = (
        <ConfirmComponent
          name={G.cutString(roles.name, 20, true)}
          textLocale={G.getWindowLocale(
            'messages:before-remove',
            'Are you sure you want to remove')} />
      );
      const modal = {
        component: confirmationContent,
        options: {
          width: '600px',
          height: 'max-content',
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                props.removeRoleRequest([roles.guid]);
                props.closeModal();
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleGoToEditPage: () => (roles: Object) => (
      G.goToRoute(routesMap.getEditRolePage(roles.guid))
    ),
  }),
  pure,
);

const getOptions = (props: Object) => ([
  {
    permissions,
    frontIcon: I.pencil(blueColor),
    text: G.getWindowLocale('actions:edit', 'Edit'),
    action: () => props.handleGoToEditPage(props.roles),
  },
  {
    permissions,
    frontIcon: I.trash(blueColor),
    action: () => props.handleRemove(props.roles),
    text: G.getWindowLocale('actions:delete', 'Delete'),
  },
]);

const RoleActions = (props: Object) => (
  <ActionsElement options={getOptions(props)} />
);

export default enhance(RoleActions);
