import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo, useEffect, useCallback } from 'react';
// components
import { TextComponent } from '../../../components/text';
import { ActionBox } from '../../../components/action-box';
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { makeSelectCurrentUserSettingsFields } from '../../profile/selectors';
import { makeSelectExpandedContainerOpened } from '../../expanded-container/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Flex, scrollableContainerCss3px } from '../../../ui';
// feature task-management
import Task from './';
import {
  makeSelectEntities,
  makeSelectTaskTypesTaskStatuses,
  makeSelectGroupedByTaskTypesTaskStatusMap,
} from '../selectors';
import {
  getTaskTypeListRequest,
  getTaskStatusListRequest,
  createOrUpdateTaskRequest,
  setEntityListByEntityTypeSuccess,
  getGroupedByTaskTypesTaskStatusMapRequest,
} from '../actions';
//////////////////////////////////////////////////

export const RelatedTaskFrom = (props: Object) => {
  const { dispatch, callback } = props;

  const entities = useSelector(makeSelectEntities());
  const taskTypeTaskStatuses = useSelector(makeSelectTaskTypesTaskStatuses());
  const groupedByTaskTypesTaskStatusMap = useSelector(makeSelectGroupedByTaskTypesTaskStatusMap());

  useEffect(() => {
    if (R.isNil(taskTypeTaskStatuses.taskTypes)) {
      dispatch(getTaskTypeListRequest());
      dispatch(getTaskStatusListRequest());
      dispatch(getGroupedByTaskTypesTaskStatusMapRequest());
    }
  }, []);

  return (
    <Task
      {...props}
      {...taskTypeTaskStatuses}
      entities={entities}
      disabledSubObjectFields={true}
      withoutExpandedContainer={true}
      setExpandedContainerOptions={() => {}}
      groupedByTaskTypesTaskStatusMap={groupedByTaskTypesTaskStatusMap}
      setEntityListByEntityTypeSuccess={(...args: Array) => dispatch(setEntityListByEntityTypeSuccess(args))}
      createOrUpdateTaskRequest={(values: Object) => dispatch(createOrUpdateTaskRequest({ ...values, callback }))}
    />
  );
};

const RelatedTasksTableCell = ({ relatedTasks }: Object) => {
  const dispatch = useDispatch();

  const currentUserSettings = useSelector(makeSelectCurrentUserSettingsFields());
  const expandedContainerOpened = useSelector(makeSelectExpandedContainerOpened());

  const commonActions = useMemo(() => ({
    closeModal: () => dispatch(closeModal()),
    openLoader: () => dispatch(openLoader()),
    closeLoader: () => dispatch(closeLoader()),
    openModal: (...args: Array) => dispatch(openModal(...args)),
  }), [dispatch]);

  const handleOpenTask = useCallback(({ guid, taskNumber }: Object = {}) => {
    const component = (
      <RelatedTaskFrom
        {...commonActions}
        taskGuid={guid}
        dispatch={dispatch}
        currentUserSettings={currentUserSettings}
      />
    );

    const title = R.isNil(guid) ?
      G.getWindowLocale('titles:create-task', 'Create Task') :
      `${G.getWindowLocale('titles:task-number', 'Task Number')}: ${taskNumber}`;

    const modal = {
      p: '0px',
      component,
      options: {
        title,
        movable: false,
        overflow: 'auto',
        maxHeight: '90vh',
      },
    };

    commonActions.openModal(modal);
  }, [dispatch]);

  const boxStyles = G.ifElse(
    expandedContainerOpened,
    { mr: '5px', maxWidth: '90%', display: 'block', withEllipsis: true },
    { mr: '5px', maxWidth: '90%' },
  );

  const isExpandedContainerOpenedFromTaskManagementPage = R.and(
    expandedContainerOpened,
    R.includes('task_management', R.path(['location', 'pathname'], window)),
  );

  if (G.isFalse(isExpandedContainerOpenedFromTaskManagementPage)) {
    return (
      <Flex width='100%' overflowY='auto' css={scrollableContainerCss3px}>
        {
          relatedTasks.map(({ guid, taskNumber }: Object) => (
            <ActionBox
              key={guid}
              text={taskNumber}
              boxStyles={boxStyles}
              action={() => handleOpenTask({ guid, taskNumber })}
            />
          ))
        }
      </Flex>
    );
  }

  const taskNumbers = G.createStringFromArrayWithProp(relatedTasks, ', ', GC.FIELD_TASK_NUMBER);

  return <TextComponent {...boxStyles} title={taskNumbers}>{taskNumbers}</TextComponent>;
};

export default RelatedTasksTableCell;
