import { createSelector } from 'reselect';
//////////////////////////////////////////////////

const selectSequenceStore = (state: Object) => state.sequences;

const makeSelectSequencesList = () => createSelector(
  selectSequenceStore,
  ({ sequencesList }: Object) => sequencesList,
);

const makeSelectCurrentSequence = () => createSelector(
  selectSequenceStore,
  ({ currentSequence }: Object) => currentSequence,
);

const makeSelectSequenceTransformers = () => createSelector(
  selectSequenceStore,
  ({ transformers }: Object) => transformers,
);

export {
  selectSequenceStore,
  makeSelectSequencesList,
  makeSelectCurrentSequence,
  makeSelectSequenceTransformers,
};
