import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { PageActions } from '../../../../components/page-actions';
import { ConfirmComponent } from '../../../../components/confirm';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// features
import { AuthWrapper } from '../../../permission';
import ReferenceFormComponent from '../../../reference/components/reference-form';
// hocs
import { withMassActionSubmit } from '../../../../hocs/with-mass-action-submit';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature template-report
import { withCreateOrUpdateForm } from '../hocs';
import { getWritePermissionsByReportType } from '../settings';
import { createReferenceRequest, restoreInheritedSharedAccessorialsRequest } from '../actions';
import {
  makeSelectUsedReport,
  makeSelectSelectedList,
  makeSelectFilterParams,
  makeSelectAdditionalFilters,
} from '../selectors';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');

const selectItemTxtLocale = () => G.getWindowLocale('messages:select-item', 'Please, select at least one item');

const itemNameTextMap = {
  [GC.ITEM_REPORT]: G.getWindowLocale('titles:item', 'Item'),
  [GC.CONTACT_BOOK_REPORT]: G.getWindowLocale('titles:contact', 'Contact'),
  [GC.LOCATION_TEMPLATE_REPORT]: G.getWindowLocale('titles:location', 'Location'),
  [GC.CONTAINER_TEMPLATE_REPORT]: G.getWindowLocale('titles:container', 'Container'),
  [GC.COMPENSATION_TEMPLATE_REPORT]: G.getWindowLocale('titles:compensation', 'Compensation'),
  [GC.SHARED_ACCESSORIAL_REPORT]: G.getWindowLocale('titles:shared-accessorial', 'Shared Accessorial'),
};

const actionsToPickMap = {
  [GC.CLO_TEMPLATE_REPORT]: ['remove'],
  [GC.CONTACT_BOOK_REPORT]: ['remove'],
  [GC.CONTAINER_TEMPLATE_REPORT]: ['remove'],
  [GC.ITEM_REPORT]: ['addReference', 'remove'],
  [GC.COMPENSATION_TEMPLATE_REPORT]: ['remove'],
  [GC.SHARED_ACCESSORIAL_REPORT]: ['restoreInherited'],
  [GC.ROUTE_TEMPLATE_REPORT]: ['laneExport', 'remove'],
  [GC.LOCATION_TEMPLATE_REPORT]: ['addReference', 'remove'],
};

const mainActionToPickMap = {
  [GC.ITEM_REPORT]: 'createForm',
  [GC.CONTACT_BOOK_REPORT]: 'createForm',
  [GC.LOCATION_TEMPLATE_REPORT]: 'createForm',
  [GC.CONTAINER_TEMPLATE_REPORT]: 'createForm',
  [GC.SHARED_ACCESSORIAL_REPORT]: 'createForm',
  [GC.COMPENSATION_TEMPLATE_REPORT]: 'createForm',
};

const actionsMap = ({
  reportType,
  handleLaneExport,
  handleRemoveItems,
  handleAddReference,
  handleCreateOrUpdateTemplateItem,
  restoreInheritedSharedAccessorialsRequest,
}: Object) => ({
  addReference: {
    type: 'massAction',
    action: handleAddReference,
    icon: I.plusRound(whiteColor, 25, 25),
    permissions: getWritePermissionsByReportType(reportType),
    text: G.getWindowLocale('actions:add-reference', 'Add Reference'),
  },
  remove: {
    type: 'massAction',
    action: handleRemoveItems,
    icon: I.trash(whiteColor, 20, 20),
    text: G.getWindowLocale('actions:delete', 'Delete'),
    permissions: getWritePermissionsByReportType(reportType),
  },
  createForm: {
    action: handleCreateOrUpdateTemplateItem,
    permissions: getWritePermissionsByReportType(reportType),
    icon: I.createCloIcon(whiteColor, 40, 40, 'transparent'),
    text: `${G.getWindowLocale('actions:create', 'Create')} ${G.getPropFromObject(reportType, itemNameTextMap)}`,
  },
  restoreInherited: {
    icon: I.arrowUndo(whiteColor, 20, 20),
    action: restoreInheritedSharedAccessorialsRequest,
    permissions: getWritePermissionsByReportType(reportType),
    text: G.getWindowLocale('titles:return-inherited', 'Return Inherited'),
  },
  laneExport: {
    action: handleLaneExport,
    icon: I.gear(whiteColor),
    text: G.getWindowLocale('titles:laneExport', 'Lane Export'),
  },
});

const withRemoveItems = withHandlers({
  handleRemoveItems: (props: Object) => () => {
    const { openModal, selectedList, removeTemplateItemsRequest } = props;

    if (R.isEmpty(selectedList)) return G.showToastrMessageSimple('info', selectItemTxtLocale());

    const component = (
      <ConfirmComponent
        textLocale={G.getWindowLocale(
          'messages:confirm-delete-entities',
          'Are you sure you want to delete these entities?',
        )}
      />
    );
    const modal = {
      component,
      options: {
        width: '600px',
        controlButtons: [
          {
            type: 'button',
            name: G.getWindowLocale('actions:delete', 'Delete'),
            action: () => removeTemplateItemsRequest(selectedList),
          },
        ],
      },
    };

    openModal(modal);
  },
});

const withAddReference = withHandlers({
  handleAddReference: (props: Object) => () => {
    const { openModal, reportType, selectedList, createReferenceRequest } = props;

    if (R.isEmpty(selectedList)) return G.showToastrMessageSimple('info', selectItemTxtLocale());

    const scopesMap = {
      [GC.ITEM_REPORT]: GC.REF_SCOPE_NAME_ITEM,
      [GC.LOCATION_TEMPLATE_REPORT]: GC.REF_SCOPE_NAME_LOCATION,
    };
    const component = (
      <ReferenceFormComponent
        scope={G.getPropFromObject(reportType, scopesMap)}
        submitAction={(values: Object) => createReferenceRequest({ values, selectedList })}
      />
    );
    const modal = G.createAddReferenceModalOptions(component);
    openModal(modal);
  },
});

const PageActionsComponent = (props: Object) => {
  const { reportType, selectedList } = props;

  return (
    <AuthWrapper has={getWritePermissionsByReportType(reportType)}>
      <PageActions
        count={R.length(selectedList)}
        shadowColor={G.getTheme('colors.light.grey')}
        listActions={R.values(R.pick(G.getPropFromObject(reportType, actionsToPickMap), actionsMap(props)))}
        mainAction={R.pathOr(null, [G.getPropFromObject(reportType, mainActionToPickMap)], actionsMap(props))}
      />
    </AuthWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  selectedList: makeSelectSelectedList(state),
});

const withItemAndLocationPageActions = compose(
  connect(mapStateToProps, { createReferenceRequest }),
  withRemoveItems,
  withAddReference,
  withCreateOrUpdateForm,
  pure,
);

const ItemAndLocationPageActions = withItemAndLocationPageActions(PageActionsComponent);

const withCreateOrUpdateAndRemovePageActions = compose(
  connect(mapStateToProps),
  withCreateOrUpdateForm,
  withRemoveItems,
);

const CreateOrUpdateAndRemovePageActions = withCreateOrUpdateAndRemovePageActions(PageActionsComponent);

const withSharedAccessorialPageActions = compose(
  connect(null, { restoreInheritedSharedAccessorialsRequest }),
  withCreateOrUpdateForm,
);

const SharedAccessorialPageActions = withSharedAccessorialPageActions(PageActionsComponent);

const withCloTemplateRowActions = compose(
  connect(mapStateToProps),
  withRemoveItems,
);

const CloTemplatePageActions = withCloTemplateRowActions(PageActionsComponent);

const routeTemplateListLaneExport = async (reqData: Object, props: Object) => {
  const {
    closeModal,
    openLoader,
    closeLoader,
  } = props;

  try {
    openLoader();

    const options = {
      data: reqData,
      resType: 'arraybuffer',
    };

    const res = await sendRequest(
      'post',
      endpointsMap.routeTemplateListLaneExport,
      options,
    );

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res);
    } else {
      G.handleFailResponse(G.convertArrayBufferFailResponse(res), 'routeTemplateListLaneExport fail');
    }

    closeModal();
    closeLoader();
  } catch (error) {
    closeModal();
    closeLoader();

    G.handleException('error', 'routeTemplateListLaneExport exception');
  }
};

const mapStateToPropsRoute = (state: Object) => createStructuredSelector({
  selectedList: makeSelectSelectedList(state),
  filterParams: makeSelectFilterParams(state),
  selectedReport: makeSelectUsedReport(state),
  additionalFilters: makeSelectAdditionalFilters(state),
});

const withRouteTemplateRowActions = compose(
  connect(mapStateToPropsRoute),
  withMassActionSubmit,
  withRemoveItems,
  withHandlers({
    handleLaneExport: (props: Object) => () => {
      const {
        filterParams,
        selectedList,
        selectedReport,
        additionalFilters,
        handleMassActionSubmit,
      } = props;

      const guids = selectedList;

      const noSelected = R.isEmpty(guids);

      const submitAction = (data: Object) => {
        const { guids } = data;

        let reqData = {
          ...data,
          currentEnterprise: G.getAmousCurrentBranchGuidFromWindow(),
        };

        if (G.isNilOrEmpty(guids)) {
          const reqBody = {
            ...reqData,
            ...additionalFilters,
            searchCriteria: G.getOrElse(selectedReport, 'searchCriteria', []),
          };

          reqData = G.setSearchCriteria({ reqBody, filterParams });
        }

        routeTemplateListLaneExport(reqData, props);
      };

      handleMassActionSubmit({
        noSelected,
        submitAction,
        formValues: { guids },
      });
    },
  }),
);

const RouteTemplatePageActions = withRouteTemplateRowActions(PageActionsComponent);

const TemplatePageActions = (props: Object) => {
  const { reportType } = props;

  const componentMap = {
    [GC.ITEM_REPORT]: <ItemAndLocationPageActions {...props} />,
    [GC.CLO_TEMPLATE_REPORT]: <CloTemplatePageActions {...props} />,
    [GC.LOCATION_TEMPLATE_REPORT]: <ItemAndLocationPageActions {...props} />,
    [GC.ROUTE_TEMPLATE_REPORT]: <RouteTemplatePageActions {...props} />,
    [GC.CONTACT_BOOK_REPORT]: <CreateOrUpdateAndRemovePageActions {...props} />,
    [GC.SHARED_ACCESSORIAL_REPORT]: <SharedAccessorialPageActions {...props} />,
    [GC.CONTAINER_TEMPLATE_REPORT]: <CreateOrUpdateAndRemovePageActions {...props} />,
    [GC.COMPENSATION_TEMPLATE_REPORT]: <CreateOrUpdateAndRemovePageActions {...props} />,
  };

  return G.getPropFromObject(reportType, componentMap);
};

export default TemplatePageActions;
