import * as R from 'ramda';
import styled, { css } from 'styled-components';
// helpers/constants
import * as G from '../../../helpers';
// forms
import { Checkbox } from '../../../forms/formik/fieldset2/ui';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const SwitchableFlex = styled(Flex)`
  ${({ disabled }: Object) => (
    R.and(
      disabled,
      `
        opacity: 0.5;
        pointer-events: none;
      `,
    )
  )}
`;

const ComponentUI = styled(Flex)`
  width: 100%;
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  padding: 10px 12px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${G.getTheme('colors.dark.lightGrey')};

  & > :last-child {
    visibility: hidden;
  }

  &:hover > :last-child {
    visibility: visible;
  }

  ${({ disabled }: Object) => (
    R.and(
      disabled,
      `
        opacity: 0.5;
        cursor: not-allowed;
      `,
    )
  )}
`;

const ActiveWrapper = styled(Box)`
  width: 100%;

  ${({ inactive }: Object) => inactive && `
    pointer-events: none;
  `}
`;

const SectionUI = styled(Flex)`
  gap: 10px;
  width: 100%;
  display: flex;
  border-radius: 8px;
  align-items: center;
  flex-direction: column;
  padding: 10px 12px 14px;
  justify-content: space-between;
  background-color: ${() => G.getTheme('colors.light.mainLight')};

  & > :last-child {
    height: ${({ collapsed }: Object) => G.ifElse(collapsed, 0, 'auto')};
  }

  ${({ inactive }: Object) => inactive && `
    opacity: 0.6;
  `}
`;

const SectionComponentUI = styled(SectionUI)`
  z-index: 2;
  position: relative;
  background-color: ${() => G.getTheme('colors.light.lightGrey')};

  & > :last-child {
    height: ${({ collapsed }: Object) => G.ifElse(collapsed, 0, 'auto')};
  }
`;

const CheckboxUI = styled(Checkbox)`
  &:before {
    left: 0;
    top: 50%;
    content: '';
    width: 17px;
    height: 17px;
    border-radius: 3px;
    position: absolute;
    transform: translate(0, -50%);
    background-color: ${() => G.getTheme('forms.checkboxes.bgColor')};
    border: 1px solid ${() => G.getTheme('forms.checkboxes.borderColor')};
  }
`;

const Divider = styled(Box)`
  height: 0;
  width: 100%;
  margin: 4px 0;
  border: 1px solid ${() => G.getTheme('colors.lightGrey')};
`;

const MoveHandler = styled(Box)`
  top: 1px;
  outline: none;
  position: relative;

  ${({ disabled }: Object) => disabled && `
    opacity: 0.5;
    pointer-events: none;
  `}
`;

const VehicleComponentCircle = styled(Flex)`
  width: 36px;
  height: 36px;
  cursor: pointer;
  border-width: 2px;
  border-radius: 50%;
  position: absolute;
  align-items: center;
  border-style: solid;
  pointer-events: all;
  justify-content: center;
  color: ${() => G.getTheme('colors.dark.blue')};
  border-color: ${() => G.getTheme('colors.lightGrey')};
  background-color: ${() => G.getTheme('colors.bgGrey')};

  ${({ filled }: Object) => filled && css`
    & {
      color: ${() => G.getTheme('colors.white')};
      border-color: ${() => G.getTheme('colors.bgGrey')};
      background-color: ${() => G.getTheme('colors.softGreen')};
    }
  `}

  ${({ error }: Object) => error && css`
    & {
      color: ${() => G.getTheme('colors.dark.mainRed')};
      border-color: ${() => G.getTheme('colors.dark.mainRed')};
    }
  `}

  ${({ active }: Object) => active && css`
    & {
      color: ${() => G.getTheme('colors.white')};
      border-color: ${() => G.getTheme('colors.dark.blue')};
      background-color: ${() => G.getTheme('colors.dark.blue')};
    }
  `}
`;

const VehicleImageBox = styled(Flex)`
  position: relative;

  ${({ flip }: Object) => flip && css`
    & {
      transform: scaleX(-1);
    }
  `}
`;

const IconBox = styled(Box)`
  & > div:nth-child(2) {
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    pointer-events: none;
    justify-content: center;

    svg {
      filter: drop-shadow(0px 0px 2px ${() => G.getTheme('colors.black')});
    }
  }
  ${({ isActive }: Object) => isActive && css`
    &:hover > div:nth-child(2) {
      opacity: 1;
      transition: 0.3s;
    }
  `}
`;

export {
  Divider,
  IconBox,
  SectionUI,
  CheckboxUI,
  ComponentUI,
  MoveHandler,
  ActiveWrapper,
  SwitchableFlex,
  VehicleImageBox,
  SectionComponentUI,
  VehicleComponentCircle,
};

