import React from 'react';
// helpers/constants
import * as G from '../../../../helpers';
// ui
import { Flex, ActionButton, CancelButton } from '../../../../ui';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');
const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

const defaultStyles = {
  height: 32,
  width: 120,
  p: '4px 8px',
  fontSize: 15,
  bgColor: 'none',
  background: 'none',
  border: '1px solid',
  borderRadius: '5px',
};

const cancelStyles = {
  ...defaultStyles,
  textColor: greyMatterhornColor,
  borderColor: greyMatterhornColor,
};

const submitStyles = {
  ...defaultStyles,
  textColor: darkBlueColor,
  borderColor: darkBlueColor,
};

const ChooseIcon = (props: Object) => (
  <ActionButton
    {...submitStyles}
    p={0}
    width={80}
    height={22}
    minWidth={80}
    fontSize={14}
    type='button'
    onClick={props.action}
  >
    {G.getWindowLocale('actions:inspection-component-add-icon', 'Add Icon')}
  </ActionButton>
);

const FooterActions = (props: Object) => (
  <Flex width='100%' justifyContent='space-between'>
    <CancelButton
      {...cancelStyles}
      type='button'
      onClick={props.cancelAction}
    >
      {G.getWindowLocale('actions:cancel', 'Cancel')}
    </CancelButton>
    <ActionButton
      {...submitStyles}
      type='button'
      disabled={props.disabled}
      onClick={props.submitAction}
      cursor={props.disabled && 'not-allowed'}
    >
      {G.getWindowLocale('actions:submit-inspection-template', 'Submit')}
    </ActionButton>
  </Flex>
);

export {
  ChooseIcon,
  FooterActions,
};
