import * as R from 'ramda';
import { createSelector } from 'reselect';
// report-common
import { getReportSelectors } from '../../report-common';
//////////////////////////////////////////////////

const {
  store,
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectTotalCount,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} = getReportSelectors('user');

const makeSelectAvailableByTypeRoleList = () => createSelector(
  store,
  ({ availableByTypeRoleList }: Object) => availableByTypeRoleList,
);

const makeSelectSelectedUserList = () => createSelector(
  store,
  (state: Object) => R.compose(
    R.keys,
    R.filter(({ selected }: boolean) => selected),
    R.pathOr([], ['itemList']),
  )(state),
);

export {
  store,
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectTotalCount,
  makeSelectPagination,
  makeSelectListLoading,
  makeSelectPageVisited,
  makeSelectFilterParams,
  makeSelectReportStatus,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectSelectedUserList,
  makeSelectTableTitleFilters,
  makeSelectAvailableByTypeRoleList,
};
